import React, { useState, useRef } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import notes from "./noteEdit.svg";
import plusIcon from "../../../../../assets/icons/add.png";
import useStyles from "../styles";
export default function DataReviewTwo() {
  var classes = useStyles();
  const [fields, setFields] = useState([{ value: null }]);

  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    setFields(values);
  }

  const addTextField = () => {
    const values = [...fields];
    values.push({ value: null });
    setFields(values);
  };
  //

  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />

        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Please list the Data Sources that you have reviewed to confirm the
            potential impacts on patients from Equality and Health Inequality
            Groups
          </Typography>

          {fields.map((field, idx) => (
            <TextField
              size="small"
              style={{ marginTop: "4px" }}
              className={classes.txtForm}
              fullWidth
              placeholder="Text"
              variant="outlined"
              value={field.value || ""}
              onChange={(e) => handleChange(idx, e)}
            />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          style={{
            marginTop: "20px",
            display: "flex",
            marginLeft: "4px",
            cursor: "pointer",
          }}
          onClick={() => addTextField()}
        >
          <img src={plusIcon} />
          <Typography className={classes.formHeading}>Add More</Typography>
        </Grid>
      </Grid>
    </>
  );
}
