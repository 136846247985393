import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "./styles";
// API data imports
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { toggleProjectRepoList } from "../../store/actions/ehiiaAction";
import Loader from "../../../component/common/loader/Loader";
export default function RemoveRepositoryPopup({
  onHide,
  repositoryModalShow,
  setRepositoryModalShow,
  projectIdRec,
  
}) {
  //
  console.log("repositoryModalShow", repositoryModalShow);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();

  //  const [repositoryModalShow, setRepositoryModalShow] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setRepositoryModalShow(false);
    setOpen(false);
  };
  const submitHandler = (mode) => {
    setOpen(false);
    return dispatch(
      toggleProjectRepoList({
        projectId: projectIdRec,
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        setSuccessMessage(payload?.message);
        setRepositoryModalShow(false);
        setIsShow(true);
        setErrorApiMessage(null);
      } else {
        setErrorApiMessage(payload?.message);
        setIsShow(true);
        setRepositoryModalShow(false);
      }
    });
  };
 
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={repositoryModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText style={{ borderRadius: "5px" }}>
            <center>
            <img src={alertIcon} alt="alert-icon" />
            <Typography style={{ fontSize: "16px", fontWeight: "500" }}>
              Are you sure, you want to remove project to project <br />
              repository?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Project Repository",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
