import React from 'react'
import ProjectList from '../../components/Project/ProjectList'

export default function Project() {
  return (
    <div>
      <ProjectList/>
    </div>
  )
}
