import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  tableCell: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily:'Poppins'
  },
  imgAvtar: {
    marginRight: "10px",
  },
  tableHeadCell: {
    fontSize: "14px !important",
    fontWeight: 500,
    // textAlign: "center",
    fontFamily:'Poppins',
    color:'#201F1E'
  },
  tableBodyCell: {
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily:'Poppins'
  },
}));
