import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Fade,
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Button,
  DialogContent,
  DialogContentText,
  Checkbox,
  ListItemText,
  IconButton,
} from "@material-ui/core";

import Popup from "../../../component/common/modal/Popup";
import editIcon from "../../../images/imgEdit.svg";
// styles
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../hooks/Validation";
import {
  createUpdateClient,
  checkSubdomain,
} from "../../store/actions/clientsAction";
import { fetchAllEhiiaVersionList } from "../../store/actions/ehiiasAction";
// import { fetchVersionList } from "../../../store/actions/versionsAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../component/common/loader/Loader";
import { useToasts } from "react-toast-notifications";
const formElements = [
  {
    key: "name",
    label: "Organisation",
    placeHolder: "Enter client organisation name",
    type: "text",
  },
  {
    key: "subdomain",
    label: "Short Name",
    placeHolder: "Enter client organisation short name",
    type: "text",
  },
  {
    key: "contactPerson",
    label: "Admin",
    placeHolder: "Enter contact person name",
    type: "text",
  },
  {
    key: "email",
    label: "Email ID",
    placeHolder: "Enter client email",
    type: "text",
  },
  {
    key: "module",
    label: "Add Module",
    placeHolder: "Select module",
    type: "select",
    options: ["EHIA"],
  },
  {
    key: "ehiiaVersionId",
    label: "Add Version",
    placeHolder: "Select version",
    type: "select",
    options: [],
  },
];
export default function ManageClient(props) {
  //
  console.log("props-24", props);
  const { selectclient } = props;
  const { addToast } = useToasts();
  const [isUploading, setIsUploading] = useState(false);
  console.log("selectclient", selectclient);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [moduleIdForVersion, setModuleIdForVersion] = useState(null);

  const [moduleOption, setModuleOption] = useState(null);

  const [versionOption, setVersionOption] = useState(null);
  const [versionDataValue, setVersionDataValue] = React.useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [subDomainSuccess, setSubDomainSuccess] = useState(null);
  const [subDomainError, setSubDomainError] = useState(null);
  const inputRef = useRef();
  var classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // for version
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setVersionDataValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const [clientDetail, setClientDetail] = useState({
    name: null,
    subdomain: null,
    contactPerson: null,
    email: null,
    ehiiaVersionId: null,
    module: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: clientDetail?.name,
      subdomain: clientDetail?.subdomain,
      contactPerson: clientDetail?.contactPerson,
      email: clientDetail?.email,
      ehiiaVersionId: clientDetail?.ehiiaVersionId,
      module: clientDetail?.module,
    });
  const dispatch = useDispatch();
  const { list } = useSelector((state) => {
    return state.ehiias;
  });
  const { versions, filtered, pagination } = useSelector((state) => {
    return state.versions;
  });
  useEffect(() => {
    if (!Array.isArray(list)) {
      dispatch(fetchAllEhiiaVersionList());
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(list)) {
      console.log("list", list);
      setModuleOption(list);
    }
  }, [list]);

  // useEffect(() => {
  //   console.log("moduleIdForVersion", moduleIdForVersion);
  //   dispatch(fetchVersionList({ moduleId: moduleIdForVersion })).then(
  //     ({ payload }) => {
  //       console.log("data", payload);
  //       if (payload?.success) {
  //         setVersionOption(payload.data.versions);
  //       }
  //     },
  //   );
  // }, [moduleOption, moduleIdForVersion]);

  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);

  useEffect(() => {
    console.log("selectclient useEffect", selectclient);
    if (selectclient === null) {
      setClientDetail({
        name: null,
        subdomain: null,
        contactPerson: null,
        email: null,
        ehiiaVersionId: null,
        module: null,
      });
    } else {
      setClientDetail({
        orgId: selectclient?.orgId,
        name: selectclient?.name,
        subdomain: selectclient?.subdomain,
        contactPerson: selectclient?.contactPerson,
        email: selectclient?.email,
        ehiiaVersionId: selectclient?.ehiiaVersionId,
        module: selectclient?.module,
      });
    }
  }, [selectclient]);
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    console.log("versionDataValue", versionDataValue);
    // console.log(JSON.parse(versionDataValue));
  }, [versionDataValue]);
  //api call
  useEffect(() => {
    console.log("versions", versionDataValue);

    setIsUploading(true);
    console.log("file logo", logo);
    let formData = new FormData();

    if (clientDetail.orgId) {
      formData.append("orgId", clientDetail.orgId);
    }
    formData.append("email", clientDetail.email);
    formData.append("contactPerson", clientDetail.contactPerson);
    formData.append("name", clientDetail.name);
    formData.append("subdomain", clientDetail.subdomain);
    formData.append("ehiiaVersionId", clientDetail.ehiiaVersionId);
    // for (let i = 0; i < versionDataValue.length; i++) {
    //   formData.append("versions", versionDataValue[i]);
    // }
    // formData.append("versions", versionDataValue);

    if (logo) {
      formData.append("logo", logo);
    }
    console.log("formData-", ...formData);

    if (
      !isError() &&
      error !== null &&
      isActionCalled &&
      subDomainError === null
    ) {
      dispatch(createUpdateClient(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setIsUploading(true);
          setSuccessMessage(payload?.message);
          setErrorApiMessage(null);
          hideHandler();
          setLogoUrl(null);
          setLogo(null);
          // setIsShow(true);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.message);
          // setIsShow(true);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
        }
      });
      setIsActionCalled(false);
      setLogoUrl(null);
      console.log("inputRef", inputRef);
      inputRef.current.value = "";
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  // Form cancel Button
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    props.onHide();

    if (selectclient === null) {
      setClientDetail({
        name: null,
        subdomain: null,
        contactPerson: null,
        email: null,
        ehiiaVersionId: null,
        module: null,
      });
      setLogoUrl(null);
      setLogo(null);
      setSubDomainError(null);
      setSubDomainSuccess(null);
    } else {
      setClientDetail({
        orgId: selectclient?.orgId,
        name: selectclient?.name,
        subdomain: selectclient?.subdomain,
        contactPerson: selectclient?.contactPerson,
        email: selectclient?.email,
        ehiiaVersionId: selectclient?.ehiiaVersionId,
        module: selectclient?.module,
      });
      setLogoUrl(logo);
    }
    console.log("clientDetail", clientDetail);
    console.log("logo", logo);
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
    setLogoUrl(null);
  };
  // logo upload
  const upload = (e) => {
    setIsWrongFileType(false);
    console.log(e.target.files[0]);
    var fileName = e.target.files[0]?.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
      setLogo(e.target.files[0]);
    } else {
      console.log("Only jpg/jpeg and png files are allowed!");

      setIsWrongFileType(true);
      setTimeout(() => {
        setIsWrongFileType(false);
      }, 3000);
    }
  };
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => hideHandler(e)}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {/* Avtar */}
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.avatarContainer}>
                <center>
                  {logoUrl ? (
                    <div
                    className={classes.avatar}
                    style={{
                      width: "72px",
                      height: "72px",
                      borderRadius:'50%',
                      border: "1px solid #03997B",
                      overflow:'hidden'
                    }}
                    src={logoUrl}
                  >
                    <img
                      src={logoUrl}
                      className="MuiAvatar-img"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      
                      }}
                      alt="Avatar Image"
                    />
                  </div>
                  ) : (
                    <Avatar
                      className={classes.avatar}
                      style={{ width: "72px", height: "72px" }}
                      src="/broken-image.jpg"
                    />
                  )}
                  <IconButton
                    className={classes.uploadIcon}
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      onChange={(e) => upload(e)}
                      ref={inputRef}
                      name="myImage"
                      accept="image/*"
                      type="file"
                    />
                    <img src={editIcon} alt="edit" />
                  </IconButton>
                </center>
              </Grid>

              {/* <Grid item xs={10} className={classes.gridBox}>
                <Button
                  variant="contained"
                  className={classes.blueButton}
                  component="label"
                  disableElevation
                >
                  Add Logo
                  <input
                    hidden
                    onChange={(e) => upload(e)}
                    ref={inputRef}
                    name="myImage"
                    accept="image/*"
                    type="file"
                  />
                </Button>
              </Grid> */}
            </Grid>
            {isLoader && <Loader />}
            {/* org name */}
            <Grid container spacing={2}>
              {formElements.map((element, i) => {
                return (
                  (!selectclient || element.key !== "password") && (
                    <Grid item xs={12}>
                      <div key={i}>
                        <Typography className={classes.formHeading}>
                          {element.label}
                        </Typography>
                        {element.type === "select" ? (
                          <>
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                value={
                                  clientDetail && clientDetail[element.key]
                                }
                                onChange={async (e) => {
                                  const selectedValue = e.target.value;
                                  setClientDetail({
                                    ...clientDetail,
                                    [element.key]: selectedValue,
                                  });
                                  resetValidator(e.target.name);
                                  setModuleIdForVersion(selectedValue); // Assuming this is where you want to store the selected option's ID
                                  console.log(
                                    "moduleIdForVersion",
                                    moduleIdForVersion,
                                  );
                                }}
                                isInvalid={error && error[element.key]}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {clientDetail.module != null &&
                                element.key === "ehiiaVersionId" &&
                                moduleOption
                                  ? moduleOption.map((item) => (
                                      <MenuItem
                                        key={item.versionId}
                                        value={item.versionId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                            <Fade in={error}>
                              <Typography className={classes.errorMessage}>
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </>
                        ) : element.type === "multiselect" ? (
                          <FormControl
                            fullWidth
                            className={classes.dropdownFormControl}
                            style={{ padding: "4px" }}
                          >
                            <Select
                              multiple
                              style={{ fontSize: "12px" }}
                              value={versionDataValue}
                              onChange={handleChange}
                              className={classes.selectDropdown}
                              renderValue={(selected) =>
                                selected
                                  .map((value) => {
                                    const selectedItem = versionOption.find(
                                      (item) => item.versionId === value,
                                    );
                                    return selectedItem
                                      ? selectedItem.name
                                      : "";
                                  })
                                  .join(", ")
                              }

                              // MenuProps={MenuProps}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {element.key === "versions" && versionOption
                                ? versionOption.map((item) => (
                                    <MenuItem
                                      key={item.versionId}
                                      value={item.versionId}
                                    >
                                      <Checkbox
                                        checked={
                                          versionDataValue.indexOf(
                                            item.versionId,
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={item.name} />
                                    </MenuItem>
                                  ))
                                : element.options.map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <>
                            <TextField
                              hiddenLabel
                              size="small"
                              type={element.type}
                              name={element.key}
                              placeholder={element.placeHolder}
                              className={classes.txtForm}
                              value={
                                clientDetail &&
                                clientDetail[element.key] === null
                                  ? ""
                                  : clientDetail && clientDetail[element.key]
                              }
                              // value={clientDetail && clientDetail[element.key]}
                              fullWidth
                              // defaultValue={clientDetail && clientDetail[element.key]}
                              variant="outlined"
                              onChange={async (e) => {
                                // Call the checkSubdomain API here
                                if (
                                  element.key === "subdomain" &&
                                  e.target.value.length > 0
                                ) {
                                  const newName = e.target.value;
                                  dispatch(
                                    checkSubdomain({ subdomain: newName }),
                                  ).then(({ payload }) => {
                                    console.log("payload", payload);
                                    // Handle the API response as needed
                                    if (payload?.success) {
                                      // Subdomain is available
                                      setSubDomainSuccess(payload?.message);
                                      setSubDomainError(null);
                                    } else {
                                      // Subdomain is not available or there was an error
                                      setSubDomainError(payload?.message);
                                      setSubDomainSuccess(null);
                                    }
                                  });
                                }

                                setClientDetail({
                                  ...clientDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);

                                console.log("clientDetail", clientDetail);
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            {error && error[element.key] ? (
                              <Fade in={error}>
                                <Typography
                                  color="secondary"
                                  className={classes.errorMessage}
                                >
                                  {error && error[element.key]}
                                </Typography>
                              </Fade>
                            ) : (
                              element.key === "subdomain" && (
                                <>
                                  {subDomainError ? (
                                    <Typography
                                      color="secondary"
                                      className={classes.errorMessage}
                                    >
                                      {subDomainError}
                                    </Typography>
                                  ) : (
                                    subDomainSuccess && (
                                      <Typography
                                        color="secondary"
                                        className={classes.successMessage}
                                      >
                                        {subDomainSuccess}
                                      </Typography>
                                    )
                                  )}
                                </>
                              )
                            )}
                          </>
                        )}
                      </div>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
        {/* message popup */}
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Manage Client",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
