import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Select,
  FormControl,
} from "@material-ui/core";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { MoreVert as MoreIcon } from "@material-ui/icons";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";

// data
import mock from "./mock";
// styles
import useStyles from "./styles";
import ManageClient from "./ManageClient";
import TableDesign from "../../../component/common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
export default function ReportList() {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/client-details`);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Client Organisation Name",
      align: "left !important",
    },
    {
      label: "Client Organisation Short Name",
      align: "left",
    },
    {
      label: "Contact Person Name",
      align: "left",
    },
    {
      label: "Client Email",
    },
    {
      label: "Assigned Module",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  //
  const [value, setValue] = React.useState("");
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleResendLink = () => {
    setToggleActivateModalShow(true);

    setAnchorEl(null);
  };
  const handleDelete = () => {
    setDeleteModalShow(true);
    setAnchorEl(null);
  };

  return (
    <>
      <ManageClient
        open={open}
        onHide={hideHandle}
        title={"Add Client"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />
      <PageTitle title="Reports" search="true" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {mock.table && mock.table.length ? (
                mock.table.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() =>
                            history.push(`/app/client-details/${row._id}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <span className={classes.tableCell}>
                            <Avatar
                              className={classes.imgAvtar}
                              sx={{ width: 68, height: 68 }}
                              src="/broken-image.jpg"
                            />
                            {row.org_name}
                          </span>
                        </TableCell>
                        <TableCell
                          align="left"
                          onClick={() =>
                            history.push(`/app/client-details/${row._id}`)
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.org_shortname}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.module}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.isActivated ? (
                            row.status === "ACTIVE" ? (
                              <span
                                style={{ color: "#10940D", fontWeight: 500 }}
                              >
                                Active
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>Inactive</span>
                            )
                          ) : (
                            <span style={{ color: "#F8A80C" }}>Pending</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                            <MenuItem onClick={handleResendLink}>
                              Resend activation link
                            </MenuItem>
                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
        </Grid>
      </Grid>
    </>
  );
}
