import { FullscreenExit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(-1),
    marginTop:theme.spacing(2)
  },
  btnGroup:{
    display:'flex',
    justifyContent:'flex-end',
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(2),
},
txtForm: {
  marginTop: theme.spacing(0.5),
  padding: "0px 4px",
},
leftButton:{
  fontFamily:'Poppins',
  fontSize:'14px',
  fontWeight:400,
  textTransform:'none',
  height:'38px',
  borderRadius:'2px'
},
rightButton:{
  fontFamily:'Poppins',
  fontSize:'14px',
  fontWeight:500,
  marginLeft:theme.spacing(2),
  textTransform:'none',
  height:'38px',
  borderRadius:'2px'
},
warningMessage:{
  fontSize:'12px',
  textAlign:'left',
  '& p':{
    display:'flex',
    
    '&$invalid':{
      display:'flex',
      color:'#3734A9'
    },
    '&$valid':{
      display:'flex',
      color:'#03997B',
    }
  },
  
},
required:{
  color:'red'
},
errorMessage:{
  fontSize:'14px',
  color:'red',
  textAlign:'left'
},
errorMessage2:{
  fontSize:'14px',
  color:'red',
  textAlign:'center',
  marginTop:'6px'
},
eyeIcon: {
  position: 'relative',
  height: '35px',
  padding: '4px 10px',
  cursor: 'pointer',
  zIndex:'999',
  marginTop:'-40px',
  left:'90%',
  [theme.breakpoints.down("md")]: {
    left:'85%',
  }
}

}));
