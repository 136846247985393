import React from 'react'

import ApprovalDone from '../../component/Reports/ApprovalDone'

export default function ApprovalDonePage() {
  return (
    <>
    <ApprovalDone/>
    </>
  )
}
