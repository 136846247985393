import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdateVersion,
  fetchVersionList,
  deleteVersion,
  updateVersion,
  getVersionByVersionId,
  toggleActivation,
  fetchVersionListByModuleId
} from "../actions/versionsAction";

export const versions = createSlice({
  name: "versions",
  initialState: {
    versions: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchVersionList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchVersionList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    },
    [fetchVersionList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [fetchVersionListByModuleId.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchVersionListByModuleId.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    },
    [fetchVersionListByModuleId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [createUpdateVersion.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateVersion.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateVersion.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    // update
    [updateVersion.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [updateVersion.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [updateVersion.rejected]: (state, { payload }) => {
      console.log("payload-clients", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [deleteVersion.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [deleteVersion.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [deleteVersion.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [getVersionByVersionId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getVersionByVersionId.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.error = null;
      state.isRecordLoaded = true;
      state.isLoader = false;
    },
    [getVersionByVersionId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [toggleActivation.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [toggleActivation.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } = versions.actions;

export default versions.reducer;
