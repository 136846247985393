import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
  Button,
} from "@material-ui/core";

import {
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";

import TableDesign from "../../../component/common/table/TableDesign";
// components

import TableWidget from "../../../component/Widget/TableWidget";
import { fetchEhiiaVersionList } from "../../store/actions/ehiiasAction";
import { resetPopup } from "../../store/reducerSlice/ehiias";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";

import classNames from "classnames";
// styles
import useStyles from "./styles";

import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";

const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function EhiiaList() {
  var classes = useStyles();
  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Version ID",
    },
    {
      label: "Version Name",
    },
    {
      label: "Created Date",
    },
    // {
    //   label: "Created By",
    // },

    {
      label: "Updated Date",
    },
    // {
    //   label: "Updated By",
    // },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const [selectRow, setSelectRow] = useState(null);

  const [selectmodule, setSelectmodule] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [moduleDetail, setModuleDetail] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [anchorE2, setAnchorE2] = React.useState(null);
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  const [filterOption, setFilterOption] = useState({
    // moduleId: moduleId,
    page: 1,
    search: "",
    orderBy: "email",
    orderIn: -1,
  });
  const [message, setMessage] = useState(null);

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.ehiias;
  });
  const page = pagination?.currentPage;

  const cc = list || filtered;

  useEffect(() => {
    console.log("list", list);
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);
    console.log("list", list);
    console.log("filterOption", filterOption);
    dispatch(fetchEhiiaVersionList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setSelectmodule(null);
    setOpen(false);
    setToggleActivateModalShow(false);
  };

  const toggleActivateEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateRec(rec?.versionId);

    setToggleActivateModalShow(true);
    if (rec?.isActive) {
      setTitle("Deactivate Version");
      setMessage("Version deactivated successfully!");
    } else {
      setTitle("Activate Version");
      setMessage("Version activated successfully!");
    }
    // setShowResult(true);
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
      {/* <ManageEhiia
        open={open}
        onHide={hideHandle}
        selectmodule={selectmodule}
        title={"Add New Version"}
      /> */}
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />

      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          {/* {moduleDetail?.name} */}
          Module
        </Typography>
        <div className={classes.groupBtn}>
          {/* <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div> */}
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of Ehiia Module",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          className={classes.tableCell}
                          onClick={() =>
                            navigate(
                              `/super-admin/ehiia-details/${row.versionId}`,
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {row.versionId}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            navigate(
                              `/super-admin/ehiia-details/${row.versionId}`,
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableCell}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {formatedDate(row.createdAt)}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          {row?.createdBy?.firstName} {row?.createdBy?.lastName}
                        </TableCell> */}

                        <TableCell className={classes.tableCell}>
                          {formatedDate(row.updatedAt)}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          {row?.updatedBy?.firstName} {row?.updatedBy?.lastName}
                        </TableCell> */}
                        <TableCell className={classes.tableCell}>
                          {row.isActive ? (
                            <span style={{ color: "#10940D", fontWeight: 500 }}>
                              Active
                            </span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() =>
                                navigate(
                                  `/super-admin/ehiia-edit/${selectRow?.versionId}`,
                                )
                              }
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Edit
                            </MenuItem>
                            {selectRow?.isActive ? (
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  toggleActivateEventHandler(selectRow)
                                }
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Deactivate
                              </MenuItem>
                            ) : (
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  toggleActivateEventHandler(selectRow)
                                }
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Activate
                              </MenuItem>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
