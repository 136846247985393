import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Link,
  Fade,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import Validation from "../../hooks/Validation";
import classnames from "classnames";

// styles
import useStyles from "../../../assets/login/styles";
import production from "../../../images/provider.png";
// logo
import logo from "../../../images/logo.png";
import topDot from "../../../assets/icons/top_dot.svg";
import spin from "../../../assets/icons/spin.svg";
import curve from "../../../assets/icons/curve.svg";
import bottomDot from "../../../assets/icons/bottom_dot.svg";

// context
// import { useUserDispatch, loginUser } from "../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
// api
import { resetError } from "../../store/reducerSlice/admin";
import { adminLogin } from "../../store/actions/adminAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import ehiiaLogo from "../../../assets/icons/ehiia_logo.svg";
import { getOrgData } from "../../../store/actions/reviewsAction";
import { useToasts } from "react-toast-notifications";
function Login() {
  var classes = useStyles();
  const location = useLocation();
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({ email, password });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailValue = searchParams.get("email");

    setEmail(emailValue);
  }, [location]);
  const {
    isAdminLoggedIn,
    adminDetail,
    error: apiError,
  } = useSelector((state) => state.admin);

  let clientAdminPath = "";

  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    clientAdminPath = pathSegments[1]; // Assuming "admin" is the first segment of the path
    console.log("Client Admin Path 4:", clientAdminPath);
  }, []);
  useEffect(() => {
    if (isAdminLoggedIn)
      if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
        navigate(`/admin/dashboard`);
      } else if (clientAdminPath === "super-admin") {
        navigate(`/super-admin/dashboard`);
      }

    return () => {
      dispatch(resetError());
    };
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      console.log("api hitted");
      dispatch(adminLogin({ email, password })).then((payload) => {
        console.log("payload-", payload);
        if (!payload?.payload?.data?.success) {
          // setIsShow(true);

          const toastHeight = payload?.payload?.data?.message.length > 35 ? 'auto' : '34px';
          addToast(payload?.payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          setErrorApiMessage(payload?.payload?.data?.message);
        } else {
          console.log("success");
          navigate(`/admin/dashboard`);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  const hideHandlerRes = () => {
    setIsShow(false);
  };

  return (
    <>
      <section
       className={classes.mainSectionContainer}
      >
        <div
          style={{
            // width: "1120px",
            cursor: "pointer",
            justifyItems: "center",
            position: "relative",
            margin: "auto",
            marginBottom: "16px",
          }}
        >
          <img
            style={{ width: "170.52px", height: "34.17px" }}
            // onClick={gotoHome}
            src={ehiiaLogo}
            alt="icons"
          />
        </div>
        <Grid
          
          className={classes.cardContainer}
        >
          <div className={classes.logotypeContainer}>
            <div className={classes.groupContainer}>
              {/* <div className={classes.imageContainer}>
          <img src={topDot} className={classes.topImage} alt="img" />
          <img src={spin} className={classes.topRightImage} alt="img" />
        </div> */}
              <Typography
                style={{ color: "#101010",marginTop:'-10px',marginBottom:'6px' }}
                className={classes.loginTitle}
              >
                Welcome to ImpactEQ Platform
              </Typography>
              <Typography
                style={{ textAlign: "center", color: "#03997B" }}
                className={classes.loginSubTitle}
              >
                {orgDetails ? orgDetails?.name:''}
              </Typography>
              <center>
                <img
                  src={logo}
                  alt="logo"
                  className={classes.logotypeImage}
                  style={{ width: "407px", height: "251px" }}
                />
              </center>
              {/* <div className={classes.imageContainer}>
                <img src={curve} className={classes.bottomImage} alt="img" />
                <img
                  src={bottomDot}
                  className={classes.bottomRightImage}
                  alt="img"
                />
              </div> */}
            </div>
          </div>
          <div className={classes.formContainer}>
           
            <div className={classes.form} style={{ marginTop: "40px" }}>
              <React.Fragment>
                {/* <Typography className={classes.greeting}>
                  Welcome back
                </Typography> */}
                <Typography className={classes.subGreeting} style={{fontSize:'24px'}}>
                Enter your password
                </Typography>
                <br />
                <Typography variant="h6" className={classes.txtHeading}>
                  Email
                </Typography>
                <TextField
                  disabled
                  id="email"
                  name="email"
                  value={email}
                  InputProps={{
                    classes: { adornedEnd: null, adornedStart: null },
                    disableUnderline: true,
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.email}
                  margin="normal"
                  placeholder="Email Adress"
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.email}
                  </Typography>
                </Fade>
                <Typography variant="h6" className={classes.txtHeading}>
                  Password
                </Typography>
                <TextField
                  id="password"
                  type={eyes ? "text" : "password"}
                  name="password"
                  // InputProps={{
                  //   classes: {
                  //     underline: classes.textFieldUnderline,
                  //     input: classes.textField,
                  //   },
                  // }}
                  InputProps={{
                    classes: { adornedEnd: null, adornedStart: null },
                    disableUnderline: true,
                  }}
                  // value={passwordValue}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    resetValidator(e.target.name);
                  }}
                  margin="normal"
                  placeholder="Password"
                  fullWidth
                  variant="outlined"
                  size="small"
                  isInvalid={error?.password}
                />
                <div
                  className={classes.eyeIcon}
                  onClick={(e) => setEyes(!eyes)}
                >
                  {eyes ? <Icons.Visibility /> : <Icons.VisibilityOff />}
                </div>
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.password}
                  </Typography>
                </Fade>
                <div className={classes.imageContainer}>
                  <FormGroup style={{visibility:'hidden'}}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Remember me"
                    />
                  </FormGroup>
                  <Link
                    href="/admin/forget-password"
                    className={classes.Link}
                    underline="none"
                  >
                    Forgot password?
                  </Link>
                  {/* <Button
              color="primary"
              size="large"
              className={classes.forgetButton}
            >
              Forgot password?
            </Button> */}
                </div>
                <div className={classes.creatingButtonContainer}>
                  <Button
                    onClick={(e) => submitHandler(e)}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    LogIn
                  </Button>
                </div>
                <center style={{ marginTop: "16px" }}>
                  {/* <Box sx={{ textAlign: "center" }}>Powered by</Box> */}
                  <img
                    src={production}
                    alt="production"
                    style={{ width: "30%", marginTop: "4px" }}
                  />
                </center>
              </React.Fragment>

              <ApiResponsePopup
                {...{
                  hideHandlerRes,
                  isRecordUpdated: isShow,
                  title: "Login",
                  successMsg: successMessage,
                  errorRes: errorApiMessage,
                }}
              />
            </div>
           
            {/* <div className={classes.bottomLink}>
          <Link className={classes.linkItem} href="#" underline="none">
            {""}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
            {""}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
           
            {"© 2024 Provide Digital Ltd. All rights reserved"}
           
          </Link>
        </div> */}
          </div>
          {/* <div
              className={classes.logotypeContainer}
              style={{
                overflow: "hidden",
                color: "#fff",
                textAlign: "center",
                backgroundColor: "#03997B",
                position: "absolute",
                bottom: "0px",
                fontSize: "13px",
                padding: "4px",
                width: "inherit",
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                height: "28px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",left:'20px'
                }}
              >
                <div style={{ left: "20px" }}>
                  <span style={{ marginRight: "6px", cursor: "pointer" }}>
                    Terms & Conditions
                  </span>
                  ||
                  <span style={{ marginLeft: "6px", cursor: "pointer" }}>
                    {" "}
                    Privacy & Policy
                  </span>
                </div>
                <div style={{  marginRight: "36px" }}>
                  © 2023 Provide Digital Ltd. All rights reserved
                </div>
              </div>
            </div> */}
             <div className={classes.bottomLink}>
            <div style={{display:'flex'}}>
          <Link className={classes.linkItem} href="#" underline="none">
            {"Terms & Conditions"}
          </Link> ||
          <Link style={{marginLeft:'16px'}} className={classes.linkItem} href="/admin/privacy-policy" underline="none">
            {"Privacy & Policy"}
          </Link>
          </div>
          <div>
          <Link align="right" className={classes.linkItem} href="#" underline="none">
          © 2024 Provide Digital Ltd. All rights reserved
          </Link>
          </div>
        </div>
        </Grid>
      </section>
    </>
  );
}

export default Login;
