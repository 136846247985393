import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // model
  formBox: {
    fontFamily: "Poppins",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  dialogBoxContent: {
    padding: "0px",
  },
  formAddBox: {
    fontFamily: "Poppins",
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    // [theme.breakpoints.down("md")]: {
    //   paddingLeft: theme.spacing(0),
    //   paddingRight: theme.spacing(0),
    // },
  },
  bodyDial: {
    borderTop: "1px solid #EDEBE9",
  },
  outlineBtn: {
    textTransform: "none",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "2px",
  },
  fillBtn: {
    textTransform: "none",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "2px",
  },
  avatarContainer: {
    position: 'relative',
  },
  uploadIcon: {
    position: "absolute",
    marginTop: "-36px",
    // marginLeft: "8px",
  },
  avatar:{
    width:"64px",
    height:"64px"
},
  formHeading: {
    marginTop: theme.spacing(1),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0.5),
    fontSize: "14px",
    color: "#4A5568",
    fontWeight: 400,
  },
  formLinkHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#2A2338",
    fontSize: "14px",
    fontStyle: "normal",
  },
  formLinkHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#0445AF",
    fontSize: "15px",
    fontStyle: "normal",
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
    paddingLeft: "6px",
  },
  successMessage: {
    fontSize: "12px",
    color: "green",
    paddingLeft: "6px",
  },
  txtForm: {
    marginTop: theme.spacing(0.5),
    padding: "0px 4px",
  },
  modelFooter: {
    fontFamily: "Poppins",
    padding: theme.spacing(2),
  },
  blueButton: {
    fontFamily: "Poppins",
    background: "#0078D4",
    fontSize: "12px",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
    height: "26px",

    textTransform: "none",
    borderRadius: "2px",
    padding: "5px, 8px, 5px, 8px",
  },
  gridBox: {
    fontFamily: "Poppins",
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingLeft: "40px !important",
    },
  },
  dropdownFormControl: {
    marginTop: theme.spacing(0.5),
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    background: "#fff",
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  //   table
  tableCell: {
    display: "flex",
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    // textAlign: "center",
    fontFamily: "Poppins",
  },
  imgAvtar: {
    marginRight: "10px",
  },
  tableHeadCell: {
    fontSize: "14px !important",
    fontWeight: 500,
    textAlign: "center",
    fontFamily: "Poppins",
  },
  tableBodyCell: {
    fontSize: "12px !important",
    fontWeight: 400,
    height:'42px',
    padding: "0px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  // Activation Message
  formActiveHeading: {
    marginTop: theme.spacing(0),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
    fontSize: "16px",
    color: "#4A5568",
    fontWeight: 500,
  },
  menuBox: {
    padding: "4px",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  menuItem: {
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "4px 10px",
    textAlign: "center",
    fontFamily: "Poppins",
  },

  // page title
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    // marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
      marginBottom: theme.spacing(2),
    },
  },
  typo: {
    color: "#2A2338",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "20px !important",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "3px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  button1: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    backgroundColor: "#3CCB97",
    color: "#fff",
    marginRight: theme.spacing(2),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    borderRadius: "4px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
  typoGray: {
    color: "#ABAFB7",
  },
  icons: {
    // width:'12px',
    // height:'44px'
    // marginTop:theme.spacing(2),
    // paddingTop:theme.spacing(2)
  },
  groupBtn: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "inline-grid",
    },
  },
  search: {
    position: "relative",
    borderRadius: "2px",
    border: "1px solid #F0F0F0",
    paddingLeft: theme.spacing(2.5),
    width: 36,
    // backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
    },
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
    marginRight: theme.spacing(2),
    background: "#fff",
    height: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  clearButton: {
    marginLeft: theme.spacing(1), // Adjust spacing as needed
    color: 'gray', // Set the color of the clear button
  },
  searchSvg2 :{
    position: "absolute",
    color: "#03997B",
    top: '4px',
    right: '0px',
    cursor: 'pointer',
    height:'2px'
},
  searchFocused: {
    // backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
    background: "#fff",
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
  },
  searchIcon: {
    width: 36,
    left: 0,
    color: "#0078D4",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    // backgroundColor:'#fff'
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    // height: 36,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    paddingLeft: "10px",
    fontSize: "14px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  downloadImg: {
    marginRight: theme.spacing(1),
  },
  editicons: {
    marginRight: theme.spacing(0.5),
  },
  // page title
}));
