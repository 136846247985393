import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
// styles
import useStyles from "./styles";
import Loader from "../../../../component/common/loader/Loader";
import { createEhiiaModule } from "../../../store/actions/ehiiaAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import { useToasts } from "react-toast-notifications";
export default function SendApproval({
  setUpdated,
  stageTypeValue,
  stageValue,
  stageWiseData,
  onHide,
  alertModalShow,
  setAlertModalShow,
  setIsActionCalled,
}) {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  var classes = useStyles();

  const submitHandler = () => {
    const data = {
      projectId: stageWiseData?.projectId,
      sectionId: "approvalResult",
      questions: {
        approvalResult: {
          stage: stageTypeValue,
          type: stageValue,
          comments: "",
        },
      },
    };

    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          // setIsAction(true);
          setAlertModalShow(false);
          setUpdated(true);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          setSuccessMessage(payload?.message);
        } else {
          // setIsAction(true);
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  const hideHandle = () => {
    setAlertModalShow(false);
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsAction(false);
  };
  return (
    <>
      <Popup
        onHide={hideHandle}
        open={alertModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                No
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Yes
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <center>
              <img src={alertIcon} alt="alert icon" />
            </center>
            <Typography className={classes.formHeading} style={{marginTop:'4px'}}>
              Are you sure to submit this case for approval?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isAction,
          title: "Approval Module",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
