import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  Typography,
  Menu,
  Button,
  TextField,
} from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";
import useStyles from "./styles";
import TableDesign from "../../../component/common/table/TableDesign";
import ManageTeam from "./ManageTeam";
import downArrow from "../../../assets/icons/downArrow.png";
import { useToasts } from "react-toast-notifications";
import { createUpdateApproval } from "../../store/actions/approvalAction";
import ManageProject from "./ManageProject";
import DeleteProjectPopup from "./DeleteProjectPopup";
export default function ProjectType({
  stage,
  subStage,
  projectLevel,
  data,
  enabled,
}) {
  console.log("stage", stage);
  console.log("subStage", subStage);
  console.log("data", data);
  console.log("projectLevel", projectLevel);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [selectRow, setSelectRow] = useState(null);
  const [selectedStage, setSelectedStage] = useState("");
  const [selectgroup, setSelectGroup] = useState(null);
  const [level, setLevel] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [deleteRec, setDeleteRec] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const dispatch = useDispatch();
  const addClient = (levelname, stagelvl, rowData) => {
    console.log("rowData", rowData);
    console.log("levelname", levelname);
    console.log("stagelvl", stagelvl);

    setSelectGroup(stagelvl);
    setLevel(levelname);
    console.log("selectgroup -0", selectgroup);
    if (rowData && stagelvl === "stage1") {
      console.log("stagelvl-1", stagelvl);
      console.log("selectgroup -1", selectgroup);
      setSelectedStage(rowData.stage1); // or any other property from stage1
      setTitle("Equality Sign-off");
    } else {
      console.log("selectgroup -2", selectgroup);
      console.log("stagelvl-2", stagelvl);
      setSelectedStage(rowData.stage2);

      setTitle("Report Sign-off");
    }
    setOpen(true);
    // setAnchorEl(null);
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Project Type Name",
    },
    // {
    //   label: "Project Description",
    // },
    {
      label: "Is Engagement Required?",
    },

    {
      label: "Equality Sign-off",
      // align: "left",
    },
    {
      label: "Report Sign-off",
      // align: "left",
    },
    {
      label: "Action",
      align: "center !important",
    },
  ];

  const classes = useStyles();
  const [selectProject, setSelectProject] = useState(null);
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
    setSelectedStage("");
    setTitle(null);
    setSelectGroup(null); // Reset selectgroup
    setLevel(null); // Reset level
    setDeleteModalShow(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // setOpen(false);
    setAnchorEl(null);
  };
  const [projectTypeDetails, setProjectTypeDetails] = useState({
    name: "",
    description: "",
  });
  const addProject = (e, stageKey, subStageKey) => {
    console.log("stageKey-137", stageKey);
    console.log("subStageKey-138", subStageKey);
    e.preventDefault();
    const newData = {
      [stage]: {
        [subStage]: {
          projectLevels: [
            ...projectLevel,
            {
              name: projectTypeDetails?.name || "",
              description: projectTypeDetails?.description || "",
            },
          ],
        },
      },
    };
    console.log("newData", newData);
    dispatch(createUpdateApproval(newData))
      .then(({ payload }) => {
        console.log("payload", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";

          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });

          setProjectTypeDetails("");
        } else {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          console.log("payload?.error", payload);

          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const editProjectHandler = (rec) => {
    setOpen(true);
    setSelectProject(rec);
    setAnchorEl(null);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    // dispatch(resetPopup());
    setDeleteRec(rec);
    setDeleteModalShow(true);

    // setShowResult(true);
  };
  return (
    <>
      <DeleteProjectPopup
        onHide={hideHandle}
        open={deleteModalShow}
        title={"Delete Project Type"}
        deleteRec={deleteRec}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
        subOrg={stage}
        type={subStage}
      />
      <ManageProject
        open={open}
        onHide={hideHandle}
        title={"Update Project Type"}
        setModalShow={setModalShow}
        selectProject={selectProject}
        subOrg={stage}
        type={subStage}
      />
      {/* <Grid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={4}>
                    <TextField
                      style={{ marginBottom: "10px" }}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      type="text"
                      name="name"
                      label="Enter Project Type Name"
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        style: { fontSize: "12px" }, // Adjust the font size as needed
                      }}
                      
                      // rows={5}
                      value={
                        projectTypeDetails.name ? projectTypeDetails.name : ""
                      }
                      onChange={async (e) => {
                        setProjectTypeDetails({
                          ...projectTypeDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ marginBottom: "10px" }}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      type="text"
                      name="description"
                      label="Enter Project Description"
                      variant="outlined"
                      multiline
                      InputLabelProps={{
                        style: { fontSize: "12px" }, // Adjust the font size as needed
                      }}
                      // rows={5}
                      value={
                        projectTypeDetails.description ? projectTypeDetails.description : ""
                      }
                      onChange={async (e) => {
                        setProjectTypeDetails({
                          ...projectTypeDetails,
                          description: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      style={{ float: "right", fontSize: "12px" }}
                      classes={{ root: classes.button }}
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={(e) => addProject(e)}
                    >
                      Add Project
                    </Button>
                  </Grid>
                </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          <div
            style={{
              paddingTop: "0px !important",
              padding: "10px",
              fontWeight: "500",
              color: "#03997B",
              textTransform: "capitalize",
            }}
          >
            {subStage} Project Type
          </div>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {projectLevel.length > 0 ? (
                projectLevel.map((item, index) => (
                  <TableRow key={index} className={classes.tableBodyCell}>
                    <TableCell className={classes.tableBodyCell}>
                      {index + 1}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item?.name}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item?.engagement?.email
                        ? `Yes (${item?.engagement?.email})`
                        : item?.engagement?.option &&
                          item?.engagement?.option === "None"
                        ? "No"
                        : item?.engagement?.option}
                    </TableCell>

                    <TableCell className={classes.tableBodyCell}>
                      {item?.approver?.stage1?.email
                        ? item?.approver?.stage1?.email
                        : item?.approver?.stage1?.option &&
                          item?.approver?.stage1?.option === "None"
                        ? "Not Required"
                        : item?.approver?.stage1?.option}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {item?.approver?.stage2?.email
                        ? item?.approver?.stage2?.email
                        : item?.approver?.stage2?.option &&
                          item?.approver?.stage2?.option === "None"
                        ? "Not Required"
                        : item?.approver?.stage2?.option}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      <IconButton
                        id="basic-button"
                        aria-controls={openDown ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDown ? "true" : undefined}
                        onClick={(e) => {
                          handleClick(e);
                          setSelectRow(item);
                        }}
                      >
                        <MoreIcon />
                      </IconButton>
                      <Menu
                        elevation={0}
                        className={classes.menuBox}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openDown}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => editProjectHandler(selectRow)}
                          className={classes.menuItem}
                        >
                          <Lens
                            style={{
                              height: "4px",
                              width: "4px",
                              marginRight: "4px",
                            }}
                          />{" "}
                          Edit
                        </MenuItem>

                        <MenuItem
                          onClick={() => deleteEventHandler(selectRow)}
                          className={classes.menuItem}
                        >
                          <Lens
                            style={{
                              height: "4px",
                              width: "4px",
                              marginRight: "4px",
                            }}
                          />{" "}
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="6">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
