import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  Tooltip,
  Drawer,
  Divider,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import infoIcon from "../../../../assets/info-icon.svg";
import useStylesAdd from "../../../pages/ehiia/Cases/styles";
import useStylesDetail from "../../../components/Ehiia/Detail/styles";
// import notes from "./noteEdit.svg";
import whiteEdit from "../../../../images/whiteEdit.png";
import expand from "../../../../assets/expand.png";
import hide from "../../../../assets/hide.png";
import Widget from "../../../../component/Widget";
import Up from "../../../../assets/up.png";
import Down from "../../../../assets/down.png";
import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";

import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
import ManageAssessment from "./ManageAssessment";
import AlertConfirmation from "./AlertConfirmation";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ArrowForwardIosRounded } from "@material-ui/icons";
export default function Assessment({
  setIsUpdateCalled,
  assessmentForm,
  setAssessmentForm,
  nextQuestionsValue,
  setNextQuestionsValue,
  setionStatus,
  setProjectIdValue,
  projectIdValue,
  clientAdminPath,
  setCurrentSection = () => {},
  isAssessmentCalled = false,
  setIsAssessmentCalled = () => {},
  setActiveTabId = () => {},
  type = "",
}) {
 
  var classes = useStyles();
  const { addToast } = useToasts();
  var classesAdd = useStylesAdd();
  var classesDetail = useStylesDetail();
  const navigate = useNavigate();
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [uploadSuccessData, setUploadSuccessData] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track the index of the current question being displayed
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(""); // State variable to track selected option
  const [selectedOptions, setSelectedOptions] = useState([]); // state variable for multiple checkbox and his textfield
  const [nextQuestionId, setNextQuestionId] = useState(""); // State variable to track the ID of the next question
  const [previousQuestions, setPreviousQuestions] = useState([]); // Array to hold previous questions
  const [additionalSingleInfo, setAdditionalSingleInfo] = useState("");
  //  select single question for update
  const [selectedQuestion, setSelectedQuestion] = useState(null); // State variable to store the selected question
  const [selectedAnswer, setSelectedAnswer] = useState(null); // State variable to store the selected answer
  const [modalShow, setModalShow] = useState(false); // State variable to control the modal visibility
  const [open, setOpen] = React.useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [nextSection, setNextSection] = useState(null);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [isDataReviewCompleted, setIsDataReviewCompleted] = useState(false);
  const assessmentSectionRef = useRef(null);
  const dispatch = useDispatch();
 useEffect(()=>{
  if(setionStatus?.length>0){
    const dataReviewSection = setionStatus.find(section => section.value === "dataReview");
      if (dataReviewSection) {
        setIsDataReviewCompleted(dataReviewSection.completed);
       
      }
  }

 },[setionStatus])
  const handleEditClick = (question) => {
    console.log("question", question);

    setSelectedQuestion(question);
    setOpen(true);
  };
  useEffect(() => {
    console.log("hitApi1");
    if (nextSection === "assessment" && assessmentSectionRef.current) {
      console.log("hitApi2");
      console.log("assessmentSectionRef", assessmentSectionRef);

      assessmentSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });

      // Add a delay to wait for the smooth scroll to complete
      setTimeout(() => {
        // Add a top margin of 10 pixels after scrolling
        window.scrollBy(0, -100);
      }, 500); // Adjust the delay as needed
    }
  }, [updated, nextSection]);

  // get assessment filled file data
  useEffect(() => {
    console.log("called Assessment");
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "assessment",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        // Filter out entries with 'questionId' as 'undefined'
        console.log(
          "payload?.data?.form?.assessment?.questions",
          payload?.data?.form?.assessment?.questions,
        );
        const filteredData = payload?.data?.form?.assessment?.questions;
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        setNextSection(payload?.data?.form?.nextSection);
        setCurrentSection({
          value: payload?.data?.form?.currentSection,
          isCompleted: payload?.data?.form?.sectionCompleted,
        });
        setIsAssessmentCompleted(payload?.data?.form?.sectionCompleted);
        setNextQuestionsValue(payload?.data?.form?.nextQuestions);
        setUpdated(false);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "assessment",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        const filteredData = payload?.data?.form?.assessment?.questions;
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        setNextSection(payload?.data?.form?.nextSection);
        setCurrentSection({
          value: payload?.data?.form?.currentSection,
          isCompleted: payload?.data?.form?.sectionCompleted,
        });
        setIsAssessmentCompleted(payload?.data?.form?.sectionCompleted);
        setNextQuestionsValue(payload?.data?.form?.nextQuestions);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setUpdated(false);
      }
    });
  }, [updated]);
  // useEffect(() => {
  //   console.log("uploadSuccessData", uploadSuccessData);
  // }, [uploadSuccessData, updated]);

  const handleCheckboxChange = (event, optionValue, questionId) => {
    const isChecked = event.target.checked;

    if (optionValue === "No" && isChecked) {
      if (nextQuestionsValue?.length > 1) {
        setSelectedOptions({
          ...selectedOptions,
          [questionId]: [{ value: "No", additionalInfo: "" }],
        });
      } else {
        setSelectedOptions([{ value: "No", additionalInfo: "" }]);
      }
    } else {
      if (isChecked) {
        if (nextQuestionsValue?.length > 1) {
          setSelectedOptions((prevSelected) => {
            // Check if "No" checkbox is already selected
            let noCheckboxSelected;
            console.log("prevSelected[questionId]-", prevSelected[questionId]);
            if (prevSelected[questionId]) {
              noCheckboxSelected = prevSelected[questionId]?.some(
                (opt) => opt.value === "No",
              );
            }
            // If "No" checkbox is selected, unselect it and select the current checkbox
            if (noCheckboxSelected) {
              return {
                ...selectedOptions,
                [questionId]: [{ value: optionValue, additionalInfo: "" }],
              };
            } else {
              // Otherwise, add the current checkbox to selectedOptions array
              return {
                ...selectedOptions,
                [questionId]: prevSelected[questionId]
                  ? [
                      ...prevSelected[questionId],
                      { value: optionValue, additionalInfo: "" },
                    ]
                  : [{ value: optionValue, additionalInfo: "" }],
              };
            }
          });
        } else {
          setSelectedOptions((prevSelected) => {
            // Check if "No" checkbox is already selected
            const noCheckboxSelected = prevSelected.some(
              (opt) => opt.value === "No",
            );
            // If "No" checkbox is selected, unselect it and select the current checkbox
            if (noCheckboxSelected) {
              return [{ value: optionValue, additionalInfo: "" }];
            } else {
              // Otherwise, add the current checkbox to selectedOptions array
              return [
                ...prevSelected,
                { value: optionValue, additionalInfo: "" },
              ];
            }
          });
        }
      } else {
        if (nextQuestionsValue?.length > 1) {
          setSelectedOptions((prevSelected) => {
            return {
              ...selectedOptions,
              [questionId]:
                prevSelected[questionId] &&
                prevSelected[questionId].filter(
                  (opt) => opt.value !== optionValue,
                ),
            };
          });
        } else {
          setSelectedOptions((prevSelected) =>
            prevSelected.filter((opt) => opt.value !== optionValue),
          );
        }
      }
    }
  };

  //for radio buttons

  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event, questionId) => {
    if (nextQuestionsValue?.length > 1) {
      setSelectedValue(() => {
        return {
          ...selectedValue,
          [questionId]: event.target.value,
        };
      });
    } else {
      setSelectedValue(event.target.value);
    }
  };

  const handleAdditionalInfoChange = (event, optionValue, questionId) => {
    // Update the additionalInfo field for the corresponding option in the selectedOptions array
    if (nextQuestionsValue?.length > 1) {
      setSelectedOptions((prevSelected) => {
        return {
          ...selectedOptions,
          [questionId]:
            prevSelected[questionId] &&
            prevSelected[questionId].map((opt) =>
              opt.value === optionValue
                ? { ...opt, additionalInfo: event.target.value.replace(/\n/g, '') }
                : opt,
            ),
        };
      });
    } else {
      setSelectedOptions((prevSelected) =>
        prevSelected.map((opt) =>
          opt.value === optionValue
            ? { ...opt, additionalInfo: event.target.value.replace(/\n/g, '') }
            : opt,
        ),
      );
    }
  };
  const handleAdditionalSingleInfoChange = (event, questionId) => {
    if (nextQuestionsValue?.length > 1) {
      setAdditionalSingleInfo(() => {
        return {
          ...additionalSingleInfo,
          [questionId]: event.target.value.replace(/\n/g, ''),
        };
      });
    } else {
      setAdditionalSingleInfo(event.target.value);
    }
  };

  // Add assessment form functionality
  const submitHandler = (e, action = "") => {
    e.preventDefault();
    if (isAssessmentCompleted) {
      return navigate(
        `/${
          clientAdminPath === "staff" ? "staff" : "admin"
        }/manage-module/ehiia`,
      );
    }

    const questionId = nextQuestionsValue[0]?.questionId; // Get the questionId from the first element of nextQuestionsValue array
    // const selectedValue = assessmentForm?.assessment?.value; // Get the selected value from the assessmentForm state
    setIsLoading(true);
    let assessment = [];
    if (nextQuestionsValue?.length > 1) {
      nextQuestionsValue.map((question) => {
        assessment.push({
          questionId: question?.questionId,
          value:
            typeof selectedValue === "string"
              ? selectedValue
              : selectedValue[question?.questionId]
              ? selectedValue[question?.questionId]
              : false,
          options: selectedOptions[question?.questionId],
          additionalInfo:
            typeof additionalSingleInfo === "string"
              ? additionalSingleInfo
              : additionalSingleInfo[question?.questionId] || "",
        });
      });
    } else {
      assessment = {
        questionId: questionId,
        value: selectedOption ? selectedOption : selectedValue || true,
        options: selectedOptions,
        additionalInfo: additionalSingleInfo,
      };
    }
    const data = {
      projectId: projectIdValue,
      sectionId: "assessment",
      questions: {
        assessment: assessment,
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          hideHandler();
          setSelectedOption("");
          setSuccessMessage(payload?.message);
          // setIsShow(true);

          setErrorApiMessage(null);
          // Set the previous questions by concatenating the current nextQuestionsValue with the previousQuestions array
          setPreviousQuestions((prevQuestions) => [
            ...prevQuestions,
            ...nextQuestionsValue,
          ]);

          // Update the nextQuestionsValue with the new set of questions
          setNextQuestionsValue(payload?.data?.nextQuestions);

          setCurrentQuestionIndex(currentQuestionIndex + 1);
          // Move to the next question
          setUpdated(true);
          setIsUpdateCalled(true);
          setSelectedOptions([]);
          setAdditionalSingleInfo("");
          setSelectedOption("");
          setSelectedValue("");
          if (action === "save") {
            navigate(
              `/${
                clientAdminPath === "staff" ? "staff" : "admin"
              }/manage-module/ehiia`,
            );
          }

          // if (payload?.data?.nextSection === "dataReview") {
          //   setActiveTabId(2);
          // }
        } else {
          const toastHeight = payload?.message.length > 38 ? "auto" : "34px";
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              display: "flex",
              // alignItems:'center',
              // justifyContent: 'center',
              height: toastHeight,
            },
          });
          // setIsShow(true);

          setIsLoading(false);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };

  // useEffect(() => {
  //   if (isAssessmentCalled) {
  //     submitHandler({ preventDefault: () => {} });
  //     setIsAssessmentCalled(false);
  //   }
  // }, [isAssessmentCalled]);

  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const hideHandler = () => {
    console.log("hide handler called");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setSelectedQuestion(null);
    setSelectedQuestion({
      ...selectedQuestion,
      value: "",
      additionalInfo: "",
      valueOptions: [],
    });
    setSelectedValue("");
    setModalShow(false);
    setOpen(false);
    console.log("clear radio buttons");
  };
  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  const actionCancel = (e) => {
    navigate(
      `/${clientAdminPath === "staff" ? "staff" : "admin"}/manage-module/ehiia`,
    );
  };

  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };

  // remove mouse
  const textFieldRef = useRef(null);
  const handleBlur = () => {
    // Get the current value of the textarea
    textFieldRef.current.scrollTop = 0;
    // const currentValue = textFieldRef.current.value;

    // // Set the caret position to the end of the text
    // textFieldRef.current.setSelectionRange(currentValue.length, currentValue.length);
  };

  function TableCellWithNumberedItems({ paragraph }) {
    if (paragraph === "") {
      return ""; // Display null for null values
    }
    // Split the paragraph by commas to create an array of items
    let items = paragraph.replaceAll("\n\n", "\n");
    items = items.split("\n");

    return (
      <div
        align="left"
        style={{
          textAlign: "left",
          border: "0px",
          padding: "0px",
          verticalAlign: "top",
          margin: "-16px 0px 0px -16px",
        }}
      >
        <ul type="none">
          {items.map((item, index) => (
            <li
              style={{ border: "1px solid #333" }}
              key={index}
            >{`${item.trim()}`}</li>
          ))}
        </ul>
      </div>
    );
  }
  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);

  // info model
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [showNotes, setShowNotes] = useState(true);
  const notesToggle = () => {
    setShowNotes(!showNotes);
  };
  const [drawerStates, setDrawerStates] = React.useState({});

  const toggleDrawer = (info, title, open) => () => {
    setDrawerStates((prevStates) => ({
      ...prevStates,
      [info]: { open, title, info },
    }));
  };
  useEffect(() => {
    console.log("uploadSuccessData", uploadSuccessData);
  }, [uploadSuccessData]);
  const drawerWidth = 240;
  let afterQ96 = false;
  let passBorderToParentValue = false;

  const [openBlocks, setOpenBlocks] = useState({});
  const toggleBlock = (blockId) => {
    setOpenBlocks((prevState) => ({
      ...prevState,
      [blockId]: !prevState[blockId],
    }));
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <AlertConfirmation
            // onHide={hideHandle}
            // setIsActionCalled={setIsActionCalled}
            setAlertModalShow={setAlertModalShow}
            alertModalShow={alertModalShow}
          />
          <ManageAssessment
            open={open}
            onHide={hideHandle}
            title={"Edit Your Answer"}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            assessmentForm={assessmentForm}
            setAssessmentForm={setAssessmentForm}
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            setPreviousQuestions={setPreviousQuestions}
            setUpdated={setUpdated}
            projectIdValue={projectIdValue}
            setModalShow={setModalShow}
          />
          <Widget noBodyPadding>
            {isLoading && (
              <Loader message={"Please wait, loading questions..."} />
            )}
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{
                visibility: isLoading ? "hidden" : "visible",
                backgroundColor: isLoading ? "rgb(200 232 221 / 30%)" : "#fff",
              }}
            >
              {/* Render previous questions */}
              {uploadSuccessData &&
                uploadSuccessData.map((element, i) => {
                  const {
                    key,
                    title,
                    type,
                    questionId,
                    questionSerial,
                    value,
                    options,
                    optional,
                    info,
                  } = element;

                  const isQ17BothValue = uploadSuccessData.some(
                    (item) =>
                      item.questionSerial === "Q17." &&
                      item.value === "Impacts both Staff and Patients",
                  );
                  const isQ17PatientValue = uploadSuccessData.some(
                    (item) =>
                      item.questionSerial === "Q17." &&
                      item.value === "Only Impacts Patients",
                  );
                  const isQ17StaffValue = uploadSuccessData.some(
                    (item) =>
                      item.questionSerial === "Q17." &&
                      item.value === "Only Impacts Staff",
                  );
                  const isBlockOpen = element.block
                    ? openBlocks[element.block?.id] !== false
                    : true;
                  return (
                    <>
                      {/* divider */}
                      {element?.block?.title && element?.block?.start && (
                        <>
                          <Grid
                            onClick={
                              element?.block
                                ? () => toggleBlock(element?.block?.id)
                                : ""
                            }
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              backgroundColor: "#F5F5F5",
                              padding: "10px",
                              border: "1px solid #B6B6B6",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // marginTop: "10px",
                              // borderBottom: "2px solid #26A88E",
                            }}
                            container
                            xs={12}
                            md={12}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#201F1E",
                                textAlign: "center",
                                position: "relative",
                                margin: "0 auto",
                                display: "flex",
                                justifyItems: "center",alignItems:'center'
                              }}
                            >
                              {" "}
                              {element?.block?.title}{" "}
                              {element?.block?.info && (
                                <>
                                  <img
                                    onClick={toggleDrawer(
                                      element?.block?.info,
                                      element?.block?.title,
                                      true,
                                    )}
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "0px",marginLeft:'4px',
                                      height: "16px",
                                    }}
                                    src={infoIcon}
                                    alt="img"
                                  />
                                  <Drawer
                                    style={{
                                      width: "240px",
                                      maxWidth: "240px",overflowWrap:'anywhere'
                                    }}
                                    sx={{
                                      paddingRight: 4,
                                      "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                      },
                                    }}
                                    elevation={0}
                                    anchor="right"
                                    open={
                                      drawerStates[element?.block?.info]
                                        ?.open || false
                                    }
                                    onClose={toggleDrawer(
                                      element?.block?.info,
                                      element?.block?.title,
                                      false,
                                    )}
                                  >
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        marginLeft: "-14px",
                                      }}
                                      onClick={toggleDrawer(
                                        element?.block?.info,
                                        element?.block?.title,
                                        false,
                                      )}
                                    >
                                      <ArrowForwardIosRounded />
                                    </IconButton>
                                    <p
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        paddingLeft: "24px",
                                        textAlign: "center",
                                        width: "260px",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      {
                                        drawerStates[element?.block?.info]
                                          ?.title
                                      }
                                    </p>
                                    <Divider />
                                    <span
                                      style={{
                                        padding: "6px 10px",
                                        width: "260px",
                                        fontSize: "12px",overflowWrap:'anywhere'
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          drawerStates[element?.block?.info]
                                            ?.info,
                                      }}
                                    ></span>
                                  </Drawer>
                                </>
                              )}
                            </Typography>
                            <div style={{ display: "flex" }}>
                              {isBlockOpen ? (
                                <img
                                  style={{ height: "8px", cursor: "pointer" }}
                                  src={Up}
                                  alt="up"
                                />
                              ) : (
                                <img
                                  src={Down}
                                  style={{ height: "8px", cursor: "pointer" }}
                                  alt="down"
                                />
                              )}
                            </div>
                          </Grid>
                        </>
                      )}
                      {/* divider */}
                      {isBlockOpen && (
                        <Grid
                          style={{
                            borderTop: element?.block?.start
                              ? "1px  solid #B6B6B6"
                              : "0px  solid #B6B6B6",
                            borderRight: element?.block && "1px  solid #B6B6B6",
                            borderBottom: element?.block?.end
                              ? "1px  solid #B6B6B6"
                              : "0px  solid #B6B6B6",
                            borderLeft: element?.block && "1px  solid #B6B6B6",
                            borderRadius:
                              element?.block?.end && "0px 0px 5px 5px",
                            marginBottom: element?.block?.end && "6px",
                          }}
                          item
                          xs={12}
                          md={12}
                          className={classes.textGroup}
                          key={i}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <Typography
                              className={classes.formHeading}
                              style={{ width: "90%" }}
                            >
                              {questionSerial} {title}
                              {!optional && (
                                <span
                                  style={{
                                    color: "#f50b0be1",
                                    fontSize: "11px",
                                  }}
                                >
                                  *
                                </span>
                              )}{" "}
                              {info && (
                                <>
                                  <img
                                    onClick={toggleDrawer(info, title, true)}
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "6px",
                                      height: "16px",
                                    }}
                                    src={infoIcon}
                                    alt="img"
                                  />
                                  <Drawer
                                    style={{
                                      width: "240px",
                                      maxWidth: "240px",
                                    }}
                                    sx={{
                                      paddingRight: 4,
                                      "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                      },
                                    }}
                                    elevation={0}
                                    anchor="right"
                                    open={drawerStates[info]?.open || false}
                                    onClose={toggleDrawer(info, title, false)}
                                  >
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        marginLeft: "-14px",
                                      }}
                                      onClick={toggleDrawer(info, title, false)}
                                    >
                                      <ArrowForwardIosRounded />
                                    </IconButton>
                                    <p
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        paddingLeft: "24px",
                                        textAlign: "center",
                                        width: "260px",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      {drawerStates[info]?.title}
                                    </p>
                                    <Divider />
                                    <span
                                      style={{
                                        padding: "6px 10px",
                                        width: "260px",
                                        fontSize: "12px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: drawerStates[info]?.info,
                                      }}
                                    ></span>
                                  </Drawer>
                                </>
                              )}
                            </Typography>

                            <>
                              {show &&
                                checkQuestionId === element?.questionId &&
                                type !== "reviewTableNoActionAcknowledge" && (
                                  <div
                                    style={{
                                      backgroundColor: "#FEAA00",
                                      width: "30px",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() => handleEditClick(element)}
                                  >
                                    <img
                                      style={{ width: "14px", height: "14px" }}
                                      src={whiteEdit}
                                      alt="Edit Icon"
                                    />
                                  </div>
                                )}

                              {show &&
                              checkQuestionId === element?.questionId ? (
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    transition: "opacity 0.3s ease-in-out",
                                  }}
                                  onClick={() => handleHideContent(questionId)}
                                >
                                  <img
                                    src={hide}
                                    style={{ marginTop: "5px" }}
                                    alt="hide"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    transition: "opacity 0.3s ease-in-out",
                                  }}
                                  onClick={() => handleShowContent(questionId)}
                                >
                                  <img
                                    src={expand}
                                    style={{ marginTop: "5px" }}
                                    alt="expand"
                                  />
                                </div>
                              )}
                            </>
                          </div>
                          {show && checkQuestionId === element?.questionId && (
                            <>
                              {/* Render question based on type */}
                              {type === "singleSelect" ? (
                                <>
                                  <FormControl
                                    style={{ textTransform: "lowercase" }}
                                    fullWidth
                                    className={classes.dropdownFormControl}
                                  >
                                    <Select
                                      style={{ textTransform: "lowercase" }}
                                      name={questionId}
                                      className={classes.selectDropdown}
                                      value={value || ""}
                                      // disabled={true}
                                      readOnly={true}
                                    >
                                      {/* Render options */}
                                      {options &&
                                        options.map((item) => (
                                          <MenuItem
                                            style={{
                                              textTransform: "lowercase",
                                            }}
                                            questionId={item.value}
                                            value={item.value}
                                          >
                                            {item.title}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                </>
                              ) : type === "singleSelectAdditionalInfo" ? (
                                <>
                                  <>
                                    <FormControl
                                      fullWidth
                                      className={classes.dropdownFormControl}
                                    >
                                      <Select
                                        name={questionId}
                                        className={classes.selectDropdown}
                                        value={value || ""}
                                      >
                                        {options &&
                                          options.map((item) => (
                                            <MenuItem
                                              questionId={item.value}
                                              value={item.value}
                                            >
                                              {item.title}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                    <TextField
                                      size="small"
                                      className={classes.txtForm}
                                      fullWidth
                                      placeholder="Additional Info"
                                      variant="outlined"
                                      name={questionId}
                                      value={value}
                                      disabled
                                      multiline
                                      rows={4}
                                    />
                                  </>
                                </>
                              ) : type ===
                                "singleSelectAdditionalInfoExceptLast" ? (
                                <>
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{
                                      marginTop: "4px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {element.options.map((item) => (
                                        <Grid
                                          item
                                          xs={12}
                                          md={3}
                                          className={classes.textGroup}
                                        >
                                          <FormControlLabel
                                            style={{ fontSize: "14px" }}
                                            value={item.value}
                                            control={<Radio />}
                                            label={item.title}
                                            checked={
                                              element.value === item.value
                                            }
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className={classes.textGroup}
                                    >
                                      <TextField
                                        style={{ fontSize: "14px" }}
                                        size="small"
                                        className={`${classes.txtForm} ${classes.disabledTextField}`}
                                        fullWidth
                                        placeholder="Additional Info"
                                        variant="outlined"
                                        multiline
                                        value={element.additionalInfo} // Use the form state value for the text field
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              ) : type ===
                                "multiSelectEachAdditionalInfoExceptLast" ? (
                                <>
                                  {element.options.map((item) => (
                                    <Grid
                                      container
                                      spacing={2}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "0px",
                                        padding: "0px 10px",
                                      }}
                                    >
                                      <Grid
                                        style={{ padding: "6px" }}
                                        item
                                        xs={12}
                                        md={5}
                                        className={classes.textGroup}
                                      >
                                        <FormGroup>
                                          <FormControlLabel
                                            style={{ fontSize: "14px" }}
                                            control={
                                              <Checkbox
                                                checked={item.selected === true}
                                              />
                                            }
                                            label={item?.value}
                                          />
                                        </FormGroup>
                                      </Grid>
                                      {item.selected && (
                                        <Grid
                                          style={{ marginTop: "0px" }}
                                          item
                                          xs={12}
                                          md={7}
                                          className={classes.textGroup}
                                        >
                                          <TextField
                                            style={{ fontSize: "14px" }}
                                            name={element.questionId}
                                            size="small"
                                            className={`${classes.txtForm} ${classes.disabledTextField}`}
                                            fullWidth
                                            placeholder="Additional Info"
                                            variant="outlined"
                                            multiline
                                            value={
                                              element.options &&
                                              element.options.find(
                                                (opt) =>
                                                  opt.value === item.value,
                                              )?.additionalInfo
                                            }
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  ))}
                                </>
                              ) : type === "reviewTableNoActionAcknowledge" ? (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className={classes.textGroup}
                                    >
                                      <TableContainer
                                        style={{ marginTop: "16px" }}
                                        sx={{ boxShadow: 0 }}
                                        component={Paper}
                                      >
                                        <Table
                                          sx={{
                                            minWidth: "100%",
                                            boxShadow: 0,
                                          }}
                                          aria-label="simple table"
                                        >
                                          <TableHead
                                            style={{
                                              backgroundColor: "#EDFDFA",
                                            }}
                                          >
                                            <TableRow>
                                              {element.tableData.columns.map(
                                                (column) => (
                                                  <TableCell
                                                    key={column}
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {column}
                                                  </TableCell>
                                                ),
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {element.tableData.rows.length >
                                            0 ? (
                                              element.tableData.rows.map(
                                                (row, index) => {
                                                  const groupName = row[0]; // 'Identified Groups' is the first item
                                                  const impactsText = row[1]; // 'Impact' is the second item
                                                  const notesText = row[2]; // 'Notes' is the third item

                                                  // Check if impacts and notes exist and are strings before splitting
                                                  const impacts =
                                                    impactsText &&
                                                    typeof impactsText ===
                                                      "string"
                                                      ? impactsText.replace(/\n+/g, '\n').split("\n")
                                                      : [""];
                                                  const notes =
                                                    notesText &&
                                                    typeof notesText ===
                                                      "string"
                                                      ? notesText.replace(/\n+/g, '\n').split("\n")
                                                      : [""];

                                                  // Determine the number of rows needed
                                                  const numRows = Math.max(
                                                    impacts.length,
                                                    notes.length,
                                                  );
                                                  return Array.from({
                                                    length: numRows,
                                                  }).map((_, impactIndex) => (
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      key={`${index}-${impactIndex}`}
                                                    >
                                                      {impactIndex === 0 && (
                                                        <TableCell
                                                          align="left"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            textAlign: "left",
                                                            whiteSpace:
                                                              "pre-line",
                                                            verticalAlign:
                                                              "top",
                                                            width: "30%",
                                                          }}
                                                          rowSpan={numRows}
                                                        >
                                                          {groupName}
                                                        </TableCell>
                                                      )}
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "left",
                                                          whiteSpace:
                                                            "pre-line",
                                                          verticalAlign: "top",
                                                          width: "35%",
                                                        }}
                                                      >
                                                        {impacts[impactIndex] ||
                                                          ""}
                                                      </TableCell>
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "left",
                                                          whiteSpace:
                                                            "pre-line",
                                                          verticalAlign: "top",
                                                          width: "35%",
                                                        }}
                                                      >
                                                        {notes[impactIndex] ||
                                                          ""}
                                                      </TableCell>
                                                    </TableRow>
                                                  ));
                                                },
                                              )
                                            ) : (
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <TextField
                                    variant="outlined"
                                    className={classes.txtForm}
                                    fullWidth
                                    name={questionId}
                                    value={value}
                                    disabled
                                  />
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      )}
                      {/* patient title */}
                      {((questionSerial.trim() === "Q17." &&
                        isQ17PatientValue) ||
                        (questionSerial.trim() === "Q17." &&
                          isQ17BothValue)) && (
                        <>
                          <Grid
                            style={{
                              // borderBottom: "0px solid #B6B6B6",
                              textAlign: "center",
                              justifyContent: "center",
                              backgroundColor: "#F8FFFE",
                              padding: "10px",
                              border: "2px solid #26A88E",
                              // borderBottom: "2px solid #26A88E",
                            }}
                            container
                            xs={12}
                            md={12}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#26A88E",
                              }}
                            >
                              Patient Assessment Section
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {/*  staff title */}
                      {((questionSerial.trim() === "Q17." && isQ17StaffValue) ||
                        (questionSerial.trim() === "Q96." &&
                          isQ17BothValue)) && (
                        <>
                          <Grid
                            style={{
                              // borderBottom: "0px solid #B6B6B6",
                              textAlign: "center",
                              justifyContent: "center",
                              backgroundColor: "#F8FFFE",
                              padding: "10px",
                              border: "2px solid #26A88E",
                              // borderBottom: "2px solid #26A88E",
                            }}
                            container
                            xs={12}
                            md={12}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#26A88E",
                              }}
                            >
                              Staff Assessment Section
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </>
                  );
                })}

              {/* Render current question */}

              {nextSection === "assessment" &&
                nextQuestionsValue.map((element, i) => {
                  const isBlockOpen = element.block
                    ? openBlocks[element.block.id] !== false
                    : true;
                  const {
                    key,
                    title,
                    type,
                    questionId,
                    questionSerial,
                    value,
                    options,
                    optional,
                    info,
                  } = element;
                  return (
                    <>
                      <Grid
                        ref={i === 0 ? assessmentSectionRef : null}
                        // ref={assessmentSectionRef}
                        item
                        style={{
                          borderTop:
                            i === 0 && type !== "singleSelect"
                              ? "1px  solid #B6B6B6"
                              : "0px  solid #B6B6B6",
                          borderRight:
                            type !== "singleSelect" && "1px  solid #B6B6B6",
                          borderBottom:
                            i === nextQuestionsValue.length - 1 &&
                            type !== "singleSelect"
                              ? "1px  solid #B6B6B6"
                              : "0px  solid #B6B6B6",
                          borderLeft:
                            type !== "singleSelect" && "1px  solid #B6B6B6",
                          borderRadius:
                            i === 0 && type !== "singleSelect"
                              ? "5px 5px 0px 0px"
                              : i === nextQuestionsValue.length - 1 &&
                                type !== "singleSelect" &&
                                "0px 0px 5px 5px",
                        }}
                        xs={12}
                        md={12}
                        className={classes.textGroup}
                      >
                        {element?.block && element?.block?.title && (
                          <div
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              fontSize: "14px",
                              fontWeight: "600",
                              cursor: "pointer",
                              color: "#201F1E",
                              margin: "-6px -6px 10px -6px",

                              backgroundColor: "#F5F5F5",
                              padding: "6px 16px",
                              borderRadius: "5px 5px 0px 0px",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ fontWeight: "600", color: "#201F1E", textAlign: "center",
                              position: "relative",
                              margin: "0 auto",
                              display: "flex",
                              justifyItems: "center",alignItems:'center' }}
                            >
                              {element?.block?.title}{" "}
                              {element?.block?.info && (
                                <>
                                  <img
                                    onClick={toggleDrawer(
                                      element?.block?.info,
                                      element?.block?.title,
                                      true,
                                    )}
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "0px",marginLeft:'4px',
                                      height: "16px",
                                    }}
                                    src={infoIcon}
                                    alt="img"
                                  />
                                  <Drawer
                                    style={{
                                      width: "240px",
                                      maxWidth: "240px",overflowWrap:'anywhere'
                                    }}
                                    sx={{
                                      paddingRight: 4,
                                      "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                      },
                                    }}
                                    elevation={0}
                                    anchor="right"
                                    open={
                                      drawerStates[element?.block?.info]
                                        ?.open || false
                                    }
                                    onClose={toggleDrawer(
                                      element?.block?.info,
                                      element?.block?.title,
                                      false,
                                    )}
                                  >
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        marginLeft: "-14px",
                                      }}
                                      onClick={toggleDrawer(
                                        element?.block?.info,
                                        element?.block?.title,
                                        false,
                                      )}
                                    >
                                      <ArrowForwardIosRounded />
                                    </IconButton>
                                    <p
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        paddingLeft: "24px",
                                        textAlign: "center",
                                        width: "260px",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      {
                                        drawerStates[element?.block?.info]
                                          ?.title
                                      }
                                    </p>
                                    <Divider />
                                    <span
                                      style={{
                                        padding: "6px 10px",
                                        width: "260px",
                                        fontSize: "12px",overflowWrap:'anywhere'
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          drawerStates[element?.block?.info]
                                            ?.info,
                                      }}
                                    ></span>
                                  </Drawer>
                                </>
                              )}
                            </Typography>
                            <div
                              style={{ display: "flex" }}
                              onClick={
                                element?.block
                                  ? () => toggleBlock(element?.block?.id)
                                  : ""
                              }
                            >
                              {isBlockOpen ? (
                                <img
                                  style={{ height: "8px", cursor: "pointer" }}
                                  src={Up}
                                  alt="up"
                                />
                              ) : (
                                <img
                                  src={Down}
                                  style={{ height: "8px", cursor: "pointer" }}
                                  alt="down"
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {isBlockOpen && (
                          <>
                            <Typography
                              style={{ marginBottom: "10px" }}
                              key={i}
                              className={classes.formHeading}
                            >
                              {questionSerial} {title}
                              {!optional && (
                                <span
                                  style={{
                                    color: "#f50b0be1",
                                    fontSize: "11px",
                                  }}
                                >
                                  *
                                </span>
                              )}{" "}
                              {info && (
                                <>
                                  <img
                                    onClick={toggleDrawer(info, title, true)}
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "6px",
                                      height: "16px",
                                    }}
                                    src={infoIcon}
                                    alt="img"
                                  />
                                  <Drawer
                                    style={{
                                      width: "240px",
                                      maxWidth: "240px",
                                    }}
                                    sx={{
                                      paddingRight: 4,
                                      "& .MuiDrawer-paper": {
                                        boxSizing: "border-box",
                                        width: drawerWidth,
                                      },
                                    }}
                                    elevation={0}
                                    anchor="right"
                                    open={drawerStates[info]?.open || false}
                                    onClose={toggleDrawer(info, title, false)}
                                  >
                                    <IconButton
                                      style={{
                                        position: "absolute",
                                        marginLeft: "-14px",
                                      }}
                                      onClick={toggleDrawer(info, title, false)}
                                    >
                                      <ArrowForwardIosRounded />
                                    </IconButton>
                                    <p
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        paddingLeft: "24px",
                                        textAlign: "center",
                                        width: "260px",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      {drawerStates[info]?.title}
                                    </p>
                                    <Divider />
                                    <span
                                      style={{
                                        padding: "6px 10px",
                                        width: "240px",
                                        fontSize: "12px",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: drawerStates[info]?.info,
                                      }}
                                    ></span>
                                  </Drawer>
                                </>
                              )}
                            </Typography>
                            {type === "singleSelect" ? (
                              <>
                                <FormControl
                                  style={{ marginTop: "6px",textTransform: "lowercase", }}
                                  fullWidth
                                  className={classes.dropdownFormControl}
                                >
                                  <Select 
                                    name={questionId}
                                    className={classes.selectDropdown}
                                    value={nextQuestionsValue[0]?.value || ""}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setNextQuestionsValue([
                                        {
                                          ...nextQuestionsValue[0],
                                          value: value,
                                        },
                                      ]);
                                      setSelectedOption(value); // Update selected option
                                      // Find the selected option in the nextQuestionsValue array
                                      const selectedOptionObj =
                                        nextQuestionsValue.find(
                                          (option) => option.value === value,
                                        );
                                      if (selectedOptionObj) {
                                        const nextQuestionIds =
                                          selectedOptionObj.nextQuestionId;
                                        if (
                                          nextQuestionIds &&
                                          nextQuestionIds.length > 0
                                        ) {
                                          // Assume we take the first nextQuestionId from the array
                                          const nextQuestionId =
                                            nextQuestionIds[0];
                                          setNextQuestionId(nextQuestionId);
                                        }
                                      }
                                      // setAssessmentForm((prevForm) => ({
                                      //   ...prevForm,
                                      //   [questionId]: {
                                      //     ...prevForm[questionId],
                                      //     value: value,
                                      //   },
                                      // }));
                                      // Other logic...
                                    }}
                                    // Add necessary props and validation as needed...
                                  >
                                    <MenuItem disabled value="">
                                      select
                                    </MenuItem>
                                    {options &&
                                      options.map((item) => (
                                        <MenuItem  style={{
                                          textTransform: "lowercase",
                                        }}
                                          questionId={item.value}
                                          value={item.value}
                                        >
                                          {item.title}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                                {/* Error message logic... */}
                              </>
                            ) : type ===
                              "singleSelectAdditionalInfoExceptLast" ? (
                              <>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    marginTop: "4px",
                                    paddingRight: "10px",
                                  }}
                                  key={`${questionId}-${i}`}
                                >
                                  <Grid
                                    container
                                    spacing={2}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {element.options.map((item) => (
                                      <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        className={classes.textGroup}
                                      >
                                        <FormControlLabel
                                          value={item.value}
                                          control={<Radio />}
                                          label={item.title}
                                          checked={
                                            //selectedValue === item.value
                                            nextQuestionsValue?.length > 1
                                              ? selectedValue[questionId]
                                                ? selectedValue[questionId] ===
                                                  item.value
                                                : false
                                              : selectedValue === item.value
                                          }
                                          onChange={(event) =>
                                            handleRadioChange(event, questionId)
                                          }
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className={classes.textGroup}
                                  >
                                    <TextField
                                      size="small"
                                      className={classes.txtForm}
                                      fullWidth
                                      placeholder="Additional Info"
                                      variant="outlined"
                                      multiline
                                      rows={4}
                                      value={
                                        nextQuestionsValue?.length > 1
                                          ? additionalSingleInfo[questionId]
                                          : additionalSingleInfo
                                      }
                                      onChange={(event) =>
                                        handleAdditionalSingleInfoChange(
                                          event,
                                          questionId,
                                        )
                                      }
                                      onBlur={handleBlur}
                                      inputRef={textFieldRef} // Assign the ref to the TextField
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            ) : type ===
                              "multiSelectEachAdditionalInfoExceptLast" ? (
                              <>
                                {element.options.map((item, i) => (
                                  <Grid
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "0px 10px",
                                    }}
                                    container
                                    spacing={2}
                                    key={`${questionId}-${i}`}
                                  >
                                    <Grid
                                      style={{ padding: "6px" }}
                                      item
                                      xs={12}
                                      md={5}
                                      className={classes.textGroup}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                nextQuestionsValue?.length > 1
                                                  ? selectedOptions[questionId]
                                                    ? selectedOptions[
                                                        questionId
                                                      ].some(
                                                        (opt) =>
                                                          opt.value ===
                                                          item.value,
                                                      )
                                                    : false
                                                  : selectedOptions.some(
                                                      (opt) =>
                                                        opt.value ===
                                                        item.value,
                                                    )
                                              }
                                              onChange={(event) =>
                                                handleCheckboxChange(
                                                  event,
                                                  item.value,
                                                  questionId,
                                                )
                                              }
                                            />
                                          }
                                          label={item?.value}
                                        />
                                      </FormGroup>
                                    </Grid>
                                    {nextQuestionsValue?.length > 1
                                      ? selectedOptions[questionId] &&
                                        selectedOptions[questionId]?.some(
                                          (opt) => opt.value === item.value,
                                        ) && (
                                          <Grid
                                            item
                                            xs={12}
                                            md={7}
                                            style={{ padding: "6px" }}
                                            className={classes.textGroup}
                                          >
                                            <TextField
                                              style={{ marginTop: "0px" }}
                                              name={element.questionId}
                                              size="small"
                                              className={classes.txtForm}
                                              fullWidth
                                              multiline
                                              placeholder="Additional Info"
                                              variant="outlined"
                                              value={
                                                nextQuestionsValue?.length > 1
                                                  ? (selectedOptions[
                                                      questionId
                                                    ] &&
                                                      selectedOptions[
                                                        questionId
                                                      ]?.find(
                                                        (opt) =>
                                                          opt.value ===
                                                          item.value,
                                                      )?.additionalInfo) ||
                                                    ""
                                                  : selectedOptions.find(
                                                      (opt) =>
                                                        opt.value ===
                                                        item.value,
                                                    )?.additionalInfo || ""
                                              }
                                              onChange={(event) =>
                                                handleAdditionalInfoChange(
                                                  event,
                                                  item.value,
                                                  questionId,
                                                )
                                              }
                                            />
                                          </Grid>
                                        )
                                      : selectedOptions.some(
                                          (opt) => opt.value === item.value,
                                        ) && (
                                          <Grid
                                            item
                                            xs={12}
                                            md={7}
                                            style={{ padding: "6px" }}
                                            className={classes.textGroup}
                                          >
                                            <TextField
                                              name={element.questionId}
                                              size="small"
                                              className={classes.txtForm}
                                              fullWidth
                                              multiline
                                              placeholder="Additional Info"
                                              variant="outlined"
                                              value={
                                                nextQuestionsValue?.length > 1
                                                  ? (selectedOptions[
                                                      questionId
                                                    ] &&
                                                      selectedOptions[
                                                        questionId
                                                      ]?.find(
                                                        (opt) =>
                                                          opt.value ===
                                                          item.value,
                                                      )?.additionalInfo) ||
                                                    ""
                                                  : selectedOptions.find(
                                                      (opt) =>
                                                        opt.value ===
                                                        item.value,
                                                    )?.additionalInfo || ""
                                              }
                                              onChange={(event) =>
                                                handleAdditionalInfoChange(
                                                  event,
                                                  item.value,
                                                  questionId,
                                                )
                                              }
                                            />
                                          </Grid>
                                        )}
                                  </Grid>
                                ))}
                              </>
                            ) : type === "freeText" ? (
                              <>
                                {element.valueOptions.map((item) => (
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      md={5}
                                      className={classes.textGroup}
                                    >
                                      <FormGroup>
                                        <FormControlLabel
                                          control={<Checkbox />}
                                          label={item?.value}
                                        />
                                      </FormGroup>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={7}
                                      className={classes.textGroup}
                                    >
                                      <TextField
                                        name={element.questionId}
                                        size="small"
                                        className={classes.txtForm}
                                        fullWidth
                                        placeholder="Additional Info"
                                        variant="outlined"
                                        value={
                                          assessmentForm[questionId]
                                            ?.additionalInfo || ""
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                              </>
                            ) : type === "reviewTableNoActionAcknowledge" ? (
                              <>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className={classes.textGroup}
                                  >
                                    <TableContainer
                                      style={{ marginTop: "16px" }}
                                      sx={{ boxShadow: 0 }}
                                      component={Paper}
                                    >
                                      <Table
                                        sx={{ minWidth: "100%", boxShadow: 0 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead
                                          style={{ backgroundColor: "#EDFDFA" }}
                                        >
                                          <TableRow>
                                            {element.tableData.columns.map(
                                              (column) => (
                                                <TableCell
                                                  key={column}
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {column}
                                                </TableCell>
                                              ),
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {element.tableData.rows.length > 0 ? (
                                            element.tableData.rows.map(
                                              (row, index) => {
                                                const groupName = row[0]; // 'Identified Groups' is the first item
                                                const impactsText = row[1]; // 'Impact' is the second item
                                                const notesText = row[2]; // 'Notes' is the third item

                                                // Check if impacts and notes exist and are strings before splitting
                                                const impacts =
                                                  impactsText &&
                                                  typeof impactsText ===
                                                    "string"
                                                    ? impactsText.replace(/\n+/g, '\n').split("\n")
                                                    : [""];
                                                const notes =
                                                  notesText &&
                                                  typeof notesText === "string"
                                                    ? notesText.replace(/\n+/g, '\n').split("\n")
                                                    : [""];

                                                // Determine the number of rows needed
                                                const numRows = Math.max(
                                                  impacts.length,
                                                  notes.length,
                                                );
                                                return Array.from({
                                                  length: numRows,
                                                }).map((_, impactIndex) => (
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    key={`${index}-${impactIndex}`}
                                                  >
                                                    {impactIndex === 0 && (
                                                      <TableCell
                                                        align="left"
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "left",
                                                          whiteSpace:
                                                            "pre-line",
                                                          verticalAlign: "top",
                                                          width: "30%",
                                                        }}
                                                        rowSpan={numRows}
                                                      >
                                                        {groupName}
                                                      </TableCell>
                                                    )}
                                                    <TableCell
                                                      align="left"
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        textAlign: "left",
                                                        whiteSpace: "pre-line",
                                                        verticalAlign: "top",
                                                        width: "35%",
                                                      }}
                                                    >
                                                      {impacts[impactIndex] ||
                                                        ""}
                                                    </TableCell>
                                                    <TableCell
                                                      align="left"
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        textAlign: "left",
                                                        whiteSpace: "pre-line",
                                                        verticalAlign: "top",
                                                        width: "35%",
                                                      }}
                                                    >
                                                      {notes[impactIndex] || ""}
                                                    </TableCell>
                                                  </TableRow>
                                                ));
                                              },
                                            )
                                          ) : (
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <TextField
                                  name={questionId}
                                  // Add necessary props and validation as needed...
                                  value={value}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setAssessmentForm((prevForm) => ({
                                      ...prevForm,
                                      [questionId]: {
                                        ...prevForm[questionId],
                                        value: value,
                                      },
                                    }));
                                    // Other logic...
                                  }}
                                />
                                {/* Error message logic... */}
                              </>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  );
                })}
              {isAssessmentCompleted && (nextSection === "dataReview" || isDataReviewCompleted) && (
                <Grid
                  style={{
                    borderBottom: "0px solid #B6B6B6",
                    backgroundColor: "#F8FFFE",
                    padding: "10px",
                  }}
                  container
                  xs={12}
                  md={12}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ color: "red" }}>Notes:-</span> You have
                    completed the questions section. Please now move on to the
                    Data review section to continue your EHIA
                  </Typography>
                </Grid>
              )}
            </Grid>

            {nextSection === "assessment" ? (
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  visibility: isLoading ? "hidden" : "visible",
                  backgroundColor: isLoading
                    ? "rgb(200 232 221 / 30%)"
                    : "#fff",
                }}
              >
                <Button
                  style={{
                    float: "right",
                    marginRight: "20px",
                    marginBottom: "20px",
                  }}
                  className={classes.rightButton}
                  onClick={(e) => submitHandler(e)}
                  variant="contained"
                  size="large"
                  color="secondary"
                  elevation={0}
                >
                  Next Question
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Widget>
          <ApiResponsePopup
            {...{
              hideHandlerRes,
              isRecordUpdated: isShow,
              title: "Assessment",
              successMsg: successMessage,
              errorRes: errorApiMessage,
              // errorRes: apiError?.message,
            }}
          />
        </>
      </Grid>
      {type === "add" ? (
        <Grid className={classesAdd.btnGroup} item xs={12}>
          <Button
            className={classesAdd.leftBluetButton}
            variant="outlined"
            size="large"
            onClick={(e) => setActiveTabId(0)}
          >
            Previous
          </Button>
          <Button
            className={classesAdd.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isAssessmentCompleted && (nextSection === "dataReview" || isDataReviewCompleted) && (
            <Button
              className={classesAdd.btnlRightDark}
              variant="contained"
              size="large"
              color="secondary"
              onClick={(e) => setActiveTabId(2)}
            >
              Go to Data Review Section
            </Button>
          )}
        </Grid>
      ) : (
        <Grid className={classesDetail.btnGroup} item xs={12}>
          <Button
            className={classesDetail.btnleftOutline}
            variant="outlined"
            style={{ marginRight: "10px" }}
            // onClick={(e) => setActiveTabId(0)}
            onClick={(e) => actionCancel(e)}
          >
            Cancel
          </Button>
          <Button
            className={classesDetail.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isAssessmentCompleted && (
            <Button
              className={classesDetail.btnlRightDark}
              variant="contained"
              color="secondary"
              onClick={(e) => setActiveTabId(2)}
            >
              Go to Data Review Section
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}
