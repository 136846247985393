import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Tabs, Tab, Paper, Button } from "@material-ui/core";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import Introduction from "./QuestionsView/Introduction";
import Assessment from "./QuestionsView/Assessment";
import DataReview from "./QuestionsView/DataReview";
import Engagement from "./QuestionsView/Engagement";
import Result from "./QuestionsView/Result";

import { getEhiiaProjectByProjectIdwithSectionId } from "../../../store/actions/ehiiaAction";
// styles
import useStyles from "./styles";

export default function ViewProjectDetails() {
  const { projectId } = useParams();
  console.log("projectId", projectId);
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var [activeTabId, setActiveTabId] = useState(0);
  var classes = useStyles();

  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.ehiia);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [projectSummaryData, setProjectSummaryData] = useState([]);
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [ehiiaIntroductionDetail, setEhiiaIntroductionDetail] = useState(null);
  const [ehiiaAssessmentDetail, setEhiiaAssessmentDetail] = useState(null);
  const [ehiiaDataReviewDetail, setEhiiaDataReviewDetail] = useState(null);
  const [engagementDetail, setEngagementDetail] = useState(null);
  const [ehiiaResultDetail, setEhiiaResultDetail] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [setionStatus, setSectionStatus] = useState([]);
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);

    setClientAdminPath(clientAdminPath);
  }, []);
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        setProjectSummaryData(payload?.data?.projectSummary);
        setEhiiaProjectDetail(payload?.data?.form);
        setEhiiaIntroductionDetail(payload?.data?.form?.introduction);
        setEhiiaAssessmentDetail(payload?.data?.form?.assessment?.questions);
        setEhiiaDataReviewDetail(payload?.data?.form?.dataReview?.questions);
        setEngagementDetail(payload?.data?.form?.engagement?.questions);
        setEhiiaResultDetail(payload?.data?.form?.finalApproval);
        setSectionStatus(payload?.data?.form?.sections);
        setNextSection(payload?.data?.form?.nextSection);
      }
    });
  }, []);
  useEffect(() => {
    console.log("ehiiaProjectDetail-1", ehiiaProjectDetail);
  }, [ehiiaProjectDetail]);
  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(
        getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
      ).then(
        ({ payload }) => setEhiiaProjectDetail(payload?.data?.form),
        // setEhiiaIntroductionDetail(payload?.data?.data?.form?.introduction),
      );
    }
  }, [isRecordUpdated]);
  const gotoEdit = () => {
    navigate(`/${clientAdminPath==='staff' ?'staff':'admin'}/edit-ehiia/${projectId}`);
  };
  const downloadRepost=()=>{
    const downloadLink = ehiiaResultDetail?.overview?.downloadReportLink;
    if (downloadLink) {
      window.open(downloadLink, '_blank'); // Opens the link in a new tab
    }
    
  }

  const [headerTitle, setHeaderTitle] = useState('Complete Projects >');
useEffect(()=>{
if(projectSummaryData){
  const statusItem = projectSummaryData.find(item => item?.value === 'In-Progress');
  if (statusItem) {
    const status = statusItem.value;

    if (status === 'In-Progress') {
      setHeaderTitle('Ongoing Projects >');
    } else if (status === 'Complete') {
      setHeaderTitle('Completed Projects >');
    } else {
      setHeaderTitle('Complete Projects >');
    }
  }
}
},[projectSummaryData])
  const renderTabContent = (id, tabKey) => {
    switch (tabKey) {
      case "introduction":
        return (<Grid container spacing={4}>
          <Grid className={classes.gridItem} item xs={12}>
            <Widget>
              <Introduction
                ehiiaIntroductionDetail={ehiiaIntroductionDetail}
              />
            </Widget>
          </Grid>
        </Grid>)
      case "assessment":
        return ( <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget>
              <Assessment ehiiaAssessmentDetail={ehiiaAssessmentDetail} />
            </Widget>
          </Grid>
        </Grid>)
      case "dataReview":
        return (<Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget>
              <DataReview ehiiaDataReviewDetail={ehiiaDataReviewDetail} />
            </Widget>
          </Grid>
        </Grid>)
      case "engagement":
        return ( <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget noBodyPadding>
              <Engagement engagementDetail={engagementDetail} />
            </Widget>
          </Grid>
        </Grid>)
      case "approvalResult":
        return (<Grid container spacing={4}>
          <Grid item xs={12}>
            {/* <Widget noBodyPadding> */}
            <Result ehiiaResultDetail={ehiiaResultDetail} projectSummaryData={projectSummaryData} />
            {/* </Widget> */}
          </Grid>
        </Grid>)
      default:
        return null
    }
  }
  return (
    <>
      <PageTitle
        subtitle={`${headerTitle}`}
        title="Details"
        urlLink={`/${clientAdminPath==='staff'?'staff':'admin'}/manage-module/ehiia`}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          {projectSummaryData?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={index % 2 === 0 ? 4 : 5}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {" "}
                {item?.title}:
              </Typography>
              <Typography className={classes.formHeadingValue}>
                {item?.title === "Status" ? (
                  <>
                    {item?.value === "In-Progress" ? (
                      <span style={{ color: "#FEAA00" }}>{item?.value}</span>
                    ) : (
                      <span style={{ color: "#10940D" }}>{item?.value}</span>
                    )}
                  </>
                ) : (
                  <>{item?.value}</>
                )}
              </Typography>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            md={3}
            className={classes.textGroup}
            style={{ display: "block" }}
          >
            <Button  onClick={downloadRepost}
              style={{
                float: "right",
                backgroundColor: "#FEAA00",
                width: "110px",
                height: "34px",
                textTransform: "none",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
             
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </Widget>
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBar}
        >
          {setionStatus.map((section, index) => (
            <Tab
              key={index}
              label={section.title}
              classes={{ root: classes.tab }}
              disabled={
                section.completed
                  ? false
                  : section.value === nextSection
                  ? false
                  : true
              } // Disable the tab if the section is not completed
              onClick={() => setActiveTabId(index)}
            />
          ))}
        </Tabs>
        {setionStatus.map((section, index) => (
            activeTabId === index && renderTabContent(index,section?.value)
        ))}
       
      </Paper>
    </>
  );
}
