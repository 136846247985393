import React from 'react'
import EhiiaCase from '../../../../user-admin/pages/ehiia/Cases/EhiiaCase'

export default function EhiiaForm() {
  return (
    <div>
      <EhiiaCase/>
    </div>
  )
}
