import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,Fade
} from "@material-ui/core";
// components
import EhiiaList from "../Ehiia/EhiiaList";
import Widget from "../../../component/Widget";
import PageTitle from "../../../component/PageTitle";
import editIcon from "../../../images/imgEdit.svg";
// api
import Validation from "../../hooks/Validation";
import { updateProfile, getProfile } from "../../store/actions/adminAction";
import { adminReset } from "../../store/reducerSlice/admin";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";

// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../component/common/loader/Loader";
export default function ProfileDetails() {
  const navigate = useNavigate();
  var classes = useStyles();
  const { addToast } = useToasts();
  const [profileImage, setLogo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const inputRef = useRef();
  const [successMessage, setSuccessMessage] = useState(null);
  const [adminDetail, setAdminDetail] = useState({
    name: null,
    email: null,
    clientOrgShortName: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: adminDetail.name,
      email: adminDetail.email,
      // clientOrgShortName:adminDetail.clientOrgShortName
    });
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setAdminDetail(payload?.data?.data);
      }
      console.log("adminDetail", adminDetail);
    });
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  //Api call
  useEffect(() => {
    setIsUploading(true);
    console.log("file profileImage", profileImage);
    let formData = new FormData();
    if (adminDetail?.adminId) {
      formData.append("clientId", adminDetail?.clientId);
    }
    formData.append("name", adminDetail?.name);
 

    console.log("formData-", ...formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateProfile(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.data?.success) {
          const toastHeight = payload?.data?.message.length > 35 ? 'auto' : '34px';
          setIsUploading(true);
          setSuccessMessage(payload?.data?.message);
          // hideHandler();
          // setIsShow(true);
          addToast(payload?.data?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
        } else {
          const toastHeight = payload?.data?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.data?.message);
          // setIsShow(true);
          addToast(payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
        }
      });
      setIsActionCalled(false);
     
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandlerRes = () => {
    if (isDone) {
      dispatch(adminReset());
      navigate("/profile");
    } else {
      // navigate("/profile");
    }
    setIsShow(false);
  };
  const cancelHandler = () => {
    navigate(`/staff/dashboard`);
  };
  return (
    <>
   {isLoader && <Loader />}
      <PageTitle title="Profile" />
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            {logoUrl ? (
              <Avatar className={classes.avatar} src={logoUrl} />
            ) : adminDetail?.org?.logo ? (
              <Avatar className={classes.avatar} src={adminDetail?.org?.logo} />
            ) : (
              <Avatar className={classes.avatar} src="/photo.svg" />
            )}
            {/* <IconButton
              className={classes.uploadIcon}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <img src={editIcon} />
            </IconButton> */}
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Organisation Name
                </Typography>
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Organisation Name"
                  variant="outlined"
                  value={adminDetail?.org?.name}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Name</Typography>
                <TextField
                  name="name"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  value={adminDetail?.name}
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.name}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.name}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Email Id
                </Typography>
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  value={adminDetail?.email}
                />
              </Grid>

              <Grid
                className={classes.btnGroup}
                container
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  className={classes.leftButton}
                  variant="outlined"
                  size="large"
                  onClick={(e)=> cancelHandler(e)}
                >
                  Cancel
                </Button>
                <Button
                 onClick={(e) => submitHandler(e)}
                  className={classes.rightButton}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Widget>
      {/* <EhiiaList /> */}
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordAdded: isShow,
          title: "Profile Update",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
