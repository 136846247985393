import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../store/actions/clientsAction";
// styles
import useStyles from "./styles";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  const dispatch = useDispatch();
  console.log("toggleActivateStatus", toggleActivateStatus);
  const { error: apiError, isLoader } = useSelector((state) => state.clients);

  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        orgId: toggleActivateRec,
        // status:toggleActivateStatus,
      }),
    ).then(() => {
      onHide();
      setToggleActivateModalShow(false);
    });
  };
  var classes = useStyles();

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent className={classes.dialogBoxContent}>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            <Typography className={classes.formActiveHeading}>
              {toggleActivateStatus ? (
                <>Are you sure, you want to deactivate this client?</>
              ) : (
                <>Are you sure, you want to activate this client?</>
              )}
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
