import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;
export const fetchSubAdminList = createAsyncThunk(
  "subadmins/fetchSubAdminList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/org-admins/v1?search=${arg?.search}&page=${arg?.page}&orderIn=${arg?.orderIn}&orderBy=${arg?.orderBy}`,
      arg,
      rejectWithValue,
    ),
);

export const createUpdateSubAdmin = createAsyncThunk(
  "subadmins/createUpdateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org-admins/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const updateSubAdmin = createAsyncThunk(
  "subadmins/updateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org-admins/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const deleteSubAdmin = createAsyncThunk(
  "subadmins/deleteSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(`/admin/org-admins/v1/${arg?.adminId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const toggleActivation = createAsyncThunk(
  "subadmins/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/admin/org-admins/v1/toggle-status/${arg?.adminId}`,
      arg,
      
    ).then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data)),
);

export const sendActivationLink = createAsyncThunk(
  "subadmins/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/admin/org-admins/v1/resend-account-mail/${arg?.adminId}`,
      arg,
      
    ).then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data)),
);

export const sendResetPasswordLink = createAsyncThunk(
  "subadmins/sendResetPasswordLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/clients/reset-password/${arg?.adminId}`,
      rejectWithValue,
    ),
);

export const getSubAdminBySubAdminId = createAsyncThunk(
  "clients/getSubAdminBySubAdminId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/admin/org-admins/v1/${arg?.adminId}`, rejectWithValue),
);
