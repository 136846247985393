import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Button,
  Link,IconButton
} from "@material-ui/core";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import editIcon from "../../../images/imgEdit.svg";
// styles
import useStyles from "./styles";
export default function ProjectEdit() {
    var classes = useStyles();
    return (
      <>
        <PageTitle subtitle='Project Repositories >' urlLink="project-details/" title="Edit" />
        <Widget>
        
          <Grid container spacing={2} className={classes.formBox}>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Project ID:</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth disabled
                placeholder="Enter project id"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
              Project Title:
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Enter project title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Project Lead:</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Enter project lead"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
              Sub Organisations:
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Enter organisation name"
                variant="outlined"
              />
            </Grid>
            
            
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Date Completed:</Typography>
              <TextField type='date'
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Enter status"
                variant="outlined"
              />
            </Grid>
            
          </Grid>
          
        </Widget>
        <div className={classes.footerBox}>
          <Button variant="outlined" size="large" className={classes.leftButton}>
            Cancel
          </Button>
          <Button variant="contained" size="large" color="secondary" className={classes.rightButton}>
            Update
          </Button>
        </div>
      </>
    );
}
