import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
// Model
import { DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";

import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
// styles
import useStyles from "./styles";
import AlertConfirmation from "./AlertConfirmation";
import { useToasts } from "react-toast-notifications";

export default function ManageAssessment(props) {
  //
  console.log("props-24", props);
  const { selectedQuestion } = props;

  console.log(
    "props.selectedQuestion.questionId",
    props.selectedQuestion?.questionId,
  );
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(""); // State variable to track the ID of the next question
  const [selectedOption, setSelectedOption] = useState(""); // State variable to track selected option
  const [additionalSingleInfo, setAdditionalSingleInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]); // state variable for multiple checkbox and his textfield
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  var classes = useStyles();
  const { onHide, ...props1 } = props;

  useEffect(() => {
    const questions = [];
    selectedQuestion &&
      selectedQuestion?.options.map((q) => {
        questions.push({
          value: q.value,
          additionalInfo: q.additionalInfo,
          selected: q.selected,
        });
      });
    setSelectedOptions(questions);
  }, [selectedQuestion]);

  //for checkbox
  const handleCheckboxChange = (event, optionValue) => {
    const isChecked = event.target.checked;

    if (optionValue === "No" && isChecked) {
      setSelectedOptions((prevSelected) =>
        prevSelected.map((opt) => ({
          ...opt,
          selected: opt.value === optionValue ? true : false,
          additionalInfo: "",
        })),
      );
    } else {
      setSelectedOptions((prevSelected) =>
        prevSelected.map((opt) => ({
          ...opt,
          selected:
            opt.value === optionValue
              ? isChecked
              : opt.value === "No"
              ? false
              : opt.selected,
          additionalInfo:
            opt.value === optionValue && !isChecked
              ? ""
              : optionValue === "No"
              ? ""
              : opt.additionalInfo,
        })),
      );
    }
  };

  //for radio buttons

  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    // Update the selectedQuestion value and additionalInfo
    props.setSelectedQuestion((prevQuestion) => ({
      ...prevQuestion,
      value: selectedOption,
      // additionalInfo: "",
    }));
  };

  const handleAdditionalInfoChange = (event, optionValue) => {
    const additionalInfoValue = event.target.value.replace(/\n/g, '');

    // Update the additionalInfo field for the corresponding option in the selectedOptions array
    setSelectedOptions((prevSelected) =>
      prevSelected.map((opt) =>
        opt.value === optionValue
          ? { ...opt, additionalInfo: opt?.selected ? additionalInfoValue.replace(/\n/g, '') : "" }
          : opt,
      ),
    );
  };

  const handleAdditionalSingleInfoChange = (event) => {
    setAdditionalSingleInfo(event.target.value.replace(/\n/g, ''));
  };

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();

    // Clear all field values
    setSelectedOptions([]);
    setSelectedValue("");
    setAdditionalSingleInfo("")
    console.log("clear radio button");
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  // Add assessment form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    const questionId = props.selectedQuestion.questionId; // Get the questionId from the first element of nextQuestionsValue array
    const selectedValue = props.selectedQuestion.value; // Get the selected value from the assessmentForm state
    const selectCheckedValue = props.selectedQuestion.valueOptions;
    const selectAdditionInfoValue = props.selectedQuestion.additionalInfo;
    console.log("selectedValue", selectedValue);
    console.log("selectedOption", selectedOption);
    console.log("selectCheckedValue", selectCheckedValue);
    const filteredArr = selectedOptions.filter((opt) => opt.selected);
    const mergedOptions = [...filteredArr];

    if (selectCheckedValue) {
      // Add selectCheckedValue to mergedOptions array
      mergedOptions.push(...selectCheckedValue);
    }
    const data = {
      projectId: props.projectIdValue,
      sectionId: "assessment",
      questions: {
        assessment: {
          questionId: questionId,
          value: selectedOption ? selectedOption : selectedValue,
          options: mergedOptions,
          additionalInfo: additionalSingleInfo
            ? additionalSingleInfo
            : selectAdditionInfoValue,
        },
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          hideHandler();
          setSelectedValue("");
          setSelectedOptions([]);
          setSuccessMessage(payload?.message);
          // setIsShow(true);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          setErrorApiMessage(null);
          // Set the previous questions by concatenating the current nextQuestionsValue with the previousQuestions array
          props.setPreviousQuestions((prevQuestions) => [
            ...prevQuestions,
            // ...nextQuestionsValue,
          ]);

          // // Update the nextQuestionsValue with the new set of questions
          // props.setNextQuestionsValue(payload?.data?.form?.nextQuestions);
          // props.setAssessmentForm((prevForm) => ({
          //   ...prevForm,
          //   [payload?.data?.nextQuestions[0].questionId]: {
          //     ...prevForm?.[payload?.data?.nextQuestions[0].questionId],
          //     value: '',
          //   },
          // }));
          props.setUpdated(true);
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
          // setIsShow(true);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };

  return (
    <>
   
      <AlertConfirmation
        // onHide={hideHandle}
        setIsActionCalled={setIsActionCalled}
        setAlertModalShow={setAlertModalShow}
        alertModalShow={alertModalShow}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        alert ={
          <>
          {selectedQuestion?.reset ? (
              <Typography
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Note:- If you edit this question, all your next questions will
                be reset and you will have to answer again.
              </Typography>
            ) : (
              ""
            )}
          </>
        }
        footer={
          <>
          
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Save Changes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText
            className={classes.formAddBox}
            style={{ padding: "10px 4px" }}
          >
            {selectedQuestion && (
              <Grid item xs={12} md={12} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  {selectedQuestion.questionSerial} {selectedQuestion.title}
                </Typography>
                {selectedQuestion.type === "singleSelect" ? (
                  <>
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={selectedQuestion?.questionId}
                        className={classes.selectDropdown}
                        value={selectedQuestion?.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSelectedOption(value);
                          const selectedOptionObj =
                            props.nextQuestionsValue.find(
                              (option) => option.value === value,
                            );
                          // if (selectedOptionObj) {
                          //   const nextQuestionIds =
                          //     selectedOptionObj.nextQuestionId;
                          //   if (nextQuestionIds && nextQuestionIds.length > 0) {
                          //     const nextQuestionId = nextQuestionIds[0];
                          //     setNextQuestionId(nextQuestionId);
                          //   }
                          // }
                          props.setSelectedQuestion((prevForm) => ({
                            ...prevForm,
                            value: value,
                          }));
                        }}
                        // Add necessary props and validation as needed...
                      >
                        <MenuItem value="">select</MenuItem>
                        {selectedQuestion?.options &&
                          selectedQuestion?.options.map((item) => (
                            <MenuItem
                              questionId={item.value}
                              value={item.value}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {/* Error message logic... */}
                  </>
                ) : selectedQuestion.type ===
                  "singleSelectAdditionalInfoExceptLast" ? (
                  <>
                    <Grid container spacing={2} style={{ marginTop: "4px" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginLeft: "10px" }}
                      >
                        {selectedQuestion.options.map((item) => (
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.textGroup}
                          >
                            <FormControlLabel
                              value={item.value}
                              control={<Radio />}
                              label={item.title}
                              checked={selectedQuestion.value === item.value}
                              // checked={selectedValue === item.value}
                              onChange={handleRadioChange}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12} md={12} className={classes.textGroup}>
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Additional Info"
                          variant="outlined"
                          multiline
                          rows={4}
                          value={
                            additionalSingleInfo
                              ? additionalSingleInfo
                              : selectedQuestion.additionalInfo
                          }
                          onChange={handleAdditionalSingleInfoChange}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : selectedQuestion.type ===
                  "multiSelectEachAdditionalInfoExceptLast" ? (
                  <>
                    {selectedOptions &&
                      selectedOptions.map((item) => {
                        const optionChecked = item?.selected;
                        const optionAdditionalInfo = item?.additionalInfo;

                        return (
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              className={classes.textGroup}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={optionChecked}
                                      onChange={(event) =>
                                        handleCheckboxChange(event, item.value)
                                      }
                                    />
                                  }
                                  label={item?.value}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={7}
                              className={classes.textGroup}
                            >
                              <TextField
                                name={selectedQuestion.questionId}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                multiline
                                placeholder="Additional Info"
                                variant="outlined"
                                value={
                                  item?.selected ? item?.additionalInfo : ""
                                }
                                onChange={(event) =>
                                  handleAdditionalInfoChange(event, item.value)
                                }
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                  </>
                ) : selectedQuestion.type === "freeText" ? (
                  <>
                    {selectedQuestion.valueOptions.map((item) => (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5} className={classes.textGroup}>
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={item?.value}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={7} className={classes.textGroup}>
                          <TextField
                            name={selectedQuestion.questionId}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            multiline
                            placeholder="Additional Info"
                            variant="outlined"
                            value={selectedQuestion?.additionalInfo || ""}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    "{" "}
                    <TextField
                      name={selectedQuestion?.questionId}
                      // Add necessary props and validation as needed...
                      // value={value}
                      //   onChange={(e) => {
                      //     const value = e.target.value;
                      //     setAssessmentForm((prevForm) => ({
                      //       ...prevForm,
                      //       [questionId]: {
                      //         ...prevForm[questionId],
                      //         value: value,
                      //       },
                      //     }));
                      //     // Other logic...
                      //   }}
                    />
                    "{/* Error message logic... */}
                  </>
                )}
              </Grid>
            )}
            {/* Name */}
            
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Update Assessment",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
