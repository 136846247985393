import React, { useState } from "react";
import { Button, Link, InputBase } from "@material-ui/core";
import { ArrowForwardIos, Search as SearchIcon } from "@material-ui/icons";
import classNames from "classnames";
import download from "./download.svg";
import editIcons from "./whiteEdit.png";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);
  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} weight="medium">
        <Link
          href={props.urlLink}
          className={classes.typoGray}
          underline="none"
        >
          {props.subtitle}{" "}
        </Link>{" "}
        {props.title}
      </Typography>
      <div className={classes.groupBtn}>
        {props.button1 && (
          <Button
            classes={{ root: classes.button1 }}
            variant="contained"
            color="secondary"
            onClick={props.onClickDownload}
          >
            <img className={classes.downloadImg} src={download} alt='img'/>
            {props.button1}
          </Button>
        )}
        {props.search && (
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
        )}
        {props.button && (
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={props.onClick}
          >
            {props.editIcon && (
              <img className={classes.editicons} src={editIcons} alt="img"/>
            )}
            {props.button}
          </Button>
        )}
      </div>
    </div>
  );
}
