import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// styles
import useStyles from "./styles";

// context
import { useUserDispatch, loginUser } from "../../../context/UserContext";

function Registration(props) {
  var classes = useStyles();
  const formElements = [
    {
      key: "org_name",
      label: "Organisation Name",
      placeHolder: "Enter Organisation Name",
      type: "text",
    },
    {
      key: "confirmPassword",
      label: "Sub-Organisation",
      placeHolder: "Select",
      type: "select",
      options: ["MSEFT", "MSEICB", "EPUT", "PROVIDE"],
    },
    {
      key: "project_title",
      label: "Project Title",
      placeHolder: "Enter Project Title",
      type: "text",
    },
    {
      key: "description",
      label: "Description",
      placeHolder: "Type here....",
      type: "textarea",
    },
    {
      key: "first_name",
      label: "First Name",
      placeHolder: "Enter First Name",
      type: "text",
    },
    {
      key: "last_name",
      label: "Last Name",
      placeHolder: "Enter Last Name",
      type: "text",
    },
    {
      key: "email",
      label: "Email ID",
      placeHolder: "Enter Email ID",
      type: "text",
    },
  ];
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const history = useNavigate();
  const handleOnSubmit = () => {
    history.push(`/register-succes`);
  };
  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.topBar}>
          <Box sx={{ textAlign: "center", m: 1 }}>Client Organisation Name</Box>
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <Typography className={classes.subGreeting}>
          <Box sx={{ textAlign: "center", m: 1 }}>
            Portal User Registration Request Form
          </Box>
        </Typography>
        <div elevation={4} className={classes.form}>
          <React.Fragment>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :(
              </Typography>
            </Fade>
            {formElements.map((element, i) => {
              return (
                <div key={i}>
                  <Typography variant="h6" className={classes.txtHeading}>
                    {element.label}
                  </Typography>
                  {element.type == "select" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.name}
                        className={classes.selectDropdown}
                        value={value}
                        onChange={handleChange}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}> {item} </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type == "text" ? (
                    <TextField
                      InputProps={{
                        classes: {
                          border: classes.textFieldline,
                          input: classes.textField,
                        },
                      }}
                      value={loginValue}
                      onChange={(e) => setLoginValue(e.target.value)}
                      margin="normal"
                      placeholder={element.placeHolder}
                      type={element.type}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  ) : (
                    <TextField
                      InputProps={{
                        classes: {
                          border: classes.textFieldline,
                          input: classes.textField,
                        },
                      }}
                      value={loginValue}
                      onChange={(e) => setLoginValue(e.target.value)}
                      margin="normal"
                      placeholder={element.placeHolder}
                      type={element.type}
                      fullWidth
                      variant="outlined"
                      size="small"
                      multiline
                      rows={4}
                    />
                  )}
                </div>
              );
            })}

            <div className={classes.creatingButtonContainer}>
              <Button
                onClick={handleOnSubmit}
                size="large"
                variant="contained"
                color="primary"
                className={classes.createAccountButton}
              >
                Register
              </Button>
            </div>
            {/* <Box sx={{ textAlign: 'center', m: 2 }}>
            <Link href="#/login" className={classes.Link} underline="none">
   Back to login
</Link>
       </Box>     */}
          </React.Fragment>
        </div>
        {/* <div className={classes.bottomLink}>
          <Link className={classes.linkItem} href="#" underline="none">
            {"Privacy & terms"}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
            {"Contact Us"}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
            <Icons.Language className={classes.iconGlobal} />
            {"Change region"}
            <Icons.ArrowDropDown />
          </Link>
        </div> */}
      </div>
    </Grid>
  );
}

export default Registration;
