import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Model
import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import { Alert } from "@material-ui/lab";
// styles
import useStyles from "./styles";
import Loader from "../../../component/common/loader/Loader";
import { useToasts } from "react-toast-notifications";
import { deleteProjectLevel } from "../../store/actions/approvalAction";
export default function DeleteProjectPopup({
  deleteRec,
  onHide,
  deleteModalShow,
  setDeleteModalShow,subOrg,type
}) {
  console.log("deleteRec", deleteRec);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => {
    return state.approvals;
  });
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setIsAction(true);
    return dispatch(
      deleteProjectLevel({
        subOrg: subOrg,
        type:type,
        level:deleteRec?.level
      }),
    ).then(({payload}) => {
      console.log("deleteProjectLevel", payload);
      if(payload?.success){
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:toastHeight
          },
        });
      }else{
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "error",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }
      
    });
  };
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setDeleteModalShow(false);
    }
  }, [isRecordUpdated]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {apiError ? (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  Ok
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center>

            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
              </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
                Are you sure, you want to delete this project type?
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
