import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Validation from "../../hooks/Validation";
import { changePassword } from "../../store/actions/adminAction";
import { adminReset } from "../../store/reducerSlice/admin";
import { Grid, Button, TextField, Fade } from "@material-ui/core";
import * as Icons from "@material-ui/icons";

import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../../component/PageTitle";
import Widget from "../../../component/Widget";
import { Typography } from "../../../component/Wrappers";
import { useToasts } from "react-toast-notifications";
const formElements = [
  {
    key: "currentPassword",
    label: "Current Password",
    placeHolder: "Enter current password",
    type: "password",
  },
  {
    key: "newPassword",
    label: "New Password",
    placeHolder: "Enter new password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Confirm new password",
    type: "password",
  },
];
export default function ChangePassword() {
  var classes = useStyles();

  const [passwordDetail, setPasswordDetail] = useState({
    currentPassword: null,
    newPassword: null,
    confirmPassword: null,
  });
  const [eyes, setEyes] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  // const [eyes, setEyes] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation(passwordDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    adminDetail,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  const submitHandler = (e) => {
    console.log("actioncalled");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    console.log("actioncalled 58", isActionCalled);
    if (!isError() && error !== null && isActionCalled) {
      console.log("actioncalled 57", isActionCalled);
      const { confirmPassword, newPassword, currentPassword, ...rest } =
        passwordDetail;
      dispatch(
        changePassword({
          // adminId: adminDetail.adminId,
          // ...rest,
          newPassword: newPassword,
          currentPassword: currentPassword,
          // confirmPassword: confirmPassword,
        }),
      ).then((payload) => {
        // setIsShow(true);
        console.log("payload-", payload?.payload?.success);
        console.log("payload-", payload);
        if (payload?.payload?.success) {
          setSuccessMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:'34px'
            },
          });
          setIsDone(true);
          setPasswordDetail({
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
          });
          setErrorApiMessage(null);
        } else {
          console.log("error",error);
          setSuccessMessage(null);
          setErrorApiMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setPasswordDetail({
            currentPassword: null,
            newPassword: null,
            confirmPassword: null,
          });
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  const hideHandlerRes = () => {
    if (isDone) {
      dispatch(adminReset());
      // navigate("/super-admin/profile");
    } 
    setIsShow(false);
  };

  useEffect(() => {
    return () => {
      dispatch(adminReset());
    };
  }, []);
  const cancelHandler = () => {
    navigate(`/super-admin/dashboard`);
  };
  return (
    <>
   
      {/* <PageTitle title="Change Password" /> */}
      <Grid container spacing={4} style={{justifyContent:'center'}}>
        <Grid item xs={12} md={6}>
        <center><div style={{fontSize:'20px',fontWeight:'600',color:'#2A2338',marginBottom:'20px'}}>Change Password</div></center>
          <Widget disableWidgetMenu>
            {formElements.map((element, i) => {
              return (
                <div key={i}>
                  <Typography variant="h6" className={classes.text}>
                    {element.label} <span className={classes.required}>*</span>
                  </Typography>
                  <TextField
                    name={element.key}
                    placeholder={element.placeHolder}
                    InputProps={{
                      classes: {
                        border: classes.textFieldline,
                        input: classes.textField,
                      },
                    }}
                    margin="normal"
                    type={eyes[element.key] ? "text" : element.type}
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={
                      passwordDetail && passwordDetail[element.key] === null
                        ? ""
                        : passwordDetail && passwordDetail[element.key]
                    }
                    onChange={(e) => {
                      setPasswordDetail({
                        ...passwordDetail,
                        [element.key]: e.target.value,
                      });
                    }}
                    onKeyUp={(e) => {
                      if (
                        element.key === "newPassword" &&
                        passwordDetail?.confirmPassword != null
                      ) {
                        checkValidator();
                      } else {
                        checkValidator(element.key);
                      }
                    }}
                    isInvalid={
                      error &&
                      error !== null &&
                      typeof error[element.key] !== "object" &&
                      error[element.key]
                    }
                  />
                  <div
                    className={classes.eyeIcon}
                    onClick={(e) =>
                      setEyes({
                        ...eyes,
                        [element.key]: !eyes[element.key],
                      })
                    }
                  >
                    {eyes[element.key] ? (
                      <Icons.Visibility />
                    ) : (
                      <Icons.VisibilityOff />
                    )}
                  </div>
                  <Fade in={error} type="invalid">
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      {error &&
                        error !== null &&
                        typeof error[element.key] !== "object" &&
                        error[element.key]}
                    </Typography>
                  </Fade>
                  {element.key === "newPassword" && (
                    <Typography
                      className={`passwordRules ${
                        error && passwordDetail[element.key] && "valid"
                      }`}
                    >
                      <h4
                            style={{ textAlign: "left", marginBottom: "0px" }}
                          >
                            Your password must have:
                          </h4>
                          <table></table>
                          <table className={classes.warningMessage}>
                            <tr
                              style={{ display: "flex", alignItems: "left" }}
                            >
                              <td>
                                <p
                                  style={{ marginBottom: "0px",marginTop:'0px',textAlign:'left' }}
                                  className={
                                    passwordDetail[element.key]
                                      ? error &&
                                        error?.newPassword?.lowercase &&
                                        error[element.key]?.lowercase
                                        ? "invalid"
                                        : "valid"
                                      : ""
                                  }
                                >
                                  {!passwordDetail?.newPassword ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : error?.newPassword?.lowercase ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : (
                                    <Icons.CheckCircle
                                      style={{ color: "green", width: "16px" }}
                                    />
                                  )}{" "}
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "4px",
                                      color: "#999999",
                                    }}
                                  >
                                    At least one lowercase letter
                                  </span>
                                </p>
                              </td>
                              <td style={{textAlign:'left'}}>
                                <p
                                  style={{ margin: "0px", marginLeft: "12px",marginTop:'0px',textAlign:'left' }}
                                  className={
                                    passwordDetail[element.key]
                                      ? error &&
                                        error?.newPassword?.uppercase &&
                                        error[element.key]?.uppercase
                                        ? "invalid"
                                        : ""
                                      : ""
                                  }
                                >
                                  {!passwordDetail?.newPassword ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : error?.newPassword?.uppercase ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : (
                                    <Icons.CheckCircle
                                      style={{ color: "green", width: "16px" }}
                                    />
                                  )}{" "}
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "4px",
                                      color: "#999999",
                                    }}
                                  >
                                    At least one UPPERCASE letter
                                  </span>
                                </p>
                              </td>
                            </tr>
                            <tr
                              style={{ display: "flex", alignItems: "left" }}
                            >
                              <td>
                                <p
                                  style={{ margin: "0px",marginTop:'0px',textAlign:'left' }}
                                  className={
                                    passwordDetail[element.key]
                                      ? error &&
                                        error?.newPassword?.number &&
                                        error[element.key]?.number
                                        ? "invalid"
                                        : ""
                                      : ""
                                  }
                                >
                                  {!passwordDetail?.newPassword ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : error?.newPassword?.number ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : (
                                    <Icons.CheckCircle
                                      style={{ color: "green", width: "16px" }}
                                    />
                                  )}{" "}
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "4px",
                                      color: "#999999",
                                    }}
                                  >
                                    At least one number
                                  </span>
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{ margin: "0px", marginLeft: "60px",marginTop:'0px',textAlign:'left' }}
                                  className={
                                    passwordDetail[element.key]
                                      ? error &&
                                        error?.newPassword?.special &&
                                        error[element.key]?.special
                                        ? "invalid"
                                        : ""
                                      : ""
                                  }
                                >
                                  {!passwordDetail?.newPassword ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : error?.newPassword?.special ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : (
                                    <Icons.CheckCircle
                                      style={{ color: "green", width: "16px" }}
                                    />
                                  )}{" "}
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "4px",
                                      color: "#999999",
                                    }}
                                  >
                                    At least one special character
                                  </span>
                                </p>
                              </td>
                            </tr>
                            <p
                              style={{ marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.length &&
                                    error[element.key]?.length
                                    ? "invalid"
                                    : ""
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.length ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                Minimum 8 characters
                              </span>
                            </p>
                          </table>
                    </Typography>
                  )}
                </div>
              );
            })}
            <div className={classes.btnGroup}>
              <Button
                className={classes.leftButton}
                variant="outlined"
                size="large"
                onClick={(e) => cancelHandler(e)}
              >
                Cancel
              </Button>
              <Button
                onClick={(e) => submitHandler(e)}
                className={classes.rightButton}
                variant="contained"
                size="large"
                color="secondary"
              >
                Update
              </Button>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordAdded: isShow,
          title: "Reset Password",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
