import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { adminReset } from "../../store/reducerSlice/admin";

// styles
import useStyles from "../resetPassword/styles";

import lock from "../../../assets/icons/Lock2.svg";
import expireLink from "../../../assets/icons/checked.png";
import arrow from "../../../assets/icons/arrow.png";
import { useDispatch, useSelector } from "react-redux";
// api
import Validation from "../../hooks/Validation";
import { getOrgData } from "../../../store/actions/reviewsAction";
import { activateUser } from "../../store/actions/adminAction";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../component/common/loader/Loader";
const formElements = [
  {
    key: "email",
    label: "Email",
    placeHolder: "Enter email",
    type: "text",
  },
  // {
  //   key: "activationCode",
  //   label: "Enter Code",
  //   placeHolder: "Enter code",
  //   type: "text",
  // },
  {
    key: "newPassword",
    label: "Password",
    placeHolder: "Enter password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter confirm password",
    type: "password",
  },
];
function Activation() {
  const location = useLocation();
  const { addToast } = useToasts();
  const [orgDetails, setOrgDetails] = useState(null);
  const [email, setEmail] = useState(null);
  const [activationCode, setActivationCode] = useState(null);
  const [checkLinkExpired, setCheckLinkExpired] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [passwordDetail, setPasswordDetail] = useState({
    newPassword: null,
    confirmPassword: null,
    email: null,
    activationCode: null,
  });
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailValue = searchParams.get("email");
    const activationCodeValue = searchParams.get("activationCode");

    setEmail(emailValue);
    setActivationCode(activationCodeValue);

    console.log("Email:", emailValue);
    console.log("Activation Code:", activationCodeValue);
    setPasswordDetail((prevState) => ({
      ...prevState,
      email: emailValue,
      activationCode: activationCodeValue,
    }));
  }, [location]);
  useEffect(() => {
    console.log("email:", email);
    console.log("activationCode:", activationCode);
    if (email && activationCode) {
      dispatch(
        activateUser({
          // password: newPassword,
          email: email,
          activationCode: activationCode,
        }),
      ).then(({ payload }) => {
        console.log("payload page load-", payload);
        // setIsShow(true);
        if (payload?.success) {
          setCheckLinkExpired(false);
        } else {
          console.log("payload", payload);

          setCheckLinkExpired(true);
        }
      });
    }
  }, [email, activationCode]);
  var classes = useStyles();

  const [eyes, setEyes] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [activationKeyNotExist, setActivationKeyNotExist] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const navigate = useNavigate();
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      newPassword: passwordDetail?.newPassword,
      confirmPassword: passwordDetail?.confirmPassword,
      email: passwordDetail?.email,
      // activationCode: passwordDetail?.activationCode,
    });
  const dispatch = useDispatch();
  const {
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  const submitHandler = (e) => {
    console.log("actioncalled");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      console.log("passwordDetail", passwordDetail);
      console.log("email", email);
      console.log("activationCode", activationCode);
      const { newPassword } = passwordDetail;
      dispatch(
        activateUser({
          password: newPassword,
          email: passwordDetail?.email,
          activationCode: passwordDetail?.activationCode,
        }),
      ).then(({ payload }) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.success) {
          setSuccessMessage(payload?.message);
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          setTimeout(() => {
            setIsDone(true);
          }, 3000);
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          console.log("payload", payload);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
        }
      });
    } else {
      console.log("error", error);
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  useEffect(() => {
    if (isDone) {
      dispatch(adminReset());
      navigate("/");
      setIsDone(false);
    }
    setIsDone(false);
  }, [isDone]);
  const hideHandlerRes = () => {
    setIsShow(false);
    if (successMessage) {
      dispatch(adminReset());
      navigate("/");
    } else {
      setSuccessMessage(null);
      navigate("/");
    }
  };

  return (
    <>
      <Grid
        style={{
          height: checkLinkExpired ? "auto" : "100vh",
          backgroundColor: checkLinkExpired ? "white" : "#F9F9F9",
        }}
        
        className={classes.container}
      >
        <div
          className={classes.logotypeContainer}
          style={{ color: "#fff", textAlign: "center" }}
        >
          <span>{orgDetails?.name}</span>
        </div>
        {isLoader && <Loader />}
        {checkLinkExpired ? (
          <center>
            <img
              style={{ height: "64px", marginTop: "24px" }}
              src={expireLink}
              alt="expire link"
            />
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Typography
                style={{
                  color: "#03997B",
                  fontFamily: "Sanchez",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                Link has expired.
                <br />
                <span style={{ fontSize: "26px" }}>
                  You can close this page now.
                </span>
              </Typography>
            </div>
          </center>
        ) : (
          <div className={classes.formContainer} style={{ width: "100%" }}>
            <center>
              <Typography
                className={classes.subGreeting}
                style={{ color: "#03997B", fontFamily: "Sanchez" }}
              >
                <Box sx={{ textAlign: "center", m: 1, fontSize: "28px" }}>
                  Please set your password
                </Box>
              </Typography>
              <Typography
                className={classes.greeting}
                style={{ color: "#03997B", fontFamily: "Sanchez" }}
              >
                <Box sx={{ textAlign: "center", m: 1 }}>
                  and activate your account
                </Box>
              </Typography>
            </center>
            <div
              className={classes.form}
              style={{
                width: "480px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <React.Fragment>
                {/* <Box sx={{ textAlign: "center", m: 1 }}>
                  <img src={lock} alt="img" />
                </Box> */}

                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    &nbsp;
                  </Typography>
                </Fade>
                {formElements.map((element, i) => {
                  return (
                    <>
                      <Typography variant="h6" className={classes.txtHeading}>
                        {element.label}{" "}
                        <span className={classes.required}>*</span>
                      </Typography>
                      {element.type === "text" ? (
                        <>
                          <TextField
                            disabled
                            hiddenLabel
                            size="small"
                            type={element.type}
                            name={element.key}
                            placeholder={element.placeHolder}
                            className={classes.txtForm}
                            value={
                              passwordDetail &&
                              passwordDetail[element.key] === null
                                ? ""
                                : passwordDetail && passwordDetail[element.key]
                            }
                            fullWidth
                            variant="outlined"
                            onChange={async (e) => {
                              setPasswordDetail({
                                ...passwordDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);

                              console.log("passwordDetail", passwordDetail);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : (
                        <>
                          <TextField
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputProps={{
                              classes: {
                                border: classes.textFieldline,
                                input: classes.textField,
                              },
                            }}
                            name={element.key}
                            type={eyes[element.key] ? "text" : element.type}
                            placeholder={element.placeHolder}
                            value={
                              passwordDetail && passwordDetail[element.key]
                            }
                            onChange={(e) => {
                              setPasswordDetail({
                                ...passwordDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                            onKeyUp={(e) => {
                              checkValidator(element.key);
                            }}
                            isInvalid={
                              error &&
                              error !== null &&
                              typeof error[element.key] !== "object" &&
                              error[element.key]
                            }
                          />
                          <div
                            className={classes.eyeIcon}
                            onClick={(e) =>
                              setEyes({
                                ...eyes,
                                [element.key]: !eyes[element.key],
                              })
                            }
                          >
                            {eyes[element.key] ? (
                              <Icons.Visibility />
                            ) : (
                              <Icons.VisibilityOff />
                            )}
                          </div>
                          <Fade in={error} type="invalid">
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error &&
                                error !== null &&
                                typeof error[element.key] !== "object" &&
                                error[element.key]}
                            </Typography>
                          </Fade>

                          {element.key === "newPassword" && (
                            <Typography
                              className={`passwordRules ${
                                error && passwordDetail[element.key] && "valid"
                              }`}
                            >
                              <h4
                                style={{
                                  textAlign: "left",
                                  marginBottom: "4px",
                                }}
                              >
                                Your password must have:
                              </h4>
                              <table></table>
                              <table className={classes.warningMessage}>
                                <tr
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <td>
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        textAlign: "center",
                                      }}
                                      className={
                                        passwordDetail[element.key]
                                          ? error &&
                                            error?.newPassword?.lowercase &&
                                            error[element.key]?.lowercase
                                            ? "invalid"
                                            : "valid"
                                          : ""
                                      }
                                    >
                                      {!passwordDetail?.newPassword ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : error?.newPassword?.lowercase ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : (
                                        <Icons.CheckCircle
                                          style={{
                                            color: "green",
                                            width: "16px",
                                          }}
                                        />
                                      )}{" "}
                                      <span
                                        style={{
                                          marginTop: "0px",
                                          marginLeft: "4px",
                                          color: "#999999",
                                        }}
                                      >
                                        At least one lowercase letter
                                      </span>
                                    </p>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <p
                                      style={{
                                        margin: "0px",
                                        marginLeft: "12px",
                                        marginTop: "0px",
                                        textAlign: "center",
                                      }}
                                      className={
                                        passwordDetail[element.key]
                                          ? error &&
                                            error?.newPassword?.uppercase &&
                                            error[element.key]?.uppercase
                                            ? "invalid"
                                            : ""
                                          : ""
                                      }
                                    >
                                      {!passwordDetail?.newPassword ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : error?.newPassword?.uppercase ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : (
                                        <Icons.CheckCircle
                                          style={{
                                            color: "green",
                                            width: "16px",
                                          }}
                                        />
                                      )}{" "}
                                      <span
                                        style={{
                                          marginTop: "0px",
                                          marginLeft: "4px",
                                          color: "#999999",
                                        }}
                                      >
                                        At least one UPPERCASE letter
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <td>
                                    <p
                                      style={{
                                        margin: "0px",
                                        marginTop: "0px",
                                        textAlign: "center",
                                      }}
                                      className={
                                        passwordDetail[element.key]
                                          ? error &&
                                            error?.newPassword?.number &&
                                            error[element.key]?.number
                                            ? "invalid"
                                            : ""
                                          : ""
                                      }
                                    >
                                      {!passwordDetail?.newPassword ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : error?.newPassword?.number ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : (
                                        <Icons.CheckCircle
                                          style={{
                                            color: "green",
                                            width: "16px",
                                          }}
                                        />
                                      )}{" "}
                                      <span
                                        style={{
                                          marginTop: "0px",
                                          marginLeft: "4px",
                                          color: "#999999",
                                        }}
                                      >
                                        At least one number
                                      </span>
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      style={{
                                        margin: "0px",
                                        marginLeft: "60px",
                                        marginTop: "0px",
                                        textAlign: "center",
                                      }}
                                      className={
                                        passwordDetail[element.key]
                                          ? error &&
                                            error?.newPassword?.special &&
                                            error[element.key]?.special
                                            ? "invalid"
                                            : ""
                                          : ""
                                      }
                                    >
                                      {!passwordDetail?.newPassword ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : error?.newPassword?.special ? (
                                        <Icons.Cancel
                                          style={{
                                            color: "red",
                                            width: "16px",
                                          }}
                                        />
                                      ) : (
                                        <Icons.CheckCircle
                                          style={{
                                            color: "green",
                                            width: "16px",
                                          }}
                                        />
                                      )}{" "}
                                      <span
                                        style={{
                                          marginTop: "0px",
                                          marginLeft: "4px",
                                          color: "#999999",
                                        }}
                                      >
                                        At least one special character
                                      </span>
                                    </p>
                                  </td>
                                </tr>
                                <p
                                  style={{ marginTop: "0px" }}
                                  className={
                                    passwordDetail[element.key]
                                      ? error &&
                                        error?.newPassword?.length &&
                                        error[element.key]?.length
                                        ? "invalid"
                                        : ""
                                      : ""
                                  }
                                >
                                  {!passwordDetail?.newPassword ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : error?.newPassword?.length ? (
                                    <Icons.Cancel
                                      style={{ color: "red", width: "16px" }}
                                    />
                                  ) : (
                                    <Icons.CheckCircle
                                      style={{ color: "green", width: "16px" }}
                                    />
                                  )}{" "}
                                  <span
                                    style={{
                                      marginTop: "0px",
                                      marginLeft: "4px",
                                      color: "#999999",
                                    }}
                                  >
                                    Minimum 8 characters
                                  </span>
                                </p>
                              </table>
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  );
                })}

                <div
                  className={classes.creatingButtonContainer}
                  style={{ marginBottom: "6px" }}
                >
                  <Button
                    onClick={(e) => submitHandler(e)}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Activate
                  </Button>
                </div>
                <Box sx={{ textAlign: "center", m: 2 }}>
                  <Link
                    href="/"
                    className={classes.Link}
                    underline="none"
                  >
                    <img src={arrow} alt="arrow" />
                    &nbsp;&nbsp; Back to login
                  </Link>
                </Box>
              </React.Fragment>
              {/* <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage2}>
              {apiError?.message}
            </Typography>
          </Fade> */}
            </div>
          </div>
        )}

        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            successMsg: "User Activated Successfully",
            updateMsg: "Password successfully updated!",
            title: "Activation",
            errorRes: apiError?.message,
          }}
        />
      </Grid>
    </>
  );
}

export default Activation;
