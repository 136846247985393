import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Validation from "../../hooks/EhiiaValidation";
import { createUpdateSubAdmin } from "../../store/actions/subAdminAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";

import Loader from "../../../component/common/loader/Loader";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";

const formElements = [
  {
    key: "name",
    label: "Name",
    placeHolder: "Enter Name",
    type: "text",
  },

  {
    key: "email",
    label: "Email",
    placeHolder: "Enter Email ID",
    type: "text",
  },
];
export default function ManageAdmin(props) {
  console.log("props-24", props);
  const { selectmodule } = props;
  const { adminId } = useParams();
  console.log("selectmodule", selectmodule);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [moduleOption, setModuleOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  //
  const [ehiiaDetail, setEhiiaDetail] = useState({
    name: null,
    email: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: ehiiaDetail?.name,
      email: ehiiaDetail?.email,
    });
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const hideHandler = () => {
    resetAllValidator();
    console.log("hide handler called");
    props.onHide();
    setEhiiaDetail({ name: null, email: null });
    if (selectmodule === null) {
      setEhiiaDetail({
        name: null,
        email: null,
      });
    } else {
      setEhiiaDetail({
        adminId: selectmodule?.adminId,
        name: ehiiaDetail?.name,
        email: ehiiaDetail?.email,
      });
    }
    console.log("ehiiaDetail", ehiiaDetail);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    let formData = {
      name: ehiiaDetail?.name,
      email: ehiiaDetail?.email,
    };
    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(createUpdateSubAdmin(formData))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            hideHandler();
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: '99999',height:toastHeight
              },
            });
            // setIsShow(true);
            setErrorApiMessage(null);
            setEhiiaDetail({ name: null, email: null });
          } else {
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            console.log("payload?.error", payload);
            setErrorApiMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style:{
                height:toastHeight
              }
            });
            // setIsShow(true);
            // setEhiiaDetail({ name: null, email: null });
          }
        })
        .catch((error) => console.log("error", error));
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          {formElements.map((element, i) => {
            return (
              (!selectmodule || element.key !== "password") && (
                <DialogContentText className={classes.formBox}>
                  {/* name */}
                  <Typography className={classes.formHeading} key={i}>
                    {element.label}
                  </Typography>
                  {element.type == "textarea" ? (
                    <>
                      <TextField
                        type={element.type}
                        name={element.key}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder={element.placeHolder}
                        variant="outlined"
                        value={
                          ehiiaDetail && ehiiaDetail[element.key] === null
                            ? ""
                            : ehiiaDetail && ehiiaDetail[element.key]
                        }
                        onChange={async (e) => {
                          setEhiiaDetail({
                            ...ehiiaDetail,
                            [element.key]: e.target.value,
                          });
                          resetValidator(e.target.name);
                        }}
                        isInvalid={error && error[element.key]}
                      />
                      <Fade in={error}>
                        <Typography
                          color="secondary"
                          className={classes.errorMessage}
                        >
                          {error && error[element.key]}
                        </Typography>
                      </Fade>
                    </>
                  ) : element.type == "select" ? (
                    <>
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          value={ehiiaDetail && ehiiaDetail[element.key]}
                          onChange={async (e) => {
                            setEhiiaDetail({
                              ...ehiiaDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log("ehiiaDetail", ehiiaDetail);
                          }}
                          isInvalid={error && error[element.key]}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.key == "module" && moduleOption
                            ? moduleOption.map((item) => (
                                <MenuItem value={item?.moduleId}>
                                  {item?.name}
                                </MenuItem>
                              ))
                            : element.options.map((item) => (
                                <MenuItem value={item}> {item} </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                      <Fade in={error}>
                        <Typography className={classes.errorMessage}>
                          {error && error[element.key]}
                        </Typography>
                      </Fade>
                    </>
                  ) : (
                    <>
                      <TextField
                        type={element.type}
                        name={element.key}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder={element.placeHolder}
                        variant="outlined"
                        value={
                          ehiiaDetail && ehiiaDetail[element.key] === null
                            ? ""
                            : ehiiaDetail && ehiiaDetail[element.key]
                        }
                        onChange={async (e) => {
                          setEhiiaDetail({
                            ...ehiiaDetail,
                            [element.key]: e.target.value,
                          });
                          resetValidator(e.target.name);
                        }}
                        isInvalid={error && error[element.key]}
                      />
                      <Fade in={error}>
                        <Typography
                          color="secondary"
                          className={classes.errorMessage}
                        >
                          {error && error[element.key]}
                        </Typography>
                      </Fade>
                    </>
                  )}
                </DialogContentText>
              )
            );
          })}
        </DialogContent>
        {/* message popup */}
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Manage Admin",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
