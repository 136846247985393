import React from "react";
import ClientList from "../../components/Client/ClientList";

export default function Client() {
  return (
    <div>
      <ClientList />
    </div>
  );
}
