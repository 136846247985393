export const manageProjectSchema = {
  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter project name",
      noLeadingSpace: "Project name cannot start with a space",
      atLeastOneAlpha: "At least one alphabet character is required",
    },
  },

  engagementEmail: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  equalityEmail: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },
  reportEmail: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  description: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter description",
    },
  },
};
