import React, { useEffect, useRef, useState } from "react";
import { withRouter, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Apps,
  ExitToApp,
  LockOpen,
} from "@material-ui/icons";

import classNames from "classnames";
import ques from "./ques.svg";
import profileImg from "../../assets/icons/photo-89.png";
import whiteLogo from "../../assets/icons/ehiia-white-logo.svg";
// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../../component/Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../../component/UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { adminReset as superAdminReset } from "../../super-admin/store/reducerSlice/admin";
import { adminReset as clientAdminReset } from "../../client-admin/store/reducerSlice/admin";
import { adminReset as userAdminReset } from "../../user-admin/store/reducerSlice/admin";
import { getProfile as getSuperAdminProfile } from "../../super-admin/store/actions/adminAction";
import { getProfile as getClientAdminProfile } from "../../client-admin/store/actions/adminAction";
import { getProfile as getUserAdminProfile } from "../../user-admin/store/actions/adminAction";
const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  var classes = useStyles();
  const [subdomain, setSubdomain] = useState("");
  const navigate = useNavigate();
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);
    console.log("adminDetail", adminDetail);
    // Update the value of clientAdminPath using setClientAdminPath
    setClientAdminPath(clientAdminPath);

    if (!adminDetail?.success) {
      if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
        dispatch(getClientAdminProfile());
      } else if (clientAdminPath === "super-admin") {
        dispatch(getSuperAdminProfile());
      } else {
        console.log("called profile 5");
        dispatch(getUserAdminProfile());
      }
      // dispatch(getProfile());
    }
    console.log("admin", admin);
  }, []);
  const navigateProfile = () => {
    if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
      navigate(`/admin/profile`);
    } else if (clientAdminPath === "super-admin") {
      navigate(`/super-admin/profile`);
    } else {
      navigate(`/staff/profile`);
    }

    setProfileMenu(null);
  };
  const navigatePassword = () => {
    if (clientAdminPath === "admin") {
      navigate(`/admin/reset-password`);
    } else if (clientAdminPath === "super-admin") {
      navigate(`/super-admin/reset-password`);
    } else {
      navigate(`/staff/reset-password`);
    }

    setProfileMenu(null);
  };
  // api
  const admin = useSelector((state) => state.admin);
  console.log("admin", admin);
  const { adminDetail } = admin;

  const dispatch = useDispatch();
  const logoutRef = useRef();

  useEffect(() => {
    console.log("adminDetail", adminDetail);
  }, [adminDetail]);
  useEffect(() => {
    if (admin.invalidJwtToken) {
      localStorage.removeItem("admin");
      if (clientAdminPath === "admin") {
        dispatch(clientAdminReset());
      } else if (clientAdminPath === "super-admin") {
        dispatch(superAdminReset());
      } else {
        dispatch(userAdminReset());
      }
      // dispatch(adminReset());
      logoutRef.current.click();
      // navigate(`/login`);
    }
  }, [admin.invalidJwtToken]);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  return (
    <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <Apps
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography weight="medium" className={classes.logotype}>
          <img
            src={whiteLogo}
            style={{
              width: "97px",
              height: "19.45px",
              marginTop: "10px",
              marginLeft: "-10px",
            }}
            alt="logo-icon"
          />
        </Typography>
        <div style={{width:'100%', alignItems:'center',display:'flex',justifyContent:'center'}}>
        <Typography weight="medium" className={classes.logotype} >
          {clientAdminPath ==='admin' ? 'Client Admin Portal' : clientAdminPath==='super-admin' ? 'PD Admin Portal':' Staff Portal'}
        </Typography>
        </div>
        <div className={classes.grow} />
       
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
        {adminDetail?.data?.org ?
         <img
         src={adminDetail?.data?.org?.logo}
         classes={{ root: classes.headerIcon }}
         alt="img"
         style={{ height: "32px", width: "32px", borderRadius: "25px" }}
       />
        :
        <img
        src={profileImg}
        classes={{ root: classes.headerIcon }}
        alt="img"
        style={{ height: "32px", width: "32px", borderRadius: "25px" }}
      />
        }
          
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {adminDetail?.data?.name}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              href="#"
            >
              {adminDetail?.data?.email}
            </Typography>
          </div>
          <MenuItem
            onClick={navigateProfile}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            onClick={navigatePassword}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <LockOpen className={classes.profileMenuIcon} /> Change Password
          </MenuItem>
          <MenuItem
            ref={logoutRef}
            onClick={() => {
              localStorage.removeItem("authorization");
              if (clientAdminPath === "admin") {
                dispatch(clientAdminReset());

                // navigate(`/`);
              } else if (clientAdminPath === "super-admin") {
                dispatch(superAdminReset());
              } else {
                dispatch(userAdminReset());
                // navigate(`/`);
              }
              window.location.reload(true);
              // navigate('/login');
            }}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <ExitToApp className={classes.profileMenuIcon} /> Logout
          </MenuItem>

          {/* <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
             
            >
              Sign Out
            </Typography>
          </div> */}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
