import { createSlice } from "@reduxjs/toolkit";
import {
  fetchOngoingProjectList,
  fetchCompleteProjectList,
  getEhiiaProjectByProjectId,
  createEhiiaModule,
  getCareGroupByOrganization,fetchProjectRepoList,autoSuggestTag
} from "../actions/ehiiaAction";

export const ehiiaversions = createSlice({
  name: "ehiiaversions",
  initialState: {
    ehiiaversions: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchOngoingProjectList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchOngoingProjectList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log("payload", payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchOngoingProjectList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [fetchCompleteProjectList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchCompleteProjectList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchCompleteProjectList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },

    [fetchProjectRepoList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchProjectRepoList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchProjectRepoList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [getEhiiaProjectByProjectId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getEhiiaProjectByProjectId.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordLoaded = true;
      state.isLoader = false;
    },
    [getEhiiaProjectByProjectId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
    [createEhiiaModule.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createEhiiaModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createEhiiaModule.rejected]: (state, { payload }) => {
      console.log("payload-clients", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [getCareGroupByOrganization.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getCareGroupByOrganization.fulfilled]: (state, { payload }) => {
      console.log("payload-fulfil",payload)
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [getCareGroupByOrganization.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
    [autoSuggestTag.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [autoSuggestTag.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [autoSuggestTag.rejected]: (state, { payload }) => {
      console.log("payload-clients", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
  ehiiaversions.actions;

export default ehiiaversions.reducer;
