import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Fade,
} from "@material-ui/core";

// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../component/common/modal/Popup";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
import notes_bg from "../../images/notes_bg.png";
import { reviewFinalApprovalModule } from "../../user-admin/store/actions/reviewAction";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
import Loader from "../../component/common/loader/Loader";
import moment from "moment";
import ApprovalValidation from "../../client-admin/hooks/ApprovalValidation";
export default function FinalApprovalSendReview({
  onHide,
  reviewModalShow,
  setReviewModalShow,

  projectId,
  type,
  stage,
}) {
  console.log("projectId", projectId);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAction, setIsAction] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();
  var classes = useStyles();
  const [finalApprovalDetail, setFinalApprovalDetail] = useState({
    action: null,
    type: null,
    email: null,
    name: null,
    comments: null,
    approvalDate: moment().format("YYYY-MM-DD"),
    supportComments: null,
    approvalType: null,
  });

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ApprovalValidation({
      name: finalApprovalDetail?.name,
      comments: finalApprovalDetail?.comments,
      approvalDate: finalApprovalDetail?.approvalDate,
      supportComments: finalApprovalDetail?.supportComments,
      approvalType: finalApprovalDetail?.approvalType,
    });
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFinalApprovalDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    resetValidator(name);
    setErrorApiMessage("");
   
  };
  const [isActionCalled, setIsActionCalled] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    const data = {
      projectId: projectId,
      action: "approved",
      type: type,
      stage: stage,
      // email: "abhimanyu@yopmail.com",
      name: finalApprovalDetail?.name,
      approvalDate: finalApprovalDetail.approvalDate,
      approvalType: finalApprovalDetail?.approvalType,
      comments: finalApprovalDetail?.comments,
      supportComments: finalApprovalDetail?.supportComments,
    };
    if (!isError() && error !== null && isActionCalled) {
      dispatch(reviewFinalApprovalModule(data))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            setReviewModalShow(false);
            setFinalApprovalDetail({});
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: "99999",
                height: toastHeight,
              },
            });
            // setIsShow(true);
            setErrorApiMessage(null);
            setTimeout(() => {
              navigate("/staff/approval-complete");
            }, 3000);
          } else {
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            setErrorApiMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style: {
                height: toastHeight,
              },
            });
            // setIsShow(true);
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);
  const clear = () => {
    setFinalApprovalDetail({
      action: null,
      type: null,
      email: null,
      name: null,
      comments: null,
      approvalDate: moment().format("YYYY-MM-DD"),
      supportComments: null,
      approvalType: null,
    });
  };
  const hideHandle = () => {
    clear();
    resetAllValidator();
    setErrorApiMessage("");
  
    setReviewModalShow(false);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
    clear();
  };
  return (
    <>
      <Popup
        onHide={hideHandle}
        open={reviewModalShow}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                Cancel
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler(e)}
              >
                Submit
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={notes_bg}
                style={{
                  width: "48.96px",
                  height: "48.96px",
                  marginRight: "10px",
                }}
                alt="icons"
              />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#25282B",
                }}
              >
                Add Comment
              </Typography>
            </div>
            <div style={{ marginTop: "4px" }}>
              <Typography
                style={{
                  margin: "10px 6px",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#201F1E",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    color: "#52575C",
                    marginRight: "6px",
                  }}
                >
                  Outcome Started:
                </span>
                Approval
              </Typography>

              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#52575C",
                  paddingLeft: "8px",
                }}
              >
                Approval Name
              </Typography>
              <TextField
                style={{ marginTop: "4px" }}
                size="small"
                className={classes.txtForm}
                fullWidth
                // label="Enter Approval Name"
                type="text"
                name="name"
                placeholder="Enter approval name"
                variant="outlined"
                multiline
                inputProps={{
                  style: { fontSize: "13px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "13px", paddingLeft: "10px" }, // Adjust the font size as needed
                }}
                // rows={5}
                value={finalApprovalDetail.name ? finalApprovalDetail.name : ""}
                onChange={handleInputChange}
                error={error && error.name} // Set error state based on the error object
                // helperText={error && error.name} // Display error message
              />
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error && error?.name}
                </Typography>
              </Fade>
              <FormControl style={{ margin: "10px 6px" }}>
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "#52575C",
                  }}
                >
                  Select Approval Type
                </Typography>
                <RadioGroup
                  name="approvalType"
                  style={{
                    border: "1px solid #E8E8E8",
                    borderRadius: "5px",
                    padding: "0px 10px",
                    fontWeight: "400",
                    fontSize: "13px",
                    marginTop: "2px",
                  }}
                  label="Select Approval Type"
                  variant="outlined"
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={""}
                  value={
                    finalApprovalDetail.approvalType
                      ? finalApprovalDetail.approvalType
                      : ""
                  }
                  onChange={handleInputChange}
                  error={error && error.approvalType}
                >
                  <FormControlLabel
                    value="individual"
                    size="small"
                    control={
                      <Radio size="small" style={{ fontSize: "13px" }} />
                    }
                    label={
                      <span style={{ fontSize: "13px" }}>
                        Individual Approval
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="group"
                    control={
                      <Radio size="small" style={{ fontSize: "13px" }} />
                    }
                    label={
                      <span style={{ fontSize: "13px" }}>Group Approval</span>
                    }
                  />
                </RadioGroup>
                <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error && error?.approvalType}
                </Typography>
              </Fade>
              </FormControl>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#52575C",
                  paddingLeft: "8px",
                }}
              >
                Official Comments
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                type="textarea"
                // label="Add your Official Comments"
                name="comments"
                placeholder="Enter Official Comments"
                variant="outlined"
                multiline
                rows={3}
                inputProps={{
                  style: { fontSize: "13px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "13px", paddingLeft: "10px" }, // Adjust the font size as needed
                }}
                value={
                  finalApprovalDetail.comments
                    ? finalApprovalDetail.comments
                    : ""
                }
                onChange={handleInputChange}
                error={error && error.comments} // Set error state based on the error object
                // helperText={error && error.comments} // Display error message
              />
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error && error?.comments}
                </Typography>
              </Fade>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#52575C",
                  paddingLeft: "8px",
                  marginTop: "10px",
                }}
              >
                Support and Guidance Comments
              </Typography>
              <TextField
                style={{ marginTop: "4px" }}
                size="small"
                className={classes.txtForm}
                fullWidth
                type="textarea"
                name="supportComments"
                // label="Add Support and Guidance Comments"
                variant="outlined"
                placeholder="Enter Support and Guidance Comments"
                multiline
                rows={3}
                inputProps={{
                  style: { fontSize: "13px" },
                }}
                InputLabelProps={{
                  style: { fontSize: "13px", paddingLeft: "10px" }, // Adjust the font size as needed
                }}
                value={
                  finalApprovalDetail.supportComments
                    ? finalApprovalDetail.supportComments
                    : ""
                }
                onChange={handleInputChange}
                error={error && error.supportComments} // Set error state based on the error object
                // helperText={error && error.comments} // Display error message
              />
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error && error?.supportComments}
                </Typography>
              </Fade>

              <Typography
                style={{
                  marginTop: "4px",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#52575C",
                  display: "grid",
                  alignItems: "center",
                  paddingLeft: "6px",
                }}
              >
                Approval Date :
              </Typography>
              <TextField
                style={{ marginBottom: "0px", fontSize: "13px" }}
                size="small"
                fullWidth
                className={classes.txtForm}
                type="date"
                name="approvalDate"
                // label="Enter Approval Date"
                variant="outlined"
                inputProps={{ style: { fontSize: "13px" } }}
                // rows={5}
                value={
                  finalApprovalDetail.approvalDate
                    ? finalApprovalDetail.approvalDate
                    : ""
                }
                onChange={handleInputChange}
                error={error && error.approvalDate} // Set error state based on the error object
                // helperText={error && error.comments} // Display error message
              />
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error && error?.approvalDate}
                </Typography>
              </Fade>
            </div>
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Send Approval ",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
