import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputBase,
  Paper,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";

import {Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

// API data imports
import { fetchProjectRepoList } from "../../store/actions/ehiiaAction";

// components

import TableWidget from "../../../component/Widget/TableWidget";
import classNames from "classnames";

// styles
import useStyles from "./styles";

import TableDesign from "../../../component/common/table/TableDesign";

const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function ProjectList() {
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);

  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
 

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ID",
      sort:'projectUID'
    },
    {
      label: "Title",
      sort: "introduction.projectTitle"
    },
    {
      label: "Lead",
    },
    {
      label: "Sub-Org",
    },
    {
      label: "Date Completed",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "introduction",
    orderIn: -1,
  });

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.ehiia;
  });

  const page = pagination?.currentPage;

  const cc = list || filtered;
  useEffect(() => {
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);

    console.log("filterOption", filterOption);
    dispatch(fetchProjectRepoList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  return (
    <>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Project Repositories
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";                  
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() =>
                            navigate(`/staff/view-project/${row.projectId}`)
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.projectUID}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            navigate(`/staff/view-project/${row.projectId}`)
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.introduction?.projectTitle}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.introduction?.projectLead}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.introduction?.organization}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {formatedDate(row.updatedAt)}
                        </TableCell>
                        <TableCell
                          style={{ color: "#10940D" }}
                          className={classes.tableBodyCell}
                        >
                          {row.isCompleted ? (
                            <span
                              style={{
                                color: "#10940D",
                                fontWeight: 500,
                              }}
                            >
                              Complete
                            </span>
                          ) : (
                            <span style={{ color: "#F8A80C" }}>
                              In-Progress
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            elevation={0}
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() =>
                                navigate(`/staff/view-project/${row.projectId}`)
                              }
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Details
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
