import React from "react";

import AdminDetail from "../../components/Admin/Detail/AdminDetail";
export default function AdminDetails() {
  return (
    <>
      <AdminDetail />
    </>
  );
}
