import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Fade,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../hooks/Validation";
import { createUpdateSubAdmin } from "../../store/actions/subAdminAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../component/common/loader/Loader";

// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ManageClient(props) {
  //
  console.log("props-24", props);
  const { selectclient,title } = props;

  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  const formElements = [
    {
      key: "name",
      label: "Please Enter Admin Name",
      placeHolder: "Enter admin name",
      type: "text",
    },

    {
      key: "email",
      label: "Please Enter Admin Email ID",
      placeHolder: "Enter admin email",
      type: "text",
    },
    {
      key: "subOrg",
      label: "Select Sub Organisations",
      placeHolder: "Select",
      type: "select",
      options: [],
    },
  ];
  var classes = useStyles();
  const { addToast } = useToasts();
  const [subOrgError, setSubOrgError] = useState(null);
  const [clientDetail, setClientDetail] = useState({
    name: null,
    email: null,
    subOrg: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: clientDetail?.name,
      email: clientDetail?.email,
    });
  const [isUploading, setIsUploading] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [orgDataValue, setOrgDataValue] = React.useState([]);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [orgOption, setOrgOption] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "subOrg") {
      setOrgDataValue(value);
    } else {
      setClientDetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    setSubOrgError(null);
    props.onHide();

    if (selectclient === null) {
      setClientDetail({
        name: null,

        email: null,
        subOrg: null,
      });
      setOrgDataValue([]);
    } else {
      setClientDetail({
        adminId: selectclient?.adminId,
        name: selectclient?.name,

        email: selectclient?.email,
        subOrg: selectclient?.subOrg,
      });
      setOrgDataValue(selectclient?.subOrg || []);
    }
    console.log("clientDetail", clientDetail);
  };

  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  console.log("adminDetail", adminDetail);

  useEffect(() => {
    console.log("adminDetail", adminDetail);
    console.log(
      "adminDetail.data.subOrg.ehiiaVersion",
      adminDetail?.data?.subOrg?.ehiiaVersion,
    );
    const subOrgOptions = adminDetail?.data?.subOrg?.ehiiaVersion || [];
    if (Array.isArray(subOrgOptions)) {
      console.log("subOrgOptions", subOrgOptions);

      setOrgOption(subOrgOptions);
    }
  }, [adminDetail]);
  // Check if subOrgOptions is not null or undefined

  useEffect(() => {
    console.log("orgOption", orgOption);
  }, [orgOption]);
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    if (!orgDataValue || orgDataValue.length === 0) {
      setSubOrgError("Please select at least one sub organisation");
      
    } else {
      setSubOrgError(null);
      setIsActionCalled(true);
    }
    
  };

  //api call
  useEffect(() => {
    console.log("orgDataValue", orgDataValue);

    setIsUploading(true);

    const formData = {
      name: clientDetail.name,
      email: clientDetail.email,
      subOrg: {
        ehiiaVersion: orgDataValue,
      },
    };

    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(createUpdateSubAdmin(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setIsUploading(true);
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          hideHandler();
          // setIsShow(true);
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
          // setIsShow(true);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        title={title}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        disableEnforceFocus
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {formElements.map((element, i) => {
              return (
                (!selectclient || element.key !== "password") && (
                  <div key={i}>
                    <Typography className={classes.formHeading}>
                      {element.label}
                    </Typography>
                    {element.type === "select" ? (
                      <>
                        <FormControl 
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select 
                            name={element.key}
                            className={classes.selectDropdown}
                            value={orgDataValue}
                            onChange={handleChange}
                            renderValue={(selected) =>
                              selected
                                .map((value) =>
                                  orgOption.includes(value) ? value : "",
                                )
                                .join(", ")
                            }
                            multiple
                          >
                            <MenuItem disabled value="">Select</MenuItem>
                            {element.key === "subOrg" && orgOption
                              ? orgOption.map((item) => (
                                  <MenuItem value={item}>
                                    <Checkbox
                                      checked={orgDataValue.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                        {!orgDataValue.length > 0 &&
                        <Fade in={subOrgError}>
                        <Typography className={classes.errorMessage}>
                          {subOrgError && subOrgError}
                        </Typography>
                      </Fade>
                        }
                        
                      </>
                    ) : element.type === "text" ? (
                      <>
                        <TextField
                          hiddenLabel
                          size="small"
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          className={classes.txtForm}
                          fullWidth
                          value={
                            clientDetail && clientDetail[element.key] === null
                              ? ""
                              : clientDetail && clientDetail[element.key]
                          }
                          variant="outlined"
                          onChange={async (e) => {
                            setClientDetail({
                              ...clientDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log("clientDetail", clientDetail);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : (
                      <>
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          variant="outlined"
                          multiline
                          rows={4}
                        />
                      </>
                    )}
                  </div>
                )
              );
            })}
            {/* Name */}
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Manage Admin User",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
