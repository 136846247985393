import React from "react";
import { Route } from "react-router-dom";
import Redirect from "../Redirect";
import Login from "./pages/login/Login";
import Forget from "./pages/forgetPassword/Forget";
import Reset from "./pages/resetPassword/Reset";
import Dashboard from "./pages/dashboard/Dashboard";
import Ehiia from "./pages/ehiia/Ehiia";
import EhiiaDetails from "./pages/ehiia/EhiiaDetails";
import EditEhiia from "./pages/ehiia/EditEhiia";
import Client from "./pages/manageClient/Client";
import ClientDetails from "./pages/manageClient/ClientDetails";
import EditClient from "./pages/manageClient/EditClient";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/profile/ChangePassword";
import Layout from "../component/Layout/Layout";
import Admin from "./pages/admin/Admin";
import AdminDetails from "./pages/admin/AdminDetails";
import EditAdmin from "./pages/admin/EditAdmin";
import Activation from "./pages/profile/Activation";




export const superAdminApp = (admin) => {
  console.log("super-admin",admin);
  if (!admin?.isAdminLoggedIn) {
    return (
      <>
        <Route path="*" element={<Redirect to="/super-admin/login" />} />
        <Route exact path="/super-admin/login" element={<Login />} />
        <Route exact path="/super-admin/forget-password" element={<Forget />} />
        <Route
          exact
          path="/super-admin/reset-password/:token"
          element={<Reset />}
        />
        <Route exact path="/super-admin/activate" element={<Activation />} />
       
      </>
    );
  }

  if (admin?.isAdminLoggedIn) {
    return (
      <>
        <Route path="*" element={<Redirect to="/super-admin/dashboard" />} />
        <Route exact path="/super-admin" element={<Layout />}>
          <Route exact path="/super-admin/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/super-admin/manage-module/ehiia"
            element={<Ehiia />}
          />
          <Route
            path="/super-admin/ehiia-details/:versionId"
            element={<EhiiaDetails />}
          />
          <Route
            path="/super-admin/ehiia-edit/:versionId"
            element={<EditEhiia />}
          />

          <Route path="/super-admin/manage-client" element={<Client />} />
          <Route
            path="/super-admin/client-details/:orgId"
            element={<ClientDetails />}
          />
          <Route
            path="/super-admin/client-edit/:orgId"
            element={<EditClient />}
          />
          <Route exact path="/super-admin/manage-admin" element={<Admin />} />
          <Route
            path="/super-admin/admin-details/:rootAdminId"
            element={<AdminDetails />}
          />
          <Route
            path="/super-admin/admin-edit/:rootAdminId"
            element={<EditAdmin />}
          />
          <Route path="/super-admin/profile" element={<Profile />} />
          <Route
            path="/super-admin/reset-password"
            element={<ChangePassword />}
          />
        </Route>
      </>
    );
  }
};
