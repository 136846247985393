import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Button,
  IconButton,
  Fade,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import editIcon from "../../../../images/imgEdit.svg";
import Validation from "../../../hooks/Validation";
import Loader from "../../../../component/common/loader/Loader";
//
import {
  getClientByClientId,
  updateClient,
} from "../../../store/actions/clientsAction";
import { fetchAllEhiiaVersionList } from "../../../store/actions/ehiiasAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ClientEdit() {
  const { orgId } = useParams();
  console.log("orgId", orgId);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [moduleOption, setModuleOption] = useState(null);
  const inputRef = useRef();
  var classes = useStyles();
  const { addToast } = useToasts();
  const [clientDetail, setClientDetail] = useState({
    name: null,
    subdomain: null,
    contactPerson: null,
    email: null,
    ehiiaVersionId: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: clientDetail?.name,
      subdomain: clientDetail?.subdomain,
      contactPerson: clientDetail?.contactPerson,
      email: clientDetail?.email,
      // ehiiaVersionId: clientDetail?.ehiiaVersionId,
    });
  const dispatch = useDispatch();
  const { list } = useSelector((state) => {
    return state.ehiias;
  });
  useEffect(() => {
    if (!Array.isArray(list)) {
      dispatch(fetchAllEhiiaVersionList());
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(list)) {
      console.log("list", list);
      setModuleOption(list);
    }
  }, [list]);

  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);

  useEffect(() => {
    dispatch(getClientByClientId({ orgId })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.success) {
        setClientDetail(payload?.data?.organization);
      }
      console.log("clientDetail", clientDetail);
    });
  }, []);
  // record updated
  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getClientByClientId({ orgId })).then(({ payload }) =>
        setClientDetail(payload?.data?.organization),
      );
    }
  }, [isRecordUpdated]);
  // submit form data
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  //Api call
  useEffect(() => {
    setIsUploading(true);
    console.log("file logo", logo);
    let formData = new FormData();
    if (clientDetail?.orgId) {
      formData.append("orgId", clientDetail?.orgId);
    }

    formData.append("name", clientDetail?.name);
    formData.append("subdomain", clientDetail?.subdomain);
    formData.append("contactPerson", clientDetail?.contactPerson);
    formData.append("email", clientDetail?.email);
    formData.append(
      "ehiiaVersionId",
      clientDetail?.ehiiaVersionId
        ? clientDetail?.ehiiaVersionId
        : clientDetail?.versionId?.ehiia,
    );
    if (logo) {
      formData.append("logo", logo);
    }

    console.log("formData-", ...formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateClient(formData))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            setIsUploading(true);
            setSuccessMessage(payload?.message);
            hideHandler();
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: '99999',height:toastHeight
              },
            });
            // setIsShow(true);
          } else {
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            setErrorApiMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style:{
                height:toastHeight
              }
            });
            // setIsShow(true);
          }
        })
        .catch((error) => {
          console.error("Error during service worker registration:", error);
        });
      setIsActionCalled(false);
      // inputRef.current.value = "";
    } else {
      console.log("error-", error);
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  //
  const hideHandler = () => {
    resetAllValidator();

    setClientDetail({
      name: clientDetail?.name,
      subdomain: clientDetail?.subdomain,
      contactPerson: clientDetail?.contactPerson,
      email: clientDetail?.email,
      ehiiaVersionId: clientDetail?.ehiiaVersionId,
    });
  };
  //
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  // logo upload
  const upload = (e) => {
    setIsWrongFileType(false);
    console.log(e.target.files[0]);
    var fileName = e.target.files[0]?.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
      setLogo(e.target.files[0]);
    } else {
      console.log("Only jpg/jpeg and png files are allowed!");

      setIsWrongFileType(true);
      setTimeout(() => {
        setIsWrongFileType(false);
      }, 3000);
    }
  };
  // call logo
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);
  const cancelHandler = () => {
    navigate(`/client-details/${orgId}`);
  };
  return (
    <>

      {isLoader && <Loader />}
      <PageTitle
        subtitle="Manage Clients >"
        urlLink="/super-admin/manage-client"
        title="Edit"
      />
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            {logoUrl ? (
              <Avatar className={classes.avatar} src={logoUrl} />
            ) : clientDetail?.logo ? (
              <Avatar className={classes.avatar} src={clientDetail?.logo} />
            ) : (
              <Avatar className={classes.avatar} src="/photo.svg" />
            )}

            <IconButton
              className={classes.uploadIcon}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                onChange={(e) => upload(e)}
                ref={inputRef}
                name="myImage"
                accept="image/*"
                type="file"
              />
              <img src={editIcon} alt="edit" />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Organisation
                </Typography>
                <TextField
                  name="name"
                  size="small" multiline
                  value={clientDetail && clientDetail?.name}
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter organisation name"
                  variant="outlined"
                  onChange={async (e) => {
                    setClientDetail({
                      ...clientDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.name}
                />
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.name}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                 Short Name
                </Typography>
                <TextField
                  name="subdomain"
                  size="small"
                  value={clientDetail && clientDetail?.subdomain}
                  className={classes.txtForm}
                  fullWidth multiline
                  placeholder="Enter short name"
                  variant="outlined"
                  onChange={async (e) => {
                    setClientDetail({
                      ...clientDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.subdomain}
                />
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.subdomain}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Admin
                </Typography>
                <TextField
                  name="contactPerson"
                  size="small"
                  value={clientDetail && clientDetail?.contactPerson}
                  className={classes.txtForm}
                  fullWidth multiline
                  placeholder="Enter contact name"
                  variant="outlined"
                  onChange={async (e) => {
                    setClientDetail({
                      ...clientDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                    // console.log("clientDetail", clientDetail);
                    // console.log("clientDetail", clientDetail?.status);
                  }}
                  isInvalid={error?.contactPerson}
                />
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.contactPerson}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Email
                </Typography>
                <TextField
                  disabled
                  style={{ cursor: "not-allowed" }}
                  name="email"
                  size="small"
                  value={clientDetail && clientDetail?.email}
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter email"
                  variant="outlined"
                  onChange={async (e) => {
                    setClientDetail({
                      ...clientDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.email}
                />
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.email}
                  </Typography>
                </Fade>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Select Module
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name="ehiiaVersionId"
                    className={classes.selectDropdown}
                    value="EHIIA"
                  >
                    <MenuItem disabled>Select</MenuItem>

                    <MenuItem value="EHIIA">EHIA</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Select Version
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name="ehiiaVersionId"
                    className={classes.selectDropdown}
                    value={
                      clientDetail && clientDetail?.versionId?.ehiia
                        ? clientDetail?.versionId?.ehiia
                        : clientDetail?.ehiiaVersionId
                    }
                    onChange={async (e) => {
                      setClientDetail({
                        ...clientDetail,
                        [e.target.name]: e.target.value,
                      });
                      resetValidator(e.target.name);
                    }}
                    isInvalid={error?.ehiiaVersionId}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {moduleOption ? (
                      moduleOption.map((item) => (
                        <MenuItem
                          key={item.versionId}
                          value={item.versionId}
                          // selected={
                          //   clientDetail?.ehiiaVersionId === item.versionId
                          // }
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value=""></MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.ehiiaVersionId}
                  </Typography>
                </Fade>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Widget>
      <div className={classes.footerBox}>
        <Button
          onClick={(e) => cancelHandler(e)}
          variant="outlined"
          size="large"
          className={classes.leftButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
          onClick={(e) => submitHandler(e)}
        >
          Update
        </Button>
      </div>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Update Client",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
