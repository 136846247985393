import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { getEhiiaProjectByProjectIdwithSectionId } from "../../../store/actions/ehiiaAction";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";

import Introduction from "../Cases/Introduction";
import Assessment from "../Cases/Assessment";
import DataReview from "../Cases/DataReview";
import Engagement from "../Cases/Engagement";
import Result from "../Cases/Result";
export default function ProjectDetail() {
  const { projectId } = useParams();
  var [activeTabId, setActiveTabId] = useState(0);
  const dispatch = useDispatch();
  const [projectSummaryData, setProjectSummaryData] = useState([]);
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [introductionDetail, setIntroductionDetail] = useState(null);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [dataReviewDetail, setDataReviewDetail] = useState(null);
  const [engagementDetail, setEngagementDetail] = useState(null);
  const [ehiiaResultDetail, setEhiiaResultDetail] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [setionStatus, setSectionStatus] = useState([]);
  const navigate = useNavigate();
  var classes = useStyles();
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
    ).then(({ payload }) => {
      console.log("data-payload", payload);
      if (payload?.success) {
        setEhiiaProjectDetail(payload?.data?.form);
        setProjectSummaryData(payload?.data?.projectSummary);
        setIntroductionDetail(payload?.data?.form?.introduction);
        setAssessmentDetail(payload?.data?.form?.assessment?.questions);
        setDataReviewDetail(payload?.data?.form?.dataReview?.questions);
        setEngagementDetail(payload?.data?.form?.engagement?.questions);
        setEhiiaResultDetail(payload?.data?.form?.finalApproval);
        setSectionStatus(payload?.data?.form?.sections);
        setNextSection(payload?.data?.form?.nextSection);
      }
    });
  }, []);
  // 
  let [accessPath, setAccessPath] = useState("");

  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);
   
    const pathSegments = path.split("/");
    setAccessPath(pathSegments[2]); // Assuming "complete-project-details" is the third segment of the path
    console.log("Access Path:", accessPath);
  }, []);
  return (
    <>
      <PageTitle
        subtitle={accessPath === 'complete-project-details' ? 'EHIA Project >' : 'Project Repositories >'}
        title="Project Details"
        urlLink={accessPath === 'complete-project-details' ? '/admin/manage-module/ehiia' : '/admin/project'}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          {projectSummaryData?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={index % 2 === 0 ? 4 : 8}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {item?.title}:
              </Typography>
              <Typography className={classes.formHeadingValue}>
                {item?.title === "Status" ? (
                  <>
                    {item?.value === "In-Progress" ? (
                      <span style={{ color: "#FEAA00" }}>{item?.value}</span>
                    ) : (
                      <span style={{ color: "#10940D" }}>{item?.value}</span>
                    )}
                  </>
                ) : (
                  <>{item?.value}</>
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Widget>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        {setionStatus.map((section, index) => (
          <Tab
            key={index}
            label={section.title}
            classes={{ root: classes.tab }}
            disabled={
              section.completed
                ? false
                : section.value === nextSection
                ? false
                : true
            } // Disable the tab if the section is not completed
            onClick={() => setActiveTabId(index)}
          />
        ))}
      </Tabs>

      {activeTabId === 0 && (
        <Widget>
          <Introduction introductionDetail={introductionDetail} />
        </Widget>
      )}
      {activeTabId === 1 && (
        <Widget>
          <Assessment assessmentDetail={assessmentDetail} />
        </Widget>
      )}
      {activeTabId === 2 && (
        <Widget>
          <DataReview dataReviewDetail={dataReviewDetail} />
        </Widget>
      )}
      {activeTabId === 3 && (
        <Widget>
          <Engagement engagementDetail={engagementDetail} />
        </Widget>
      )}
      {activeTabId === 4 && <Result ehiiaResultDetail={ehiiaResultDetail} projectSummaryData={projectSummaryData} />}
    </>
  );
}
