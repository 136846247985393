import React from "react";
import { Grid, Typography } from "@material-ui/core";
// styles
import useStyles from "./styles";
export default function Introduction({ introductionDetail }) {
  var classes = useStyles();
  console.log("introductionDetail", introductionDetail);
  return (
    <>
      <Grid container spacing={2}>
        {Object.entries(introductionDetail).map(([key, element], i) => {
          if (key === "section") return null; // Skip the "section" property
          return (
            <Grid
              item
              xs={12}
              md={
                key === "projectTitle" ||
                key === "serviceManager" ||
                key === "serviceManagerEmail" ||
                key === "serviceManagerRole" ||
                key === "trainingCompleted" ||
                key === "mainPurpose" ||
                key === "newOrExisting" ||
                key === "careGroup"
                  ? 12
                  : 6
              }
              className={
                key === "trainingCompleted" ||
                key === "mainPurpose" ||
                key === "careGroup"
                  ? classes.textGroupInline
                  : classes.textGroup
              }
            >
              <Typography
                className={classes.formHeading}
                style={{
                  color: "#201F1E",
                  fontSize: "15px",
                  fontWeight: "500",
                  width:
                    key === "mainPurpose" ||
                    key === "trainingCompleted" ||
                    key === "careGroup"
                      ? "100%"
                      : key === "projectTitle"
                      ? "25%"
                      : "40%",
                }}
              >
                {element?.title.replace(
                  "{organisation}",
                  `${introductionDetail?.organization?.value}`,
                )}{" "}
                :
              </Typography>
              <Typography
                className={classes.formHeadingValue}
                style={{
                  color: "#52575C",
                  fontSize: "15px",
                  fontWeight: "400",width:key === "projectTitle"
                  ? "100%"
                  : "60%"
                }}
              >
                {key === "searchTags"
                  ? element?.values.map((item, index) => (
                      <span
                        key={index}
                        style={{
                          background: "#EBFFFB",
                          color: "#666",
                          borderRadius: "50px",
                          marginRight: "5px",
                          padding: "4px 10px",
                        }}
                      >
                        {item}
                      </span>
                    ))
                  : element?.value}
              </Typography>
            </Grid>
          );
        })}
       
      </Grid>
    </>
  );
}
