import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import useStyles from "./styles";
export default function Introduction() {
  var classes = useStyles();
  const formElements = [
    {
      key: "project_title",
      label: "Project Title",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "project_lead",
      label: "Project Lead",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "line_manager",
      label: "Line Manager",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "line_manager_title",
      label: "Line Manager Role Title",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "service_manager",
      label: "Service Manager",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "service_manager_title",
      label: "Service Manager Role Title",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "organization",
      label: "Organisation",
      placeHolder: "Select",
      type: "select",
      options: ["MSEFT", "MSEICB", "EPUT", "PROVIDE"],
    },
    {
      key: "group_service",
      label: "Care Group, Division and Service",
      placeHolder: "Select",
      type: "select",
      options: [
        "Care Group 1 - Division 1 -Specialist Medicine",
        "Care Group 1 - Division 2 -Cardiothoracic Centre (CTC)",
        "Care Group 1 - Division 2 - Critical Care",
        "Care Group 1 - Division 2 - Renal",
        "Care Group 1 - Division 2 - Vascular",
        "Care Group 2 - Division 3 - Burns and Plastics",
        "Care Group 2 - Division 3 - Dermatology",
        "Care Group 2 - Division 3 - Emergency Department (ED)",
        "Care Group 2 - Division 3 - Local Services Medical",
        "Care Group 2 - Division 4 - Trauma and Orthopaedics",
        "Care Group 2 -Division 5 - Surgery",
        "Care Group 2 - Division 6 - Theatres",
        "Care Group 3 - Division 7 - Women's and Children's",
        " Care Group 3 - Division 8 - Cancer and Oncology",
        "Care Group 3 - Division 8 - Cancer RTT",
        "Care Group 3 - Division 8 - Local Services and Medical",
        " Care Group 3 - Division 8 - Ophthalmology",
        "Care Group 3 - Division 8 - Outpatients and Access",
      ],
    },
    {
      key: "policy",
      label:
        "What are the main aims and purposes of the Policy/ Service/ Event or Strategy",
      placeHolder: "Answer",
      type: "text",
    },
    {
      key: "activity",
      label: "Is this a new activity or a change to an existing one",
      placeHolder: "Select",
      type: "select",
      options: ["New", "Changes"],
    },
    {
      key: "commission",
      label: "Commissioning Status",
      placeHolder: "Select",
      type: "select",
      options: ["New", "Changes"],
    },
    {
      key: "equality",
      label:
        "Have you completed the MSEFT Equality and Health Inequality Training for colleagues preparing to undertake an Equality and Health Inequality Impact Assessment",
      placeHolder: "Select",
      type: "select",
      options: ["No", "Yes"],
    },
  ];
  const [value, setValue] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [patient, setPatient] = React.useState("");
  const [no, setNo] = React.useState("");
  const [old, setOld] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleChange1 = (event) => {
    setGroup(event.target.value);
  };
  const handleChange2 = (event) => {
    setPatient(event.target.value);
  };
  const handleChange3 = (event) => {
    setOld(event.target.value);
  };
  const handleChange4 = (event) => {
    setNo(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formElements.map((element, i) => {
          return (
            <Grid item xs={12} md={6} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
                Q{i + 1}. {element.label}
              </Typography>
              {element.type == "select" ? (
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name={element.name}
                    className={classes.selectDropdown}
                    value={value}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {element.options.map((item) => (
                      <MenuItem value={item}> {item} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  className={classes.txtForm}
                  placeholder={element.placeHolder}
                  type={element.type}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
