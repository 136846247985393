import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Tabs, Tab } from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";

// data
import mock from "./mock";
// styles
import useStyles from "./styles";

import TableDesign from "../../../component/common/table/TableDesign";
import ProjectLogs from "./ProjectLogs";
import AccountLogs from "./AccountLogs";

export default function AuditTrailsList() {
  const navigate = useNavigate();
  var [activeTabId, setActiveTabId] = useState(0);
  var classes = useStyles();

  return (
    <>
      <PageTitle title="Audit Trail" search="true" />
      <Tabs
        TabIndicatorProps={{
          style: { background: "#0445AF" },
        }}
        textColor="primary"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab label="Projects Logs" classes={{ root: classes.tab }} />
        <Tab label="Accounts Logs" classes={{ root: classes.tab }} />
      </Tabs>
      {activeTabId === 0 && <ProjectLogs />}
      {activeTabId === 1 && <AccountLogs />}
    </>
  );
}
