import React, { useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,Select,
  MenuItem,
  FormControl
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
// styles
import useStyles from "./styles";
export default function ManageClient(props) {
  //
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {/* Avtar */}
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Avatar
                  className={classes.avatar}
                  sx={{ width: 68, height: 68 }}
                  src="/broken-image.jpg"
                />
              </Grid>
              <Grid item xs={9} className={classes.gridBox}>
                <Button
                  variant="contained"
                  className={classes.blueButton}
                  component="label"
                  disableElevation
                >
                  Add Logo
                  <input hidden accept="image/*" multiple type="file" />
                </Button>
              </Grid>
            </Grid>
            {/* org name */}
            <Typography className={classes.formHeading}>
              Client Organisation Name
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
            />
            {/* shortname */}
            <Typography className={classes.formHeading}>
              Client Organisation Short Name
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Short Name"
              variant="outlined"
            />
            {/* Name */}
            <Typography className={classes.formHeading}>
              Contact Person Name
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
            />
            {/* Email */}
            <Typography className={classes.formHeading}>
              Client Email Id
            </Typography>
            <TextField
              className={classes.txtForm}
              size="small"
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
            />
            {/* Modules */}
            <Typography className={classes.formHeading}>Add Modules</Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={classes.selectDropdown}
                value={value}
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Ehiia">Ehiia</MenuItem>
                
              </Select>
            </FormControl>
            {/* Your Public URL : */}
            <Typography className={classes.formLinkHeading}>
              Your Public URL :
            </Typography>
            <Typography className={classes.formLinkHeadingValue}>
              www.ehiiamodule.com/client/clientname
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
