import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdateSubAdmin,
  updateSubAdmin,
  fetchSubAdminList,
  deleteSubAdmin,
  toggleActivation,
  sendActivationLink,
  sendResetPasswordLink,
  getSubAdminBySubAdminId,
  sendActivationMail,
} from "../actions/subAdminAction";

export const subadmins = createSlice({
  name: "subadmins",
  initialState: {
    subadmins: null,

    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
    // isRecordAdded: false,
    // isRecordUpdated: false,
    // isRecordLoaded:false,
  },
  reducers: {
    userReset: (state) => {
      // state.subadmins= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchSubAdminList.pending]: (state, payload) => {
      console.log("payload", payload);
      state.error = null;
      state.isLoader = true;
    },
    [fetchSubAdminList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log("payload", payload);
      // state.subadmins = payload?.data;
      // state.isLoader = false;
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchSubAdminList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    // create
    [createUpdateSubAdmin.pending]: (state, payload) => {
      // console.log("payload-subadmins",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateSubAdmin.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateSubAdmin.rejected]: (state, { payload }) => {
      console.log("payload-subadmins", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

    // update
    [updateSubAdmin.pending]: (state, payload) => {
      // console.log("payload-subadmins",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [updateSubAdmin.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [updateSubAdmin.rejected]: (state, { payload, error }) => {
      console.log("payload-subadmins-error", error);
      console.log("payload", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [deleteSubAdmin.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [deleteSubAdmin.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [deleteSubAdmin.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [toggleActivation.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [toggleActivation.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [sendActivationLink.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [sendActivationLink.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [sendActivationLink.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [sendResetPasswordLink.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [sendResetPasswordLink.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [sendResetPasswordLink.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [getSubAdminBySubAdminId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getSubAdminBySubAdminId.fulfilled]: (state, { payload }) => {
     console.log("payload",payload);
      state.error = null;
      state.isRecordLoaded = true;
      state.isLoader = false;
    },
    [getSubAdminBySubAdminId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
    [sendActivationMail.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [sendActivationMail.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [sendActivationMail.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
  subadmins.actions;

export default subadmins.reducer;
