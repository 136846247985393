import React from 'react'
import ApprovalList from '../../components/Approval/ApprovalList'
import ApprovalListTest from '../../components/Approval/ApprovalListTest'

export default function ApprovalProcess() {
  return (
    <div>
      {/* <ApprovalListTest/> */}
      <ApprovalList/>
      </div>
  )
}
