import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";

export const adminLoginWithEmail = createAsyncThunk(
  "admin/adminLoginWithEmail",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/public/v1/accounts.lookup?email=${arg?.email}&type=${arg?.userType}`,
      arg,   
    ).then((response) => response)
    .catch((error) => rejectWithValue(error.response)),
);

export const adminLogin = createAsyncThunk(
  "admin/adminLogin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post(`/admin/auth/v1/login`, arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const forgotPassword = createAsyncThunk(
  "admin/forgotPassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/auth/v1/forgot", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const changePassword = createAsyncThunk(
  "admin/changePassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/my/v1/password", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const resetPassword = createAsyncThunk(
  "admin/resetPassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/auth/v1/reset", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const updateProfile = createAsyncThunk(
  "admin/updateProfile",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/my/v1/profile", arg, rejectWithValue),
);

export const getProfile = createAsyncThunk(
  "admin/getProfile",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get("/admin/my/v1/profile", rejectWithValue),
);
export const activateUser = createAsyncThunk(
  "admin/activateUser",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/auth/v1/activate", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
