import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Avatar,AppBar,
  Toolbar,
} from "@material-ui/core";
import Widget from "../../component/Widget";
import whiteLogo from "../../assets/icons/ehiia-white-logo.svg";
import Introduction from "./Introduction";
import Assessment from "./Assessment";
import DataReview from "./DataReview";
import Engagement from "./Engagement";
import Approval from "./Approval";
import notes from "../../assets/noteEdit.svg";
import expireLink from "../../assets/link_expire.svg";
import Loader from "../../component/common/loader/Loader";
// styles
import useStyles from "./styles";

import { getReview,getOrgData } from "../../store/actions/reviewsAction";
export default function ApprovalRequest() {
  var classes = useStyles();
  const { projectId } = useParams();

  console.log("projectId", projectId);
  const searchParams = new URLSearchParams(useLocation().search);
  const type = searchParams.get("type");
  const stage = searchParams.get("stage");
  const dispatch = useDispatch();
  var [activeTabId, setActiveTabId] = useState(3);
  const [orgDetails, setOrgDetails] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [introductionDetail, setIntroductionDetail] = useState(null);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [dataReviewDetail, setDataReviewDetail] = useState(null);
  const [engagementDetail, setEngagementDetail] = useState(null);
  const [approvalDetail, setApprovalDetail] = useState(null);
  const [setionStatus, setSectionStatus] = useState([]);
  const [projectSummaryData, setProjectSummaryData] = useState([]);
  const [isEngagementCompleted, setIsEngagementCompleted] = useState(false);
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(getReview({ projectId, type, stage })).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.data?.success) {
        console.log("payload?.data", payload?.data);

        setProjectSummaryData(payload?.data?.data?.projectSummary);
        setEhiiaProjectDetail(payload?.data?.data?.form);
        setIntroductionDetail(payload?.data?.data?.form?.introduction);
        setAssessmentDetail(payload?.data?.data?.form?.assessment?.questions);

        setDataReviewDetail(payload?.data?.data?.form?.dataReview?.questions);
        setEngagementDetail(payload?.data?.data?.form?.engagement?.questions);
        setSectionStatus(payload?.data?.data?.form?.sections);

        const engagementSection = setionStatus.find(
          (section) => section.value === "engagement",
        );
        setIsEngagementCompleted(
          engagementSection && engagementSection.completed,
        );
        setApprovalDetail(payload?.data?.data?.form?.finalApproval);
        switch (payload?.data?.form?.nextSection) {
          case "introduction":
            setActiveTabId(0);
            break;
          case "assessment":
            setActiveTabId(1);
            break;
          case "dataReview":
            setActiveTabId(2);
            break;
          case "engagement":
            setActiveTabId(3);
            break;
          default:
            setActiveTabId(3);
        }
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("introductionDetail", introductionDetail);
    console.log("projectSummaryData", projectSummaryData);
    console.log("isEngagementCompleted", isEngagementCompleted);
  }, [introductionDetail, projectSummaryData, isEngagementCompleted]);
  const downloadRepost=()=>{
    const downloadLink = approvalDetail?.overview?.downloadReportLink;
    if (downloadLink) {
      window.open(downloadLink, '_blank'); // Opens the link in a new tab
    }
    
  }
  return (
    <>
     <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <Apps
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
          <img src={whiteLogo} style={{ width: "97px", height: "19.45px" }} alt="img" />
        </Typography>
        <div className={classes.grow} />

        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <img src={ques} classes={{ root: classes.headerIcon }} alt="imgOrg" />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
          <img src={orgDetails?.logo} style={{ height: "36.45px" }} alt="img" />
        </Typography>
      </Toolbar>
    </AppBar>
      {isLoader && <Loader />}
      {projectSummaryData.length > 0 ? (
        <>
          <div style={{ textAlign: "center", margin: "10px 0px" }}>
            <Typography
              style={{
                color: "#03997B",
                fontFamily: "Sanchez",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {activeTabId === 3 ? (
                <>Project Engagement Plan Approval Request</>
              ) : (
                <>Project Approval Request</>
              )}
            </Typography>
          </div>
          <div style={{ float: "right", margin: "10px 0px" }}>
            <Button
              style={{
                float: "right",
                backgroundColor: "#FEAA00",
                padding: "10px 19.5px 10px 18.5px",
                textTransform: "none",
                justifyContent: "center",
                textAlign: "center",
                color: "#fff",
                borderRadius: "2px",
              }}
              onClick={downloadRepost}
            >
              Download
            </Button>
          </div>
          <Grid
            container
            style={{ margin: "-0.50px" }}
            className={classes.formBox}
          >
            <Grid item xs={12} md={1}>
              <Avatar className={classes.avatar} src={notes} />
            </Grid>
            <Grid item xs={12} md={11}>
              <Grid container spacing={2}>
                {projectSummaryData?.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    md={index === 0 ? 12 : index % 2 === 0 ? 5 : 7}
                    className={classes.textGroup}
                  >
                    <Typography
                      className={classes.formHeading}
                      style={{ color: "#201F1E", fontWeight: "500",width:index === 0 ? '21%':'30%' }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      className={classes.formHeadingValue}
                      style={{ color: "#52575C", fontWeight: "400",width:index === 0 ? '100%':'70%' }}
                    >
                      {item?.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Paper className={classes.iconsContainer}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Introduction" classes={{ root: classes.tab }} />
              <Tab label="Assessment" classes={{ root: classes.tab }} />
              <Tab label="Data Review" classes={{ root: classes.tab }} />
              <Tab label="Engagement" classes={{ root: classes.tab }} />

              {/* <Tab
                label="Approval & Report"
                classes={{ root: classes.tab }}
                disabled={isEngagementCompleted ? false : true}
              /> */}
            </Tabs>
            {activeTabId === 0 && (
              <Widget>
                <Introduction introductionDetail={introductionDetail} />
              </Widget>
            )}
            {activeTabId === 1 && (
              <Widget>
                <Assessment assessmentDetail={assessmentDetail} />
              </Widget>
            )}
            {activeTabId === 2 && (
              <Widget>
                <DataReview dataReviewDetail={dataReviewDetail} />
              </Widget>
            )}
            {activeTabId === 3 && (
              // <Widget>
              <Engagement type={type} engagementDetail={engagementDetail} />
              // </Widget>
            )}
            {activeTabId === 4 && (
              // <Widget>
              <Approval type={type} approvalDetail={approvalDetail} projectSummaryData={projectSummaryData} />
              // </Widget>
            )}
          </Paper>
        </>
      ) : (
        <>
          {!isLoader && (
            <center style={{ marginTop: "24px" }}>
              <div style={{ textAlign: "center" }}>
                <Typography
                  style={{
                    color: "#03997B",
                    fontFamily: "Sanchez",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                >
                  The link you followed has Expired
                </Typography>
              </div>
              <img
                style={{ height: "72px", marginTop: "24px" }}
                src={expireLink}
                alt="expire link"
              />
            </center>
          )}
        </>
      )}
    </>
  );
}
