import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      height: "60%",
      overflow: "visible",
    },
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  mainSectionContainer: {
    width: "1170px",
    background: "#fff",
    overflow: "hidden",
    // height: "100vh",
    padding: "16px 16px 0 0px",
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down('md')]: {
      width: "auto",
    },
    '@media (max-width: 700px)': {
      width: "fit-content",
    },
   
  },
  cardContainer: {
    width: "auto",
    height: "500px",
    justifyItems: "center",
    margin: "auto",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '@media (min-width: 1360px)': {
      marginTop:'40px'
    },
    // [theme.breakpoints.down("md")]: {
    //   height: "100% !important",
    //   overflow: "visible",
    //   display: "inline-grid",
    // },
    '@media (max-width: 700px)': {
      height: "100% !important",
      overflow: "visible",
      display: "inline-grid",
    },
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  logotypeContainer: {
    // backgroundColor: theme.palette.primary.main,
    width: "50%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "end",
    // marginTop:"auto",

    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    // },
    '@media (max-width: 700px)': {
      width: "100% !important",
    },
    [theme.breakpoints.up("xxl")]: {
      height: "100%",
    },
  },
  groupContainer: {
    [theme.breakpoints.up("xxl")]: {
      height: "100%",
      // marginTop:'280px'
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  topImage: {
    width: "151px",
    height: "38px",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "38px",
    },
  },
  topRightImage: {
    width: "37.14px",
    height: "4.59px",
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  loginTitle: {
    display: "flex",
    color: "white",
    fontWeight: 500,
    fontSize: "30px",
    fontFamily: "Poppins",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  loginSubTitle: {
    display: "flex",
    color: "white",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: theme.spacing(4),
    fontFamily: "Sanchez",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  logotypeImage: {
    // width: "545px",
    height: "476px",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "240px",
      height: "230px",
    },
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  bottomImage: {
    width: "514px",
    height: "115px",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",

    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "80px",
    },
  },
  bottomRightImage: {
    width: "151px",
    height: "38px",
    display: "flex",
    justifyContent: "right",
    alignItems: "right",

    marginTop: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "38px",
    },
  },
  formContainer: {
    width: "50%",
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor:'#ffffff',
    alignItems: "center",
    // [theme.breakpoints.down("md")]: {
    //   width: "auto",
    //   // flexDirection: "row"
    //   // marginLeft:'-60px'
    // },
    '@media (max-width: 700px)': {
      width: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
      // marginLeft:'-60px'
    },
    [theme.breakpoints.up("xxl")]: {
      height: "auto",
    },
  },
  form: {
    width: 340,
    marginTop: theme.spacing(6),
    // backgroundColor:'#ffffff',
    // '@media only screen and (min-width: 1660px)':{
    //   marginTop:'270px'
    // },
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "left",

    color: "#2D3748",
    fontFamily: "Poppins",
  },
  subGreeting: {
    fontSize: 30,
    fontWeight: 500,
    textAlign: "left",
    marginBottom: theme.spacing(-4),
    color: "#101010",
    fontFamily: "Poppins",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px !important",
    },
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountChangeButton: {
    height: 46,
    textTransform: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#FEAA00",
      color: "white",
    },
    "&:after": {
      backgroundColor: "#FEAA00",
      color: "white",
    },
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },

  txtHeading: {
    color: "#4A5568",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  textFieldline: {
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderColor: theme.palette.background.light,
    border: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  // copyright: {
  //   marginTop: theme.spacing(6),
  //   whiteSpace: "nowrap",
  //   [theme.breakpoints.up("md")]: {
  //     position: "absolute",
  //     bottom: theme.spacing(0),
  //   },
  // },
  bottomText: {
    color: "#616161",
    fontSize: 14,
  },
  copyRight: {
    display: "flex",
    // justifyContent: "space-around",
    alignContent: "flex-end",
    fontSize: 14,
  },
  Link: {
    marginTop: theme.spacing(1),
    color: "#2C5282",
  },

  bottomLink: {
    position: "fixed",
    marginTop: theme.spacing(4),
    bottom: 0,
    display: "flex",
    //     backgroundColor: "#03997B",
    // color:'#fff',
    color: "#616161",
    marginBottom: theme.spacing(2),
    '@media (max-width: 700px)': {
      position: "relative",
      display:'inline'
      // display:'inline'
    },
  },
  linkItem: {
    display: "flex",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    color: "#616161",
    marginRight: theme.spacing(2),
  },
  iconGlobal: {
    height: "12px",
    width: "12px",
    marginTop: theme.spacing(0.5),
  },
  errorMessage: {
    fontSize: "14px",
    color: "red",
    textAlign: "left",
  },
  errorMessage2: {
    fontSize: "14px",
    color: "red",
    textAlign: "center",
    marginTop: "6px",
  },
  eyeIcon: {
    position: "absolute",
    // right: '14%',
    // top: '44%',
    marginTop: "-40px",
    marginLeft: "300px",
    height: "35px",
    padding: "4px 10px",
    cursor: "pointer",
    zIndex: "999999999",
    [theme.breakpoints.down("md")]: {
      marginLeft: "280px",
      right:0
    },
  },
}));
