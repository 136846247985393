import React from 'react'
import EhiiaList from '../../components/Ehiia/EhiiaList'

export default function Ehiia() {
  return (
    <div>
        <EhiiaList/>
    </div>
  )
}
