import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Modal,
    Typography,
    Box,
    TextField,
    Avatar,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    IconButton,Fade
  } from "@material-ui/core";
  // styles
import useStyles from "../../components/Profile/styles";
import PageTitle from "../../../component/PageTitle/PageTitle";
import lock from "../../images/lock.svg";
export default function Settings() {
    var classes = useStyles(); 
    const navigate = useNavigate();
  return (
    <>
    <PageTitle title="Settings" />
    <Grid container className={classes.widgetBox} spacing={4}>
        <Grid item xs={12} md={4}>
          <div
            className={classes.passwordBox}
            onClick={() => navigate(`/reset-password`)}
          >
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Change Password
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.passwordBox}>
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Notifications
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.passwordBox}>
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Delete Account
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
