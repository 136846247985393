import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  InputBase,
  Button,
} from "@material-ui/core";

import {Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";
// components
import editIcons from "../../images/whiteEdit.png";

import TableWidget from "../../../component/Widget/TableWidget";

// styles
import useStyles from "./styles";
import ManageClient from "./ManageClient";
import TableDesign from "../../../component/common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
import ActivationMailLinkClientPopup from "./ActivationMailLinkClientPopup";
// API data imports
import { fetchPortalUserList } from "../../store/actions/portalUserAction";

import { resetPopup } from "../../store/reducerSlice/portalusers";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function ClientList() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/admin/client-edit/`);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Name",
      align: "left !important",
      sort:'name'
    },
    {
      label: "Email",
      sort:'email'
    },
    {
      label: "Sub-Organisations",
    },
    {
      label: "Created on",
      sort:'createdAt'
    },
    // {
    //   label: "Activated on",
    // },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();
  const [selectRow, setSelectRow] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  //
  const [value, setValue] = React.useState("");
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [activationLinkRec, setActivationLinkRec] = useState(null);
  const [activationLinkModalShow, setActivationLinkModalShow] = useState(false);
  const [deleteRec, setDeleteRec] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [resetPasswordRec, setResetPasswordRec] = useState(null);

  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "createdAt",
    orderIn: -1,
  });
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  const [message, setMessage] = useState(null);

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.portalusers;
  });

  const page = pagination?.currentPage;

  const cc = filtered || list;
  useEffect(() => {
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);

    console.log("filterOption", filterOption);
    dispatch(fetchPortalUserList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Staff");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setselectclient(null);
    setModalShow(false);
    setOpen(false);
    setDeleteModalShow(false);
    setDeleteRec(null);
    setToggleActivateModalShow(false);
    setActivationLinkModalShow(false);
    setResetPasswordModalShow(false);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const activationLinkEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    console.log("clicked");
    console.log("rec", rec);
    dispatch(resetPopup());
    setActivationLinkRec(rec?.userId);
    setToggleActivateStatus(rec?.isActivated);
    setActivationLinkModalShow(true);
    setTitle("Reset Password");
    setMessage("Reset password link sent successfully!");
    // setShowResult(true);
  };
  const deleteEventHandler = (rec) => {
    console.log("rec", rec);
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    dispatch(resetPopup());
    setDeleteRec(rec?.userId);
    setDeleteModalShow(true);
    setTitle("Delete Staff");
    setMessage("Staff deleted successfully!");
    // setShowResult(true);
  };
  const toggleActivateEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateRec(rec?.userId);
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateModalShow(true);

    // setShowResult(true);
    if (rec?.isActive) {
      setTitle("Deactivate Staff");
      setMessage("Staff deactivated successfully!");
    } else {
      setTitle("Activate Staff");
      setMessage("Staff activated successfully!");
    }
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
    <ToastContainer/>
      <ManageClient
        open={open}
        onHide={hideHandle}
        title={"Add New Staff"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        title={"Delete"}
        deleteRec={deleteRec}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />
      <ActivationMailLinkClientPopup
        onHide={hideHandle}
        title={"Activation"}
        activationLinkRec={activationLinkRec}
        toggleActivateStatus={toggleActivateStatus}
        activationLinkModalShow={activationLinkModalShow}
        setActivationLinkModalShow={setActivationLinkModalShow}
      />

      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          {/* <Link
          href="manage-client"
          className={classes.typoGray}
          underline="none"
        >
          {props.subtitle}{" "}
        </Link>{" "} */}
          Manage Staff
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";                  
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={addClient}
          >
            <img className={classes.editicons} src={editIcons} alt="img" />
            Add Staff
          </Button>
        </div>
      </div>
      <Tabs
        TabIndicatorProps={{
          style: { background: "#0445AF" },
        }}
        textColor="primary"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab label="Registered Staff" classes={{ root: classes.tab }} />
      </Tabs>
      {activeTabId === 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of All Staff",
                  tableHeadings,
                  filterHandler,
                  pagination,
                  filterOption,
                  setFilterOption,
                  isLoader,
                }}
              >
                {cc && cc.length ? (
                  cc.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={() =>
                              navigate(
                                `/admin/client-details/${row.userId}`,
                              )
                            }
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                            }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() =>
                              navigate(
                                `/admin/client-details/${row.userId}`,
                              )
                            }
                            style={{ cursor: "pointer" }}
                            className={classes.tableBodyCell}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                           
                            {row.subOrg.ehiiaVersion &&
                              row.subOrg.ehiiaVersion.join(", ")}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {formatedDate(row.createdAt)}
                          </TableCell>
                          {/* <TableCell className={classes.tableBodyCell}>
                            {row.Activate}
                          </TableCell> */}
                          <TableCell className={classes.tableBodyCell}>
                            {row.isActive ? (
                              row.isActivated ? (
                                <span
                                  style={{ color: "#10940D", fontWeight: 500 }}
                                >
                                  Active
                                </span>
                              ) : (
                                <span style={{ color: "#F8A80C" }}>
                                  Pending
                                </span>
                              )
                            ) : (
                              <span style={{ color: "red" }}>Inactive</span>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <IconButton
                              id="basic-button"
                              aria-controls={
                                openDown ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openDown ? "true" : undefined}
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  navigate(
                                    `/admin/client-edit/${selectRow?.userId}`,
                                  )
                                }
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />
                                Edit
                              </MenuItem>
                              {selectRow?.isActive ? (
                                selectRow.isActivated ? (
                                  <>
                                    <MenuItem
                                      className={classes.menuItem}
                                      onClick={() =>
                                        activationLinkEventHandler(selectRow)
                                      }
                                    >
                                      <Lens
                                        style={{
                                          height: "4px",
                                          width: "4px",
                                          marginRight: "4px",
                                        }}
                                      />{" "}
                                      Reset Password
                                    </MenuItem>
                                    <MenuItem
                                      className={classes.menuItem}
                                      onClick={() =>
                                        toggleActivateEventHandler(selectRow)
                                      }
                                    >
                                      <Lens
                                        style={{
                                          height: "4px",
                                          width: "4px",
                                          marginRight: "4px",
                                        }}
                                      />{" "}
                                      Deactivate
                                    </MenuItem>
                                  </>
                                ) : (
                                  <>
                                    <MenuItem
                                      className={classes.menuItem}
                                      onClick={() =>
                                        activationLinkEventHandler(selectRow)
                                      }
                                    >
                                      <Lens
                                        style={{
                                          height: "4px",
                                          width: "4px",
                                          marginRight: "4px",
                                        }}
                                      />{" "}
                                      Resend activation link
                                    </MenuItem>
                                    <MenuItem
                                      className={classes.menuItem}
                                      onClick={() =>
                                        deleteEventHandler(selectRow)
                                      }
                                    >
                                      <Lens
                                        style={{
                                          height: "4px",
                                          width: "4px",
                                          marginRight: "4px",
                                        }}
                                      />{" "}
                                      Delete
                                    </MenuItem>
                                  </>
                                )
                              ) : (
                                <MenuItem
                                  className={classes.menuItem}
                                  onClick={() =>
                                    toggleActivateEventHandler(selectRow)
                                  }
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Activate
                                </MenuItem>
                              )}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Record Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </Grid>
        </Grid>
      )}
      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
