import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
// styles
import useStyles from "./styles";

export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
}) {
  console.log("deleteModalShow", deleteModalShow);
  //  const { selectclient } = props;
  //  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setDeleteModalShow(false);
    setOpen(false);
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            <Typography className={classes.formActiveHeading}>
              Are you sure, you want to delete this client?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
