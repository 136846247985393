import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { withRouter, useNavigate, useParams } from "react-router-dom";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import expireLink from "../../../assets/icons/checked.png";
import lock from "./Lock.svg";

import arrow from "./arrow.png";
import { useDispatch, useSelector } from "react-redux";
// api
import Validation from "../../hooks/Validation";
import { getOrgData } from "../../../store/actions/reviewsAction";

import { resetPassword } from "../../store/actions/adminAction";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../component/common/loader/Loader";
const formElements = [
  {
    key: "newPassword",
    label: "Password",
    placeHolder: "Enter password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter confirm password",
    type: "password",
  },
];
function Reset() {
  var classes = useStyles();
  const { token } = useParams();
  const { addToast } = useToasts();
  const [orgDetails, setOrgDetails] = useState(null);

  const [passwordDetail, setPasswordDetail] = useState({
    newPassword: null,
    confirmPassword: null,
  });
  const [eyes, setEyes] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [checkLinkExpired, setCheckLinkExpired] = useState(false);
  const navigate = useNavigate();
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation(passwordDetail);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(
        resetPassword({
          token,
        }),
      ).then((payload) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.payload?.success) {
          setCheckLinkExpired(false);
        } else {
          setCheckLinkExpired(true);
        }
      });
    }
  }, [token]);
  const {
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  const submitHandler = (e) => {
    console.log("actioncalled");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    console.log("actioncalled 58", isActionCalled);
    if (!isError() && error !== null && isActionCalled) {
      console.log("actioncalled 57", isActionCalled);
      const { newPassword, ...rest } = passwordDetail;
      dispatch(
        resetPassword({
          // ...rest,
          password: newPassword,
          token,
        }),
      ).then((payload) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.payload?.success) {
          setSuccessMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:'34px'
            },
          });
          setTimeout(() => {
            setIsDone(true);
          }, 3000);
        } else {
          setErrorApiMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "error",
            autoDismiss: true,
          });
          setSuccessMessage(null);
        }
      });
      setIsActionCalled(false);
    } else {
      console.log("actioncalled 76 else", isActionCalled);
      setSuccessMessage(null);
      setIsActionCalled(false);
    }
  }, [isActionCalled]);
  useEffect(() => {
    if (isDone) {
      navigate("/");
    }
  }, [isDone]);
  const hideHandlerRes = () => {
    setIsShow(false);
    if (successMessage) {
      navigate("/");
    } else {
      setSuccessMessage(null);
    }
  };

  return (
    <>
      <Grid
        style={{
          height: checkLinkExpired ? "auto" : "100vh",
          backgroundColor: checkLinkExpired ? "white" : "#F9F9F9",
        }}
        container
        className={classes.container}
      >
        <div
          className={classes.logotypeContainer}
          style={{ color: "#fff", textAlign: "center" }}
        >
          <span>{orgDetails?.name}</span>
        </div>
        {isLoader && <Loader />}
        {checkLinkExpired ? (
          <center style={{ marginTop: "24px" }}>
            <img
              style={{ height: "64px", marginTop: "24px" }}
              src={expireLink}
              alt="expire link"
            />
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Typography
                style={{
                  color: "#03997B",
                  fontFamily: "Sanchez",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                Link has expired.
                <br />
                <span style={{ fontSize: "26px" }}>
                  You can close this page now.
                </span>
              </Typography>
            </div>
          </center>
        ) : (
          <div className={classes.formContainer}>
            <div
              elevation={1}
              className={classes.form}
              style={{
                marginTop: "30px",
                marginBottom: "20px",
                width: "480px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <React.Fragment>
                {/* <Box sx={{ textAlign: "center", m: 1 }}>
                  <img src={lock} alt="img" />
                </Box> */}
                <Typography className={classes.subGreeting}>
                  <Box sx={{ textAlign: "center", m: 1 }}>
                    Reset your password
                  </Box>
                </Typography>
                <Typography className={classes.greeting}>
                  <Box sx={{ textAlign: "center", m: 1 }}>
                    Please choose your new password.
                  </Box>
                </Typography>
                <Fade in={error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    &nbsp;
                  </Typography>
                </Fade>
                {formElements.map((element, i) => {
                  return (
                    <>
                      <Typography variant="h6" className={classes.txtHeading}>
                        {element.label} <span className="required">*</span>
                      </Typography>
                      <TextField
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        size="small"
                        InputProps={{
                          classes: {
                            border: classes.textFieldline,
                            input: classes.textField,
                          },
                        }}
                        name={element.key}
                        type={eyes[element.key] ? "text" : element.type}
                        placeholder={element.placeHolder}
                        value={passwordDetail && passwordDetail[element.key]}
                        onChange={(e) => {
                          setPasswordDetail({
                            ...passwordDetail,
                            [element.key]: e.target.value,
                          });
                        }}
                        onKeyUp={(e) => {
                          checkValidator(element.key);
                        }}
                        isInvalid={
                          error &&
                          error !== null &&
                          typeof error[element.key] !== "object" &&
                          error[element.key]
                        }
                      />
                      <div
                        className={classes.eyeIcon}
                        onClick={(e) =>
                          setEyes({
                            ...eyes,
                            [element.key]: !eyes[element.key],
                          })
                        }
                      >
                        {eyes[element.key] ? (
                          <Icons.Visibility />
                        ) : (
                          <Icons.VisibilityOff />
                        )}
                      </div>
                      <Fade in={error} type="invalid">
                        <Typography
                          color="secondary"
                          className={classes.errorMessage}
                        >
                          {error &&
                            error !== null &&
                            typeof error[element.key] !== "object" &&
                            error[element.key]}
                        </Typography>
                      </Fade>

                      {element.key === "newPassword" && (
                        <Typography
                          className={`passwordRules ${
                            error && passwordDetail[element.key] && "valid"
                          }`}
                        >
                          <h4>Your password must have:</h4>
                          <div className={classes.warningMessage}>
                            <p
                              style={{ marginBottom: "0px", marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.lowercase &&
                                    error[element.key]?.lowercase
                                    ? "invalid"
                                    : "valid"
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.lowercase ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                At least one lowercase letter
                              </span>
                            </p>
                            <p
                              style={{ marginBottom: "0px", marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.uppercase &&
                                    error[element.key]?.uppercase
                                    ? "invalid"
                                    : ""
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.uppercase ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                At least one UPPERCASE letter
                              </span>
                            </p>
                            <p
                              style={{ marginBottom: "0px", marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.number &&
                                    error[element.key]?.number
                                    ? "invalid"
                                    : ""
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.number ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                At least one number
                              </span>
                            </p>
                            <p
                              style={{ marginBottom: "0px", marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.special &&
                                    error[element.key]?.special
                                    ? "invalid"
                                    : ""
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.special ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                At least one special character
                              </span>
                            </p>
                            <p
                              style={{ marginTop: "0px" }}
                              className={
                                passwordDetail[element.key]
                                  ? error &&
                                    error?.newPassword?.length &&
                                    error[element.key]?.length
                                    ? "invalid"
                                    : ""
                                  : ""
                              }
                            >
                              {!passwordDetail?.newPassword ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : error?.newPassword?.length ? (
                                <Icons.Cancel
                                  style={{ color: "red", width: "16px" }}
                                />
                              ) : (
                                <Icons.CheckCircle
                                  style={{ color: "green", width: "16px" }}
                                />
                              )}{" "}
                              <span
                                style={{
                                  marginTop: "0px",
                                  marginLeft: "4px",
                                  color: "#999999",
                                }}
                              >
                                Minimum 8 characters
                              </span>
                            </p>
                          </div>
                        </Typography>
                      )}
                    </>
                  );
                })}

                <div className={classes.creatingButtonContainer}>
                  <Button
                    onClick={(e) => submitHandler(e)}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Reset Password
                  </Button>
                </div>
                <Box style={{marginTop:'10px'}} sx={{ textAlign: "center", m: 2 }}>
                  <Link
                    href="/"
                    className={classes.Link}
                    underline="none"
                  >
                    <img src={arrow} alt="img" />
                    &nbsp;&nbsp; Back to login
                  </Link>
                </Box>
              </React.Fragment>
              {/* <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage2}>
              {apiError?.message}
            </Typography>
          </Fade> */}
            </div>
          </div>
        )}

        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            updateMsg: successMessage,
            title: "Reset Password",
            errorRes: errorApiMessage,
          }}
        />
      </Grid>
    </>
  );
}

export default Reset;
