import React,{useState, useEffect} from "react";
import whiteLogo from "../../assets/icons/ehiia-white-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { Typography, AppBar,
    Toolbar, } from "@material-ui/core";
import expireLink from "../../assets/icons/checked.png";
import useStyles from "./styles";
import { getOrgData } from "../../store/actions/reviewsAction";

export default function ApprovalDone() {
  const dispatch = useDispatch();
  const [orgDetails, setOrgDetails] = useState(null);

    var classes = useStyles();
    useEffect(() => {
      dispatch(getOrgData()).then(({ payload }) => {
        console.log("data", payload);
        if (payload?.data?.success) {
          setOrgDetails(payload?.data?.data);
        }
      });
    }, []);
  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <Apps
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
          <img src={whiteLogo} style={{ width: "97px", height: "19.45px" }} alt="img" />
        </Typography>
        <div className={classes.grow} />

        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <img src={ques} classes={{ root: classes.headerIcon }} alt="imgOrg" />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
        <img src={orgDetails?.logo} style={{ height: "36.45px" }} alt="img" />
        </Typography>
      </Toolbar>
    </AppBar>
      <center style={{ marginTop: "24px" }}>
        <img
          style={{ height: "64px", marginTop: "24px" }}
          src={expireLink}
          alt="expire link"
        />
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          <Typography
            style={{
              color: "#03997B",
              fontFamily: "Sanchez",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Thank you for your submission.
            <br />
            <span style={{fontSize:'26px'}}>You can close this page now.</span>
          </Typography>
        </div>
      </center>
    </>
  );
}
