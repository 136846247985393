import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles(theme => ({
    loader: {
        background: 'rgba(187, 180, 180, 0.8)',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        // minHeight:'140%',
        // maxHeight:'100vh',
        height: 'fit-content',
        zIndex: 1,
    },
    img: {
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    message: {
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color:'#03997B',
        fontWeight:'400'
    }
}));
