import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
// styles
import useStyles from "./styles";
import expand from "../../assets/expand.png";
import hide from "../../assets/hide.png";
import Up from "../../assets/up.png";
import Down from "../../assets/down.png";
export default function Assessment({ assessmentDetail }) {
  var classes = useStyles();
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  function TableCellWithNumberedItems({ paragraph }) {
    if (paragraph === "") {
      return ""; // Display null for null values
    }
    // Split the paragraph by commas to create an array of items
    let items = paragraph.replaceAll("\n\n", "\n");
    items = items.split("\n");

    return (
      <div
        align="left"
        style={{
          textAlign: "left",
          border: "0px",
          padding: "0px",
          verticalAlign: "top",
          margin: "-16px 0px 0px -16px",
        }}
      >
        <ul type="disc">
          {items.map((item, index) => (
            <li key={index}>{`${item.trim()}`}</li>
          ))}
        </ul>
      </div>
    );
  }
  const [showNotes, setShowNotes] = useState(true);
  const notesToggle = () => {
    setShowNotes(!showNotes);
  };
  const [openBlocks, setOpenBlocks] = useState({});
  const toggleBlock = (blockId) => {
    setOpenBlocks(prevState => ({
      ...prevState,
      [blockId]: !prevState[blockId]
    }));
  };
  return (
    <>
      <Grid container spacing={2}>
                
        {assessmentDetail &&
          assessmentDetail.map((element, i) => {
            const {
              key,
              title,
              type,
              questionId,
              questionSerial,
              value,
              options,
            } = element;
            const isQ17BothValue = assessmentDetail.some(
              (item) =>
                item.questionSerial === "Q17." &&
                item.value === "Impacts both Staff and Patients",
            );
            const isQ17PatientValue = assessmentDetail.some(
              (item) =>
                item.questionSerial === "Q17." &&
                item.value === "Only Impacts Patients",
            );
            const isQ17StaffValue = assessmentDetail.some(
              (item) =>
                item.questionSerial === "Q17." &&
                item.value === "Only Impacts Staff",
            );
            const isBlockOpen = element.block ? openBlocks[element.block?.id] !== false : true;
            return (
              <>
                 {element?.block?.title && element?.block?.start && (
                        <>
                          <Grid onClick={element?.block ? () => toggleBlock(element?.block?.id) : ('')}
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              backgroundColor: "#F5F5F5",
                              padding: "10px",
                              border: "1px solid #B6B6B6",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // marginTop: "10px",
                              // borderBottom: "2px solid #26A88E",
                            }}
                            container
                            xs={12}
                            md={10}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#201F1E",textAlign: "center",
                                position: "relative",
                                margin: "0 auto",
                                display: "flex",
                                justifyItems: "center",alignItems:'center'
                              }}
                            >
                              {" "}
                              {element?.block?.title}{" "}
                              
                            </Typography>
                            <div style={{ display: "flex" }}>
                            {isBlockOpen ? <img
                                style={{ height: "8px", cursor: "pointer" }}
                                src={Up}
                                alt="up"
                              /> :  <img
                              src={Down}
                              style={{ height: "8px", cursor: "pointer" }}
                              alt="down"
                            />}
                            </div>
                          </Grid>
                        </>
                      )}
                      {isBlockOpen && (
                <Grid style={{
                  borderTop: element?.block?.start
                    ? "1px  solid #B6B6B6"
                    : "0px  solid #B6B6B6",
                  borderRight: element?.block && "1px  solid #B6B6B6",
                  borderBottom: element?.block?.end
                    ? "1px  solid #B6B6B6"
                    : "0px  solid #B6B6B6",
                  borderLeft: element?.block && "1px  solid #B6B6B6",
                  borderRadius:
                    element?.block?.end && "0px 0px 5px 5px",
                  marginBottom: element?.block?.end && "6px",
                }} item xs={12} md={10} className={classes.textGroupInline}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // marginBottom:'6px'
                    }}
                  >
                    <Typography
                      className={classes.formHeading}
                      style={{
                        // marginTop: "10px",
                        width: "100%",
                        padding: "10px 4px",
                        color: "#201F1E",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      {title}{" "}
                    </Typography>
                    {show && checkQuestionId === element?.questionId ? (
                      <div
                        style={{ marginLeft: "8px" }}
                        onClick={() => handleHideContent(questionId)}
                      >
                        <img
                          src={hide}
                          style={{ marginTop: "5px" }}
                          alt="hide"
                        />
                      </div>
                    ) : (
                      <div
                        style={{ marginLeft: "8px" }}
                        onClick={() => handleShowContent(questionId)}
                      >
                        <img
                          src={expand}
                          style={{ marginTop: "5px" }}
                          alt="expand"
                        />
                      </div>
                    )}
                  </div>

                  {show && checkQuestionId === element?.questionId && (
                    <>
                      {type === "singleSelect" ? (
                        <>
                          <FormControl
                            fullWidth
                            className={classes.dropdownFormControl}
                          >
                            <Select
                              name={questionId}
                              className={classes.selectDropdown}
                              value={value || ""}
                              // disabled={true}
                              readOnly={true}
                            >
                              {/* Render options */}
                              {options &&
                                options.map((item) => (
                                  <MenuItem
                                    questionId={item.value}
                                    value={item.value}
                                  >
                                    {item.title}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </>
                      ) : type === "multiSelectEachAdditionalInfoExceptLast" ? (
                        <>
                          {element.options.map(
                            (item) =>
                              item?.selected && (
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    className={classes.textGroup}
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        style={{ fontSize: "14px" }}
                                        control={
                                          <Checkbox
                                            checked={item.selected === true}
                                          />
                                        }
                                        label={item?.value}
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={7}
                                    className={classes.textGroup}
                                  >
                                    <TextField
                                      style={{
                                        fontSize: "14px",
                                        padding: "4px",
                                      }}
                                      name={element.questionId}
                                      size="small"
                                      className={`${classes.txtForm} ${classes.disabledTextField}`}
                                      fullWidth
                                      placeholder="Additional Info"
                                      variant="outlined"
                                      multiline
                                      readOnly
                                      value={
                                        element.options &&
                                        element.options.find(
                                          (opt) => opt.value === item.value,
                                        )?.additionalInfo
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              ),
                          )}
                        </>
                      ) : type === "singleSelectAdditionalInfoExceptLast" ? (
                        <>
                          <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "4px" }}
                          >
                            <Grid
                              container
                              spacing={2}
                              style={{ marginLeft: "10px" }}
                            >
                              {element.options.map((item) => (
                                <Grid
                                  item
                                  xs={12}
                                  md={3}
                                  className={classes.textGroup}
                                >
                                  <FormControlLabel
                                    style={{ fontSize: "14px" }}
                                    value={item.value}
                                    control={<Radio />}
                                    label={item.title}
                                    checked={element.value === item.value}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.textGroup}
                            >
                              <TextField
                                style={{ fontSize: "14px" }}
                                className={`${classes.txtForm} ${classes.disabledTextField}`}
                                fullWidth
                                placeholder="Additional Info"
                                variant="outlined"
                                multiline
                                value={element.additionalInfo} // Use the form state value for the text field
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : type === "reviewTableNoActionAcknowledge" ? (
                        <>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              className={classes.textGroup}
                            >
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: "100%", boxShadow: 0 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      {element.tableData.columns.map(
                                        (column) => (
                                          <TableCell
                                            key={column}
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            {column}
                                          </TableCell>
                                        ),
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {element.tableData.rows.length > 0 ? (
                                        element.tableData.rows.map(
                                          (row, index) => {
                                            const groupName = row[0]; // 'Identified Groups' is the first item
                                            const impactsText = row[1]; // 'Impact' is the second item
                                            const notesText = row[2]; // 'Notes' is the third item

                                            // Check if impacts and notes exist and are strings before splitting
                                            const impacts =
                                              impactsText &&
                                              typeof impactsText === "string"
                                                ? impactsText.replace(/\n+/g, '\n').split("\n")
                                                : [""];
                                            const notes =
                                              notesText &&
                                              typeof notesText === "string"
                                                ? notesText.replace(/\n+/g, '\n').split("\n")
                                                : [""];

                                            // Determine the number of rows needed
                                            const numRows = Math.max(
                                              impacts.length,
                                              notes.length,
                                            );
                                            return Array.from({
                                              length: numRows,
                                            }).map((_, impactIndex) => (
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                key={`${index}-${impactIndex}`}
                                              >
                                                {impactIndex === 0 && (
                                                  <TableCell  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "left",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top", width:'30%'
                                                  }} rowSpan={numRows}>
                                                    {groupName}
                                                  </TableCell>
                                                )}
                                                <TableCell
                                                  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "left",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top",width:'35%'
                                                  }}
                                                >
                                                  {impacts[impactIndex] || ""}
                                                </TableCell>
                                                <TableCell  align="left"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "left",
                                                    whiteSpace: "pre-line",
                                                    verticalAlign: "top",width:'35%'
                                                  }}>
                                                  {notes[impactIndex] || ""}
                                                </TableCell>
                                              </TableRow>
                                            ));
                                          },
                                        )
                                      ):<>
                                     <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  textAlign: "center",
                                                  whiteSpace: "pre-line",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                    </>}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </Grid>
                      )}
                      {/* patient title */}
                      {((questionSerial.trim() === "Q17." &&
                        isQ17PatientValue) ||
                        (questionSerial.trim() === "Q17." &&
                          isQ17BothValue)) && (
                        <>
                          <Grid
                            style={{
                              // borderBottom: "0px solid #B6B6B6",
                              textAlign: "center",
                              justifyContent: "center",
                              backgroundColor: "#F8FFFE",
                              padding: "10px",
                              border: "2px solid #26A88E",
                              // borderBottom: "2px solid #26A88E",
                            }}
                            container
                            xs={12}
                            md={10}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#26A88E",
                              }}
                            >
                              Patient Assessment Section
                            </Typography>
                          </Grid>
                        </>
                      )}
                {/*  staff title */}
                      {((questionSerial.trim() === "Q17." && isQ17StaffValue) ||
                        (questionSerial.trim() === "Q96." && isQ17StaffValue && isQ17BothValue)) && (
                        <>
                          <Grid
                            style={{
                              // borderBottom: "0px solid #B6B6B6",
                              textAlign: "center",
                              justifyContent: "center",
                              backgroundColor: "#F8FFFE",
                              padding: "10px",
                              borderTop: "2px solid #26A88E",
                              border: "2px solid #26A88E",
                            }}
                            container
                            xs={10}
                            md={10}
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                cursor: "pointer",
                                color: "#26A88E",
                              }}
                            >
                              Staff Assessment Section
                            </Typography>
                          </Grid>
                        </>
                      )}
              </>
            );
          })}
      </Grid>
    </>
  );
}
