import React from "react";
import classnames from "classnames";
// styles
import useStyles from "./styles";
import { Outlet } from "react-router-dom";
// components
import Header from "../Header/Header";
import Sidebar from "../Sidebar";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Outlet />
        </div>
      </>
    </div>
  );
}

export default Layout;
