import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Typography, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { reviewEngagementModule } from "../../user-admin/store/actions/reviewAction";
import Popup from "../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";

// styles
import useStyles from "./styles";
import Loader from "../../component/common/loader/Loader";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
import SendReview from "./SendReview";
import SendApprovalReview from "./SendApprovalReview";
export default function ApprovalEngagementModal({
  onHide,
  setConfirmApproveModalShow,
  confirmApproveModalShow,
  setIsActionCalled,
  projectId,
  type,
}) {
  console.log("projectId", projectId);
  const navigate = useNavigate();
  const [isAction, setIsAction] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setConfirmApproveModalShow(false);
    setReviewModalShow(true);
   
  };

  const hideHandle = () => {
    setConfirmApproveModalShow(false);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };

  return (
    <>
    <SendApprovalReview
     setReviewModalShow={setReviewModalShow}
     reviewModalShow={reviewModalShow}
     projectId={projectId}
     type={type}
    />
      <Popup
        onHide={hideHandle}
        open={confirmApproveModalShow}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                No
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Yes
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <center>
              <img
                src={alertIcon}
                style={{
                  width: "52.96px",
                  height: "52.96px",
                  marginRight: "10px",
                }}
                alt="note-icon"
              />
            </center>
            <div style={{ marginTop: "10px" }}>
              <Typography
                style={{
                  margin: "16px 6px",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#201F1E",textAlign:'center'
                }}
              >
                Are you sure you want to approve this engagement?
              </Typography>
            </div>
          </DialogContentText>
        </DialogContent>
      </Popup>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Send Approval ",
          successMsg: successMessage,
          errorRes: errorApiMessage,
        }}
      />
    </>
  );
}
