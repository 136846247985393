import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../store/axios";

export const fetchSubAdminList = createAsyncThunk(
  "subadmins/fetchSubAdminList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/root/super-admins/v1/?search=${arg?.search}&page=${arg?.page}&orderIn=${arg?.orderIn}&orderBy=${arg?.orderBy}`,
      rejectWithValue,
    ),
);

export const createUpdateSubAdmin = createAsyncThunk(
  "subadmins/createUpdateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/super-admins/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const updateSubAdmin = createAsyncThunk(
  "subadmins/updateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/super-admins/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const deleteSubAdmin = createAsyncThunk(
  "subadmins/deleteSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(`/root/super-admins/v1/${arg?.rootAdminId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const toggleActivation = createAsyncThunk(
  "subadmins/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/root/super-admins/v1/toggle-status/${arg?.rootAdminId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const sendActivationLink = createAsyncThunk(
  "subadmins/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(`/clients/resend-activation/${arg?.rootAdminId}`)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const sendResetPasswordLink = createAsyncThunk(
  "subadmins/sendResetPasswordLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(`/clients/reset-password/${arg?.rootAdminId}`)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const sendActivationMail = createAsyncThunk(
  "clients/sendActivationMail",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/root/super-admins/v1/resend-account-mail/${arg?.rootAdminId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const getSubAdminBySubAdminId = createAsyncThunk(
  "clients/getSubAdminBySubAdminId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/root/super-admins/v1/${arg?.rootAdminId}`)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
