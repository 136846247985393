import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Radio,
  RadioGroup,
  Paper,
  Drawer,
  Divider,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import infoIcon from "../../../../assets/info-icon.svg";
import useStylesAdd from "../../../pages/ehiia/Cases/styles";
import useStylesDetail from "../../../components/Ehiia/Detail/styles";

import Widget from "../../../../component/Widget/Widget";
import whiteEdit from "../../../../images/whiteEdit.png";
import expand from "../../../../assets/expand.png";
import hide from "../../../../assets/hide.png";
import removeIcon from "../../../../assets/delete-icon.svg";
// import notes from "./noteEdit.svg";
import plusIcon from "../../../../assets/icons/add.png";
import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import ManageDataReview from "./ManageDataReview";
import Loader from "../../../../component/common/loader/Loader";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { ArrowForwardIosRounded } from "@material-ui/icons";

export default function DataReview({
  setIsUpdateCalled,
  nextQuestionsValue,
  setNextQuestionsValue,
  clientAdminPath,
  setProjectIdValue,
  setionStatus,
  projectIdValue,
  setActiveTabId = () => {},
  type = "",
}) {
  console.log("projectIdValue", projectIdValue);
  // console.log("assessmentForm", assessmentForm);
  console.log("nextQuestionsValue", nextQuestionsValue);
  var classes = useStyles();
  const { addToast } = useToasts();
  var classesAdd = useStylesAdd();
  var classesDetail = useStylesDetail();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [uploadSuccessData, setUploadSuccessData] = useState(null);
  const [updated, setUpdated] = useState(false);

  const [isShow, setIsShow] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track the index of the current question being displayed

  const [selectedOption, setSelectedOption] = useState(""); // State variable to track selected option
  const [selectedOptions, setSelectedOptions] = useState([]); // state variable for multiple checkbox and his textfield

  const [previousQuestions, setPreviousQuestions] = useState([]); // Array to hold previous questions
  const [additionalSingleInfo, setAdditionalSingleInfo] = useState("");
  //  select single question for update
  const [selectedQuestion, setSelectedQuestion] = useState(null); // State variable to store the selected question
  const [nextQuestionsValueState, setNextQuestionsValueState] = useState(null);

  const [modalShow, setModalShow] = useState(false); // State variable to control the modal visibility
  const [nextSection, setNextSection] = useState(null);
  const [tabSections, setTabSections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [fields, setFields] = useState([{ value: "" }]);
  // Assuming you have a state to manage the table field values
  const [tableData, setTableData] = useState([
    {
      groupName: "",
      values: "",
      findings: "",
      issues: "",
      actionRequired: false,
    },
    // Add more initial entries if needed
  ]);
  const [isDataReviewCompleted, setIsDataReviewCompleted] = useState(false);
  const [isEngagementCompleted, setIsEngagementCompleted] = useState(false);
  const dataReviewSectionRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(setionStatus?.length>0){
      const engagementSection = setionStatus.find(section => section.value === "engagement");
        if (engagementSection) {
          setIsEngagementCompleted(engagementSection.completed);
         
        }
    }
  
   },[setionStatus])
  useEffect(() => {
    console.log("hitApi1");
    if (nextSection === "dataReview" && dataReviewSectionRef.current) {
      console.log("hitApi2");
      dataReviewSectionRef.current.scrollIntoView({ behavior: "smooth" });
      // Add a delay to wait for the smooth scroll to complete
      setTimeout(() => {
        // Add a top margin of 10 pixels after scrolling
        window.scrollBy(0, -50);
      }, 500); // Adjust the delay as needed
    }
  }, [updated, nextSection]);

  const handleEditClick = (question) => {
    console.log("question", question);

    setSelectedQuestion(question);
    setOpen(true);
  };

  useEffect(() => {
    const questions = [];
    console.log("nextQuestionsValue-", nextQuestionsValue);
    nextQuestionsValue &&
      nextQuestionsValue.length &&
      nextQuestionsValue[0]?.type === "multipleFreeTextTable" &&
      nextQuestionsValue[0]?.findings.map((q) => {
        questions.push({
          groupName: q.groupName,
          values: q.values?.length ? q.values : [""],
        });
      });
    setNextQuestionsValueState(questions);
  }, [nextQuestionsValue]);

  // Function to handle changes in text field values
  const handleChange = (idx, event) => {
    const newFields = [...fields];
    newFields[idx].value = event.target.value;
    setFields(newFields);
  };

  // Function to add more text fields
  const addTextField = () => {
    setFields([...fields, { value: "" }]);
  };

  var [activeComponent, setActiveComponent] = useState(0);
  const nextPage = () => {
    if (activeComponent < 3) {
      setActiveComponent(activeComponent + 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };
  const previewsPage = () => {
    if (activeComponent > 0) {
      setActiveComponent(activeComponent - 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };

  useEffect(() => {
    console.log("nextQuestionsValue", nextQuestionsValue);
  }, [nextQuestionsValue]);

  // get assessment filled file data
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "dataReview",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        const filteredData = payload?.data?.form?.dataReview?.questions;
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        setIsDataReviewCompleted(payload?.data?.form?.sectionCompleted);
        setUpdated(false);
      }
    });
  }, []);
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "dataReview",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        // Filter out entries with 'questionId' as 'undefined'
        console.log(
          "payload?.data?.form?.assessment?.questions",
          payload?.data?.form?.assessment?.questions,
        );
        const filteredData = payload?.data?.form?.dataReview?.questions;
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        setNextQuestionsValue(payload?.data?.form?.nextQuestions);
        setNextSection(payload?.data?.form?.nextSection);
        setIsDataReviewCompleted(payload?.data?.form?.sectionCompleted);
        setTabSections(payload?.data?.form?.sections);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setUpdated(false);
      }
    });
  }, [updated]);

  // useEffect(() => {
  //   console.log("Updated tableData:", tableData);
  // }, [tableData]);

  const [selectedValue, setSelectedValue] = useState("");

  // Add assessment form functionality
  const submitHandler = (e) => {
    e.preventDefault();

    const questionId = nextQuestionsValue[0]?.questionId; // Get the questionId from the first element of nextQuestionsValue array
    // const selectedValue = assessmentForm?.assessment?.value; // Get the selected value from the assessmentForm state
    setIsLoading(true);
    const data = {
      projectId: projectIdValue,
      sectionId: "dataReview",
      questions: {
        dataReview: {
          questionId: questionId,
          value: selectedOption ? selectedOption : selectedValue || true,
          options: fields.map((field) => ({
            value: field.value,
          })),
          findings: tableData, // Use the formFindings state here
        },
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          setSuccessMessage(payload?.message);
          // setIsShow(true);
          setErrorApiMessage(null);
          // Set the previous questions by concatenating the current nextQuestionsValue with the previousQuestions array
          setPreviousQuestions((prevQuestions) => [
            ...prevQuestions,
            ...nextQuestionsValue,
          ]);

          // Update the nextQuestionsValue with the new set of questions
          setNextQuestionsValue(payload?.data?.nextQuestions);

          setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to the next question
          setUpdated(true);
          setIsUpdateCalled(true);
          setSelectedOptions([]);
          setAdditionalSingleInfo("");
          setSelectedOption("");
          setSelectedValue("");
          // if(payload?.data?.nextSection === "engagement"){
          //   setActiveTabId(3);
          // }
        } else {
          console.log("payload?.error", payload);
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setIsLoading(false);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };

  const submitDataTableHandler = (e, action = "") => {
    e.preventDefault();

    if (
      (nextQuestionsValue &&
        nextQuestionsValue.length > 0 &&
        nextQuestionsValue[0]?.type === "acknowledgeTableLinks") ||
      isDataReviewCompleted
    ) {
      return navigate(
        `/${
          clientAdminPath === "staff" ? "staff" : "admin"
        }/manage-module/ehiia`,
      );
    }

    const questionId = nextQuestionsValue[0]?.questionId; // Get the questionId from the first element of nextQuestionsValue array
    setIsLoading(true);
    // const selectedValue = assessmentForm?.assessment?.value; // Get the selected value from the assessmentForm state
    // Convert nextQuestionsValue.findingsTable to the desired format
    const transformedFindings = nextQuestionsValue[0].findings.map(
      (finding) => ({
        groupName: finding.groupName,
        values: finding.values,
        findings: finding.findings,
        issues: finding.issues,
        actionRequired: finding.actionRequired || false,
        actionPlan: finding.actionPlan || "",
      }),
    );

    const data = {
      projectId: projectIdValue,
      sectionId: "dataReview",
      questions: {
        dataReview: {
          questionId: questionId,
          value: true, // Set the value to true or false based on your requirements
          options: fields.map((field) => ({
            value: field.value,
          })),
          findings: transformedFindings,
        },
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);
          // Set the previous questions by concatenating the current nextQuestionsValue with the previousQuestions array
          setPreviousQuestions((prevQuestions) => [
            ...prevQuestions,
            ...nextQuestionsValue,
          ]);

          // Update the nextQuestionsValue with the new set of questions
          setNextQuestionsValue(payload?.data?.nextQuestions);

          setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to the next question
          setUpdated(true);
          setIsUpdateCalled(true);
          setSelectedOptions([]);
          setAdditionalSingleInfo("");
          setSelectedOption("");
          setSelectedValue("");
          // if(payload?.data?.nextSection === "engagement"){
          //   setActiveTabId(3);
          // }
          if (action === "save") {
            navigate(
              `/${
                clientAdminPath === "staff" ? "staff" : "admin"
              }/manage-module/ehiia`,
            );
          }
        } else {
          console.log("payload?.error", payload);
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setIsLoading(false);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };

  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const hideHandler = () => {
    console.log("hide handler called");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setSelectedQuestion(null);
    setSelectedQuestion({
      ...selectedQuestion,
      value: "",
      additionalInfo: "",
      valueOptions: [],
    });
    setSelectedValue("");
    setModalShow(false);
    setOpen(false);
    console.log("clear radio buttons");
  };

  const actionCancel = (e) => {
    navigate(
      `/${clientAdminPath === "staff" ? "staff" : "admin"}/manage-module/ehiia`,
    );
  };
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  // info model
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("anchor", anchor);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const drawerWidth = 240;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ManageDataReview
            open={open}
            onHide={hideHandle}
            title={"Edit Your Answer"}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            // assessmentForm={assessmentForm}
            // setAssessmentForm={setAssessmentForm}
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            setPreviousQuestions={setPreviousQuestions}
            setUpdated={setUpdated}
            projectIdValue={projectIdValue}
            setModalShow={setModalShow}
          />
          <Widget noBodyPadding>
            {isLoading && (
              <Loader message={"Please wait, loading questions..."} />
            )}
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{
                visibility: isLoading ? "hidden" : "visible",
                backgroundColor: isLoading ? "rgb(200 232 221 / 30%)" : "#fff",
              }}
            >
              {/* {uploadSuccessData &&  (
                <img src={notes} className={classes.noteIcons} />
              )} */}
              {uploadSuccessData &&
                uploadSuccessData.map((element, i) => {
                  const {
                    key,
                    title,
                    type,
                    questionId,
                    questionSerial,
                    value,
                    options,
                    optional,
                  } = element;
                  return (
                    <Grid item xs={12} md={11} className={classes.textGroup}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          className={classes.formHeading}
                          style={{ width: "90%" }}
                        >
                          {questionSerial} {title}
                          {!optional && (
                            <span
                              style={{
                                color: "#f50b0be1",
                                fontSize: "11px",
                              }}
                            >
                              *
                            </span>
                          )}{" "}
                          {element.info && (
                            <>
                              <img
                                onClick={toggleDrawer(
                                  element.info,
                                  true,
                                  element.info,
                                )}
                                style={{
                                  cursor: "pointer",
                                  marginTop: "6px",
                                  height: "16px",
                                }}
                                src={infoIcon}
                                alt="img"
                              />
                              <Drawer
                                style={{ width: "240px", maxWidth: "240px" }}
                                sx={{
                                  paddingRight: 4,
                                  "& .MuiDrawer-paper": {
                                    boxSizing: "border-box",
                                    width: drawerWidth,
                                  },
                                }}
                                elevation={0}
                                anchor="right"
                                open={state[element.info]}
                                onClose={toggleDrawer(
                                  element.info,
                                  false,
                                  null,
                                )}
                              >
                                <IconButton
                                  style={{
                                    position: "absolute",
                                    marginLeft: "-14px",
                                  }}
                                  onClick={toggleDrawer(
                                    element.info,
                                    false,
                                    element.info,
                                  )}
                                >
                                  <ArrowForwardIosRounded />
                                </IconButton>
                                <p
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    paddingLeft: "24px",
                                    textAlign: "center",
                                    width: "260px",
                                  }}
                                >
                                  {element.title}
                                </p>
                                <Divider />
                                <span
                                  style={{
                                    padding: "6px 10px",
                                    width: "260px",
                                    fontSize: "12px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: element?.info,
                                  }}
                                ></span>
                              </Drawer>
                            </>
                          )}
                        </Typography>

                        <>
                          {show &&
                            checkQuestionId === element?.questionId &&
                            type !== "acknowledgeTableLinks" && (
                              <div
                                style={{
                                  backgroundColor: "#FEAA00",
                                  width: "30px",
                                  height: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleEditClick(element)}
                              >
                                <img
                                  style={{ width: "14px", height: "14px" }}
                                  src={whiteEdit}
                                  alt="Edit Icon"
                                />
                              </div>
                            )}
                          {show && checkQuestionId === element?.questionId ? (
                            <div
                              style={{ marginLeft: "8px" }}
                              onClick={() => handleHideContent(questionId)}
                            >
                              <img
                                src={hide}
                                style={{ marginTop: "5px" }}
                                alt="hide"
                              />
                            </div>
                          ) : (
                            <div
                              style={{ marginLeft: "8px" }}
                              onClick={() => handleShowContent(questionId)}
                            >
                              <img
                                src={expand}
                                style={{ marginTop: "5px" }}
                                alt="expand"
                              />
                            </div>
                          )}
                        </>
                      </div>
                      {show && checkQuestionId === element?.questionId && (
                        <>
                          {type === "acknowledgeTableLinks" ? (
                            <>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: "100%", boxShadow: 0 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      {element.tableData.columns.map(
                                        (column) => (
                                          <TableCell
                                            key={column}
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            {column}
                                          </TableCell>
                                        ),
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.tableData.rows.length > 0 ? (
                                      element.tableData.rows.map(
                                        (row, rowIndex) => (
                                          <TableRow
                                            style={{
                                              border: "1px solid #B6B6B6",
                                            }}
                                            key={rowIndex}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: "1px solid #B6B6B6",
                                                },
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                width: "30%",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {row[0]}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                padding: "8px 16px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "grid",
                                                  flexWrap: "wrap",
                                                  color: "#03997B",
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  textTransform: "capitalize",
                                                  textDecoration: "none",
                                                  padding: "5px",
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {row[1].map((item, i) => (
                                                  <div key={i} style={{ marginBottom: "6px" }}>
                                                  {item.title === "N/A" ? (
                                                    item.title
                                                  ) : (
                                                    <a
                                                      style={{
                                                        textDecoration: "none",
                                                        display: "list-item",
                                                      }}
                                                      href={
                                                        item.link ? 
                                                          (!/^https?:\/\//.test(item.link) ? `https://${item.link}` : item.link) 
                                                          : "#"
                                                      }
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {item.title}
                                                    </a>
                                                  )}
                                                </div>
                                                ))}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )
                                    ) : (
                                      <TableRow
                                        style={{
                                          border: "1px solid #B6B6B6",
                                        }}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: "1px solid #B6B6B6",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                            whiteSpace: "pre-line",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                            whiteSpace: "pre-line",
                                            verticalAlign: "top",
                                            width: "30%",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : type === "multipleFreeText" ? (
                            <>
                              {element?.options.map((field, idx) => (
                                <TextField
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="Text"
                                  variant="outlined"
                                  value={field.value || ""}
                                  onChange={(e) => handleChange(idx, e)}
                                />
                              ))}
                            </>
                          ) : type === "findingsActionTable" ? (
                            <Grid item xs={12} md={12}>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: "100%", boxShadow: 0 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Identified Groups
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        What are your Findings?
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Is Action Required?
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.findings.length > 0 ? (
                                      element.findings.map((row, rowIndex) => (
                                        <TableRow
                                          style={{
                                            border: "1px solid #B6B6B6",
                                          }}
                                          key={row.groupName}
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "30%",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            {row.groupName}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "50%",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            {row?.findings}
                                            {/* <TextField
                                        size="small"
                                        style={{ marginTop: "4px" }}
                                        className={classes.txtForm}
                                        fullWidth
                                        name="findings"
                                        placeholder="What are your Findings?"
                                        variant="outlined"
                                        multiline
                                        value={row?.findings || ""}
                                      /> */}
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "20%",
                                              verticalAlign: "top",
                                            }}
                                          >
                                            <FormControl>
                                              <RadioGroup
                                                style={{ display: "flex" }}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={
                                                  row?.actionRequired === true
                                                    ? "yes"
                                                    : "no"
                                                }
                                              >
                                                <FormControlLabel
                                                  value="yes"
                                                  control={<Radio />}
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  value="no"
                                                  control={<Radio />}
                                                  label="No"
                                                />
                                              </RadioGroup>
                                            </FormControl>
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "30%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "50%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{ width: "20%" }}
                                        >
                                          None
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          ) : type === "actionPlanTable" ? (
                            <Grid item xs={12} md={12}>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Identified Groups
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Findings
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Issues
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Action Required
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.findings.length > 0 ? (
                                      element.findings.map((row, rowIndex) => (
                                        <TableRow
                                          style={{
                                            border: "1px solid #B6B6B6",
                                          }}
                                          key={rowIndex}
                                        >
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",whiteSpace:'normal',
                                              verticalAlign: "top",
                                            }}
                                          >
                                            {row?.groupName}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'normal'
                                            }}
                                          >
                                            {row?.findings}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'normal'
                                            }}
                                          >
                                            {row?.issues}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'normal'
                                            }}
                                          >
                                            {row?.actionPlan}
                                          </TableCell>
                                        </TableRow>
                                      ))
                                    ) : (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "25%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "25%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "25%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "25%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          ) : type === "multipleFreeTextTable" ? (
                            <>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: "100%", boxShadow: 0 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Identified Groups
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        List of Data Source
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.findings.length > 0 ? (
                                      element.findings.map(
                                        (finding, rowIndex) => (
                                          <TableRow
                                            style={{
                                              border: "1px solid #B6B6B6",
                                            }}
                                            key={rowIndex}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: "1px solid #B6B6B6",
                                                },
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                width: "30%",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {finding.groupName}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "left",
                                                verticalAlign: "top",
                                                padding: "4px 16px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "grid",
                                                  flexWrap: "wrap",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {finding.values &&
                                                finding.values.length ? (
                                                  <ul>
                                                    {finding.values.map(
                                                      (value, linkIndex) => (
                                                        <li key={linkIndex}>
                                                          {value}
                                                        </li>
                                                      ),
                                                    )}
                                                  </ul>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )
                                    ) : (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: "1px solid #B6B6B6",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "30%",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            "null"
                          )}
                        </>
                      )}
                    </Grid>
                  );
                })}
              {/* Render current question */}
              {nextSection === "dataReview" &&
                nextQuestionsValue.map((element, i) => {
                  const {
                    key,
                    title,
                    type,
                    questionId,
                    questionSerial,
                    value,
                    options,
                    optional,
                  } = element;

                  return (
                    <Grid
                      ref={i === 0 ? dataReviewSectionRef : null}
                      item
                      xs={12}
                      md={11}
                      className={classes.textGroup}
                      key={`${questionSerial}-${i}`}
                    >
                      <Typography className={classes.formHeading}>
                        {questionSerial}
                        {title}
                        {""}
                        {!optional && (
                          <span
                            style={{
                              color: "#f50b0be1",
                              fontSize: "11px",
                            }}
                          >
                            *
                          </span>
                        )}
                        {element.info && (
                          <>
                            <img
                              onClick={toggleDrawer(
                                element.info,
                                true,
                                element.info,
                              )}
                              style={{
                                cursor: "pointer",
                                marginTop: "6px",
                                height: "16px",
                              }}
                              src={infoIcon}
                              alt="img"
                            />
                            <Drawer
                              style={{ width: "240px", maxWidth: "240px" }}
                              sx={{
                                paddingRight: 4,
                                "& .MuiDrawer-paper": {
                                  boxSizing: "border-box",
                                  width: drawerWidth,
                                },
                              }}
                              elevation={0}
                              anchor="right"
                              open={state[element.info]}
                              onClose={toggleDrawer(element.info, false, null)}
                            >
                              <IconButton
                                style={{
                                  position: "absolute",
                                  marginLeft: "-14px",
                                }}
                                onClick={toggleDrawer(
                                  element.info,
                                  false,
                                  element.info,
                                )}
                              >
                                <ArrowForwardIosRounded />
                              </IconButton>
                              <p
                                style={{
                                  fontWeight: "500",
                                  fontSize: "13px",
                                  paddingLeft: "24px",
                                  textAlign: "center",
                                  width: "260px",
                                }}
                              >
                                {element.title}
                              </p>
                              <Divider />
                              <span
                                style={{
                                  padding: "6px 10px",
                                  width: "260px",
                                  fontSize: "12px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: element?.info,
                                }}
                              ></span>
                            </Drawer>
                          </>
                        )}
                      </Typography>
                      {type === "acknowledgeTableLinks" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  {element.tableData.columns.map((column) => (
                                    <TableCell
                                      key={column}
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      {column}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.tableData.rows.length > 0 ? (
                                  element.tableData.rows.map(
                                    (row, rowIndex) => (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                        key={rowIndex}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: "1px solid #B6B6B6",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "30%",
                                            verticalAlign: "top",
                                          }}
                                        >
                                          {row[0]}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "left",
                                            verticalAlign: "top",
                                            padding: "8px 16px",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "grid",
                                              flexWrap: "wrap",
                                              color: "#03997B",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              textTransform: "capitalize",
                                              textDecoration: "none",
                                              padding: "5px",
                                              marginLeft: "10px",
                                            }}
                                          >
                                            {row[1].map((item, i) => (
                                                <div key={i} style={{ marginBottom: "6px" }}>
                                                {item.title === "N/A" ? (
                                                  item.title
                                                ) : (
                                                  <a
                                                    style={{
                                                      textDecoration: "none",
                                                      display: "list-item",
                                                    }}
                                                    href={
                                                      item.link ? 
                                                        (!/^https?:\/\//.test(item.link) ? `https://${item.link}` : item.link) 
                                                        : "#"
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.title}
                                                  </a>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    ),
                                  )
                                ) : (
                                  <TableRow
                                    style={{
                                      border: "1px solid #B6B6B6",
                                    }}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: "1px solid #B6B6B6",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        verticalAlign: "top",
                                        width: "30%",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={(e) => submitHandler(e)}
                              className={classes.largeBtn}
                              elevation={0}
                            >
                              Complete this task
                            </Button>
                          </Grid>
                        </>
                      ) : type === "multipleFreeText" ? (
                        <>
                          {fields.map((field, idx) => (
                            <TextField
                              size="small"
                              style={{ marginTop: "4px" }}
                              className={classes.txtForm}
                              fullWidth
                              multiline
                              placeholder="Text"
                              variant="outlined"
                              value={field.value || ""}
                              onChange={(e) => handleChange(idx, e)}
                            />
                          ))}
                          <Grid
                            item
                            xs={12}
                            md={9}
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              marginLeft: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() => addTextField()}
                          >
                            <img
                              src={plusIcon}
                              alt="plusicon"
                              style={{ marginRight: "2px" }}
                            />
                            <Typography className={classes.formHeadingValue}>
                              Add More
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={11}>
                            <Button
                              style={{ float: "right", marginBottom: "20px" }}
                              className={classes.rightButton}
                              onClick={(e) => submitHandler(e)}
                              variant="contained"
                              size="large"
                              color="secondary"
                              elevation={0}
                            >
                              Next Question
                            </Button>
                          </Grid>
                        </>
                      ) : type === "findingsActionTable" ? (
                        <Grid item xs={12} md={12}>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    What are your Findings?
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Is Action Required?
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.findings.length > 0 ? (
                                  element.findings.map((row, rowIndex) => (
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={row}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "30%",
                                        }}
                                      >
                                        {row.groupName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "50%",
                                        }}
                                      >
                                        {console.log("row.findings", row)}
                                        <TextField
                                          size="small"
                                          style={{ marginTop: "4px" }}
                                          name="findings"
                                          className={classes.txtForm}
                                          fullWidth
                                          placeholder="What are your Findings?"
                                          variant="outlined"
                                          multiline
                                          value={row.findings || ""}
                                          onChange={(e) => {
                                            const text = e.target.value;
                                            const updatedFindingTable =
                                              nextQuestionsValue?.[0].findings.map(
                                                (t, i) => {
                                                  if (i === rowIndex) {
                                                    const updatedData = {
                                                      ...t,
                                                    };
                                                    updatedData.findings = text;
                                                    return updatedData;
                                                  }
                                                  return t;
                                                },
                                              );
                                            const updatedNextQuestions = [
                                              ...nextQuestionsValue,
                                            ];
                                            updatedNextQuestions[0].findings =
                                              updatedFindingTable;
                                            setNextQuestionsValue(
                                              updatedNextQuestions,
                                            );
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{ width: "20%" }}
                                      >
                                        <FormControl>
                                          <RadioGroup
                                            style={{ display: "flex" }}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="actionRequired"
                                            value={row.actionRequired}
                                            onChange={(e) => {
                                              const text = JSON.parse(
                                                e.target.value,
                                              );
                                              console.log(e.target.value);
                                              console.log("text", text);
                                              const updatedFindingTable =
                                                nextQuestionsValue?.[0].findings.map(
                                                  (t, i) => {
                                                    if (i === rowIndex) {
                                                      const updatedData = {
                                                        ...t,
                                                      };
                                                      updatedData.actionRequired =
                                                        text;
                                                      return updatedData;
                                                    }
                                                    return t;
                                                  },
                                                );
                                              const updatedNextQuestions = [
                                                ...nextQuestionsValue,
                                              ];
                                              updatedNextQuestions[0].findings =
                                                updatedFindingTable;
                                              setNextQuestionsValue(
                                                updatedNextQuestions,
                                              );
                                            }}
                                          >
                                            <FormControlLabel
                                              value={true}
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              value={false}
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "30%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "50%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ width: "20%" }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      ) : type === "actionPlanTable" ? (
                        <Grid item xs={12} md={12}>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                    align="center"
                                  >
                                    Findings
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                    align="center"
                                  >
                                    Issues
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                    align="center"
                                  >
                                    Action Required
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.findings.length > 0 ? (
                                  element.findings.map((row, rowIndex) => (
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={rowIndex}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                        }}
                                      >
                                        {row.groupName}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",whiteSpace:'normal'
                                        }}
                                      >
                                        {row.findings}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                        }}
                                      >
                                        <TextField
                                          size="small"
                                          style={{ marginTop: "4px" }}
                                          className={classes.txtForm}
                                          fullWidth
                                          placeholder="Please enter issues"
                                          variant="outlined"
                                          multiline
                                          name="issues"
                                          value={row.issues || ""}
                                          onChange={(e) => {
                                            const text = e.target.value;
                                            const updatedFindingTable =
                                              nextQuestionsValue?.[0].findings.map(
                                                (t, i) => {
                                                  if (i === rowIndex) {
                                                    const updatedData = {
                                                      ...t,
                                                    };
                                                    updatedData.issues = text;
                                                    return updatedData;
                                                  }
                                                  return t;
                                                },
                                              );
                                            const updatedNextQuestions = [
                                              ...nextQuestionsValue,
                                            ];
                                            updatedNextQuestions[0].findings =
                                              updatedFindingTable;
                                            setNextQuestionsValue(
                                              updatedNextQuestions,
                                            );
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                        }}
                                      >
                                        <TextField
                                          size="small"
                                          style={{ marginTop: "4px" }}
                                          className={classes.txtForm}
                                          fullWidth
                                          placeholder="Please enter action"
                                          variant="outlined"
                                          multiline
                                          name="actionPlan"
                                          value={row.actionPlan || ""}
                                          onChange={(e) => {
                                            const text = e.target.value;
                                            const updatedFindingTable =
                                              nextQuestionsValue?.[0].findings.map(
                                                (t, i) => {
                                                  if (i === rowIndex) {
                                                    const updatedData = {
                                                      ...t,
                                                    };
                                                    updatedData.actionPlan =
                                                      text;
                                                    return updatedData;
                                                  }
                                                  return t;
                                                },
                                              );
                                            const updatedNextQuestions = [
                                              ...nextQuestionsValue,
                                            ];
                                            updatedNextQuestions[0].findings =
                                              updatedFindingTable;
                                            setNextQuestionsValue(
                                              updatedNextQuestions,
                                            );
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      ) : type === "multipleFreeTextTable" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    List of Data Source
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.findings?.length > 0 ? (
                                  element.findings.map((finding, rowIndex) => (
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={rowIndex}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "30%",
                                        }}
                                      >
                                        {finding.groupName}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "grid",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {nextQuestionsValueState &&
                                            nextQuestionsValueState[
                                              rowIndex
                                            ]?.values?.map(
                                              (value, linkIndex) => (
                                                <span
                                                  style={{ display: "flex" }}
                                                >
                                                  <TextField
                                                    size="small"
                                                    key={`${rowIndex}-${linkIndex}`}
                                                    style={{ marginTop: "4px" }}
                                                    className={classes.txtForm}
                                                    fullWidth
                                                    multiline
                                                    placeholder="Text"
                                                    variant="outlined"
                                                    value={value || ""}
                                                    onChange={(e) => {
                                                      const updatedFindings = [
                                                        ...element.findings,
                                                      ];
                                                      updatedFindings[
                                                        rowIndex
                                                      ].values[linkIndex] =
                                                        e.target.value;
                                                      const updatedNextQuestions =
                                                        [...nextQuestionsValue];
                                                      updatedNextQuestions[
                                                        i
                                                      ].findings =
                                                        updatedFindings;
                                                      setNextQuestionsValue(
                                                        updatedNextQuestions,
                                                      );
                                                    }}
                                                  />
                                                  {nextQuestionsValueState[
                                                    rowIndex
                                                  ]?.values.length > 1 && (
                                                    <img
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        const updatedFindings =
                                                          [...element.findings];
                                                        updatedFindings[
                                                          rowIndex
                                                        ].values.splice(
                                                          linkIndex,
                                                          1,
                                                        );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[
                                                          i
                                                        ].findings =
                                                          updatedFindings;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                      src={removeIcon}
                                                      alt="remove"
                                                    />
                                                  )}
                                                </span>
                                              ),
                                            )}

                                          <Grid
                                            item
                                            xs={12}
                                            md={9}
                                            style={{
                                              marginTop: "20px",
                                              display: "flex",
                                              marginLeft: "4px",
                                              cursor: "pointer",
                                            }}
                                            // onClick={() => addTextField()}
                                            onClick={() => {
                                              const updatedFindings = [
                                                ...element.findings,
                                              ];
                                              updatedFindings[
                                                rowIndex
                                              ].values.push(""); // Add an empty text field
                                              const updatedNextQuestions = [
                                                ...nextQuestionsValue,
                                              ];
                                              updatedNextQuestions[i].findings =
                                                updatedFindings;
                                              setNextQuestionsValue(
                                                updatedNextQuestions,
                                              );
                                            }}
                                          >
                                            <img
                                              src={plusIcon}
                                              alt="plus-icon"
                                              style={{ marginRight: "2px" }}
                                            />
                                            <Typography
                                              className={
                                                classes.formHeadingValue
                                              }
                                            >
                                              Add More
                                            </Typography>
                                          </Grid>
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: "1px solid #B6B6B6",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "30%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : (
                        "null"
                      )}
                    </Grid>
                  );
                })}
              {isDataReviewCompleted && (nextSection ==='engagement' || isEngagementCompleted ) && (
                <Grid
                  style={{
                    borderBottom: "0px solid #B6B6B6",
                    backgroundColor: "#F8FFFE",
                    padding: "10px",
                  }}
                  container
                  xs={12}
                  md={12}
                >
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ color: "red" }}>Notes:-</span> You have
                    completed the questions section. Please now move on to the{" "}
                    {tabSections.findIndex(
                      (tab) => tab.value === "engagement",
                    ) >= 0
                      ? "Engagement"
                      : "Approval & Result"}{" "}
                    section to continue your EHIA
                  </Typography>
                </Grid>
              )}
            </Grid>

            {nextSection === "dataReview" &&
            nextQuestionsValue &&
            nextQuestionsValue.length > 0 &&
            nextQuestionsValue[0]?.type !== "acknowledgeTableLinks" ? (
              <Grid
                item
                xs={12}
                md={11}
                style={{
                  visibility: isLoading ? "hidden" : "visible",
                  backgroundColor: isLoading
                    ? "rgb(200 232 221 / 30%)"
                    : "#fff",
                }}
              >
                <Button
                  style={{ float: "right", marginBottom: "20px" }}
                  className={classes.rightButton}
                  onClick={(e) => submitDataTableHandler(e)}
                  variant="contained"
                  size="large"
                  color="secondary"
                  elevation={0}
                >
                  Next Question
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Widget>

          <ApiResponsePopup
            {...{
              hideHandlerRes,
              isRecordUpdated: isShow,
              title: "Data Review",
              successMsg: successMessage,
              errorRes: errorApiMessage,
            }}
          />
        </>
      </Grid>
      {type === "add" ? (
        <Grid className={classesAdd.btnGroup} item xs={12}>
          <Button
            className={classesAdd.leftBluetButton}
            variant="outlined"
            size="large"
            onClick={(e) => setActiveTabId(1)}
          >
            Previous
          </Button>
          <Button
            className={classesAdd.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitDataTableHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isDataReviewCompleted &&
            (tabSections.findIndex((tab) => tab.value === "engagement") >= 0 ||
              tabSections.findIndex((tab) => tab.value === "approvalResult") >=
                0) && (
              <Button
                className={classesAdd.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => setActiveTabId(3)}
              >
                {tabSections.findIndex((tab) => tab.value === "engagement") >= 0
                  ? "Go to Engagement Section"
                  : "Go to Approval & Result Section"}
              </Button>
            )}
        </Grid>
      ) : (
        <Grid className={classesDetail.btnGroup} item xs={12}>
          <Button
            className={classesDetail.btnleftOutline}
            variant="outlined"
            style={{ marginRight: "10px" }}
            // onClick={(e) => setActiveTabId(2)}
            onClick={(e) => actionCancel(e)}
          >
            Cancel
          </Button>
          <Button
            className={classesDetail.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitDataTableHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isDataReviewCompleted &&
            (tabSections.findIndex((tab) => tab.value === "engagement") >= 0 ||
              tabSections.findIndex((tab) => tab.value === "approvalResult") >=
                0) && (
              <Button
                className={classesDetail.btnlRightDark}
                variant="contained"
                color="secondary"
                onClick={(e) => setActiveTabId(3)}
              >
                {tabSections.findIndex((tab) => tab.value === "engagement") >= 0
                  ? "Go to Engagement Section"
                  : "Go to Approval & Result Section"}
              </Button>
            )}
        </Grid>
      )}
    </Grid>
  );
}
