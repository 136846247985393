import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { withRouter, useNavigate, useParams } from "react-router-dom";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";

import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo

import lock from "./Lock.svg";
import whiteLogo from "../../../assets/icons/ehiia-white-logo.svg";
import arrow from "./arrow.png";
import { useDispatch, useSelector } from "react-redux";
// api
import Validation from "../../hooks/Validation";
import { resetPassword } from "../../store/actions/adminAction";
import { useToasts } from "react-toast-notifications";
const formElements = [
  {
    key: "newPassword",
    label: "Password",
    placeHolder: "Enter password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter confirm password",
    type: "password",
  },
];
function Reset() {
  var classes = useStyles();
  const { token } = useParams();
  const { addToast } = useToasts();
  const [passwordDetail, setPasswordDetail] = useState({
    newPassword: null,
    confirmPassword: null,
  });
  const [eyes, setEyes] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const navigate = useNavigate();
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation(passwordDetail);
  const dispatch = useDispatch();
  const {
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  const submitHandler = (e) => {
    console.log("actioncalled");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    console.log("actioncalled 58", isActionCalled);
    if (!isError() && error !== null && isActionCalled) {
      console.log("actioncalled 57", isActionCalled);
      const { newPassword, ...rest } = passwordDetail;
      dispatch(
        resetPassword({
          ...rest,
          password: newPassword,
          token,
        }),
      ).then((payload) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.payload?.success) {
          setSuccessMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: "34px",
            },
          });
          setTimeout(() => {
            setIsDone(true);
          }, 3000);
        } else {
          setSuccessMessage(null);
          addToast(payload?.payload?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
      setIsActionCalled(false);
    } else {
      console.log("actioncalled 76 else", isActionCalled);
      setSuccessMessage(null);
      setIsActionCalled(false);
    }
  }, [isActionCalled]);
  useEffect(() => {
    if (isDone) {
      navigate("/super-admin/login");
      setIsDone(false);
    }
    setIsDone(false);
  }, [isDone]);
  const hideHandlerRes = () => {
    setIsShow(false);
    if (successMessage) {
      navigate("/super-admin/login");
    } else {
      setSuccessMessage(null);
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <div
          className={classes.logotypeContainer}
          style={{ color: "#fff", textAlign: "center" }}
        >
          <center>
            <img src={whiteLogo} style={{ width: "92px" }} alt="white-logo" />
          </center>
        </div>

        <div className={classes.formContainer} style={{ width: "100%" }}>
          <center>
            <Typography
              className={classes.subGreeting}
              style={{ color: "#03997B", fontFamily: "Sanchez" }}
            >
              <Box sx={{ textAlign: "center", m: 1, fontSize: "28px" }}>
                Reset your password
              </Box>
            </Typography>
            <Typography
              className={classes.greeting}
              style={{ color: "#03997B", fontFamily: "Sanchez" }}
            >
              <Box sx={{ textAlign: "center", m: 1 }}>
                Please choose your new password.
              </Box>
            </Typography>
          </center>
          <div
            elevation={1}
            className={classes.form}
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              width: "480px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <React.Fragment>
              {/* <Box sx={{ textAlign: "center", m: 1 }}>
              <img src={lock} alt="img" />
            </Box> */}

              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  &nbsp;
                </Typography>
              </Fade>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Typography variant="h6" className={classes.txtHeading}>
                      {element.label} <span className="required">*</span>
                    </Typography>
                    <TextField
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          border: classes.textFieldline,
                          input: classes.textField,
                        },
                      }}
                      name={element.key}
                      type={eyes[element.key] ? "text" : element.type}
                      placeholder={element.placeHolder}
                      value={passwordDetail && passwordDetail[element.key]}
                      onChange={(e) => {
                        setPasswordDetail({
                          ...passwordDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        checkValidator(element.key);
                      }}
                      isInvalid={
                        error &&
                        error !== null &&
                        typeof error[element.key] !== "object" &&
                        error[element.key]
                      }
                    />
                    <div
                      className={classes.eyeIcon}
                      onClick={(e) =>
                        setEyes({
                          ...eyes,
                          [element.key]: !eyes[element.key],
                        })
                      }
                    >
                      {eyes[element.key] ? (
                        <Icons.Visibility />
                      ) : (
                        <Icons.VisibilityOff />
                      )}
                    </div>
                    <Fade in={error} type="invalid">
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error &&
                          error !== null &&
                          typeof error[element.key] !== "object" &&
                          error[element.key]}
                      </Typography>
                    </Fade>

                    {element.key === "newPassword" && (
                      <Typography
                        className={`passwordRules ${
                          error && passwordDetail[element.key] && "valid"
                        }`}
                      >
                        <h4 style={{ textAlign: "left", marginBottom: "4px" }}>
                          Your password must have:
                        </h4>
                        <table className={classes.warningMessage}>
                          <tr style={{ display: "flex", alignItems: "center" }}>
                            <td>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.lowercase &&
                                      error[element.key]?.lowercase
                                      ? "invalid"
                                      : "valid"
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.lowercase ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one lowercase letter
                                </span>
                              </p>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p
                                style={{
                                  margin: "0px",
                                  marginLeft: "12px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.uppercase &&
                                      error[element.key]?.uppercase
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.uppercase ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one UPPERCASE letter
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ display: "flex", alignItems: "center" }}>
                            <td>
                              <p
                                style={{
                                  margin: "0px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.number &&
                                      error[element.key]?.number
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.number ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one number
                                </span>
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  margin: "0px",
                                  marginLeft: "60px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.special &&
                                      error[element.key]?.special
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.special ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one special character
                                </span>
                              </p>
                            </td>
                          </tr>
                          <p
                            style={{ marginTop: "0px" }}
                            className={
                              passwordDetail[element.key]
                                ? error &&
                                  error?.newPassword?.length &&
                                  error[element.key]?.length
                                  ? "invalid"
                                  : ""
                                : ""
                            }
                          >
                            {!passwordDetail?.newPassword ? (
                              <Icons.Cancel
                                style={{ color: "red", width: "16px" }}
                              />
                            ) : error?.newPassword?.length ? (
                              <Icons.Cancel
                                style={{ color: "red", width: "16px" }}
                              />
                            ) : (
                              <Icons.CheckCircle
                                style={{ color: "green", width: "16px" }}
                              />
                            )}{" "}
                            <span
                              style={{
                                marginTop: "0px",
                                marginLeft: "4px",
                                color: "#999999",
                              }}
                            >
                              Minimum 8 characters
                            </span>
                          </p>
                        </table>
                      </Typography>
                    )}
                  </>
                );
              })}

              <div className={classes.creatingButtonContainer}>
                <Button
                  onClick={(e) => submitHandler(e)}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Save New Password
                </Button>
              </div>
              <Box
                style={{ marginTop: "10px" }}
                sx={{ textAlign: "center", m: 2 }}
              >
                <Link
                  href="/super-admin/login"
                  className={classes.Link}
                  underline="none"
                >
                  <img src={arrow} alt="img" />
                  &nbsp;&nbsp; Back to login
                </Link>
              </Box>
            </React.Fragment>
            {/* <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage2}>
              {apiError?.message}
            </Typography>
          </Fade> */}
          </div>
        </div>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            updateMsg: successMessage,
            title: "Reset Password",
            errorRes: apiError?.message,
          }}
        />
      </Grid>
    </>
  );
}

export default Reset;
