import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import notes from "./noteEdit.svg";
import useStyles from "../styles";
export default function AssessmentFour() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />

        <Grid item xs={12} md={11} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q88. Will the work impact, either directly or indirectly on the
            ability of patients within recognised seldom asked groups to access
            services
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Carers" />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Homeless/ Rough Sleepers"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Vulnerable Migrants (Refugees/Asylum Seekers)"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Sex Workers" />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Traveller Community (inc. Gypsies & Roma)"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Those who have experienced Female Genital Mutilation"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Those who have experienced Human Trafficking/ Modern Slavery"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Those experiencing/recovering from Substance/Alcohol Abuse"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Those living in economically deprived communities"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Those living within geographically isolated communities"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Prisoners / Ex-offenders"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Commuters" />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Vulnerable Adults, e.g. Victims of Domestic Abuse"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Looked After Children"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Ex-service personnel / veterans"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Other groups that you have identified   (please detail)"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={7} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Additional Info"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
