import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Button,
  Link,
} from "@material-ui/core";
// components

import notes from "./noteEdit.svg";
// styles
import useStyles from "../styles";
export default function DataReviewOne() {
  var classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Follow the Links to review data relating to the patient groups that
            you have confimed could be impacted by the work that you are
            planning
          </Typography>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Link"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={9} style={{marginTop:'20px'}} >
        <Button variant="contained" color="secondary" className={classes.largeBtn} elevation={0}>
          Complete this task
        </Button>
        </Grid>
      </Grid>
    </>
  );
}
