import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// import useStyles from "../Detail/styles";
// styles
import useStyles from "./styles";
// components
import Loader from "../../../../component/common/loader/Loader";
export default function Introduction({ introductionDetail }) {
  var classes = useStyles();
  console.log("introductionDetail", introductionDetail);
  return (
    <>
      <>
        {!introductionDetail && <Loader />}
        <Grid container spacing={2} className={classes.formBox}>
        {introductionDetail && Object.entries(introductionDetail).map(([key, element], i) => {
          if (key === "section") return null; // Skip the "section" property
          return (
            <Grid
              item
              xs={12}
              md={
                key === "serviceManager" ||
                key === "serviceManagerEmail" ||
                key === "serviceManagerRole" ||
                key === "trainingCompleted" ||
                key === "mainPurpose" ||
                key === "newOrExisting" ||
                key === "careGroup"
                  ? 12
                  : 6
              }
              className={
                key === "trainingCompleted" ||
                key === "mainPurpose" ||
                key === "careGroup"
                  ? classes.textGroup
                  : classes.textGroupInline
              }
            >
              <Typography className={classes.formHeading}>
                {element?.title.replace(
                  "{organisation}",
                  `${introductionDetail?.organization?.value}`,
                )}{" "}
                :
              </Typography>
              <Typography
                className={
                  key === "trainingCompleted" ||
                  key === "mainPurpose" ||
                  key === "careGroup"
                    ? classes.formHeadingValue2
                    : classes.formHeadingValue
                }
              >
                {key === "searchTags"
                  ? element?.values.map((item, index) => (
                      <span
                        key={index}
                        style={{
                          background: "#EBFFFB",
                          color: "#666",
                          borderRadius: "50px",
                          marginRight: "5px",
                          padding: "4px 10px",
                        }}
                      >
                        {item}
                      </span>
                    ))
                  : element?.value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      </>
    </>
  );
}
