import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const fetchEhiiaVersionList = createAsyncThunk(
  "ehiias/fetchEhiiaVersionList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/root/ehiia-versions/v1`, arg, rejectWithValue),
);

export const fetchAllEhiiaVersionList = createAsyncThunk(
  "ehiias/fetchAllEhiiaVersionList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/root/ehiia-versions/v1/all`,arg, rejectWithValue),
);

export const getEhiiaVersionListByVersionId = createAsyncThunk(
  "ehiias/getEhiiaVersionListByVersionId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`root/ehiia-versions/v1/${arg?.versionId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const createUpdateEhiiaVersion = createAsyncThunk(
  "ehiias/createUpdateEhiiaVersion",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/ehiia-versions/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const toggleActivation = createAsyncThunk(
  "ehiias/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/root/ehiia-versions/v1/toggle-status/${arg?.versionId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
