import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  TextField,
  InputBase,
  Paper, IconButton,
} from "@material-ui/core";
// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import {Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";

import Complete from "./Complete";
import Ongoing from "./Ongoing";

// styles
import useStyles from "./styles";
import classNames from "classnames";
// API data imports
import { fetchOngoingProjectList } from "../../store/actions/ehiiaAction";

const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function EhiiaList() {
  var classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleOnSubmit = () => {
    navigate(`/staff/add-ehiia`);
  };
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "introduction.projectTitle",
    orderIn: -1,
  });

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
      
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  // local
  var [activeTabId, setActiveTabId] = useState(0);
  return (
    <>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          My EHIA Projects
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
             {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";                  
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={handleOnSubmit}
          >
            Add New EHIA Case
          </Button>
        </div>
      </div>

      <Paper className={classes.iconsContainer}>
        <Tabs
          TabIndicatorProps={{
            style: { background: "#0445AF" },
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBar}
        >
          <Tab label="Ongoing Projects" classes={{ root: classes.tab }} />
          <Tab label="Completed Projects" classes={{ root: classes.tab }} />
        </Tabs>
        {activeTabId === 0 && (
          <Ongoing
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            filterHandler={filterHandler}
           
          />
        )}
        {activeTabId === 1 && <Complete filterOption={filterOption}
            setFilterOption={setFilterOption}
            filterHandler={filterHandler}  />}
      </Paper>
      <Dialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add New EHIA Case"}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            {/* name */}
            <Typography className={classes.formHeading}>
              Project Title
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter title"
              variant="outlined"
            />
            {/* name */}
            <Typography className={classes.formHeading}>
              Project Lead
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter project lead name"
              variant="outlined"
            />
            {/* name */}
            <Typography className={classes.formHeading}>
              Line Manager
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter line manager name"
              variant="outlined"
            />
            {/* name */}
            <Typography className={classes.formHeading}>
              Service Manager
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter service manager name"
              variant="outlined"
            />
            {/* name */}
            <Typography className={classes.formHeading}>
              Sub Organisations
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modelFooter}>
          <Button variant="outlined" size="large" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleClose}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
