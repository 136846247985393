import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Redirect from "./Redirect";
import Login from "./super-admin/pages/login/Login";
import { superAdminApp } from "./super-admin/SuperAdminApp";
import { clientAdminApp } from "./client-admin/ClientAdminApp";
import { userAdminApp } from "./user-admin/UserAdminApp";
import LoginOptions from "./super-admin/pages/login/LoginOptions";
import LoginWithEmail from "./client-admin/pages/login/LoginWithEmail";
import PrivacyPolicy from "./client-admin/pages/PrivacyPolicy";

export default function RoutesApp() {
  const admin = useSelector((state) => state.admin);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientAdminPath, setClientAdminPath] = useState("");

  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const adminPath = pathSegments[1]; // Assuming "client-admin" or "super-admin" is the first segment of the path
    console.log("Admin Path:", adminPath);

    setClientAdminPath(adminPath);
    if (subdomain === "root" &&  adminPath !== "super-admin" && adminPath !== "privacy-policy") {
      navigate("/super-admin/login");
      setClientAdminPath("super-admin");
    } 
    else if(subdomain && subdomain !== 'root' && adminPath === "super-admin") {
      navigate("/");
      setClientAdminPath("");
    }
    else if(subdomain === 'root' && adminPath !== "super-admin" && adminPath === "privacy-policy") {
      navigate("/privacy-policy");
      setClientAdminPath("privacy-policy");
    }
  }, [admin]);

  const manageRoutes = () => {
    console.log("admin", admin);

    let renderedComponent = null;

    if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
      renderedComponent = clientAdminApp(admin);
    } else if (clientAdminPath === "super-admin") {
      renderedComponent = superAdminApp(admin);
    } else if (clientAdminPath === "staff" || clientAdminPath === "login" ) {
      renderedComponent = userAdminApp(admin);
    }else if (clientAdminPath === "privacy-policy" ) {
      renderedComponent = <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    }
     else {
      // renderedComponent = userAdminApp(admin);
      renderedComponent = <Route path="/" element={<LoginWithEmail />} />
    }

    return (
      <>
        {/* <Route path="/" element={<Redirect to="/" />} /> */}
        {/* <Route exact path="/login" element={<LoginOptions />} /> */}
        {renderedComponent}
      </>
    );
  };

  return (
    <>
      <Routes>{manageRoutes()}</Routes>
    </>
  );
}
