import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const fetchprivateDataList = createAsyncThunk(
  "privatedata/fetchprivateDataList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-data-content/v1/list/${arg?.organization}?search=${
        arg?.search
      }&page=${arg?.page}&orderIn=${arg?.orderIn || -1}&orderBy=${
        arg?.orderBy
      }`,
      rejectWithValue,
    ),
);

export const createUpdatePrivateData = createAsyncThunk(
  "privatedata/createUpdatePrivateData",
  ({ organization, ...formData }, { rejectWithValue }) => {
    console.log("formData ", formData);
    return AxiosInstance?.put(
      `/admin/ehiia-data-content/v1/update/${organization}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data));
  },
);

export const fetchgroupsList = createAsyncThunk(
  "privatedata/fetchgroupsList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-data-content/v1/identified-groups/${arg?.organization}/`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const getSinglePrivateData = createAsyncThunk(
  "privatedata/getSinglePrivateData",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-data-content/v1/list/${arg?.organization}/${arg?.id}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const downloadDataContentList = createAsyncThunk(
  "privatedata/downloadDataContentList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/admin/ehiia-data-content/v1/download/all/${arg?.organization}`, arg, rejectWithValue),
);

export const toggleActivation = createAsyncThunk(
  "privatedata/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/admin/ehiia-data-content/v1/toggle-status/${arg?.organization}/${arg?.id}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
