import React, { useEffect, useRef, useState } from "react";
import { withRouter, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  Apps,
  ExitToApp,
  LockOpen,
} from "@material-ui/icons";
import classNames from "classnames";
import ques from "./ques.svg";
import profileImg from "../../assets/icons/photo-89.png";
import whiteLogo from "../../assets/icons/ehiia-white-logo.svg";
// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../../component/Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../../component/UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { adminReset as superAdminReset } from "../../super-admin/store/reducerSlice/admin";
import { adminReset as clientAdminReset } from "../../client-admin/store/reducerSlice/admin";
import { adminReset as userAdminReset } from "../../user-admin/store/reducerSlice/admin";
import { getProfile as getSuperAdminProfile } from "../../super-admin/store/actions/adminAction";
import { getProfile as getClientAdminProfile } from "../../client-admin/store/actions/adminAction";
import { getProfile as getUserAdminProfile } from "../../user-admin/store/actions/adminAction";
const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function DefaultHeader(props) {
  var classes = useStyles();
  const [subdomain, setSubdomain] = useState("");
  const navigate = useNavigate();
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);
    console.log("adminDetail", adminDetail);
    // Update the value of clientAdminPath using setClientAdminPath
    setClientAdminPath(clientAdminPath);

    // if (!adminDetail?.success) {
    //   if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
    //     dispatch(getClientAdminProfile());
    //   } else if (clientAdminPath === "super-admin") {
    //     dispatch(getSuperAdminProfile());
    //   } else {
    //     dispatch(getUserAdminProfile());
    //   }
    //   // dispatch(getProfile());
    // }
    console.log("admin", admin);
  }, []);
  const navigateProfile = () => {
    if (clientAdminPath === "client-admin" || clientAdminPath === "admin") {
      navigate(`/admin/profile`);
    } else if (clientAdminPath === "super-admin") {
      navigate(`/super-admin/profile`);
    } else {
      navigate(`/staff/profile`);
    }

    setProfileMenu(null);
  };
  const navigatePassword = () => {
    if (clientAdminPath === "admin") {
      navigate(`/admin/reset-password`);
    } else if (clientAdminPath === "super-admin") {
      navigate(`/super-admin/reset-password`);
    } else {
      navigate(`/staff/reset-password`);
    }

    setProfileMenu(null);
  };
  // api
  const admin = useSelector((state) => state.admin);
  console.log("admin", admin);
  const { adminDetail } = admin;
  const dispatch = useDispatch();
  const logoutRef = useRef();
  useEffect(() => {
    if (admin.invalidJwtToken) {
      localStorage.removeItem("admin");
      if (clientAdminPath === "admin") {
        dispatch(clientAdminReset());
      } else if (clientAdminPath === "super-admin") {
        dispatch(superAdminReset());
      } else {
        dispatch(userAdminReset());
      }
      // dispatch(adminReset());
      logoutRef.current.click();
      // navigate(`/login`);
    }
  }, [admin.invalidJwtToken]);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  return (
    <AppBar elevation={0} position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {/* <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          <Apps
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
          <img src={whiteLogo} style={{ width: "97px", height: "19.45px" }} alt="img" />
        </Typography>
        <div className={classes.grow} />

        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={(e) => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <img src={ques} classes={{ root: classes.headerIcon }} alt="imgOrg" />
        </IconButton> */}
        <Typography weight="medium" className={classes.logotype}>
          <img src={whiteLogo} style={{ width: "97px", height: "19.45px" }} alt="img" />
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
