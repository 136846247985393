import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // model
  formBox: {
    fontFamily: "Poppins",
  },
  formAddBox:{
    fontFamily:'Poppins',
    // paddingLeft:theme.spacing(2),
    // paddingRight:theme.spacing(2),
  },
  bodyDial:{
    borderTop:'1px solid #EDEBE9',
  },
  outlineBtn:{
    textTransform:'none',
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
    paddingLeft:theme.spacing(4),
    paddingRight:theme.spacing(4),
    height:'32px',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:400,
    borderRadius:'2px'
  },
  fillBtn:{
    textTransform:'none',
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
    paddingLeft:theme.spacing(4),
    paddingRight:theme.spacing(4),
    height:'32px',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    borderRadius:'2px'
  },
  avatar: {
    width: "65px",
    height: "65px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
    paddingLeft:theme.spacing(0.5),
    fontSize:'14px',
    color:'#4A5568',
    fontWeight:400
  },
  formLinkHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#2A2338",
    fontSize: "14px",
    fontStyle: "normal",
  },
  formLinkHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#0445AF",
    fontSize: "15px",
    fontStyle: "normal",
  },
  txtForm: {
    padding: "0px 4px",
  },
  modelFooter: {
    fontFamily: "Poppins",
    padding: theme.spacing(2),
  },
  blueButton: {
    fontFamily: "Poppins",
    background: "#0078D4",
    fontSize:'12px',
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
    height:'26px',
    
    textTransform:'none',
    borderRadius:'2px',
    padding:'5px, 8px, 5px, 8px'
  },
  gridBox: {
    fontFamily: "Poppins",
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropdownFormControl: {
    marginTop: theme.spacing(0.5),
  },
  
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    background: "#fff",
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
//   table
tableCell: {
    display: "flex",
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    // textAlign: "center",
    fontFamily:'Poppins'
  },
  imgAvtar: {
    marginRight: "10px",
  },
  tableHeadCell: {
    fontSize: "14px !important",
    fontWeight: 500,
    textAlign: "center",
    fontFamily:'Poppins'
  },
  tableBodyCell: {
    fontSize: "12px !important",
    fontWeight: 400,
    height:'42px',
    padding: "4px 2px",
    textAlign: "center",
    fontFamily:'Poppins'
  },
  // Activation Message
  formActiveHeading:{
    marginTop: theme.spacing(0),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
    fontSize:'16px',
    color:'#4A5568',
    fontWeight:500
  },
  menuBox: {
    padding: "4px",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  menuItem:{
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "4px 10px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  iconsBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
   
  },
  tab: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
    "&:hover": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&:active": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#03997B'
    }
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  //tab color
  iconsBgBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
   
  },
  tabBg: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
    "&:hover": {
      
      color: "#fff",
      background:'#0445AF'
    },
    "&:active": {
      
      color: "#fff",
      background:'#0445AF'
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#0445AF'
    }
  },
  errorMessage:{
    fontSize:'12px',
    color:'red',
    paddingLeft:'6px'
  },
}));
