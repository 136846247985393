import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../service";
import AxiosInstance from "../axios";
import { constant } from "../constant";
const { path } = constant;

export const getReview = createAsyncThunk(
  "review/getReview",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/review/v1/details/${arg?.projectId}?type=${arg?.type}&stage=${arg?.stage}`,
      rejectWithValue,
    ),
);

export const getOrgData = createAsyncThunk(
  "review/getOrgData",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/public/v1/my/brand-info`,
      rejectWithValue,
    ),
);
