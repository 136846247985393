import React from "react";
import {
  Grid,
  Typography,
  Button,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
import notes from "./noteEdit.svg";
import useStyles from "../styles";
export default function DataReviewFour() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Group 1", "Please enter your action plan"),
    createData("Group 2", "Please enter your action plan"),
    createData("Group 3", "Please enter your action plan"),
    createData("Group 4", "Please enter your action plan"),
  ];
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />
        <Grid item xs={12} md={11} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            For each identified patient group confirm the actions that you
            intend to take.
          </Typography>
          <Grid item xs={12} md={9}>
            <TableContainer style={{ marginTop: "16px" }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                  <TableRow>
                    <TableCell>Identified Staff Groups</TableCell>
                    <TableCell align="center">Action Plan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.calories}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={9}>
            <Button
              style={{ float: "right", marginTop: "20px" }}
              variant="contained"
              color="secondary"
              className={classes.largeBtn}
              elevation={0}
            >
              Produce Report
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
