import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      backgroundColor: '#EBFFFB',
      borderRight:'2px solid #03997B'
    },
    borderBottom:'1px solid #EDEBE9'
   
  },
  linkActive: {
    backgroundColor: '#EBFFFB',
    borderRight:'2px solid #03997B'
  },
  linkNested: {
    
    "&:hover, &:focus": {
      backgroundColor: '#EBFFFB',
      borderRight:'2px solid #03997B'
    },
  },
  linkIcon: {
    // marginRight: theme.spacing(1),
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: '16px',
    // height:'16px',
    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    color: theme.palette.primary.main,
  },
  linkText: {
    padding: 0,
    color: '#201F1E',
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    fontWeight:400,
    fontFamily:'Poppins',
  
  },
  linkTextActive: {
    color: '#201F1E',
    fontWeight:500,
    fontFamily:'Poppins'
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 10,

  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
