import clients from "./reducerSlice/clients";
import modules from "./reducerSlice/modules";
import ehiias from "./reducerSlice/ehiias";
import versions from "./reducerSlice/versions";
import dashboards from "./reducerSlice/dashboards";
import organizations from "./reducerSlice/organizations";
import subclients from "./reducerSlice/subclients";
import subadmins from "./reducerSlice/subadmins";
import admin from "./reducerSlice/admin";

export const superAdminReducers = {
  admin,
  clients,
  modules,
  versions,
  dashboards,
  organizations,
  subclients,
  subadmins,
  ehiias,
};
