import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { createUpdateApproval } from "../../store/actions/approvalAction";
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,selectgroup, selectedStage, org, level
}) {
  console.log("level", level);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setDeleteModalShow(false);
    setOpen(false);
  };

  const handleSubmit = (e) => {
    const data = {
      [org]: {
        [selectedStage]: {
          engagement: {
            [level]: {
              email: '',
            },
          },
        },
      },
    };
    dispatch(createUpdateApproval(data))
      .then(({ payload }) => {
        console.log("payload", payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          setErrorApiMessage(null);
          
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
         
        }
      })
      .catch((error) => console.log("error", error));
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  return (
    <>
   
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center>
              <img src={alertIcon} alt="icons" />
              <Typography style={{marginTop:'6px'}} className={classes.formActiveHeading}>
                Are you sure, you want to remove this approver email in
                engagement team?
              </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Engagement Team",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
