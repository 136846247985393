import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
// components

import notes from "./noteEdit.svg";
// styles
import useStyles from "../styles";
export default function DataReviewThree() {
  var classes = useStyles();
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />

        <Grid item xs={12} md={11} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            For each identified patient group confirm what your finding are and
            whether actions<br></br> are needed.
          </Typography>
          <Grid container spacing={2} style={{marginTop:'20px'}}>
            <Grid item xs={12} md={9} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Group 1</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3} className={classes.textGroup}>
              <FormGroup style={{marginTop:'30px'}}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Action Required?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={9} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Group 2</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3} className={classes.textGroup}>
            <FormGroup style={{marginTop:'30px'}}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Action Required?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={9} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Group 3</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3} className={classes.textGroup}>
            <FormGroup style={{marginTop:'30px'}}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Action Required?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={9} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Group 4</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3} className={classes.textGroup}>
            <FormGroup style={{marginTop:'30px'}}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Action Required?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={9} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Group 5</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3} className={classes.textGroup}>
            <FormGroup style={{marginTop:'30px'}}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Is Action Required?"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
