import React, { useState } from "react";

import { difference as _difference, cloneDeep as _cloneDeep } from "lodash";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Radio,
  RadioGroup,
  Paper,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import checked from "../../../../images/checked.png";
import expand from "../../../../assets/expand.png";
import hide from "../../../../assets/hide.png";
export default function Engagement({ engagementDetail }) {
  var classes = useStyles();
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  return (
    <>
      {engagementDetail &&
        engagementDetail.map((element, i) => {
          const {
            key,
            title,
            type,
            questionId,
            questionSerial,
            value,
            options,
            action,additionalQuesTitle
          } = element;

          return (
            <Grid container spacing={2} className={classes.formBox}>
              {/* <img src={notes} className={classes.noteIcons} /> */}
              <Grid item xs={12} md={11} className={classes.textGroup}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // marginTop: "6px",
                  }}
                >
                  <Typography
                    className={classes.formHeading}
                    style={{ width: "90%" }}
                  >
                    {type === "staffEngagementApproval" ||
                    type === "patientEngagementApproval" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // marginBottom: "16px",
                          // marginTop: "16px",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "center",
                            background: "#F3F3F3",
                            padding: "6px 16px",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          Please send the engagement plan for review to the
                          Patient/Staff Engagement Specialist
                        </span>
                      </div>
                    ) : (
                      <>
                        {questionSerial} {title} {additionalQuesTitle && <span  dangerouslySetInnerHTML={{
                                  __html: additionalQuesTitle,
                                }}></span>}
                      </>
                    )}
                  </Typography>
                  {show && checkQuestionId === element?.questionId ? (
                    <div
                      style={{ marginLeft: "8px" }}
                      onClick={() => handleHideContent(questionId)}
                    >
                      <img src={hide} style={{ marginTop: "5px" }} alt="hide" />
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: "8px" }}
                      onClick={() => handleShowContent(questionId)}
                    >
                      <img
                        src={expand}
                        style={{ marginTop: "5px" }}
                        alt="expand"
                      />
                    </div>
                  )}
                </div>
                <Grid item xs={12} md={12}>
                  {show && checkQuestionId === element?.questionId && (
                    <>
                      {type === "insightsActionTable" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Engagement Required
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.insights.length > 0 ? (
                                  element.insights.map((row, rowIndex) => (
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={row.group}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "60%",
                                        }}
                                      >
                                        {row.groupName}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "40%",
                                          border: "1px solid #B6B6B6",
                                        }}
                                      >
                                        <FormControl>
                                          <RadioGroup
                                            style={{ display: "flex" }}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="actionRequired"
                                            value={row.actionRequired}
                                          >
                                            <FormControlLabel
                                              value={true}
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              value={false}
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <>
                                    <TableRow
                                      style={{
                                        border: "1px solid #B6B6B6",
                                      }}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "60%",
                                          textAlign: "center",
                                        }}
                                      >
                                        None
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "40%",
                                          border: "1px solid #B6B6B6",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : type === "insightsActionPlanTable" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Selected Engagement Options
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Details
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.insights.length > 0 ? (
                                  element?.insights?.map(
                                    (engageGrp, engageGrpIndex) => 
                                    {
                                      const groupName =
                                        engageGrp.groupName;
                                      const engagementGroups =
                                        engageGrp.engagementGroups?.length>0 ? engageGrp.engagementGroups :
                                        [""];
                                      const details =
                                        engageGrp.details.length>0 ? engageGrp.details : [];
                                      // Determine the number of rows needed
                                      const numRows = Math.max(
                                        engagementGroups.length ,
                                        details.length ,
                                      );
                                     
                                      return Array.from({
                                        length: numRows,
                                      }).map((_, rowIndex) => (
                                      <>
                                        <TableRow
                                          style={{
                                            border: "1px solid #B6B6B6",
                                          }}
                                          key={`${engageGrpIndex}-${rowIndex}`}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: "1px solid #B6B6B6",
                                              },
                                          }}
                                        >
                                           {rowIndex === 0 && (
                                                      <>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "40%",
                                              verticalAlign: "top",
                                            }}
                                            rowSpan={numRows}
                                          >
                                            {groupName}
                                          </TableCell>
</>)}
                                          <TableCell
                                            align="left"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              verticalAlign: "middle",
                                              padding: "0px 10px",
                                              width: "30%",
                                            }}
                                          >
                                            {engagementGroups[
                                                              rowIndex
                                                            ] || "N/A"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              verticalAlign: "middle",
                                              padding: "0px 10px",
                                              width: "30%",
                                            }}
                                          >
                                             {details[
                                                              rowIndex
                                                            ] || "N/A"}
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    ))},
                                  )
                                ) : (
                                  <>
                                    <TableRow
                                      style={{
                                        border: "1px solid #B6B6B6",
                                      }}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "40%",
                                          textAlign: "center",
                                        }}
                                      >
                                        None
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "30%",
                                          border: "1px solid #B6B6B6",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "30%",
                                          border: "1px solid #B6B6B6",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : type === "staffEngagementApproval" ||
                        type === "patientEngagementApproval" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    {element?.type ===
                                    "patientEngagementApproval"
                                      ? "Patient "
                                      : "Staff "}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      width: "50%",
                                    }}
                                    component="th"
                                    scope="row"
                                  >
                                    <>
                                      <Typography
                                        className={classes.formHeading}
                                        style={{ fontSize: "16px" }}
                                      >
                                        Sent on Date:{" "}
                                        {moment(element?.sentAt).format("lll")}
                                      </Typography>
                                    </>
                                    {element.history.map(
                                      (review, reviewIndex) =>
                                        review?.status === "pending" ? (
                                          <div key={reviewIndex}>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              style={{
                                                backgroundColor: "#ffffff",
                                                // border: "1px solid #03997B",
                                                marginTop: "10px",
                                                borderRadius: "5px",
                                                padding: "8px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <Typography
                                                  className={
                                                    classes.formHeading
                                                  }
                                                >
                                                  Comment from Applicant :
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    display: "contents",
                                                    color: "#52575C",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {moment(
                                                    review?.timestamp,
                                                  ).format("lll")}
                                                </Typography>
                                              </div>
                                              <Typography
                                                style={{
                                                  color: "#201F1E",
                                                  fontSize: "13px",
                                                  paddingLeft: "4px",
                                                  textAlign: "justify",
                                                  marginTop: "4px",
                                                }}
                                              >
                                                <>{review?.comments}</>
                                              </Typography>
                                            </Grid>
                                          </div>
                                        ) : review?.status === "rejected" ? (
                                          <div key={reviewIndex}>
                                            <Grid
                                              item
                                              xs={12}
                                              md={12}
                                              style={{
                                                backgroundColor: "#EDF8F6",
                                                marginTop: "10px",
                                                padding: "8px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <Typography
                                                  className={
                                                    classes.formHeading
                                                  }
                                                >
                                                  Comment from Engagement
                                                  Specialist :
                                                </Typography>
                                                <Typography
                                                  style={{
                                                    display: "contents",
                                                    color: "#52575C",
                                                    fontWeight: "400",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  {moment(
                                                    review?.timestamp,
                                                  ).format("lll")}
                                                </Typography>
                                              </div>
                                              <Typography
                                                style={{
                                                  color: "#201F1E",
                                                  fontSize: "13px",
                                                  paddingLeft: "4px",
                                                  textAlign: "justify",
                                                  marginTop: "4px",
                                                }}
                                              >
                                                <>{review?.comments}</>
                                              </Typography>
                                            </Grid>
                                          </div>
                                        ) : null,
                                    )}
                                    {element?.status === "approved" && (
                                      <>
                                        <Typography
                                          className={classes.formHeading}
                                          style={{
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "8px",
                                          }}
                                        >
                                          Your Engagement Plan Is Approved
                                          Successfully{" "}
                                          <Avatar
                                            style={{
                                              borderRadius: "0px",
                                              marginLeft: "0px",
                                            }}
                                            src={checked}
                                          />
                                        </Typography>
                                        <Typography
                                          className={classes.formHeading}
                                          style={{
                                            fontSize: "16px",
                                            marginTop: "6px",
                                          }}
                                        >
                                          Engagement Plan Approval Date :{" "}
                                          <span style={{ fontWeight: "400" }}>
                                            {moment(element?.approvedAt).format(
                                              "lll",
                                            )}
                                          </span>
                                        </Typography>
                                        <Typography
                                          className={classes.formHeading}
                                          style={{
                                            fontSize: "16px",
                                            marginTop: "6px",
                                          }}
                                        >
                                          Engagement Plan Approved By :{" "}
                                          <span style={{ fontWeight: "400" }}>
                                            {element?.name}
                                          </span>
                                        </Typography>
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : type === "multipleFreeTextTable" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{ minWidth: "100%", boxShadow: 0 }}
                              aria-label="simple table"
                            >
                              <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    List of Engagement Activity
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.findings.length > 0 ? (
                                  element.findings.map((finding, rowIndex) => (
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={rowIndex}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "30%",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {finding.groupName}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "left",
                                          padding: "0px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "grid",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {finding.values &&
                                          finding.values.length ? (
                                            <ul>
                                              {finding.values.map(
                                                (value, linkIndex) => (
                                                  <li key={linkIndex}>
                                                    {value}
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <>
                                    <TableRow
                                      style={{
                                        border: "1px solid #B6B6B6",
                                      }}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "20%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : type === "findingsActionTable" ? (
                        <>
                          <Grid item xs={12} md={12}>
                            <TableContainer
                              style={{ marginTop: "16px" }}
                              sx={{ boxShadow: 0 }}
                              component={Paper}
                            >
                              <Table
                                sx={{ minWidth: "100%", boxShadow: 0 }}
                                aria-label="simple table"
                              >
                                <TableHead
                                  style={{ backgroundColor: "#EDFDFA" }}
                                >
                                  <TableRow>
                                    <TableCell
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      Identified Groups
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      What are your Findings?
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      Is Action Required?
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {element.findings.length > 0 ? (
                                    element.findings.map((row, rowIndex) => (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                        key={row.groupName}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "30%",
                                          }}
                                        >
                                          {row.groupName}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "50%",
                                          }}
                                        >
                                          {row?.findings || ""}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          style={{ width: "20%" }}
                                        >
                                          <FormControl>
                                            <RadioGroup
                                              style={{ display: "flex" }}
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="row-radio-buttons-group"
                                              value={
                                                row?.actionRequired === true
                                                  ? "yes"
                                                  : "no"
                                              }
                                            >
                                              <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                value="no"
                                                control={<Radio />}
                                                label="No"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <>
                                      <TableRow
                                        style={{
                                          border: "1px solid #B6B6B6",
                                        }}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: "1px solid #B6B6B6",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "30%",
                                            textAlign: "center",
                                            // verticalAlign: "top",
                                            padding: "0px 16px 16px",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "50%",
                                            textAlign: "center",
                                            // verticalAlign: "top",
                                            padding: "0px 16px 16px",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "20%",
                                            textAlign: "center",
                                            // verticalAlign: "top",
                                            padding: "0px 16px 16px",
                                          }}
                                        >
                                          None
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      ) : type === "actionPlanTable" ? (
                        <>
                          <Grid item xs={12} md={12}>
                            <TableContainer
                              style={{ marginTop: "16px" }}
                              component={Paper}
                            >
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                               <TableHead
                                                style={{
                                                  backgroundColor: "#EDFDFA",
                                                }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Identified Groups
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Findings
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Action Required
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Expected Date of Completion 
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {element.findings.length > 0 ? (
                                                  element.findings.map(
                                                    (row, rowIndex) => (
                                                      <TableRow
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        }}
                                                        key={rowIndex}
                                                      >
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "30%",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {row?.groupName}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",
                                                          }}
                                                        >
                                                          {row?.findings}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",
                                                          }}
                                                        >
                                                          {/* <TextField
                                                    size="small"
                                                    style={{ marginTop: "4px" }}
                                                    className={`${classes.txtForm} ${classes.disabledTextField}`}
                                                    fullWidth
                                                    placeholder="Please enter your action plan"
                                                    variant="outlined"
                                                    name="actionPlan"
                                                    multiline
                                                    value={
                                                      row?.actionPlan || ""
                                                    }
                                                  /> */}
                                                          {row?.actionPlan ||
                                                            ""}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "20%",
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          {
                                                            row?.expectedCompletionDate
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )
                                                ) : (
                                                  <>
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                )}
                                              </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      ) : type === "multipleActivityFindingsActionTable" ? (
                        <>
                          <TableContainer
                            style={{ marginTop: "16px" }}
                            sx={{ boxShadow: 0 }}
                            component={Paper}
                          >
                            <Table
                              sx={{
                                minWidth: "100%",
                                boxShadow: 0,
                              }}
                              aria-label="simple table"
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "#EDFDFA",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Identified Groups
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    List of Engagement Activity
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Findings
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "1px solid #B6B6B6",
                                      textAlign: "center",
                                    }}
                                  >
                                    Is Action Required ?
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {element.findings.length > 0 ? (
                                  element.findings.map((finding, rowIndex) => (
                                    <TableRow
                                      style={{
                                        border: "1px solid #B6B6B6",
                                      }}
                                      key={rowIndex}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "20%",
                                          fontSize: "14px",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {finding.groupName}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "left",
                                          fontSize: "14px",
                                          padding: "0px",
                                          width: "30%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "grid",
                                            flexWrap: "wrap",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {finding.values &&
                                          finding.values.length ? (
                                            <ul>
                                              {finding.values.map(
                                                (value, linkIndex) => (
                                                  <li key={linkIndex}>
                                                    {value}
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "30%",
                                          fontSize: "14px",
                                          verticalAlign: "top",
                                        }}
                                      >
                                        {finding?.findings || ""}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          width: "20%",
                                        }}
                                      >
                                        <FormControl>
                                          <RadioGroup
                                            style={{
                                              display: "flex",
                                            }}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={
                                              finding?.actionRequired === true
                                                ? "yes"
                                                : "no"
                                            }
                                          >
                                            <FormControlLabel
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              value="yes"
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              value="no"
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <>
                                    <TableRow
                                      style={{
                                        border: "1px solid #B6B6B6",
                                      }}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          textAlign: "center",
                                          // verticalAlign: "top",
                                          padding: "0px 16px 16px",
                                        }}
                                      >
                                        None
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : (
                        "Not Available"
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
}
