import React from "react";
import classnames from "classnames";
// styles
import useStyles from "./styles";
import { Outlet } from "react-router-dom";
// components


// context
import { useLayoutState } from "../../context/LayoutContext";
import DefaultHeader from "../Header/DefaultHeader";

function DefaultLayout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <DefaultHeader history={props.history} />
        
        <div
          className={classnames(classes.contentDefault, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar}  />
          <Outlet />
        </div>
      </>
    </div>
  );
}

export default DefaultLayout;
