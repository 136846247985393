import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import notes_bg from "../../images/notes_bg.png";
import { reviewEngagementModule } from "../../user-admin/store/actions/reviewAction";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import Loader from "../../component/common/loader/Loader";
import { useToasts } from "react-toast-notifications";
export default function SendApprovalReview({
  onHide,
  reviewModalShow,
  setReviewModalShow,
  setIsActionCalled,
  projectId,
  type,
}) {
  console.log("projectId", projectId);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAction, setIsAction] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const [engagementDetail, setEngagementDetail] = useState({
    action: null,
    type: null,
    email: null,
    name: null,
    comments: null,
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isCommentsValid, setIsCommentsValid] = useState(true);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEngagementDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    setErrorApiMessage("");
    // Validate the name field
    if (name === "name") {
      setIsNameValid(value.trim() !== ""); // Adjust the validation condition as needed
    }

    // Validate the comments field
    if (name === "comments") {
      setIsCommentsValid(value.trim() !== ""); // Adjust the validation condition as needed
    }
  };

  const submitHandler = (mode) => {
    setIsAction(true);
    // Validate the name field
    if (!engagementDetail.name || engagementDetail.name.trim() === "") {
      setErrorApiMessage("Name is required");
      setIsNameValid(false);
      return; // Stop the submission if the name field is empty
    } else {
      setIsNameValid(true);
    }

    // Validate the comments field
    if (!engagementDetail.comments || engagementDetail.comments.trim() === "") {
      setErrorApiMessage("Comments are required");
      setIsCommentsValid(false);
      return; // Stop the submission if the comments field is empty
    } else {
      setIsCommentsValid(true);
    }
    const data = {
      projectId: projectId,
      action: "approved",
      type: type,
      name: engagementDetail?.name,
      comments: engagementDetail?.comments,
    };
    console.log("data", data);
    dispatch(reviewEngagementModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setReviewModalShow(false);
          setEngagementDetail({});
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);
          setEngagementDetail({});
          setTimeout(() => {
            navigate("/staff/approval-complete");
          }, 2000);
        } else {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const hideHandle = () => {
    setEngagementDetail({});
    setReviewModalShow(false);
    setErrorApiMessage("");
    setIsCommentsValid(true);
    setIsNameValid(true);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
    setEngagementDetail({});
  };
  return (
    <>
      <Popup
        onHide={hideHandle}
        open={reviewModalShow}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                Cancel
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Submit
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={notes_bg}
                style={{
                  width: "52.96px",
                  height: "52.96px",
                  marginRight: "10px",
                }}
                alt="icon"
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#25282B",
                }}
              >
                Add Comment
              </Typography>
            </div>
            <div style={{ marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    margin: "16px 6px",
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#201F1E",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#52575C",
                      marginRight: "6px",
                    }}
                  >
                    Outcome Started:
                  </span>
                  Approval
                </Typography>
               
              </div>
              <TextField
                style={{ marginBottom: "10px" }}
                size="small"
                className={classes.txtForm}
                fullWidth
                type="text"
                name="name"
                label="Enter Approval Name"
                variant="outlined"
                multiline
                InputLabelProps={{
                  // classes: {
                  //   outlined: classes.MuiInputLabel_outlined_MuiInputLabel_marginDense
                  // },
                  style: { fontSize: "14px",paddingLeft:'6px' }, // Adjust the font size as needed
                }}
                // rows={5}
                value={engagementDetail.name ? engagementDetail.name : ""}
                onChange={handleInputChange}
                error={!isNameValid}
                helperText={
                  !isNameValid && (
                    <span style={{ marginLeft: "-12px" }}>
                      Name is required
                    </span>
                  )
                }
              />
              
              <TextField
                style={{ marginBottom: "10px",marginTop: "10px"}}
                size="small"
                className={classes.txtForm}
                fullWidth
                type="textarea"
                name="comments"
                label="Add your comments "
                variant="outlined"
                multiline
                rows={4}
                InputLabelProps={{
                  // classes: {
                  //   outlined: classes.MuiInputLabel_outlined_MuiInputLabel_marginDense
                  // },
                  style: { fontSize: "14px",paddingLeft:'6px' }, // Adjust the font size as needed
                }}
                value={
                  engagementDetail.comments ? engagementDetail.comments : ""
                }
                onChange={handleInputChange}
                error={!isCommentsValid}
                helperText={
                  !isCommentsValid && (
                    <span style={{ marginLeft: "-12px" }}>
                      Comments are required
                    </span>
                  )
                }
              />
             
            </div>
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Send Approval ",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
