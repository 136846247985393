import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Paper } from "@material-ui/core";
import useStyles from "./styles";
import Overview from "./Overview"; // Import your Overview component here
import Approval from "./Approval";
export default function Result({ ehiiaResultDetail,projectSummaryData }) {
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeParentTabId, setActiveParentTabId] = useState(0);
  const [updated, setUpdated] = useState(false);
  const renderTabs = (stageData, stageKey) => {
    console.log("stageKey-15", stageKey);
    const isStage1Pending = stageData.stage1.status !== "approved";
    return (
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="primary2"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBgBar}
        >
          {Object.keys(stageData).map((subStageKey, index) => (
            <Tab
              key={index}
              label={
                subStageKey && subStageKey === "stage1" ? "Equality Sign-off" : "Report Sign-off"
              }
              classes={{ root: classes.tab }}
              disabled={subStageKey === "stage2" && isStage1Pending}
            />
          ))}
        </Tabs>
        {Object.keys(stageData).map(
          (subStageKey, index) =>
            activeTabId === index && (
              <div  key={index}>
                <Approval
                  updated={updated}
                  setUpdated={setUpdated}
                  stage={stageKey}
                  subStage={subStageKey}
                  data={stageData[subStageKey]}
                />
              </div>
            ),
        )}
      </Paper>
    );
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeParentTabId}
        onChange={(e, id) => setActiveParentTabId(id)}
        className={classes.iconsBgBar}
      >
        {ehiiaResultDetail &&
          Object.keys(ehiiaResultDetail).map((tabLabel, index) => (
            <Tab style={{textTransform:'capitalize'}}
              key={index}
              label={tabLabel}
              classes={{ root: classes.tabBg }}
            />
          ))}
      </Tabs>
      {ehiiaResultDetail &&
        Object.keys(ehiiaResultDetail).map(
          (tabLabel, index) =>
            activeParentTabId === index &&
            (tabLabel === "overview" ? (
              <Overview projectSummaryData={projectSummaryData}
                overviewData={ehiiaResultDetail[tabLabel]}
                key={index}
              />
            ) : (
              renderTabs(ehiiaResultDetail[tabLabel], tabLabel)
            )),
        )}
    </>
  );
}
