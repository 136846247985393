export const manageEhiiaSchema = {
  versionName: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter version name",
      noLeadingSpace: "Version name cannot start with a space",
    },
  },
  description: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter description",
    },
  },
  createdDate: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select created date",
    },
  },
  createdBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter created by",
    },
  },
  updatedDate: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select updated date",
    },
  },
  updatedBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter updated by",
    },
  },
  status: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select status",
    },
  },
};
