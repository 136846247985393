import React from "react";
import { Route } from "react-router-dom";
import Redirect from "../Redirect";
import Login from "./pages/login/Login";
import Forget from "./pages/forgetPassword/Forget";
import Reset from "./pages/resetPassword/Reset";
import Registration from "./pages/registration/Registration";
import RegisterSucess from "./pages/registration/RegisterSucess";
import Activation from "./pages/registration/Activation";
import Dashboard from "./pages/dashboard/Dashboard";
import Ehiia from "./pages/ehiia/Ehiia";
import EhiiaDetails from "./pages/ehiia/EhiiaDetails";
import EditEhiia from "./pages/ehiia/EditEhiia";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/profile/ChangePassword";
import Project from "./pages/manageProject/Project";
import ProjectDetails from "./pages/manageProject/ProjectDetails";
import EditProject from "./pages/manageProject/EditProject";
import Layout from "../component/Layout/Layout";
import EhiiaCase from "./pages/ehiia/Cases/EhiiaCase";
import EhiiaModuleDeatils from "./pages/ehiia/EhiiaModuleDetails";
import LoginWithEmail from "../client-admin/pages/login/LoginWithEmail";

import ApprovalPage from "./pages/ApprovalPage";
import DefaultLayout from "../component/Layout/DefaultLayout";
import ApprovalResultPage from "./pages/ApprovalResultPage";
import ApprovalDonePage from "./pages/ApprovalDonePage";
import PrivacyPolicy from "../client-admin/pages/PrivacyPolicy";

export const userAdminApp = (admin) => {
  console.log("user-admin", admin);
  if (!admin?.isAdminLoggedIn) {
    return (
      <>
        <Route path="*" element={<Redirect to="/login" />} />
        <Route exact path="/login" element={<LoginWithEmail />} />
        <Route exact path="/staff/login" element={<Login />} />
        <Route exact path="/staff/forget-password" element={<Forget />} />
        <Route exact path="/staff/reset-password/:token" element={<Reset />} />
        <Route exact path="/registration/:token" element={<Registration />} />
        <Route exact path="/register-succes" element={<RegisterSucess />} />
        <Route exact path="/staff/activate" element={<Activation />} />
        <Route exact path="/staff" element={<DefaultLayout />}>
        <Route
            exact
            path="/staff/approval-request/:projectId"
            element={<ApprovalPage />}
          />
          <Route
            exact
            path="/staff/final-approval-request/:projectId"
            element={<ApprovalResultPage />}
          />
           <Route
            exact
            path="/staff/approval-complete"
            element={<ApprovalDonePage />}
          />
                 <Route exact path="/staff/privacy-policy" element={<PrivacyPolicy />} />

        </Route>
        
      </>
    );
  }

  if (admin?.isAdminLoggedIn) {
    return (
      <>
      {/* <Route
            exact
            path="/staff/approval-request/:projectId"
            element={<ApprovalPage />}
          /> */}
        <Route path="*" element={<Redirect to="/staff/dashboard" />} />
        <Route exact path="/staff" element={<Layout />}>
          <Route exact path="/staff/dashboard" element={<Dashboard />} />
          <Route exact path="/staff/manage-module/ehiia" element={<Ehiia />} />
          <Route
            path="/staff/ehiia-details/:projectId"
            element={<EhiiaDetails />}
          />
          <Route path="/staff/edit-ehiia/:projectId" element={<EditEhiia />} />
          <Route path="/staff/add-ehiia/" element={<EhiiaCase />} />
          <Route
            path="/staff/view-project/:projectId"
            element={<EhiiaModuleDeatils />}
          />
          <Route path="/staff/project" element={<Project />} />
          <Route
            path="/staff/project-details/:projectId"
            element={<ProjectDetails />}
          />
          <Route path="/staff/project-edit/" element={<EditProject />} />
          <Route path="/staff/profile" element={<Profile />} />
          <Route path="/staff/reset-password" element={<ChangePassword />} />
        </Route>
      </>
    );
  }
};
