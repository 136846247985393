import React from 'react'
import AuditTrailsList from '../../components/AuditTrail/AuditTrailsList'

export default function AuditTrails() {
  return (
    <div>
      {/* Audit Trails */}
      <AuditTrailsList/>
    </div>
  )
}
