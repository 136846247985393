import clients from "./reducerSlice/clients";
import modules from "./reducerSlice/modules";
import ehiia from "./reducerSlice/ehiia";
import versions from "./reducerSlice/versions";
import dashboards from "./reducerSlice/dashboards";
import organizations from "./reducerSlice/organizations";
import subclients from "./reducerSlice/subclients";
import subadmins from "./reducerSlice/subadmins";
import admin from "./reducerSlice/admin";
import reviews from "./reducerSlice/reviews";

export const userAdminReducers = {
  admin,
  clients,
  modules,
  versions,
  dashboards,
  organizations,
  subclients,
  subadmins,
  ehiia,
  reviews
  
};
