import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  labelOutlined: {
    // Add your custom label outlined styles here
  },
  MuiInputLabel_outlined_MuiInputLabel_marginDense: {
    transform: 'translate(14px, 14px) scale(1) !important', // Apply the transform initially
    '&.Mui-focused': {
      transform: 'translate(26px, -4px) scale(0.75) !important', // Apply the same transform when focused
      fontSize:'16px'
    },
  },
  errorMessage:{
    color:'red',
    fontSize:'12px',
    marginLeft:'10px'
  },
  logotype: {
    color: "white",
    // marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: '16px !important',
    fontFamily:'Poppins',
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appBar: {
    width: "100vw",
    zIndex:999,
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height:'48px',
    left:0,
    paddingRight:'0px !important',
   paddingTop:'3px'
  },
  hoverableCell: {
    border: "1px solid #B6B6B6",
    textAlign: "center",
    whiteSpace: "normal", // Prevent text from wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflow
    maxWidth: "160px", // Set the maximum width
    width:'200px',
    cursor: "pointer",
    transition: "white-space 0.3s", // Add a smooth transition
    wordWrap:'break-word',
    overflowWrap:'anywhere',
    minWidth:'160px'
    
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    minHeight:'48px',
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0.5) ,
    },
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
    formBox:{
      padding:theme.spacing(4),
        // paddingRight:theme.spacing(4),
        // paddingLeft:theme.spacing(4),
        // paddingRight:theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft:theme.spacing(0),
        paddingRight:theme.spacing(0),
          },
          background: '#F8FFFE',
       borderRadius: '5px',
       border: '0.50px #03997B solid'
    },
    avatar:{
        width:"64px",
        height:"64px"
    },
    formHeading:{
        marginRight:theme.spacing(0),
        marginBottom:theme.spacing(0),
        color:'#969DAB',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:400,
        textAlign:'left',
        flexGrow: 1,
       width:'40%',
       [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    formHeadingValue:{
        marginTop:theme.spacing(0),
        marginBottom:theme.spacing(0),
        color:'#2A2338',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:500,
        textAlign:'left',
        width:'60%',
        [theme.breakpoints.down("md")]: {
            width: "100%",
          },
    },
    disabledTextField: {
      // backgroundColor: theme.palette.grey[300], // Change the background color to indicate it's disabled
      pointerEvents: 'none', // Disable pointer events to prevent interaction
    },
    txtForm:{
        padding:"0px 4px",
        fontSize:'14px',
    },
    textGroup:{
        display:"flex",
        fontSize:'14px',
        justifyContent: 'flex-start',
        [theme.breakpoints.down("md")]: {
            display: "inline",
          },
    },
    modelFooter:{
        padding:theme.spacing(2),
        
    },
    blueButton:{
        background:"#0445AF",
        color:"#fff",
        fontFamily:'Poppins',
        fontSize:14,
        fontWeight:500,
        textTransform:'none',
        marginTop:theme.spacing(2),
        "&:hover": {
            background:"#0078D4",
        color:"#fff",
        }
    },
    gridBox:{
        // display:"flex",
        // justifyItems:"center",
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4)
    },
    btnGroup: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
          display:'inline'
        },
        
      },
      btnlRightDark:{
        height:'42px',
        textTransform:'none',
        borderRadius:'4px',
        [theme.breakpoints.down("md")]: {
          width:'100%',
          marginTop:theme.spacing(1)
        },
      },
      btnleftOutline:{
        height:'42px',
        textTransform:'none',
        border:'1px solid #03997B',
        color:'#03997B',
        [theme.breakpoints.down("md")]: {
          width:'100%'
        },
      },
    
      blueButton: {
        background: "#0078D4",
        color: "#fff",
        fontWeight: 400,
        marginTop: theme.spacing(2),
        "&:hover": {
          background: "#0078D4",
          color: "#fff",
        },
      },
      btn:{
        paddingTop:theme.spacing(0),
        paddingBottom:theme.spacing(0)
      },
      
    rightButton:{
        marginLeft:theme.spacing(2),
        backgroundColor:'#0445AF',
        color:'#fff',
        fontFamily:'Poppins',
        fontSize:14,
        fontWeight:500,
    },
    widgetBox:{
        marginTop:theme.spacing(2)
    },
    passwordBox:{
        display:"flex",
        alignItems:'center',
    },
    Link:{
        color:'#0445AF',
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(4)
    },
    AssignHeading:{
        marginBottom:theme.spacing(-2),
        marginTop:theme.spacing(2)
    },
    icons:{
        marginRight:theme.spacing(2)
    },
    selectDropdown: {
      border: "1px solid #DADFEB",
      padding: "4px 10px",
      borderRadius: "5px",
      marginTop: theme.spacing(0),
      height: "42px",
      background: "#fff",
      "&:before": {
        border: theme.palette.primary.light,
      },
      "&:after": {
        border: theme.palette.primary.main,
      },
      "&:hover:before": {
        border: `${theme.palette.primary.light} !important`,
      },
    },
    iconsBar: {
        marginBottom: theme.spacing(2),
        backgroundColor: "#FFFFFF",
        padding:'4px 0px'
       
      },
      tab: {
        color: '#201F1E',
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:500,
        textTransform:'none',
        marginRight:'2px',
        borderRight:'1px solid #EDEBE9',
        "&:hover": {
          
          color: "#fff",
          background:'#03997B'
        },
        "&:active": {
          
          color: "#fff",
          background:'#03997B'
        },
        "&.Mui-selected":{
          color: "#fff",
          background:'#03997B'
        }
      },
      iconsContainer: {
        background: "none",
        boxShadow: "none",
        // overflow: "hidden",
        paddingBottom: theme.spacing(2),
        marginTop:theme.spacing(4)
      },
      //tab color
  iconsBgBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
   
  },
  tabBg: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
   
    "&:hover": {
      
      color: "#0445AF",
      // background:'#03997B',
      borderBottom:'2px solid #0445AF'
    },
    "&:active": {
      
      color: "#0445AF",
      // background:'#03997B'
      borderBottom:'2px solid #0445AF'
    },
    "&.Mui-selected":{
      color: "#0445AF",
      // background:'#03997B'
      borderBottom:'2px solid #0445AF'
    }
  },
}))