import React from "react";
import * as ReactDOMClient from "react-dom/client";
import store from "./store/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import reportWebVitals from "./reportWebVitals";
import Themes from "./themes";
import App from "./App";

// import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
root.render(
  <React.StrictMode>
    <LayoutProvider>
      <UserProvider>
        <Provider store={store}>
          <ThemeProvider theme={Themes.default}>
          
            <App />
          
          </ThemeProvider>
        </Provider>
      </UserProvider>
    </LayoutProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
