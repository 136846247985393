import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Button,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  Link,
} from "@material-ui/core";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";
export default function EhiiaEdit() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange1 = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <PageTitle
        subtitle="Ongoing Projects >"
        urlLink="manage-module/ehiia"
        title="Edit"
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Project ID</Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              disabled
              placeholder="Enter project id"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Project Title
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter project title"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Project Lead
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter project lead"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Line Manager
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter line manager"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Service Manager
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter service manager"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Sub-Organisations
            </Typography>
            <TextField
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter sub-organisation"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created on</Typography>
            <TextField
              type="date"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Select date"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Last updated on
            </Typography>
            <TextField
              type="date"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Select date"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status:</Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                className={classes.selectDropdown}
                value={value}
                onChange={handleChange1}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="a">Active </MenuItem>
                <MenuItem value="b">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Widget>
      <div className={classes.footerBox}>
        <Button variant="outlined" size="large" className={classes.leftButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
        >
          Update
        </Button>
      </div>
    </>
  );
}
