import React, { useEffect, useState, useRef } from "react";
import { difference as _difference, cloneDeep as _cloneDeep } from "lodash";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  Link,
  ListItemText,
} from "@material-ui/core";
// Model
import removeIcon from "../../../../assets/delete-icon.svg";
import { DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../../assets/icons/add.png";
import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";

import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";

import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ManageEngagement(props) {
  const { selectedQuestion, setSelectedQuestion } = props;

  const [question, setQuestion] = useState(_cloneDeep(selectedQuestion));
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]); // state variable for multiple checkbox and his textfield

  const [fields, setFields] = useState([{ value: "" }]);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const { addToast } = useToasts();
  const { onHide, ...props1 } = props;

  //for radio buttons
  useEffect(() => {
    console.log("selectedOptions", selectedOptions);
  }, [selectedOptions]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    let engagementPayload;
    if (
      selectedQuestion &&
      selectedQuestion.type === "insightsActionPlanTable"
    ) {
      const insights = [];

      engagementPayload = {
        ...selectedQuestion,
        insights: selectedQuestion.insights.map((insight) => ({
          ...insight,
          engagementGroups: insight.engagementGroups
            ? insight.engagementGroups.map((group) => {
                return group &&
                  !selectedQuestion.other.engagementGroups?.includes(group)
                  ? group.replace(/^/, "other-")
                  : group;
              })
            : null,
        })),
      };
    } else {
      engagementPayload = selectedQuestion;
    }
    setQuestion(engagementPayload);
  }, [selectedQuestion]);

  useEffect(() => {
    console.log("selectedQuestion", selectedQuestion);
    const questions = [];
    if (
      selectedQuestion &&
      selectedQuestion.type === "multipleActivityFindingsActionTable"
    ) {
      selectedQuestion &&
        selectedQuestion?.findings.map((q) => {
          questions.push({
            groupName: q.groupName,
            values: q.values?.length ? q.values : [""],
            findings: q.findings,
            actionRequired: q.actionRequired,
          });
        });
    }

    setSelectedOptions(questions);
  }, [selectedQuestion]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();

    // Clear all field values
    setSelectedOptions([]);
    setSelectedValue("");
    console.log("clear radio button");
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  // Add assessment form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    const questionId = props.selectedQuestion.questionId; // Get the questionId from the first element of nextQuestionsValue array
    const selectedValue = props.selectedQuestion.value; // Get the selected value from the assessmentForm state
    const selectCheckedValue = props.selectedQuestion.valueOptions;
    const selectAdditionInfoValue = props.selectedQuestion.additionalInfo;

    const mergedOptions = [...selectedOptions];

    if (selectCheckedValue) {
      // Add selectCheckedValue to mergedOptions array
      mergedOptions.push(...selectCheckedValue);
    }

    let engagementPayload;
    if (question && question.type === "insightsActionPlanTable") {
      const insights = [];

      engagementPayload = {
        ...question,
        insights: question.insights.map((insight) => ({
          ...insight,
          engagementGroups: insight.engagementGroups
            ? insight.engagementGroups.map((group) =>
                group.replace("other-", ""),
              )
            : null,
        })),
      };
    } else if (
      question &&
      question.type === "multipleActivityFindingsActionTable"
    ) {
      engagementPayload = {
        ...question,
        findings: selectedOptions.map((q) => ({
          groupName: q.groupName,
          values: q.values?.length ? q.values : [""],
          findings: q.findings,
          actionRequired: q.actionRequired,
        })),
      };
    } else {
      engagementPayload = question;
    }

    const data = {
      projectId: props.projectIdValue,
      sectionId: "engagement",
      questions: {
        // engagement: selectedQuestion,
        engagement: engagementPayload,
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload?.success, payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          // setSelectedValue("");
          // setSelectedOptions([]);
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);

          props.setUpdated(true);
        } else {
          console.log("payload?.error", payload);
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };
  // console.clear();
  console.log(`question`, question);
  const updateValues = (arr) => {
    let uArr = [];
    if (arr && arr.length) {
      arr.map((value) => {
        if (value?.includes("other-")) {
          value = "other-";
        }
        if (value) {
          uArr.push(value);
        }
      });
    }
    console.log("uArr-", arr, uArr);
    return uArr;
  };
  // engagement action plan handlers for insights plan question
  const insightsPlanActionsHandlerNew = ({
    action = "",
    insights = [],
    insightIndex,
    groupIndex,
    details = "",
    groups = [],
  }) => {
    debugger;
    if (insightIndex > -1) {
      const groupElement = insights?.[insightIndex];
      if (groupElement?.groupName) {
        switch (action) {
          case "updateGroup": {
            if (groups?.length) {
              const removedGroupsIndex = [];
              groupElement?.engagementGroups?.forEach((x, index) => {
                if (!groups?.includes(x)) {
                  removedGroupsIndex?.push(index);
                }
              });
              if (removedGroupsIndex?.length) {
                removedGroupsIndex?.forEach((removedIndex) => {
                  groupElement?.engagementGroups?.splice(removedIndex, 1);
                  groupElement?.details?.splice(removedIndex, 1);
                });
              }
              groupElement.engagementGroups = [...groups];
              const detailsLengthDiff =
                groupElement?.engagementGroups?.length >
                groupElement?.details?.length;
              if (detailsLengthDiff) {
                groupElement.details = [
                  ...groupElement?.details,
                  ...Array(detailsLengthDiff)?.fill(""),
                ];
              }
            } else {
              groupElement.engagementGroups = [];
              groupElement.details = [];
            }
            break;
          }
          case "updateDetails": {
            if (groupIndex > -1) {
              groupElement.details[groupIndex] = details || ``;
            }
            break;
          }
          default: {
            break;
          }
        }
        insights[insightIndex] = groupElement;
        setQuestion((prevValue) => ({
          ...prevValue,
          insights: insights,
        }));
        // setQuestion((prevValue) => {
        //   const newValue = [...prevValue];
        //   newValue[0].insights = insights;
        //   return newValue;
        // });
      }
    }
  };
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        alert={
          <>
            {question?.reset ? (
              <Typography
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Note:- If you edit this question, all your next questions will
                be reset and you will have to answer again.
              </Typography>
            ) : (
              ""
            )}
          </>
        }
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Save Changes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText
            className={classes.formAddBox}
            style={{ padding: "10px 4px" }}
          >
            {question && (
              <Grid item xs={12} md={12} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  {question.questionSerial}
                  {question.title} {question?.additionalQuesTitle && <span  dangerouslySetInnerHTML={{
                                  __html: question?.additionalQuesTitle,
                                }}></span>}
                </Typography>
                {question?.type === "insightsActionTable" ? (
                  <>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Identified Groups
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Engagement Required
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {question.insights.map((row, rowIndex) => (
                            <TableRow
                              style={{ border: "1px solid #B6B6B6" }}
                              key={row.group}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: "1px solid #B6B6B6",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "60%",
                                }}
                              >
                                {row.groupName}
                              </TableCell>

                              <TableCell
                                align="center"
                                style={{
                                  width: "40%",
                                  border: "1px solid #B6B6B6",
                                }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    style={{ display: "flex" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={row.actionRequired ? "yes" : "no"}
                                    onChange={(e) => {
                                      const actionRequired =
                                        e.target.value === "yes";
                                      const updatedFindings =
                                        question.insights.map((find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              actionRequired,
                                            };
                                          }
                                          return find;
                                        });
                                      setSelectedQuestion({
                                        ...question,
                                        insights: updatedFindings,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : selectedQuestion &&
                  selectedQuestion.type === "insightsActionPlanTable" ? (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Identified Groups
                            </TableCell>

                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Select Engagement Options
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Selected Engagement Options
                            </TableCell>

                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Details
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {question &&
                            question?.insights?.map(
                              (engageGrp, engageGrpIndex) => {
                                const groupName = engageGrp.groupName;
                                const engagementGroups =
                                  engageGrp.engagementGroups?.length > 0
                                    ? engageGrp.engagementGroups
                                    : [""];
                                const details =
                                  engageGrp.details.length > 0
                                    ? engageGrp.details
                                    : [];
                                // Determine the number of rows needed
                                const numRows = Math.max(
                                  engagementGroups.length,
                                  details.length,
                                );

                                return Array.from({
                                  length: numRows,
                                }).map((_, rowIndex) => (
                                  <>
                                    <TableRow
                                      style={{ border: "1px solid #B6B6B6" }}
                                      key={`${engageGrpIndex}-${rowIndex}`}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: "1px solid #B6B6B6",
                                        },
                                      }}
                                    >
                                      {rowIndex === 0 && (
                                        <>
                                          <TableCell
                                            rowSpan={numRows}
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {groupName}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",
                                              padding: "0px 16px 16px",
                                            }}
                                            rowSpan={numRows}
                                          >
                                            <FormControl
                                              fullWidth
                                              className={
                                                classes.dropdownFormControl
                                              }
                                              InputProps={{
                                                style: {
                                                  fontSize: "14px",
                                                },
                                              }}
                                            >
                                              <Select
                                                className={
                                                  classes.selectDropdown
                                                }
                                                style={{
                                                  maxWidth: "300px",
                                                  fontSize: "14px",
                                                }}
                                                multiple
                                                InputProps={{
                                                  style: {
                                                    fontSize: "14px",
                                                  },
                                                }}
                                                value={updateValues(
                                                  engageGrp.engagementGroups,
                                                )}
                                                renderValue={(selected) =>
                                                  selected.join(", ")
                                                }
                                                onChange={(e) => {
                                                  insightsPlanActionsHandlerNew(
                                                    {
                                                      action: "updateGroup",
                                                      insights:
                                                        question?.insights,
                                                      insightIndex:
                                                        engageGrpIndex,
                                                      groups: e?.target?.value,
                                                    },
                                                  );
                                                }}
                                              >
                                                {question?.other
                                                  ?.engagementGroups
                                                  ? question?.other?.engagementGroups.map(
                                                      (
                                                        engageGrpOther,
                                                        engageGrpOtherIndex,
                                                      ) => (
                                                        <MenuItem
                                                          style={{
                                                            maxWidth: "300px",whiteSpace:'normal'
                                                          }}
                                                          value={engageGrpOther}
                                                          key={
                                                            engageGrpOtherIndex
                                                          }
                                                        >
                                                          <Checkbox
                                                            checked={(
                                                              engageGrp?.engagementGroups ||
                                                              []
                                                            ).includes(
                                                              engageGrpOther,
                                                            )}
                                                          />
                                                          <ListItemText
                                                            primary={
                                                              engageGrpOther
                                                            }
                                                          />
                                                        </MenuItem>
                                                      ),
                                                    )
                                                  : []}
                                                <MenuItem
                                                  value={"other-"}
                                                  key={`other-${engageGrpIndex}`}
                                                >
                                                  <Checkbox
                                                    checked={(
                                                      engageGrp?.engagementGroups ||
                                                      []
                                                    ).some(
                                                      (e) =>
                                                        e &&
                                                        e?.includes("other-"),
                                                    )}
                                                  />
                                                  <ListItemText
                                                    primary={"Other"}
                                                  />
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                            {(
                                              engageGrp?.engagementGroups || []
                                            ).some(
                                              (e) => e && e.includes("other-"),
                                            ) && (
                                              <TextField
                                                size="small"
                                                style={{
                                                  marginTop: "4px",padding:'0px',
                                                  maxWidth: "300px",
                                                }}
                                                className={classes.txtForm}
                                                fullWidth
                                                multiline
                                                InputProps={{
                                                  rows: 3,
                                                  style: { fontSize: "14px" },
                                                }}
                                                placeholder="Text"
                                                variant="outlined"
                                                onChange={
                                                  engageGrp?.engagementGroups &&
                                                  engageGrp?.engagementGroups
                                                    .length &&
                                                  engageGrp?.engagementGroups.find(
                                                    (e) =>
                                                      e && e.includes("other-"),
                                                  )
                                                    ? (e) =>
                                                        props?.insightsPlanActionsHandler(
                                                          {
                                                            question: question,
                                                            action: "update",
                                                            group:
                                                              "other-" +
                                                              e?.target?.value,
                                                            insightsIndex:
                                                              engageGrpIndex,
                                                            setQuestion:
                                                              setQuestion,
                                                            // questionIndex:
                                                            //   i,
                                                          },
                                                        )
                                                    : () => {}
                                                }
                                                value={_difference(
                                                  engageGrp?.engagementGroups,
                                                  question?.other
                                                    ?.engagementGroups,
                                                )
                                                  ?.join(",")
                                                  .replace("other-", "")}
                                              />
                                            )}
                                          </TableCell>
                                        </>
                                      )}
                                      {rowIndex >= 0 &&
                                        rowIndex <= engagementGroups.length && (
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "middle",
                                              padding: "0px 10px",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {engagementGroups[rowIndex]}
                                          </TableCell>
                                        )}
                                        {rowIndex >= 0 &&
                                                      rowIndex <=
                                                        details.length && (
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "25%",
                                          verticalAlign: "middle",
                                          padding: "0px",
                                        }}
                                      >
                                        {engageGrp?.details?.length ? (
                                          <>
                                          
                                                <TextField
                                                key={`${engageGrpIndex}-${rowIndex}`}
                                                  size="small"
                                                  style={{
                                                    marginTop: "4px",
                                                    width:
                                                      "-webkit-fill-available",
                                                  }}
                                                  name="details"
                                                  className={classes.txtForm}
                                                  fullWidth
                                                  placeholder="Enter details"
                                                  variant="outlined"
                                                  multiline
                                                  value={
                                                    details[rowIndex] || ""
                                                  }
                                                  onChange={(e) => {
                                                    insightsPlanActionsHandlerNew(
                                                      {
                                                        action: "updateDetails",
                                                        insights:
                                                          question?.insights,
                                                        insightIndex:
                                                          engageGrpIndex,
                                                        groupIndex:
                                                        rowIndex,
                                                        details:
                                                          e?.target?.value,
                                                      },
                                                    );
                                                  }}
                                                />
                                              
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </TableCell>
                                      )}
                                    </TableRow>
                                  </>
                                ));
                              },
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : selectedQuestion &&
                  selectedQuestion.type === "findingsActionTable" ? (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              What are your Findings?
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Is Action Required?
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map((row, rowIndex) => (
                            <TableRow
                              style={{
                                border: "1px solid #B6B6B6",
                                verticalAlign: "top",
                              }}
                              key={row.groupName}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "30%",
                                }}
                              >
                                {row.groupName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "50%",
                                }}
                              >
                                <TextField
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="What are your Findings?"
                                  variant="outlined"
                                  multiline
                                  value={row.findings || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return { ...find, findings: text };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "20%" }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={row.actionRequired ? "yes" : "no"}
                                    onChange={(e) => {
                                      const actionRequired =
                                        e.target.value === "yes";
                                      const updatedFindings =
                                        selectedQuestion.findings.map(
                                          (find, index) => {
                                            if (index === rowIndex) {
                                              return {
                                                ...find,
                                                actionRequired,
                                              };
                                            }
                                            return find;
                                          },
                                        );
                                      setSelectedQuestion({
                                        ...selectedQuestion,
                                        findings: updatedFindings,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : selectedQuestion &&
                  selectedQuestion.type === "actionPlanTable" ? (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Findings
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Issues
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Action Required
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Expected Date of Competition
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map((row, rowIndex) => (
                            <TableRow
                              style={{ border: "1px solid #B6B6B6" }}
                              key={row.groupName}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "20%",
                                  fontSize: "14px",
                                }}
                              >
                                {row.groupName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "20%",
                                  fontSize: "14px",
                                }}
                              >
                                {row.findings}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "20%",
                                  fontSize: "14px",
                                }}
                              >
                                <TextField
                                  name="issues"
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="Please enter issues"
                                  variant="outlined"
                                  multiline
                                  InputProps={{
                                    style: { fontSize: "14px" },
                                  }}
                                  value={row.issues || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              issues: text,
                                            };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "20%",
                                  fontSize: "14px",
                                }}
                              >
                                <TextField
                                  name="actionPlan"
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="Please enter action required"
                                  variant="outlined"
                                  multiline
                                  InputProps={{
                                    style: { fontSize: "14px" },
                                  }}
                                  value={row.actionPlan || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              actionPlan: text,
                                            };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "20%",
                                }}
                              >
                                <TextField
                                  name="expectedCompletionDate"
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  InputProps={{
                                    style: { fontSize: "14px" },
                                  }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="select"
                                  variant="outlined"
                                  type="date"
                                  value={row.expectedCompletionDate || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              expectedCompletionDate: text,
                                            };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : selectedQuestion &&
                  selectedQuestion.type === "multipleFreeTextTable" ? (
                  <>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              List of Engagement Activity
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map(
                            (finding, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #B6B6B6",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "40%",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {finding.groupName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {selectedOptions &&
                                      selectedOptions[rowIndex]?.values.map(
                                        (value, linkIndex) => (
                                          <span style={{ display: "flex" }}>
                                            <TextField
                                              size="small"
                                              key={`${rowIndex}-${linkIndex}`}
                                              style={{ marginTop: "4px" }}
                                              className={classes.txtForm}
                                              fullWidth
                                              multiline
                                              placeholder="Text"
                                              variant="outlined"
                                              value={value || ""}
                                              onChange={(e) => {
                                                const updatedFinding =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      selectedOptions,
                                                    ),
                                                  );
                                                updatedFinding[rowIndex].values[
                                                  linkIndex
                                                ] = e.target.value;
                                                setSelectedOptions(
                                                  updatedFinding,
                                                );
                                              }}
                                            />
                                            {selectedOptions[rowIndex]?.values
                                              .length > 1 && (
                                              <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  const updatedOptions =
                                                    JSON.parse(
                                                      JSON.stringify(
                                                        selectedOptions,
                                                      ),
                                                    );
                                                  updatedOptions[
                                                    rowIndex
                                                  ].values.splice(linkIndex, 1);
                                                  setSelectedOptions(
                                                    updatedOptions,
                                                  );
                                                }}
                                                src={removeIcon}
                                                alt="remove"
                                              />
                                            )}
                                          </span>
                                        ),
                                      )}
                                    <Grid
                                      item
                                      xs={12}
                                      md={9}
                                      style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        marginLeft: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const updatedOptions = JSON.parse(
                                          JSON.stringify(selectedOptions),
                                        );
                                        updatedOptions[rowIndex].values.push(
                                          "",
                                        );
                                        setSelectedOptions(updatedOptions);
                                      }}
                                    >
                                      <img
                                        src={plusIcon}
                                        style={{ height: "16px" }}
                                        alt="plus-icon"
                                      />
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Add More
                                      </Typography>
                                    </Grid>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : selectedQuestion &&
                  selectedQuestion.type ===
                    "multipleActivityFindingsActionTable" ? (
                  <>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              List of Engagement Activity
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Findings
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Is Action Required ?
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map(
                            (finding, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #B6B6B6",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "20%",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                  }}
                                >
                                  {finding.groupName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                    width: "30%",
                                    fontSize: "14px",
                                    padding: "0px 6px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {selectedOptions &&
                                      selectedOptions[rowIndex]?.values.map(
                                        (value, linkIndex) => (
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <TextField
                                              size="small"
                                              key={`${rowIndex}-${linkIndex}`}
                                              style={{
                                                marginTop: "4px",
                                                fontSize: "14px",
                                                padding: "0px",
                                              }}
                                              className={classes.txtForm}
                                              fullWidth
                                              multiline
                                              InputProps={{
                                                style: { fontSize: "14px" },
                                              }}
                                              placeholder="Text"
                                              variant="outlined"
                                              value={value || ""}
                                              onChange={(e) => {
                                                const updatedFinding =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      selectedOptions,
                                                    ),
                                                  );
                                                updatedFinding[rowIndex].values[
                                                  linkIndex
                                                ] = e.target.value;
                                                setSelectedOptions(
                                                  updatedFinding,
                                                );
                                              }}
                                            />
                                            {selectedOptions[rowIndex]?.values
                                              .length > 1 && (
                                              <img
                                                style={{
                                                  cursor: "pointer",
                                                  height: "16px",
                                                  width: "16px",
                                                }}
                                                onClick={() => {
                                                  const updatedOptions =
                                                    JSON.parse(
                                                      JSON.stringify(
                                                        selectedOptions,
                                                      ),
                                                    );
                                                  updatedOptions[
                                                    rowIndex
                                                  ].values.splice(linkIndex, 1);
                                                  setSelectedOptions(
                                                    updatedOptions,
                                                  );
                                                }}
                                                src={removeIcon}
                                                alt="remove"
                                              />
                                            )}
                                          </span>
                                        ),
                                      )}
                                    <Grid
                                      item
                                      xs={12}
                                      md={9}
                                      style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        marginLeft: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const updatedOptions = JSON.parse(
                                          JSON.stringify(selectedOptions),
                                        );
                                        updatedOptions[rowIndex].values.push(
                                          "",
                                        );
                                        setSelectedOptions(updatedOptions);
                                      }}
                                    >
                                      <img
                                        src={plusIcon}
                                        style={{ height: "16px" }}
                                        alt="plus-icon"
                                      />
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Add More
                                      </Typography>
                                    </Grid>
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "30%",
                                    padding: "0px 6px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {selectedOptions &&
                                    selectedOptions[rowIndex]?.findings && (
                                      <TextField
                                        size="small"
                                        style={{
                                          marginTop: "4px",
                                          fontSize: "14px",
                                          padding: "0px",
                                        }}
                                        className={classes.txtForm}
                                        fullWidth
                                        placeholder="What are your Findings?"
                                        variant="outlined"
                                        multiline
                                        InputProps={{
                                          style: { fontSize: "14px" },
                                        }}
                                        value={
                                          selectedOptions[rowIndex]?.findings ||
                                          ""
                                        }
                                        onChange={(e) => {
                                          const text = e.target.value;
                                          const updatedFindings =
                                            selectedOptions.map(
                                              (find, index) => {
                                                if (index === rowIndex) {
                                                  return {
                                                    ...find,
                                                    findings: text,
                                                  };
                                                }
                                                return find;
                                              },
                                            );
                                          setSelectedOptions(updatedFindings);
                                        }}
                                      />
                                    )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "20%", fontSize: "14px" }}
                                >
                                  {selectedOptions &&
                                    selectedOptions[rowIndex] && (
                                      <FormControl>
                                        <RadioGroup
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          value={
                                            selectedOptions[rowIndex]
                                              ?.actionRequired
                                              ? "yes"
                                              : "no"
                                          }
                                          onChange={(e) => {
                                            const actionRequired =
                                              e.target.value === "yes";
                                            const updatedFindings =
                                              selectedOptions.map(
                                                (find, index) => {
                                                  if (index === rowIndex) {
                                                    return {
                                                      ...find,
                                                      actionRequired,
                                                    };
                                                  }
                                                  return find;
                                                },
                                              );
                                            setSelectedOptions(updatedFindings);
                                          }}
                                        >
                                          <FormControlLabel
                                            style={{ fontSize: "14px" }}
                                            value="yes"
                                            control={
                                              <Radio
                                                style={{ fontSize: "14px" }}
                                              />
                                            }
                                            label="Yes"
                                          />
                                          <FormControlLabel
                                            value="no"
                                            control={
                                              <Radio
                                                style={{ fontSize: "14px" }}
                                              />
                                            }
                                            label="No"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  "null"
                )}
              </Grid>
            )}
            {/* Name */}
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Update Engagement",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
