import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdateModule,
  fetchModuleList,
  deleteModule,
  updateModule,
  getModuleByModuleId,
} from "../actions/modulesAction";

export const modules = createSlice({
  name: "modules",
  initialState: {
    modules: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchModuleList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchModuleList.fulfilled]: (state, { payload }) => {
      state.error = null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    },
    [fetchModuleList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [createUpdateModule.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateModule.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    // update
    [updateModule.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error = null;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [updateModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      // state.isRecordAdded=true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [updateModule.rejected]: (state, { payload }) => {
      console.log("payload-clients", payload);
      state.error = payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [deleteModule.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [deleteModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [deleteModule.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [getModuleByModuleId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getModuleByModuleId.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.error = null;
      state.isRecordLoaded = true;
      state.isLoader = false;
    },
    [getModuleByModuleId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } = modules.actions;

export default modules.reducer;
