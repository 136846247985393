export const manageAdminSchema = {

    name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter name",
            noLeadingSpace: "Name cannot start with a space",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    email: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    adminEmail: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    subOrg: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select sub-organization",
        }
    },
    
}