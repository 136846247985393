import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const fetchOngoingProjectList = createAsyncThunk(
  "ehiiaversions/fetchOngoingProjectList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/list/ongoing?orderBy=${arg?.orderBy}&orderIn=${arg?.orderIn || -1}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const fetchCompleteProjectList = createAsyncThunk(
  "ehiiaversions/fetchCompleteProjectList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/list/completed?orderBy=${arg?.orderBy}&orderIn=${arg?.orderIn}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const fetchProjectRepoList = createAsyncThunk(
  "ehiiaversions/fetchProjectRepoList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/list/repository?orderBy=${arg?.orderBy}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const getEhiiaProjectByProjectId = createAsyncThunk(
  "ehiiaversions/getEhiiaProjectByProjectId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/pre-submission/${arg?.projectId || ""}`,
      rejectWithValue,
    ),
);
export const getEhiiaProjectByProjectIdwithSectionId = createAsyncThunk(
  "ehiiaversions/getEhiiaProjectByProjectIdwithSectionId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/form-details/${arg?.projectId}/${
        arg?.sectionId || ""
      }`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const createEhiiaModule = createAsyncThunk(
  "ehiiaversions/createEhiiaModule",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/ehiia-project/v1/submission/", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const getCareGroupByOrganization = createAsyncThunk(
  "ehiiaversions/getCareGroupByOrganization",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/care-groups/${arg?.organizartion}`,
      rejectWithValue,
    ),
);

export const autoSuggestTag = createAsyncThunk(
  "ehiiaversions/autoSuggestTag",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/user/ehiia-project/v1/auto-suggest/search-tags?search=${arg?.search}`,
      rejectWithValue,
    ),
);
