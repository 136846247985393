import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchVersionList = createAsyncThunk(
    'versions/fetchVersion',
    (arg, { rejectWithValue }) => DataService.get(arg,  path+`/versions`, rejectWithValue)
)

export const fetchVersionListByModuleId = createAsyncThunk(
    'versions/fetchVersionListByModuleId',
    (arg, { rejectWithValue }) => DataService.get(arg,  path+`/versions/${arg?.moduleId}`, rejectWithValue)
)

export const createUpdateVersion = createAsyncThunk(
    'versions/createUpdateVersion',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/versions', rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'versions/toggleActivation',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/versions/toggle-activation`, rejectWithValue)
)
export const updateVersion = createAsyncThunk(
    'versions/createUpdateVersion',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/versions/update', rejectWithValue)
)

export const deleteVersion = createAsyncThunk(
    'versions/deleteVersion',
    (arg, { rejectWithValue }) => DataService.delete(arg, path+`/versions/${arg?.versionId}`, rejectWithValue)
)
export const getVersionByVersionId = createAsyncThunk(
    'versions/getVersionByVersionId',
    (arg, { rejectWithValue }) => DataService.get(arg, path+`/versions/${arg?.versionId}`, rejectWithValue)
)

