import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Tooltip,
  IconButton,
  Chip,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Drawer,
} from "@material-ui/core";
// components

import { useDispatch, useSelector } from "react-redux";
import {
  getCareGroupByOrganization,
  autoSuggestTag,
} from "../../../store/actions/ehiiaAction";
import infoIcon from "../../../../assets/info-icon.svg";
// styles
import useStyles from "./styles";
import Loader from "../../../../component/common/loader/Loader";
import { Autocomplete } from "@material-ui/lab";
import {
  ArrowForwardIosRounded,
  InboxOutlined,
  MailOutline,
} from "@material-ui/icons";
import AlertForQ16 from "./AlertForQ16";

export default function Introduction({
  orgOption,
  resetValidator,
  error,
  introductionForm,
  setIntroductionForm,
  setSearchTagError,
  searchTagError,
}) {
  const dispatch = useDispatch();
  console.log("error", error);
  console.log("introductionForm", introductionForm);
  const [optionsValue, setOptionsValue] = useState(null);
  const [selectOrg, setSelectOrg] = useState(null);
  const [careGroupList, setCareGroupList] = useState(null);

  useEffect(() => {
    console.log("selectOrg", selectOrg);
    if (selectOrg !== null) {
      dispatch(getCareGroupByOrganization({ organizartion: selectOrg }))
        .then((response) => {
          console.log("response", response);
          const { data } = response?.payload?.data;
          setCareGroupList(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectOrg]);
  console.log("careGroupList", careGroupList?.list);
  useEffect(() => {
    console.log("careGroupList", careGroupList);
    setOptionsValue(careGroupList?.list);
  }, [careGroupList]);

  var classes = useStyles();
  console.log("optionsValue", optionsValue);
  useEffect(() => {
    console.log("optionsValue", optionsValue);
  }, [optionsValue]);

  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedTagValues, setSelectedTagValues] = useState([]);
  useEffect(() => {
    // Make an API call to fetch suggestions based on the inputValue
    const fetchSuggestions = async () => {
      try {
        const response = await dispatch(autoSuggestTag({ search: inputValue }));
        console.log("response", response?.payload?.data?.data?.searchTags);
        const data = await response?.payload?.data?.data?.searchTags;
        setSuggestions(data);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    };

    // Fetch suggestions when inputValue changes
    fetchSuggestions();
  }, [inputValue]);

  // info model
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("anchor", anchor);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const drawerWidth = 240;
  const [alertModalShow, setAlertModalShow] = useState(false);
  const hideHandle = () => {
    setAlertModalShow(false);
  };
  const handleDeleteTag = (tagToDelete, tagKey) => {
    console.log("click-1 tagToDelete",tagToDelete);
    console.log("click-1 tagKey",tagKey);
    console.log("selectedTagValues",selectedTagValues);
    const newTags = selectedTagValues.filter((tag) => tag !== tagToDelete);
    console.log("click-1 newTags",newTags)
    setSelectedTagValues(newTags);
    // Update the form state if necessary
    setIntroductionForm((prevForm) => ({
      ...prevForm,
      [tagKey]: {
        ...prevForm[tagKey],
        values: newTags,
      },
    }));
  };
  return (
    <>
      <AlertForQ16
        open={alertModalShow}
        onHide={hideHandle}
        alertModalShow={alertModalShow}
        setAlertModalShow={setAlertModalShow}
        title={"Alert"}
      />
      <Grid container spacing={2} className={classes.formBox}>
        {Object.entries(introductionForm).map(([key, element], i) => {
          console.log("key", key, element);
          
          if (key === "section") return null; // Skip the "section" property
          return (
            <Grid item xs={12} md={6} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
                Q{i}.{" "}
                {element.title.includes("{organisation}")
                  ? element.title.replace(
                      "{organisation}",
                      introductionForm["organization"].value
                        ? `${introductionForm["organization"].value}`
                        : "{organisation}",
                    )
                  : element.title}{" "}
                <span style={{ color: "#f50b0be1" }}>*</span>
                {element.info && (
                  <>
                    <img
                      onClick={toggleDrawer(element.info, true, element.info)}
                      style={{
                        cursor: "pointer",
                        marginTop: "6px",
                        height: "16px",
                      }}
                      src={infoIcon}
                      alt="img"
                    />
                    <Drawer
                      top={48}
                      style={{ width: "240px", maxWidth: "240px" }}
                      sx={{
                        paddingRight: 4,
                        "& .MuiDrawer-paper": {
                          boxSizing: "border-box",
                          width: drawerWidth,
                          top: "48px !important",
                        },
                      }}
                      elevation={0}
                      anchor="right"
                      open={state[element.info]}
                      onClose={toggleDrawer(element.info, false, null)}
                    >
                      <IconButton
                        style={{ position: "absolute", marginLeft: "-14px" }}
                        onClick={toggleDrawer(
                          element.info,
                          false,
                          element.info,
                        )}
                      >
                        <ArrowForwardIosRounded />
                      </IconButton>
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "13px",
                          paddingLeft: "20px",
                          textAlign: "center",
                          width: "260px",paddingRight:'10px'
                        }}
                      >
                        {element.title.includes("{organisation}")
                          ? element.title.replace(
                              "{organisation}",
                              introductionForm["organization"].value
                                ? `${introductionForm["organization"].value}`
                                : "{organisation}",
                            )
                          : element.title}
                      </p>
                      <Divider />
                      <span
                        style={{
                          padding: "6px 10px",
                          width: "260px",
                          fontSize: "12px",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: element?.info,
                        }}
                      >
                      </span>
                    </Drawer>
                  </>
                )}
              </Typography>
              {element.type === "singleSelect" ? (
                <>
                  <FormControl
                    fullWidth
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={key}
                      className={classes.selectDropdown}
                      value={element.value}
                      onChange={(e) => {
                        const value = e.target.value;
                        setIntroductionForm((prevForm) => ({
                          ...prevForm,
                          [key]: {
                            ...prevForm[key],
                            value: value,
                          },
                          
                        }));
                        if (key === "organization") {
                          setSelectOrg(value);
                          // Update the careGroupList state with the selected organization value
                          setCareGroupList((prevList) => ({
                            ...prevList,
                            selectedOrg: value,
                          }));
                        }
                        if (
                          key === "trainingCompleted" &&
                          value &&
                          value === "No"
                        ) {
                          setAlertModalShow(true);
                        }else{
                          setAlertModalShow(false);
                        }
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[key]}
                    >
                      <MenuItem disabled value="">
                        select
                      </MenuItem>
                      {key === "careGroup" && optionsValue
                        ? optionsValue.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : element.options &&
                          element.options.map(
                            (
                              item, // Use optionsValue instead of careGroupList.options
                            ) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.title}
                              </MenuItem>
                            ),
                          )}
                    </Select>
                  </FormControl>
                  
                  <Fade in={error}>
                    <Typography className={classes.errorMessage}>
                      {error && error[key]}
                    </Typography>
                  </Fade>
                </>
              ) : element.type === "searchTags" ? (
                <>
                  <Autocomplete
                    name={key}
                    style={{ background: "#fff", position: "relative" }}
                    multiple
                    id="tags-filled"
                    options={suggestions} // Use tagOptions for Autocomplete options
                    // defaultValue={[""]}
                    freeSolo
                    onInputChange={(event, newInputValue) =>
                      setInputValue(newInputValue)
                    }
                    onChange={(event, newValues) => {
                      console.log("newValues", newValues);
                      const uniqueValues = [...new Set(newValues)];
                      setSelectedTagValues(uniqueValues);
                      setIntroductionForm((prevForm) => ({
                        ...prevForm,
                        [key]: {
                          ...prevForm[key],
                          values: uniqueValues,
                        },
                      }));
                    }} // Update selected values
                    value={
                      selectedTagValues.length > 0
                        ? selectedTagValues
                        : element?.values ? element?.values :''
                    } // Control the selected values
                    renderTags={(value, getTagProps) =>
                      value.length > 0
                        ? value.map((option, index) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              color="success"
                              label={option}
                              onClick={() => handleDeleteTag(option, key)}
                              onDelete={() => handleDeleteTag(option, key)}
                              {...getTagProps({ index })}
                            />
                          ))
                        : ""
                    }
                    renderInput={(params) => (
                      <>
                        <TextField
                          style={{ background: "#fff" }}
                          {...params}
                          variant="outlined"
                          label=""
                          placeholder="Type keyword and press enter to add"
                          // onInputChange={handleSearchInputChange}
                        />
                      </>
                    )}
                  />
                  <Typography
                    style={{
                      color: "blue",
                      fontSize: "12px",
                      position: "relative",
                    }}
                  >
                    Note:- Please press enter key to add tag
                  </Typography>
                  {/* {!selectedTagValues.length > 0 && (
                    <Fade in={error}>
                      <Typography className={classes.errorMessage}>
                        {searchTagError}
                      </Typography>
                    </Fade>
                  )} */}
                </>
              ) : (
                <>
                  <TextField
                    disabled={
                      key === "projectLead" || key === "projectLeadEmail"
                        ? true
                        : false
                    }
                    name={key}
                    placeHolder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Answer"
                    variant="outlined"
                    maxRows={4}
                    multiline
                    value={element.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      setIntroductionForm((prevForm) => ({
                        ...prevForm,
                        [key]: {
                          ...prevForm[key],
                          value: value,
                        },
                      }));
                      resetValidator(e.target.name);
                    }}
                    isInvalid={error && error[key]}
                  />

                  <Fade in={error}>
                    <Typography className={classes.errorMessage}>
                      {error && error[key]}
                    </Typography>
                  </Fade>
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
