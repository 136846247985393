import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import { withRouter, useNavigate } from "react-router-dom";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { useDispatch, useSelector } from "react-redux";
// styles
import useStyles from "./styles";

// logo
import lock from "./Lock.svg";
import arrow from "./arrow.png";
// api
import Validation from "../../hooks/Validation";
import { forgotPassword } from "../../store/actions/adminAction";
import { getOrgData } from "../../../store/actions/reviewsAction";
import { adminReset } from "../../store/reducerSlice/admin";
import { useToasts } from "react-toast-notifications";
const formElements = [
  {
    key: "email",
    label: "Email",
    placeHolder: "Enter email",
    type: "text",
  },
];
function Forget() {
  var classes = useStyles();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [passwordDetail, setPasswordDetail] = useState({
    email: null,
  });
  const [sentPayload, setSentPayload] = useState(null);
  const [orgDetails, setOrgDetails] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);

  const [eyes, setEyes] = useState({
    email: false,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation(passwordDetail);
  const dispatch = useDispatch();
  const {
    adminDetail,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  const submitHandler = (e) => {
    e.preventDefault();
    setSentPayload(null);
    checkValidator();

    if (!isError() && error !== null) {
      dispatch(forgotPassword(passwordDetail)).then((payload) => {
        // setIsShow(true);
        console.log("payload-", payload);

        if (payload?.payload?.success) {
          const toastHeight = payload?.payload?.message.length > 38 ? "auto" : "34px";
          setSentPayload(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
          setErrorApiMessage(null);
          setTimeout(() => {
            setIsDone(true);
          }, 3000);
        } else {
          const toastHeight = payload?.payload?.message.length > 38 ? "auto" : "34px";
          setErrorApiMessage(payload?.payload?.message);
          addToast(payload?.payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (isDone) {
      dispatch(adminReset());
      navigate("/");
      setIsDone(false);
    }
    setIsDone(false);
  }, [isDone]);
  const hideHandlerRes = () => {
    if (isDone) {
      dispatch(adminReset());
      navigate("/");
    }
    setIsShow(false);
  };

  useEffect(() => {
    return () => {
      dispatch(adminReset());
    };
  }, []);

  return (
    <>
      <Grid container className={classes.container}>
        <div
          className={classes.logotypeContainer}
          style={{ color: "#fff", textAlign: "center" }}
        >
          <span>{orgDetails?.name}</span>
        </div>
        <div className={classes.formContainer}>
          <div elevation={1} className={classes.form}>
            <React.Fragment>
              <Box sx={{ textAlign: "center", m: 1 }}>
                <img src={lock} alt="image" />
              </Box>
              <Typography className={classes.subGreeting}>
                <Box sx={{ textAlign: "center", m: 1 }}>Forgot Password</Box>
              </Typography>
              <Typography className={classes.greeting}>
                <Box sx={{ textAlign: "center", m: 1 }}>
                  No worries, we'll send you reset instructions.
                </Box>
              </Typography>

              {formElements.map((element, i) => {
                return (
                  <>
                    <Typography variant="h6" className={classes.txtHeading}>
                      {element.label} <span className="required">*</span>
                    </Typography>
                    <TextField
                      InputProps={{
                        classes: {
                          border: classes.textFieldline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordDetail && passwordDetail[element.key]}
                      onChange={(e) => {
                        setPasswordDetail({
                          ...passwordDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        checkValidator(element.key);
                      }}
                      isInvalid={
                        error &&
                        error !== null &&
                        typeof error[element.key] !== "object" &&
                        error[element.key]
                      }
                      margin="normal"
                      name={element.key}
                      placeholder={element.placeHolder}
                      type="email"
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error &&
                          error !== null &&
                          typeof error[element.key] !== "object" &&
                          error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                );
              })}

              <div className={classes.creatingButtonContainer}>
                <Button
                  onClick={(e) => submitHandler(e)}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Reset Password
                </Button>
              </div>
              <Box sx={{ textAlign: "center" }}>
                <Link href="/" className={classes.Link} underline="none">
                  <img src={arrow} alt="img" />
                  &nbsp;&nbsp; Back to login
                </Link>
              </Box>
            </React.Fragment>

            <ApiResponsePopup
              {...{
                hideHandlerRes,
                isRecordUpdated: isShow,
                title: "Forgot Password",
                successMsg: sentPayload,
                errorRes: errorApiMessage,
                // errorRes: apiError?.message,
              }}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export default Forget;
