import { createSlice } from "@reduxjs/toolkit";
import {
 getReview,getOrgData
} from "../actions/reviewsAction";

export const reviews = createSlice({
  name: "reviews",
  initialState: {
    reviews: null,
    
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
    // isRecordAdded: false,
    // isRecordUpdated: false,
    // isRecordLoaded:false,
  },
  reducers: {
    userReset: (state) => {
      // state.reviews= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      // state.isRecordAdded=false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [getReview.pending]: (state, payload) => {
      console.log("payload", payload);
      state.error = null;
      state.isLoader = true;
    },
    [getReview.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log("payload", payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [getReview.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
  //  org
  [getOrgData.pending]: (state, payload) => {
    console.log("payload", payload);
    state.error = null;
    state.isLoader = true;
  },
  [getOrgData.fulfilled]: (state, { payload }) => {
    state.error = null;
    console.log("payload", payload);
    return {
      ...state,
      ...payload?.data?.data,
      isLoader: false,
    };
  },
  [getOrgData.rejected]: (state, { payload }) => {
    state.error = payload;
    state.isLoader = false;
  },
   
   
    
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } = reviews.actions;

export default reviews.reducer;
