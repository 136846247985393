import { createSlice } from "@reduxjs/toolkit";
import {
  reviewEngagementModule,reviewFinalApprovalModule
} from "../actions/reviewAction";

export const reviews = createSlice({
  name: "reviews",
  initialState: {
    reviews: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
   
    [reviewEngagementModule.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [reviewEngagementModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [reviewEngagementModule.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [reviewFinalApprovalModule.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [reviewFinalApprovalModule.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [reviewFinalApprovalModule.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
   
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } = reviews.actions;

export default reviews.reducer;
