import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdatePrivateData,
  fetchprivateDataList,
  fetchgroupsList,toggleActivation,getSinglePrivateData, downloadDataContentList
} from "../actions/privateDataAction";

export const privatedata = createSlice({
  name: "privatedata",
  initialState: {
    privatedata: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchprivateDataList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchprivateDataList.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchprivateDataList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },

    [fetchgroupsList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchgroupsList.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchgroupsList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },

    [createUpdatePrivateData.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdatePrivateData.fulfilled]: (state, { payload }) => {
      console.log("payload",payload);
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdatePrivateData.rejected]: (state, { payload }) => {
      console.log("payload",payload);
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [toggleActivation.pending]: (state, payload) => {

      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [toggleActivation.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [toggleActivation.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
    [getSinglePrivateData.pending]: (state, payload) => {
      state.error=null;
      state.isRecordLoaded= false;
      state.isLoader= true;
      
    },
    [getSinglePrivateData.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordLoaded= true
      state.isLoader= false;
    },
    [getSinglePrivateData.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordLoaded= false;
      state.isLoader= false;
    },
      // download list
      [downloadDataContentList.pending]: (state, payload) => {
        console.log("payload-download", payload);
        state.error = null;
        state.isLoader = true;
      },
      [downloadDataContentList.fulfilled]: (state, { payload }) => {
        state.error = null;
        console.log("payload-download", payload);
        return {
          ...state,
          ...payload?.data?.data,
          isLoader: false,
        };
      },
      [downloadDataContentList.rejected]: (state, { payload }) => {
        state.error = payload;
        state.isLoader = false;
      },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
  privatedata.actions;

export default privatedata.reducer;
