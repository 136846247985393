import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchClientList = createAsyncThunk(
  "clients/fetchClientList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get("/admin/org/v1/",arg, rejectWithValue),
);

export const createUpdateClient = createAsyncThunk(
  "clients/createUpdateClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org/v1",arg, rejectWithValue),
);
export const updateClient = createAsyncThunk(
  "clients/updateClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org/v1",arg, rejectWithValue),
);
export const reassignClient = createAsyncThunk(
  "clients/reassignClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/clients/assign-module",arg, rejectWithValue),
);
export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(`/admin/org/v1/${arg?.orgId}`,arg, rejectWithValue),
);

export const toggleActivation = createAsyncThunk(
  "clients/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/admin/org/v1/toggle-status/${arg?.orgId}`,arg,
      rejectWithValue,
    ),
);

export const sendActivationLink = createAsyncThunk(
  "clients/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/clients/resend-activation/${arg?.orgId}`,arg,
      rejectWithValue,
    ),
);

export const sendResetPasswordLink = createAsyncThunk(
  "clients/sendResetPasswordLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/clients/reset-password/${arg?.orgId}`,arg,
      rejectWithValue,
    ),
);

export const getClientByClientId = createAsyncThunk(
  "clients/getClientByClientId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/admin/org/v1/${arg?.orgId}`, rejectWithValue),
);
