import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleProjectRepoList } from "../../store/actions/ehiiaAction";
import useStyles from "./styles";
import Loader from "../../../component/common/loader/Loader";
import { Alert } from "@material-ui/lab";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  //  const { selectclient } = props;
  //  console.log("selectclient", selectclient);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.ehiiaversionsClient);

  useEffect(() => {
    console.log("apiError", apiError);
  }, [apiError]);
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [isDone, setIsDone] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setToggleActivateModalShow(false);
    setOpen(false);
  };
  const submitHandler = () => {
    return dispatch(
      toggleProjectRepoList({
        projectId: toggleActivateRec,
        // status:toggleActivateStatus,
      }),
    );
  };
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setToggleActivateModalShow(false);
    }
  }, [isRecordUpdated]);

  const hideHandlerRes = () => {
    setIsShow(false);
  };
  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {apiError ? (
              ""
            ) : (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center style={{ padding: "10px" }}>
              <img src={alertIcon} alt="icons" />
              {isLoader && <Loader />}
              {apiError ? (
                <>
                <Alert severity="error">
                  {apiError?.message || "Access denied! Please try again"}
                </Alert>
                
               </> ) : (
                <Typography className={classes.formActiveHeading}>
                  Are you sure, you want to deactivate this project?
                </Typography>
              )}
            </center>
          </DialogContentText>
          <center>
          {apiError &&
           <Button style={{marginTop:'16px', marginBottom:'0px'}}
           className={classes.outlineBtn}
           variant="outlined"
           size="medium"
           onClick={(e) => onHide()}
         >
           Close
         </Button>
          }</center>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Project Activation",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
