import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Avatar } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button,
  InputBase,
} from "@material-ui/core";
// Model
import {
  Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Typography } from "../../../component/Wrappers/Wrappers";
// components

import TableWidget from "../../../component/Widget/TableWidget";
import download from "../../../images/download.svg";
import editIcons from "../../../images/whiteEdit.png";
import downloadIcon from "../../../assets/download-btn.svg";
import downloadBoxIcon from '../../../assets/download-icon.svg';
// styles
import useStyles from "./styles";
import ManageClient from "./ManageClient";
import TableDesign from "../../../component/common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";

import ActivationMailLinkClientPopup from "./ActivationMailLinkClientPopup";
import ResetPasswordClientPopup from "./ResetPasswordClientPopup";
import DeletePopup from "./DeletePopup";
import classNames from "classnames";
// API data imports
import {
  fetchClientList,
  downloadClientList,
} from "../../store/actions/clientsAction";

import { resetPopup } from "../../store/reducerSlice/clients";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export default function ClientList() {
  const [selectRow, setSelectRow] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //
  const [anchorE2, setAnchorE2] = React.useState(null);

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Organisation",
      align: "left !important",
      sort: "name"
    },
    {
      label: "Short Name",
      align: "left !important",
      sort: "subdomain"
    },
    {
      label: "Admin",
      align: "left",
      sort:"contactPerson"
    },
    {
      label: "Email",
      align: "left !important",
      sort: "email"
    },
    {
      label: "Modules",
      // align: "left !important",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  //

  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [activationLinkRec, setActivationLinkRec] = useState(null);
  const [activationLinkModalShow, setActivationLinkModalShow] = useState(false);
  const [deleteRec, setDeleteRec] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [resetPasswordRec, setResetPasswordRec] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(null); // New state variable
  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "createdAt",
    orderIn: -1,
  });
  const [message, setMessage] = useState(null);

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.clients;
  });

  const page = pagination?.currentPage;

  const cc = filtered || list;
  useEffect(() => {
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("filterOption", filterOption);
    dispatch(fetchClientList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  const addClient = () => {
    console.log("add client called");
    setselectclient(null);

    setTitle("Add Client");

    setOpen(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setselectclient(null);
    setModalShow(false);
    setOpen(false);
    setDeleteModalShow(false);
    setDeleteRec(null);
    setToggleActivateModalShow(false);
    setActivationLinkModalShow(false);
    setResetPasswordModalShow(false);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const downloadCurrentList = () => {
    dispatch(downloadClientList())
      .unwrap()
      .then((response) => {
        const fileContent = response.data; // Assuming the response data is stored in 'response.data'

        // Set the appropriate headers for the file download
        const headers = response.headers;
        const filename = "data.csv"; // Provide a filename for the downloaded file
        const contentType =
          headers["content-type"] || "application/octet-stream";

        const blob = new Blob([fileContent], { type: contentType });

        // Create a temporary URL for the blob
        const blobURL = URL.createObjectURL(blob);

        // Create a link element and click it to trigger the download
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = filename;
        link.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(blobURL);
      })
      .catch((error) => {
        // Handle error here
        console.error("Error occurred while downloading client list:", error);
      });
  };

  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setDeleteRec(rec?.orgId);
    setDeleteModalShow(true);
    setTitle("Delete Client");
    // setMessage(showSuccessMessage);
    // setShowResult(true);
  };
  useEffect(() => {
    if (showSuccessMessage) {
      setMessage(showSuccessMessage);
      setShowResult(true);
    }
  }, [showSuccessMessage]);
  const toggleActivateEventHandler = (rec) => {
    console.log("rec", rec);
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateRec(rec?.orgId);
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateModalShow(true);
    // setShowResult(true);
    if (rec?.isActive) {
      setTitle("Deactivate Client");
      setMessage("Client deactivated successfully!");
    } else {
      setTitle("Activate Client");
      setMessage("Client activated successfully!");
    }
  };

  const activationLinkEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    console.log("clicked");
    console.log("rec", rec);
    dispatch(resetPopup());
    setActivationLinkRec(rec?.orgId);
    setToggleActivateStatus(rec?.isActivated);
    setActivationLinkModalShow(true);
    setTitle("Send Activation Link");
    setMessage("Activation link sent successfully!");
    // setShowResult(true);
  };

  const resetPasswordEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setResetPasswordRec(rec?.orgId);
    setResetPasswordModalShow(true);
    setTitle("Send Reset Password Link");
    setMessage("Reset password link sent successfully!");
    // setShowResult(true);
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
    setShowSuccessMessage(null);
  };

  return (
    <>
      <ToastContainer />
      <ManageClient
        open={open}
        onHide={hideHandle}
        title={"Add Client"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />

      <ActivationMailLinkClientPopup
        onHide={hideHandle}
        title={"Activation"}
        activationLinkRec={activationLinkRec}
        toggleActivateStatus={toggleActivateStatus}
        activationLinkModalShow={activationLinkModalShow}
        setActivationLinkModalShow={setActivationLinkModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        title={"Delete"}
        deleteRec={deleteRec}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
        setShowSuccessMessage={setShowSuccessMessage}
      />
      <ResetPasswordClientPopup
        onHide={hideHandle}
        title={"Reset Password"}
        resetPasswordRec={resetPasswordRec}
        resetPasswordModalShow={resetPasswordModalShow}
        setResetPasswordModalShow={setResetPasswordModalShow}
      />

      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Manage Clients
        </Typography>
        <div className={classes.groupBtn}>
        {cc && cc.length > 0 && (
            <>
          <img
            onClick={downloadCurrentList}
            src={downloadBoxIcon}
            style={{
              // backgroundColor: "#FEAA00",
              color: "#fff",
              textDecoration: "none",
              padding: "4px 12px",
              borderRadius: "2px",
              marginTop:'-4px',
              marginRight: "10px",
              cursor: "pointer",
            }}
            alt="image"
          />
          </>
        )}
          {/* <Button
            classes={{ root: classes.button1 }}
            variant="contained"
            color="secondary"
            onClick={downloadCurrentList}
          >
            <img className={classes.downloadImg} src={download} alt="img" />
            Download
          </Button> */}

          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              autoComplete={false}
              type="text"
              name="search"
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={addClient}
          >
            <img className={classes.editicons} src={editIcons} alt="img" />
            Add Client
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <>
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={() =>
                              navigate(
                                `/super-admin/client-details/${row.orgId}`,
                              )
                            }
                            style={{ cursor: "pointer",width:'260px' }}
                          >
                            <span className={classes.tableCell}>
                              {/* {row.logo ? (
                                <Avatar
                                  className={classes.imgAvtar}
                                  style={{ width: "30px", height: "30px" }}
                                  src={row.logo}
                                />
                              ) : (
                                <Avatar
                                  className={classes.imgAvtar}
                                  style={{ width: "30px", height: "30px" }}
                                  src="/broken-image.jpg"
                                />
                              )} */}

                              {row.name}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() =>
                              navigate(
                                `/super-admin/client-details/${row.orgId}`,
                              )
                            }
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.subdomain}
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "left", paddingLeft: "12px" }}
                            className={classes.tableBodyCell}
                          >
                            {row.contactPerson}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ textAlign: "left", paddingLeft: "10px" }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ textAlign: "center" }}
                          >
                            {/* {row.versionId.map((item, index) => (
                            <div key={index}>{item.module.name}</div>
                          ))} */}
                            {/* {row.versionId.ehiia}  */}1
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.isActive ? (
                              row.isActivated ? (
                                <span
                                  style={{ color: "#10940D", fontWeight: 500 }}
                                >
                                  Active
                                </span>
                              ) : (
                                <span style={{ color: "#F8A80C" }}>
                                  Pending
                                </span>
                              )
                            ) : (
                              <span style={{ color: "red" }}>Inactive</span>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <div>
                              <IconButton
                                id={row}
                                aria-controls={openDown ? { row } : undefined}
                                aria-haspopup="true"
                                aria-expanded={openDown ? "true" : undefined}
                                onClick={(e) => {
                                  handleClick(e);
                                  setSelectRow(row);
                                }}
                              >
                                <MoreIcon />
                              </IconButton>

                              <Menu
                                className={classes.menuBox}
                                anchorEl={anchorEl}
                                open={openDown}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  className={classes.menuItem}
                                  onClick={() =>
                                    navigate(
                                      `/super-admin/client-edit/${selectRow?.orgId}`,
                                    )
                                  }
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />
                                  Edit
                                </MenuItem>

                                {selectRow?.isActive ? (
                                  selectRow?.isActivated ? (
                                    <div>
                                      <MenuItem
                                        className={classes.menuItem}
                                        onClick={() =>
                                          resetPasswordEventHandler(selectRow)
                                        }
                                      >
                                        <Lens
                                          style={{
                                            height: "4px",
                                            width: "4px",
                                            marginRight: "4px",
                                          }}
                                        />
                                        Reset password
                                      </MenuItem>
                                      <MenuItem
                                        className={classes.menuItem}
                                        onClick={() =>
                                          toggleActivateEventHandler(selectRow)
                                        }
                                      >
                                        <Lens
                                          style={{
                                            height: "4px",
                                            width: "4px",
                                            marginRight: "4px",
                                          }}
                                        />
                                        Deactivate
                                      </MenuItem>
                                    </div>
                                  ) : (
                                    <div>
                                      <MenuItem
                                        className={classes.menuItem}
                                        onClick={() =>
                                          activationLinkEventHandler(selectRow)
                                        }
                                      >
                                        <Lens
                                          style={{
                                            height: "4px",
                                            width: "4px",
                                            marginRight: "4px",
                                          }}
                                        />
                                        Resend activation link
                                      </MenuItem>
                                      {/* <MenuItem
                                        className={classes.menuItem}
                                        onClick={() =>
                                          deleteEventHandler(selectRow)
                                        }
                                      >
                                        <Lens
                                          style={{
                                            height: "4px",
                                            width: "4px",
                                            marginRight: "4px",
                                          }}
                                        />
                                        Delete
                                      </MenuItem> */}
                                    </div>
                                  )
                                ) : (
                                  <div>
                                    <MenuItem
                                      className={classes.menuItem}
                                      onClick={() =>
                                        toggleActivateEventHandler(selectRow)
                                      }
                                    >
                                      <Lens
                                        style={{
                                          height: "4px",
                                          width: "4px",
                                          marginRight: "4px",
                                        }}
                                      />
                                      Activate
                                    </MenuItem>
                                  </div>
                                )}
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
