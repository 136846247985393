import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdateApproval,
  fetchApprovalList,createUpdateProjectLevel,deleteProjectLevel
 
} from "../actions/approvalAction";

export const approvals = createSlice({
  name: "approvals",
  initialState: {
    approvals: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchApprovalList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchApprovalList.fulfilled]: (state, { payload }) => {
      console.log("payload",payload);
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchApprovalList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
   
    [createUpdateApproval.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateApproval.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateApproval.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

    [createUpdateProjectLevel.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateProjectLevel.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateProjectLevel.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

    [deleteProjectLevel.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [deleteProjectLevel.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [deleteProjectLevel.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

   
    
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
approvals.actions;

export default approvals.reducer;
