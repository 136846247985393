import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { sendResetPasswordLink } from "../../store/actions/subAdminAction";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ResetPasswordClientPopup({
  resetPasswordRec,
  onHide,
  resetPasswordModalShow,
  setresetPasswordModalShow,
}) {
  //
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { error: apiError, isLoader } = useSelector(
    (state) => state.subadmins,
  );

  const submitHandler = () => {
    return dispatch(
      sendResetPasswordLink({
        staffId: resetPasswordRec,
      }),
    ).then(({payload}) => {
      console.log("payload-reset",payload);
      if (payload?.success) {
        // return;
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:toastHeight
          },
        });
      }else{
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "error",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }
      // onHide();
      // setresetPasswordModalShow(false);
    });
  };
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={resetPasswordModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center>
            <img src={alertIcon} alt="img"/>
            <Typography className={classes.formActiveHeading}>
              Are you sure you want to resend reset password link to this
              admin?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
