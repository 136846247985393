import React, { useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "./styles";
export default function ToggleActivationPopup({
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  //  const { selectclient } = props;
  //  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setToggleActivateModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setToggleActivateModalShow(false);
    setOpen(false);
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
            Are you sure, you want to deactivate this client?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
