import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tabs, Tab, Paper, Button } from "@material-ui/core";
import Introduction from "../../../components/Ehiia/EhiiaCases/Introduction";
import Assessment from "../../../components/Ehiia/EhiiaCases/Assessment";
import DataReview from "../../../components/Ehiia/EhiiaCases/DataReview";
import DataReviewExample from "../../../components/Ehiia/EhiiaCases/DataReviewExample";
import Engagement from "../../../components/Ehiia/EhiiaCases/Engagement";
import Result from "../../../components/Ehiia/EhiiaCases/Result";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import Widget from "../../../../component/Widget/Widget";
import backArrow from "./backArrow.svg";
import closeIcon from "../../../../assets/icons/closeIcon.png";
import useStyles from "./styles";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
import done from "../../../../images/done.png";
import {
  createEhiiaModule,
  getCareGroupByOrganization,
  getEhiiaProjectByProjectId,
} from "../../../store/actions/ehiiaAction";
import Validation from "../../../hooks/EhiiaValidation";
import { useToasts } from "react-toast-notifications";
import AlertForQ16 from "../../../components/Ehiia/EhiiaCases/AlertForQ16";
export default function EhiiaCase() {
  var classes = useStyles();
  const { addToast } = useToasts();
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);

    setClientAdminPath(clientAdminPath);
  }, []);
  const [searchTagError, setSearchTagError] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isSaveExitCalled, setIsSaveExitCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [orgOption, setOrgOption] = useState(null);
  const [selectOrg, setSelectOrg] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [nextQuestionsValue, setNextQuestionsValue] = useState([]);
  const [projectIdValue, setProjectIdValue] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    value: "",
    isCompleted: false,
  });
  const [isAssessmentCalled, setIsAssessmentCalled] = useState(false);
  const [setionStatus, setSectionStatus] = useState([]);
  const [isUpdateCalled, setIsUpdateCalled] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  // form data binding
  const [introductionForm, setIntroductionForm] = useState({});
  const [assessmentForm, setAssessmentForm] = useState({});
  //
  const navigate = useNavigate();
  const goBack = () => {
    navigate(
      `/${clientAdminPath === "staff" ? "staff" : "admin"}/manage-module/ehiia`,
    );
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEhiiaProjectByProjectId()).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.data?.success) {
        setIntroductionForm(payload?.data?.data?.form?.introduction);
        setAssessmentForm(payload?.data?.data?.form?.assessment);
        setCurrentSection({
          value: payload?.data?.data?.form?.currentSection,
          isCompleted: payload?.data?.data?.form?.sectionCompleted,
        });
        setSectionStatus(payload?.data?.data?.form?.sections);
        setNextSection(payload?.data?.data?.form?.nextSection);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectId({
        projectId: projectIdValue,
        sectionId: "",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.data?.success) {
        setIntroductionForm(payload?.data?.data?.form?.introduction);
        setAssessmentForm(payload?.data?.data?.form?.assessment);
        setCurrentSection({
          value: payload?.data?.data?.form?.currentSection,
          isCompleted: payload?.data?.data?.form?.sectionCompleted,
        });
        setSectionStatus(payload?.data?.data?.form?.sections);
        setNextSection(payload?.data?.data?.form?.nextSection);
      }
    });
    setIsUpdateCalled(false);
  }, [isUpdateCalled, projectIdValue]);

  useEffect(() => {
    console.log("introductionForm", introductionForm);
  }, [introductionForm]);

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      projectTitle: introductionForm?.projectTitle?.value,
      projectLead: introductionForm?.projectLead?.value,
      projectLeadRole: introductionForm?.projectLeadRole?.value,
      projectLeadEmail: introductionForm.projectLeadEmail?.value,
      lineManager: introductionForm?.lineManager?.value,
      lineManagerEmail: introductionForm.lineManagerEmail?.value,
      lineManagerRole: introductionForm?.lineManagerRole?.value,
      serviceManager: introductionForm?.serviceManager?.value,
      serviceManagerRole: introductionForm?.serviceManagerRole?.value,
      serviceManagerEmail: introductionForm?.serviceManagerEmail?.value,
      organization: introductionForm?.organization?.value,
      careGroup: introductionForm?.careGroup?.value,
      mainPurpose: introductionForm?.mainPurpose?.value,
      newOrExisting: introductionForm?.newOrExisting?.value,
      // searchTags:introductionForm?.searchTags?.values,
      // commissioningStatus: introductionForm?.commissioningStatus?.value,
      trainingCompleted: introductionForm?.trainingCompleted?.value,
    });
  useEffect(() => {
    console.log("searchTagError", searchTagError);
  }, [searchTagError]);
  // Add introduction form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    if(introductionForm.trainingCompleted?.value && introductionForm.trainingCompleted?.value === "No"){
      setAlertModalShow(true);
    }else{
      setIsActionCalled(true);
    }
    
  };

  useEffect(() => {
    const data = {
      ...(projectIdValue && { projectId: projectIdValue }),
      sectionId: "introduction",
      questions: {
        introduction: {
          projectTitle: introductionForm.projectTitle?.value,
          projectLead: introductionForm.projectLead?.value,
          projectLeadRole: introductionForm?.projectLeadRole?.value,
          projectLeadEmail: introductionForm.projectLeadEmail?.value,
          lineManager: introductionForm.lineManager?.value,
          lineManagerEmail: introductionForm.lineManagerEmail?.value,
          lineManagerRole: introductionForm.lineManagerRole?.value,
          serviceManager: introductionForm.serviceManager?.value,
          serviceManagerRole: introductionForm.serviceManagerRole?.value,
          serviceManagerEmail: introductionForm?.serviceManagerEmail?.value,
          organization: introductionForm.organization?.value,
          careGroup: introductionForm.careGroup?.value,
          mainPurpose: introductionForm.mainPurpose?.value,
          newOrExisting: introductionForm.newOrExisting?.value,
          searchTags: introductionForm?.searchTags?.value
            ? introductionForm?.searchTags?.value
            : introductionForm?.searchTags?.values,
          // commissioningStatus: introductionForm.commissioningStatus?.value,
          trainingCompleted: introductionForm.trainingCompleted?.value,
        },
      },
    };

    if (!isError() && error !== null && isActionCalled) {
      dispatch(createEhiiaModule(data))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            hideHandler();
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: "99999",
                height: toastHeight,
              },
            });
            // setIsShow(true);
            setErrorApiMessage(null);

            setNextSection(payload?.data?.nextSection);
            setNextQuestionsValue(payload?.data?.nextQuestions);
            setProjectIdValue(payload?.data?.projectId);
            if (isSaveExitCalled) {
              goBack();
            } else {
              setActiveTabId(1);
            }
          } else {
            console.log("payload?.error", payload);
            const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
            setErrorApiMessage(payload?.message);
            // setIsShow(true);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style: {
                height: toastHeight,
              },
            });
          }
        })
        .catch((error) => console.log("error", error));
      setIsActionCalled(false);
      setIsSaveExitCalled(false);
    } else {
      console.log("error", error);
      setIsActionCalled(false);
      setIsSaveExitCalled(false);
    }
    setIsActionCalled(false);
  }, [isActionCalled]);

  const submitExitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsSaveExitCalled(true);
    setIsActionCalled(true);
  };

  useEffect(() => {
    console.log("nextQuestionsValue", nextQuestionsValue);
  }, [nextQuestionsValue]);
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const hideHandler = () => {
    console.log("hide handler called");
  };
  const saveExit = (e) => {
    navigate(
      `/${clientAdminPath === "staff" ? "staff" : "admin"}/manage-module/ehiia`,
    );
  };

  const renderTabContent = (id, tabKey) => {
    switch (tabKey) {
      case "introduction":
        return (
          <Grid container spacing={4}>
            <Grid className={classes.gridItem} item xs={12}>
              <Widget>
                <Introduction
                  setIntroductionForm={setIntroductionForm}
                  introductionForm={introductionForm}
                  setOrgOption={setOrgOption}
                  orgOption={orgOption}
                  setSelectOrg={setSelectOrg}
                  selectOrg={selectOrg}
                  error={error}
                  checkValidator={checkValidator}
                  resetAllValidator={resetAllValidator}
                  resetValidator={resetValidator}
                  isError={isError}
                  setSearchTagError={setSearchTagError}
                  searchTagError={searchTagError}
                />
              </Widget>
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={(e) => submitExitHandler(e)}
              >
                Save & Exit
              </Button>

              <Button
                
                // disabled={
                //   introductionForm.trainingCompleted?.value &&
                //   introductionForm.trainingCompleted?.value === "No"
                //     ? true
                //     : false
                // }
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={(e) => submitHandler(e)}
              >
                Save & Continue
              </Button>
            </Grid>
          </Grid>
        );
      case "assessment":
        return (
          <Assessment
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            assessmentForm={assessmentForm}
            setAssessmentForm={setAssessmentForm}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setCurrentSection={setCurrentSection}
            isAssessmentCalled={isAssessmentCalled}
            setIsAssessmentCalled={setIsAssessmentCalled}
            setIsUpdateCalled={setIsUpdateCalled}
            setActiveTabId={setActiveTabId}
            clientAdminPath={clientAdminPath}
            setionStatus={setionStatus}
            type="add"
          />
        );
      case "dataReview":
        return (
          <DataReviewExample
            nextQuestionsValue={nextQuestionsValue}
            clientAdminPath={clientAdminPath}
            setNextQuestionsValue={setNextQuestionsValue}
            // setFormState={setFormState}
            // formState={formState}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setActiveTabId={setActiveTabId}
            setIsUpdateCalled={setIsUpdateCalled}
            setionStatus={setionStatus}
            type="add"
          />
        );
      case "engagement":
        return (
          <Engagement
            setIsUpdateCalled={setIsUpdateCalled}
            nextQuestionsValue={nextQuestionsValue}
            clientAdminPath={clientAdminPath}
            setNextQuestionsValue={setNextQuestionsValue}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setActiveTabId={setActiveTabId}
            setionStatus={setionStatus}
            type="add"
          />
        );
      case "approvalResult":
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget noBodyPadding> */}
              <Result
                setProjectIdValue={setProjectIdValue}
                projectIdValue={projectIdValue}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };
  const [alertModalShow, setAlertModalShow] = useState(false);
  const hideHandle = () => {
    setAlertModalShow(false);
  };
  return (
    <>
    <AlertForQ16
        open={alertModalShow}
        onHide={hideHandle}
        alertModalShow={alertModalShow}
        setAlertModalShow={setAlertModalShow}
        title={"Alert"}
      />
      <div className={classes.titleDiv}>
        <img
          className={classes.imageicon}
          src={backArrow}
          onClick={goBack}
          alt="goback"
        />
        <PageTitle title="Add New EHIA Case" />
        <img
          className={classes.imageCloseicon}
          src={closeIcon}
          onClick={goBack}
          alt="close"
        />
      </div>

      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBar}
        >
          {setionStatus.map((section, index) => (
            <Tab
              key={index}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {section.title}
                  {section.completed && (
                    <img style={{ marginLeft: "10px" }} src={done} alt="img" />
                  )}
                </div>
              }
              classes={{ root: classes.tab }}
              disabled={
                section.completed
                  ? false
                  : section.value === nextSection
                  ? false
                  : true
              } // Disable the tab if the section is not completed
              onClick={() => setActiveTabId(index)} // Set the active tab when clicked
            />
          ))}
        </Tabs>
        {setionStatus.map(
          (section, index) =>
            activeTabId === index && renderTabContent(index, section?.value),
        )}
      </Paper>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Introduction Module",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
