import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  Link,
} from "@material-ui/core";
// Model
import removeIcon from "../../../../assets/delete-icon.svg";
import { DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../../../assets/icons/add.png";
import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";

import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
// styles
import useStyles from "./styles";
import AlertConfirmation from "./AlertConfirmation";
import { useToasts } from "react-toast-notifications";

export default function ManageDataReview(props) {
  //
  console.log("props-24", props);
  const { selectedQuestion, setSelectedQuestion } = props;
  console.log("selectedQuestion", selectedQuestion);

  console.log(
    "props.selectedQuestion.questionId",
    props.selectedQuestion?.questionId,
  );
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(""); // State variable to track the ID of the next question
  const [selectedOption, setSelectedOption] = useState(""); // State variable to track selected option
  const [additionalSingleInfo, setAdditionalSingleInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]); // state variable for multiple checkbox and his textfield
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [fields, setFields] = useState([{ value: "" }]);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  var classes = useStyles();
  const { onHide, ...props1 } = props;

  useEffect(() => {
    const questions = [];
    selectedQuestion &&
      selectedQuestion?.findings.map((q) => {
        questions.push({
          groupName: q.groupName,
          values: q.values?.length ? q.values : [""],
        });
      });
    setSelectedOptions(questions);
  }, [selectedQuestion]);

  //for radio buttons

  const [selectedValue, setSelectedValue] = useState("");
  // Function to handle changes in text field values
  const handleChange = (idx, event) => {
    const newFields = [...fields];
    newFields[idx].value = event.target.value;
    setFields(newFields);
  };

  // Function to add more text fields
  const addTextField = () => {
    setFields([...fields, { value: "" }]);
  };
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();

    // Clear all field values
    setSelectedOptions([]);
    setSelectedValue("");
    console.log("clear radio button");
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  // Add assessment form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    const questionId = props.selectedQuestion.questionId; // Get the questionId from the first element of nextQuestionsValue array
    const selectedValue = props.selectedQuestion.value; // Get the selected value from the assessmentForm state
    const selectCheckedValue = props.selectedQuestion.valueOptions;
    const selectAdditionInfoValue = props.selectedQuestion.additionalInfo;
    console.log("selectedValue", selectedValue);
    console.log("selectedOption", selectedOption);
    console.log("selectCheckedValue", selectCheckedValue);
    const mergedOptions = [...selectedOptions];

    if (selectCheckedValue) {
      // Add selectCheckedValue to mergedOptions array
      mergedOptions.push(...selectCheckedValue);
    }
    const data = {
      projectId: props.projectIdValue,
      sectionId: "dataReview",
      questions: {
        dataReview:
          selectedQuestion?.type === "multipleFreeTextTable"
            ? {
                ...selectedQuestion,
                findings: selectedOptions.map((q) => ({
                  groupName: q.groupName,
                  values: q.values.filter((v) => v != ""),
                })),
              }
            : selectedQuestion,
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setSelectedValue("");
          setSelectedOptions([]);
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);
          // Set the previous questions by concatenating the current nextQuestionsValue with the previousQuestions array
          props.setPreviousQuestions((prevQuestions) => [
            ...prevQuestions,
            // ...nextQuestionsValue,
          ]);

          // Update the nextQuestionsValue with the new set of questions
          props.setNextQuestionsValue(payload?.data?.nextQuestions);
          props.setUpdated(true);
        } else {
          console.log("payload?.error", payload);
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          // setEhiiaIntroductionDetail({});
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };

  return (
    <>
      <AlertConfirmation
        // onHide={hideHandle}
        setIsActionCalled={setIsActionCalled}
        setAlertModalShow={setAlertModalShow}
        alertModalShow={alertModalShow}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        alert={
          <>
            {selectedQuestion?.reset ? (
              <Typography
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  color: "red",
                  fontSize: "14px",
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Note:- If you edit this question, all your next questions will
                be reset and you will have to answer again.
              </Typography>
            ) : (
              ""
            )}
          </>
        }
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Save Changes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText
            className={classes.formAddBox}
            style={{ padding: "10px 4px" }}
          >
            {selectedQuestion && (
              <Grid item xs={12} md={12} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  {selectedQuestion.questionSerial} {selectedQuestion.title}
                </Typography>
                {selectedQuestion.type === "acknowledgeTableLinks" ? (
                  <>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            {selectedQuestion.element.tableData.columns.map(
                              (column) => (
                                <TableCell
                                  key={column}
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                  }}
                                >
                                  {column}
                                </TableCell>
                              ),
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.element.tableData.rows.map(
                            (row, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #B6B6B6",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {row[0]}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {row.slice(1).map((link, linkIndex) => (
                                      <Link
                                        target="_blank"
                                        key={`${rowIndex}-${linkIndex}`}
                                        href={link}
                                        style={{
                                          marginRight: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        Open Link {linkIndex + 1}
                                      </Link>
                                    ))}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid item xs={12} md={9} style={{ marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => submitHandler(e)}
                        className={classes.largeBtn}
                        elevation={0}
                      >
                        Complete this task
                      </Button>
                    </Grid>
                  </>
                ) : selectedQuestion.type === "multipleFreeText" ? (
                  <>
                    {selectedQuestion.options.map((item, rowIndex) => (
                      <TextField
                        size="small"
                        name="value"
                        style={{ marginTop: "4px" }}
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Text"
                        variant="outlined"
                        value={item.value || ""}
                        onChange={(e) => {
                          const text = e.target.value;
                          const updatedOptions = selectedQuestion.options.map(
                            (opt, i) => {
                              if (i === rowIndex) {
                                return { ...opt, value: text };
                              }
                              return opt;
                            },
                          );
                          setSelectedQuestion({
                            ...selectedQuestion,
                            options: updatedOptions,
                          });
                        }}
                      />
                    ))}

                    <Grid
                      item
                      xs={12}
                      md={9}
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        marginLeft: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedQuestion({
                          ...selectedQuestion,
                          options: [...selectedQuestion.options, { value: "" }],
                        });
                      }}
                    >
                      <img src={plusIcon} alt="plusicon" />
                      <Typography className={classes.formHeading}>
                        Add More
                      </Typography>
                    </Grid>
                  </>
                ) : selectedQuestion.type === "findingsActionTable" ? (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              What are your Findings?
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Is Action Required?
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map((row, rowIndex) => (
                            <TableRow
                              style={{ border: "1px solid #B6B6B6" }}
                              key={row.groupName}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "30%",
                                  verticalAlign: "top",
                                }}
                              >
                                {row.groupName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "50%",
                                  verticalAlign: "top",
                                }}
                              >
                                <TextField
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="What are your Findings?"
                                  variant="outlined"
                                  multiline
                                  value={row.findings || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return { ...find, findings: text };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  width: "20%",
                                  verticalAlign: "top",
                                  border: "1px solid #B6B6B6",
                                }}
                              >
                                <FormControl>
                                  <RadioGroup
                                    style={{ display: "flex" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={row.actionRequired ? "yes" : "no"}
                                    onChange={(e) => {
                                      const actionRequired =
                                        e.target.value === "yes";
                                      const updatedFindings =
                                        selectedQuestion.findings.map(
                                          (find, index) => {
                                            if (index === rowIndex) {
                                              return {
                                                ...find,
                                                actionRequired,
                                              };
                                            }
                                            return find;
                                          },
                                        );
                                      setSelectedQuestion({
                                        ...selectedQuestion,
                                        findings: updatedFindings,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      style={{ fontSize: "14px" }}
                                      value="yes"
                                      control={
                                        <Radio style={{ fontSize: "14px" }} />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      style={{ fontSize: "14px" }}
                                      value="no"
                                      control={
                                        <Radio style={{ fontSize: "14px" }} />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : selectedQuestion.type === "actionPlanTable" ? (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      component={Paper}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Findings
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Issues
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                                fontSize: "14px",
                              }}
                              align="center"
                            >
                              Action Plan
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map((row, rowIndex) => (
                            <TableRow
                              style={{ border: "1px solid #B6B6B6" }}
                              key={row.groupName}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "25%",
                                  verticalAlign: "top",
                                  fontSize: "14px",
                                }}
                              >
                                {row.groupName}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  verticalAlign: "top",
                                  width: "25%",
                                  fontSize: "14px",
                                }}
                              >
                                {row.findings}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "25%",
                                }}
                              >
                                <TextField
                                  name="issues"
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="Please enter your issues"
                                  variant="outlined"
                                  multiline
                                  InputProps={{
                                    style: { fontSize: "14px" },
                                  }}
                                  value={row.issues || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              issues: text,
                                            };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  width: "25%",
                                }}
                              >
                                <TextField
                                  name="actionPlan"
                                  size="small"
                                  style={{ marginTop: "4px" }}
                                  className={classes.txtForm}
                                  fullWidth
                                  placeholder="Please enter your action plan"
                                  variant="outlined"
                                  multiline
                                  InputProps={{
                                    style: { fontSize: "14px" },
                                  }}
                                  value={row.actionPlan || ""}
                                  onChange={(e) => {
                                    const text = e.target.value;
                                    const updatedFindings =
                                      selectedQuestion.findings.map(
                                        (find, index) => {
                                          if (index === rowIndex) {
                                            return {
                                              ...find,
                                              actionPlan: text,
                                            };
                                          }
                                          return find;
                                        },
                                      );
                                    setSelectedQuestion({
                                      ...selectedQuestion,
                                      findings: updatedFindings,
                                    });
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : selectedQuestion.type === "multipleFreeTextTable" ? (
                  <>
                    <TableContainer
                      style={{ marginTop: "16px" }}
                      sx={{ boxShadow: 0 }}
                      component={Paper}
                    >
                      <Table
                        sx={{ minWidth: "100%", boxShadow: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                          <TableRow>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              Identified Groups
                            </TableCell>
                            <TableCell
                              style={{
                                border: "1px solid #B6B6B6",
                                textAlign: "center",
                              }}
                            >
                              List of Data Source
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedQuestion.findings.map(
                            (finding, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #B6B6B6",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    verticalAlign: "top",
                                    width: "30%",
                                  }}
                                >
                                  {finding.groupName}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {selectedOptions &&
                                      selectedOptions[rowIndex]?.values?.map(
                                        (value, linkIndex) => (
                                          <span style={{ display: "flex" }}>
                                            <TextField
                                              size="small"
                                              key={`${rowIndex}-${linkIndex}`}
                                              style={{ marginTop: "4px" }}
                                              className={classes.txtForm}
                                              fullWidth
                                              multiline
                                              placeholder="Text"
                                              variant="outlined"
                                              value={value || ""}
                                              onChange={(e) => {
                                                const updatedFinding =
                                                  JSON.parse(
                                                    JSON.stringify(
                                                      selectedOptions,
                                                    ),
                                                  );
                                                updatedFinding[rowIndex].values[
                                                  linkIndex
                                                ] = e.target.value;

                                                setSelectedOptions(
                                                  updatedFinding,
                                                );
                                              }}
                                            />
                                            {selectedOptions[rowIndex]?.values
                                              .length > 1 && (
                                              <img
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  const updatedOptions =
                                                    JSON.parse(
                                                      JSON.stringify(
                                                        selectedOptions,
                                                      ),
                                                    );
                                                  updatedOptions[
                                                    rowIndex
                                                  ].values.splice(linkIndex, 1);
                                                  setSelectedOptions(
                                                    updatedOptions,
                                                  );
                                                }}
                                                src={removeIcon}
                                                alt="remove"
                                              />
                                            )}
                                          </span>
                                        ),
                                      )}
                                    <Grid
                                      item
                                      xs={12}
                                      md={9}
                                      style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        marginLeft: "4px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        const updatedOptions = JSON.parse(
                                          JSON.stringify(selectedOptions),
                                        );
                                        updatedOptions[rowIndex].values.push(
                                          "",
                                        );
                                        setSelectedOptions(updatedOptions);
                                      }}
                                    >
                                      <img
                                        src={plusIcon}
                                        style={{ height: "16px" }}
                                        alt="plus-icon"
                                      />
                                      <Typography
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        Add More
                                      </Typography>
                                    </Grid>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  "null"
                )}
              </Grid>
            )}
            {/* Name */}
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Update DataReview",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
