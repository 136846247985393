import React from 'react'

import ProjectList from '../../components/Project/ProjectList'

export default function Ehiia() {
  return (
    <div>
        <ProjectList/>
    </div>
  )
}
