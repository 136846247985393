import React from "react";
import { Route } from "react-router-dom";

import Redirect from "../Redirect";
//
import Layout from "../component/Layout/Layout";

import Login from "./pages/login/Login";
import LoginWithEmail from "./pages/login/LoginWithEmail";

// context
import Forget from "./pages/forgetPassword/Forget";
import Reset from "./pages/resetPassword/Reset";
import Dashboard from "./pages/dashboard/Dashboard";
import Ehiia from "./pages/ehiia/Ehiia";
import EhiiaDetails from "./pages/ehiia/EhiiaDetails";
import EditEhiia from "./pages/ehiia/EditEhiia";
import Admin from "./pages/manageAdmin/Admin";
import AdminDetail from "./pages/manageAdmin/AdminDetails";
import EditAdmin from "./pages/manageAdmin/EditAdmin";
import Client from "./pages/manageClient/Client";
import ClientDetails from "./pages/manageClient/ClientDetails";
import EditClient from "./pages/manageClient/EditClient";
import Report from "./pages/reports/Reports";
import Project from "./pages/project/Project";
import ProjectDetails from "./pages/project/ProjectDetails";
import EditProject from "./pages/project/EditProject";
import AuditTrails from "./pages/audits/AuditTrails";
import Profile from "./pages/profile/Profile";
import ChangePassword from "./pages/profile/ChangePassword";
import Settings from "./pages/profile/Settings";
import Activation from "./pages/profile/Activation";
import ApprovalProcess from "./pages/approval/ApprovalProcess";
import DataContentList from "./pages/content/DataContentList";
import DataItemDetails from "./pages/content/DataItemDetails";

import EhiiaForm from "./pages/ehiia/Cases/EhiiaForm";
import UserEhiiaDetails from "../user-admin/pages/ehiia/EhiiaDetails";
import EhiiaModuleDeatils from "../user-admin/pages/ehiia/EhiiaModuleDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export const clientAdminApp = (admin) => {
  console.log("admin-pannel")
  console.log("admin", admin);
  if (!admin?.isAdminLoggedIn) {
    return (
      <>
        <Route path="*" element={<Redirect to="/login" />} />
        <Route exact path="/login" element={<LoginWithEmail />} />
        <Route exact path="/admin/login" element={<Login />} />
        <Route
          exact
          path="/admin/forget-password"
          element={<Forget />}
        />
        <Route exact path="/admin/reset-password/:token" element={<Reset />} />
        <Route exact path="/admin/activate/" element={<Activation />} />
        <Route exact path="/admin/privacy-policy" element={<PrivacyPolicy />} />

      </>
    );
  }
  if (admin?.isAdminLoggedIn) {
    return (
      <>
        <Route path="*" element={<Redirect to="/admin/dashboard" />} />
        <Route exact path="/admin" element={<Layout />}>
          <Route exact path="/admin/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/admin/manage-module/ehiia"
            element={<Ehiia />}
          />
          
          <Route path="/admin/add-ehiia/" element={<EhiiaForm />} />
          <Route
            path="/admin/ehiia-details/:projectId"
            element={<UserEhiiaDetails />}
          />
          <Route
            path="/admin/view-project/:projectId"
            element={<EhiiaModuleDeatils />}
          />
          <Route
            path="/admin/other-ehiia-details/:projectId"
            element={<EhiiaDetails />}
          />
          <Route path="/admin/ehiia-edit/:projectId" element={<EditEhiia />} />
          <Route path="/admin/manage-admin" element={<Admin />} />
          <Route
            path="/admin/admin-details/:adminId"
            element={<AdminDetail />}
          />
          <Route
            path="/admin/admin-edit/:adminId"
            element={<EditAdmin />}
          />
          <Route path="/admin/manage-client" element={<Client />} />
          <Route
            path="/admin/client-details/:userId"
            element={<ClientDetails />}
          />
          <Route
            path="/admin/client-edit/:userId"
            element={<EditClient />}
          />
          <Route path="/admin/report" element={<Report />} />
          <Route path="/admin/project" element={<Project />} />
          <Route path="/admin/approval" element={<ApprovalProcess />} />
          <Route
            path="/admin/complete-project-details/:projectId"
            element={<ProjectDetails />}
          />
          <Route
            path="/admin/project-details/:projectId"
            element={<ProjectDetails />}
          />
          <Route path="/admin/project-edit/:projectId" element={<EditProject />} />
          <Route path="/admin/audit-trail" element={<AuditTrails />} />
          <Route path="/admin/approval/data-review-content" element={<DataContentList />} />
          <Route path="/admin/data-review-details/:organization/:id" element={<DataItemDetails />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/setting" element={<Settings />} />
          <Route
            path="/admin/reset-password"
            element={<ChangePassword />}
          />
        </Route>
      </>
    );
  }
};
