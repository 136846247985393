import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    },
  },
  hoverableCell: {
    border: "1px solid #B6B6B6",
    textAlign: "center",
    whiteSpace: "normal", // Prevent text from wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflow
    maxWidth: "160px", // Set the maximum width
    width:'200px',
    cursor: "pointer",
    transition: "white-space 0.3s", // Add a smooth transition
    wordWrap:'break-word',
    overflowWrap:'anywhere',
    minWidth:'160px'
    
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  noteIcons: {
    position: "absolute",
    right: '10px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#201F1E",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "left",
    flexGrow: 1,
    paddingLeft: "3px",
    paddingRight: "4px",
  },
  formHeadingInline: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#201F1E",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "left",
    // flexGrow: 1,
    paddingLeft: "3px",
    paddingRight: "4px",
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    paddingLeft:theme.spacing(2),
    color: "#52575C",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
  },
  formHeadingValue2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    // paddingLeft:theme.spacing(2),
    color: "#B09DAB",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
  },
  formHeadingValue_right:{
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#52575C",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
    marginLeft:'10px'
  },
  txtForm: {
    padding: "0px 4px",
    marginTop: theme.spacing(1),
  },
  dropdownFormControl: {
    marginTop: theme.spacing(2),
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    marginTop: theme.spacing(0),
    height: "42px",
    background: "#fff",
    "&:before": {
      border: '0px',
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  formlabel:{
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(-2),
    },
  },
  textGroup: {
    display: "flex-row",
    justifyContent: "flex-start",
  },
  textGroup_inline: {
    display: "-webkit-inline-box",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  textGroupInline:{
    display:"-webkit-box",
    // justifyContent: 'flex-start',
    [theme.breakpoints.down("md")]: {
        display: "inline",
      },
},
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  rightButton: {
    marginLeft: theme.spacing(2),
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
  },
  leftBluetButton: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,

    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
    background: "#0445AF",
    color: "#fff",
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  largeBtn: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    height: "42px",
    textTransform: "none",
    borderRadius: "2px",
    color:'#fff',
    "&:hover": {
        background: "#038C71",
        color: "#fff",
      },
      "&:focus": {
        background: "#037962",
        color: "#fff",
      },
      "&:active": {
        background: "#037962",
        color: "#fff",
      },
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },
  Link: {
    color: "#0445AF",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
    background: "#fff",
  },
   //tab color
   tab:{
    color:'#0445AF',
    
    "&:hover": {
      
      color: "#0445AF",
      borderBottom:'2px solid #0445AF'
      // background:'#03997B'
    },
    "&:active": {
      
      color: "#0445AF",
      borderBottom:'2px solid #0445AF'
      // background:'#03997B'
    },
    "&.Mui-selected":{
      color: "#0445AF",
      borderBottom:'2px solid #0445AF'
      // background:'#03997B'
    }
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
    marginTop:theme.spacing(2)
  },
   iconsBgBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
   
  },
  tabBg: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
    "&:hover": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&:active": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#03997B'
    }
  },
}));
