export const manageApprovalSchema = {
  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter approval name",
      noLeadingSpace: "Approval name cannot start with a space",
      atLeastOneAlpha: "At least one alphabet character is required",
    },
  },
  approvalType: {
    type: "string",
    required: true,
    properties: {
        empty: "Please select approval type",
    }
},
email: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  comments: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter Official comment",
      noLeadingSpace: "Official comment cannot start with a space",
      atLeastOneAlpha: "At least one alphabet character is required",
    },
  },

  supportComments: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter support comments",
      noLeadingSpace: "Support comment cannot start with a space",
      atLeastOneAlpha: "At least one alphabet character is required",
    },
  },

  approvalDate: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter approval date",
    },
  },
};
