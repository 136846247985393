import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import moment from "moment";
// styles
import useStyles from "./styles";
import cubes from "./cubes.svg";

import { getEhiiaVersionListByVersionId } from "../../../store/actions/ehiiasAction";
import Loader from "../../../../component/common/loader/Loader";
export default function EhiiaDetail() {
  const { versionId } = useParams();
  console.log("versionId", versionId);
  const navigate = useNavigate();
  var classes = useStyles();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.ehiias);
  const [moduleDetail, setModuleDetail] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEhiiaVersionListByVersionId({ versionId })).then(
      ({ payload }) => {
        console.log("data", payload);
        if (payload?.success) {
          setModuleDetail(payload?.data?.version);
        }
      },
    );
  }, []);

  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getEhiiaVersionListByVersionId({ versionId })).then(
        ({ payload }) => setModuleDetail(payload?.data?.version),
      );
    }
  }, [isRecordUpdated]);
  const handleEdit = () => {
    navigate(`/super-admin/ehiia-edit/${versionId}`);
  };
  return (
    <>
      {isLoader && <Loader />}
      <PageTitle
        subtitle={moduleDetail?.name}
        urlLink="/super-admin/manage-module/ehiia"
        title="> Details"
        button="Edit"
        editIcon="true"
        onClick={handleEdit}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Version ID:</Typography>
            <Typography
              className={classes.formHeadingValue}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              {moduleDetail?.versionId}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Created Date:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {moment(moduleDetail?.createdAt).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Updated Date:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {moment(moduleDetail?.updatedAt).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Version Name:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {moduleDetail?.name}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created By:</Typography>
            <Typography className={classes.formHeadingValue}>
              {moduleDetail?.createdBy?.firstName}{" "}
              {moduleDetail?.createdBy?.lastName}
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status:</Typography>
            <Typography className={classes.formHeadingValue}>
            {moduleDetail?.isActive ? (
                            <span style={{ color: "#10940D", fontWeight: 500 }}>
                              Active
                            </span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={classes.textDesGroup}>
            <Typography className={classes.formHeading}>
              Description:
            </Typography>
            <Typography className={classes.formHeadingDesValue}>
              {moduleDetail?.description}
            </Typography>
          </Grid>
          {/* <Grid
            container
            xs={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button className={classes.blueButton} variant="contained">
              Clone this Module
            </Button>
          </Grid> */}
        </Grid>
      </Widget>

      {/* Assigned */}
      {/* <Typography variant="h5" className={classes.AssignHeading}>
        Projects
      </Typography>
      <Grid container className={classes.widgetBox} spacing={4}>
        <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="8"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <img className={classes.icons} src={cubes} />
            <div className={classes.progressSection}>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                Ongoing Projects
              </Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={8} sm={6} xs={12}>
          <Widget
            title="12"
            upperTitle
            className={classes.card}
            bodyClass={classes.fullHeightBody}
          >
            <img className={classes.icons} src={cubes} />
            <div className={classes.progressSection}>
              <Typography
                size="md"
                color="text"
                colorBrightness="secondary"
                className={classes.progressSectionTitle}
              >
                Completed Projects
              </Typography>
            </div>
          </Widget>
        </Grid>
      </Grid> */}
    </>
  );
}
