import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Fade,
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Button,
  DialogContent,
  DialogContentText,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

import Popup from "../../../../component/common/modal/Popup";
// styles
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Validation from "../../../hooks/Validation";
import { reassignClient } from "../../../store/actions/clientsAction";
import { fetchModuleList } from "../../../store/actions/modulesAction";
import { fetchVersionList } from "../../../store/actions/versionsAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
const formElements = [
  {
    key: "module",
    label: "Add Module",
    placeHolder: "Select module",
    type: "select",
    options: [],
  },
  {
    key: "versions",
    label: "Add Version",
    placeHolder: "Select versions",
    type: "multiselect",
    options: [],
  },
];
export default function ManageAssignModule(props) {
  //
  console.log("props-24", props);
  const { selectclient } = props;
  const { clientId } = useParams();
  console.log("clientId", clientId);
  const [isUploading, setIsUploading] = useState(false);
  console.log("selectclient", selectclient);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [moduleOption, setModuleOption] = useState(null);
  const [moduleIdForVersion, setModuleIdForVersion] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const inputRef = useRef();
  var classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [versionDataValue, setVersionDataValue] = React.useState([]);
  const [versionOption, setVersionOption] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedArray = typeof value === "string" ? value.split(",") : value;
    setVersionDataValue(selectedArray);
  };
  const [clientDetail, setClientDetail] = useState({
    clientId: clientId,
    module: null,
    versions: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      module: clientDetail?.module,
      // versions: clientDetail?.versions,
    });
  const dispatch = useDispatch();
  const { modules } = useSelector((state) => {
    return state.modules;
  });
  const { versions, filtered, pagination } = useSelector((state) => {
    return state.versions;
  });
  useEffect(() => {
    if (!Array.isArray(modules)) {
      dispatch(fetchModuleList());
    }
  }, []);
  useEffect(() => {
    if (Array.isArray(modules)) {
      // let arr = modules.map((x) => { return {_id:x._id, name:x?.name}});
      setModuleOption(modules);
    }
  }, [modules]);
  useEffect(() => {
    console.log("moduleIdForVersion", moduleIdForVersion);
    dispatch(fetchVersionList({ moduleId: moduleIdForVersion })).then(
      ({ payload }) => {
        console.log("data", payload);
        if (payload?.success) {
          setVersionOption(payload.data.versions);
        }
      },
    );
  }, [moduleOption, moduleIdForVersion]);
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);

  useEffect(() => {
    console.log("selectclient useEffect", selectclient);
    if (selectclient === null) {
      setClientDetail({
        clientName: null,
        clientOrgShortName: null,
        contactName: null,
        email: null,
        module: null,
      });
    } else {
      setClientDetail({
        clientId: selectclient?._id,
        clientName: selectclient?.clientName,
        clientOrgShortName: selectclient?.clientOrgShortName,
        contactName: selectclient?.contactName,
        email: selectclient?.email,
        module: selectclient?.module,
        publicUrl: selectclient?.publicUrl,
      });
    }
  }, [selectclient]);
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  //api call
  useEffect(() => {
    setIsUploading(true);

    let formData = {
      clientId: clientId,
      moduleId: clientDetail.module,
      versions: versionDataValue,
    };

    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(reassignClient(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload.success) {
          setIsUploading(true);
          setSuccessMessage(payload?.message);
          hideHandler();
          setIsShow(true);
        } else {
          setErrorApiMessage(payload?.message);
          setIsShow(true);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  // Form cancel Button
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    props.onHide();

    if (selectclient === null) {
      setClientDetail({
        module: null,
        versions:null,
      });
      setVersionDataValue([]);
    } else {
      setClientDetail({
        clientId: selectclient?._id,
        module: selectclient?.module,
        versions:selectclient?.versions
      });
      setVersionDataValue(selectclient?.versions || []);
    }
    console.log("clientDetail", clientDetail);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => hideHandler(e)}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {/* Avtar */}

            {isLoader && <Loader />}
            {/* org name */}
            <Grid container spacing={2}>
              {formElements.map((element, i) => {
                return (
                  (!selectclient || element.key !== "password") && (
                    <Grid item xs={12}>
                      <div key={i}>
                        <Typography className={classes.formHeading}>
                          {element.label}
                        </Typography>
                        {element.type == "select" ? (
                          <>
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                value={
                                  clientDetail && clientDetail[element.key]
                                }
                                onChange={async (e) => {
                                  const selectedValue = e.target.value;
                                  setClientDetail({
                                    ...clientDetail,
                                    [element.key]: selectedValue,
                                  });
                                  resetValidator(e.target.name);
                                  setModuleIdForVersion(selectedValue); // Assuming this is where you want to store the selected option's ID
                                  console.log(
                                    "moduleIdForVersion",
                                    moduleIdForVersion,
                                  );
                                }}
                                isInvalid={error && error[element.key]}
                              >
                                <MenuItem value="">Select</MenuItem>
                                {element.key === "module" && moduleOption
                                  ? moduleOption.map((item) => (
                                      <MenuItem
                                        key={item.moduleId}
                                        value={item.moduleId}
                                      >
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                            <Fade in={error}>
                              <Typography className={classes.errorMessage}>
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </>
                        ) : element.type == "multiselect" ? (
                          <FormControl
                            fullWidth
                            className={classes.dropdownFormControl}
                            style={{ padding: "4px" }}
                          >
                            <Select
                              multiple
                              style={{ fontSize: "12px" }}
                              value={versionDataValue}
                              onChange={handleChange}
                              className={classes.selectDropdown}
                              renderValue={(selected) =>
                                selected
                                  .map((value) => {
                                    const selectedItem = versionOption.find(
                                      (item) => item.versionId === value,
                                    );
                                    return selectedItem
                                      ? selectedItem.name
                                      : "";
                                  })
                                  .join(",")
                              }
                              // MenuProps={MenuProps}
                            >
                              <MenuItem disabled value="">
                                <em>Select</em>
                              </MenuItem>
                              {element.key === "versions" && versionOption
                                ? versionOption.map((item) => (
                                    <MenuItem
                                      key={item.versionId}
                                      value={item.versionId}
                                    >
                                      <Checkbox
                                        checked={
                                          versionDataValue.indexOf(
                                            item.versionId,
                                          ) > -1
                                        }
                                      />
                                      <ListItemText primary={item.name} />
                                    </MenuItem>
                                  ))
                                : element.options.map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <>
                            <TextField
                              hiddenLabel
                              size="small"
                              type={element.type}
                              name={element.key}
                              placeholder={element.placeHolder}
                              className={classes.txtForm}
                              value={
                                clientDetail &&
                                clientDetail[element.key] === null
                                  ? ""
                                  : clientDetail && clientDetail[element.key]
                              }
                              // value={clientDetail && clientDetail[element.key]}
                              fullWidth
                              // defaultValue={clientDetail && clientDetail[element.key]}
                              variant="outlined"
                              onChange={async (e) => {
                                setClientDetail({
                                  ...clientDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);

                                console.log("clientDetail", clientDetail);
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </>
                        )}
                      </div>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
        {/* message popup */}
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Assign Module",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
