import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  dropdownFormControl: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    marginTop: theme.spacing(0),
    height: "40px",
    background: "#fff",
    "&:before": {
      border: '0px',
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  uploadIcon: {
    position: "absolute",
    marginTop: "-36px",
    marginLeft: "36px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
    paddingLeft: "6px",
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    flexGrow: 1,
    paddingLeft: theme.spacing(0.5),
    //    width:'40%'
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    width: "60%",
  },

  txtForm: {
    padding: "0px 4px",
    marginTop: theme.spacing(1),
  },
  textGroup: {
    display: "flex-row",
    justifyContent: "flex-start",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  leftButton: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
  },
  rightButton: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },
  Link: {
    color: "#0445AF",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
    background: "#fff",
  },
}));
