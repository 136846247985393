import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { getEhiiaProjectByProjectIdwithSectionId } from "../../../store/actions/ehiiaAction";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";

import Introduction from "../Cases/Introduction";
import Assessment from "../Cases/Assessment";
import DataReview from "../Cases/DataReview";
import Engagement from "../Cases/Engagement";
import Result from "../Cases/Result";
export default function ProjectDetail() {
  const { projectId } = useParams();
  var [activeTabId, setActiveTabId] = useState(0);
  const dispatch = useDispatch();
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [downloadReport, setDownlodReport] = useState(null);
  const navigate = useNavigate();
  var classes = useStyles();
  const handleEdit = () => {
    navigate(`/project-edit`);
  };

  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        console.log("payload?.data", payload?.data);
        console.log("payload?.data?.form", payload?.data?.form);

        setEhiiaProjectDetail(payload?.data?.form);
        setDownlodReport(payload?.data?.form?.finalApproval?.overview?.downloadReportLink);
        // setIntroductionForm(payload?.data?.form?.introduction);
        // setAssessmentForm(payload?.data?.form?.assessment?.questions);
      }
    });
  }, []);

  return (
    <>
      <PageTitle
        subtitle="Project Repositories >"
        title="Details"
        button="Edit"
        urlLink="project"
        editIcon="true"
        onClick={handleEdit}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Project Title:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {/* {ehiiaProjectDetail?.projectTitle} */}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Sub Organisations:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              Organisation A
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Project ID:</Typography>
            <Typography className={classes.formHeadingValue}>
              {projectId}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Date Completed:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              28/11/2022
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Project Lead:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              Ronald Richards
            </Typography>
          </Grid>
        </Grid>
      </Widget>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab label="Introduction" classes={{ root: classes.tab }} />
        <Tab label="Assessment" classes={{ root: classes.tab }} />
        <Tab label="Data Review" classes={{ root: classes.tab }} />
        <Tab label="Engagement phase" classes={{ root: classes.tab }} />
        <Tab label="Result" classes={{ root: classes.tab }} />
      </Tabs>
      <Widget className={classes.boxDiv}>
        {activeTabId === 0 && <Introduction />}
        {activeTabId === 1 && <Assessment />}
        {activeTabId === 2 && <DataReview />}
        {activeTabId === 3 && <Engagement />}
        {activeTabId === 4 && <Result />}
      </Widget>
    </>
  );
}
