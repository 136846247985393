import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";

export const fetchClientList = createAsyncThunk(
  "clients/fetchClientList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/root/org/v1/?search=${arg?.search}&page=${arg?.page}&orderIn=${arg?.orderIn}&orderBy=${arg?.orderBy}`,
      arg,
      rejectWithValue,
    ),
);

export const downloadClientList = createAsyncThunk(
  "clients/downloadClientList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get("/root/org/v1/download/all", arg, rejectWithValue),
);

export const createUpdateClient = createAsyncThunk(
  "clients/createUpdateClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/org/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const updateClient = createAsyncThunk(
  "clients/updateClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/org/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const checkSubdomain = createAsyncThunk(
  "clients/checkSubdomain",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/root/org/v1/subdomain-available", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const reassignClient = createAsyncThunk(
  "clients/reassignClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/clients/assign-module", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(`/root/org/v1/${arg?.orgId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const toggleActivation = createAsyncThunk(
  "clients/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(`/root/org/v1/toggle-status/${arg?.orgId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const sendActivationLink = createAsyncThunk(
  "clients/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(`/clients/resend-activation/${arg?.orgId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const sendResetPasswordLink = createAsyncThunk(
  "clients/sendResetPasswordLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(`/clients/reset-password/${arg?.orgId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const sendActivationMail = createAsyncThunk(
  "clients/sendActivationMail",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(`/root/org/v1/resend-account-mail/${arg?.orgId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const getClientByClientId = createAsyncThunk(
  "clients/getClientByClientId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/root/org/v1/${arg?.orgId}`)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
