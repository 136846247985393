import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import moment from "moment";
// styles
import useStyles from "./styles";

import { getSubAdminBySubAdminId } from "../../../store/actions/subAdminAction";
import Loader from "../../../../component/common/loader/Loader";
export default function AdminDetail() {
  const { rootAdminId } = useParams();
  console.log("rootAdminId", rootAdminId);
  const navigate = useNavigate();
  var classes = useStyles();
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.subadmins);
  const [moduleDetail, setModuleDetail] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubAdminBySubAdminId({ rootAdminId })).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        setModuleDetail(payload?.data?.user);
      }
      console.log("moduleDetails", moduleDetail);
    });
  }, []);

  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getSubAdminBySubAdminId({ rootAdminId })).then(({ payload }) => {
        console.log("payload", payload);
        if (payload?.success) {
          setModuleDetail(payload?.data?.user);
        }
      });
    }
  }, [isRecordUpdated]);
  const handleEdit = () => {
    navigate(`/super-admin/admin-edit/${rootAdminId}`);
  };
  return (
    <>
      {isLoader && <Loader />}
      <PageTitle
        subtitle="Manage Admin"
        urlLink="/super-admin/manage-admin"
        title="> Details"
        button="Edit"
        editIcon="true"
        onClick={handleEdit}
      />

      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Name:</Typography>
            <Typography
              className={classes.formHeadingValue}
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              {moduleDetail?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Email ID:</Typography>
            <Typography className={classes.formHeadingValue}>
              {moduleDetail?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created On:</Typography>
            <Typography className={classes.formHeadingValue}>
              {moment(moduleDetail?.createdAt).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Account Registered:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {moduleDetail?.isPublished ? "Yes" : "No"}
              {/* {row.isActive ? (
                <span style={{ color: "#10940D", fontWeight: 500 }}>
                  Active
                </span>
              ) : (
                <span style={{ color: "red" }}>Inactive</span>
              )} */}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status:</Typography>
            <Typography className={classes.formHeadingValue}>
              {moduleDetail?.isActive ? (
                moduleDetail?.isActivated ? (
                  <span style={{ color: "#10940D", fontWeight: 500 }}>
                    Active
                  </span>
                ) : (
                  <span style={{ color: "#F8A80C" }}>Pending</span>
                )
              ) : (
                <span style={{ color: "red" }}>Inactive</span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Widget>
    </>
  );
}
