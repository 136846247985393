import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
// styles
import useStyles from "./styles";

export default function AlertForQ16({ alertModalShow, setAlertModalShow }) {
  const dispatch = useDispatch();
  var classes = useStyles();

  const hideHandle = () => {
    setAlertModalShow(false);
  };

  return (
    <>
      <Popup
        onHide={hideHandle}
        open={alertModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              style={{ left: "40%", position: "absolute", bottom: "10px" }}
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandle}
            >
              Close
            </Button>

            {/* <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={hideHandle}
                evalution={0}
              >
                Close
              </Button> */}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox} style={{padding:'10px'}}>
            <center><img src={alertIcon} alt="img" /></center>

            <Typography
              className={classes.formActiveHeading}
              style={{ fontSize: "14px", }}
            >
              You must complete the EHIA training before you progress.<a style={{textDecoration:'none'}} href="https://learninghub.nhs.uk/Resource/51905/Item" target="_blank"> Click
              this link</a> to complete the training. The training should take you
              less than 20 minutes to complete. Click save & Exit in the bottom
              left to save this project. Once you complete your training, you
              can restart this project from the ‘Ongoing Project’ section.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
