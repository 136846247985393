import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import PageTitle from "../../../component/PageTitle/PageTitle";
import PrivateDataRepo from "./PrivateDataRepo";
import useStyles from "./styles";
import { useSelector } from "react-redux";
export default function DataRepository() {
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  const [activeParentTabId, setActiveParentTabId] = useState(0);
  const [orgOption, setOrgOption] = useState(null);
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  console.log("adminDetail", adminDetail);

  useEffect(() => {
    console.log("adminDetail", adminDetail);
    console.log(
      "adminDetail.data.subOrg.ehiiaVersion",
      adminDetail?.data?.subOrg?.ehiiaVersion,
    );
    const subOrgOptions = adminDetail?.data?.subOrg?.ehiiaVersion || [];
    console.log("subOrgOptions", subOrgOptions);
    if (Array.isArray(subOrgOptions)) {
      console.log("subOrgOptions", subOrgOptions);

      setOrgOption(subOrgOptions);
    }
  }, [adminDetail]);

  const subTabData = [
    {
      label: "Private Data Repository",
    },
   
  ];
  const renderTabs = (data, stageKey) => {
    console.log("data", data);
    console.log("stageKey", stageKey);
    return (
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="none"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          lassName={classes.iconsBar}
        >
          {subTabData.map((item, index) => (
            <Tab
              key={index}
              label={item?.label} style={{textTransform:'capitalize'}}
              classes={{ root: classes.tab }}
            />
          ))}
        </Tabs>
        {subTabData.map(
          (item, index) =>
            activeTabId === index && (
              <div style={{ padding: "20px 0px",borderBottom:'0px' }} key={index}>
               <PrivateDataRepo data={data}/>
              </div>
            ),
        )}
      </Paper>
    );
  };
  return (
    <>
      <PageTitle title="Data Repository" />
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="none"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeParentTabId}
          onChange={(e, id) => setActiveParentTabId(id)}
          className={classes.iconsBgBar}
        >
          {orgOption && orgOption.map((item, index) => (
            <Tab
              key={index}
              label={item}
              style={{ textTransform: "capitalize" }}
              classes={{ root: classes.tabBg }}
            />
          ))}
        </Tabs>
      </Paper>
      {orgOption && orgOption.map((item, index) => (
            <div key={index}>
              {activeParentTabId === index && <>{renderTabs(item)}</>}
            </div>
          ))}
    </>
  );
}
