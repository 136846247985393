import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import Loader from "../../../../component/common/loader/Loader";
import alertIcon from "../../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { sendActivationLink } from "../../../store/actions/clientsAction";
// styles
import useStyles from "./styles";
export default function ActivationLinkClientPopup({
  activationLinkRec,
  onHide,
  activationLinkModalShow,
  setActivationLinkModalShow,
}) {
  //
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);
  const submitHandler = (mode) => {
    setIsAction(false);
    return dispatch(
      sendActivationLink({
        clientId: activationLinkRec,
      }),
    ).then((payload) => {
      if (payload?.type === "clients/sendActivationLink/rejected") {
        return;
      }
      setIsDone(true);
      onHide();
      setActivationLinkModalShow(false);
    });
  };
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={activationLinkModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          {isLoader && <Loader />}
          <DialogContentText className={classes.formBox}>
            <center>
            <img src={alertIcon} />
            {apiError ? (
              <Typography className={classes.formActiveHeading}>
                {apiError?.message || "Access denied! Please try again"}
              </Typography>
            ) : (
              <Typography className={classes.formActiveHeading}>
                Are you sure you want to resend activation link to this client?
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
