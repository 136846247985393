import React, { useState } from "react";
import Loader from "../loader/Loader";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
} from "@material-ui/core";
// import Pagination from '@material-ui/Pagination';
import { Pagination, PaginationItem } from "@material-ui/lab";
import sortIcon from '../../../assets/sort.svg';
// styles
import useStyles from "./styles";
import usePagination from "./Pagination";
export default function TableDesign({
  tableHeadings,tableHeadColor,
  filterHandler = () => {},
  pagination = null,
  isLoader = false,
  button = null,
  filterOption = null,
  setFilterOption = () => {},
  ...props
}) {
  console.log("pagination", pagination);
  var classes = useStyles();
  const { title = "", children } = props;
  const handlePageChange = (page) => {
    setFilterOption({
      ...filterOption,
      page: page,
    });
  };

  const paginationContainer = () => {
    let count = 1;

    const paginationData = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
      paginationData.push(
        <PaginationItem
          onClick={() =>
            setFilterOption({
              ...filterOption,
              page: i,
            })
          }
          active={i === pagination?.currentPage}
          key={i}
          type="page"
        >
          {i}
        </PaginationItem>,
      );
    }

    return (
      <Pagination
        style={{ marginTop: "10px", float: "right", marginBottom: "10px" }}
        color="primary"
        variant="outlined"
        shape="rounded"
        count={pagination?.totalPages}
        page={pagination?.currentPage}
        onChange={(event, page) =>
          setFilterOption({
            ...filterOption,
            page: page,
          })
        }
        renderItem={(item) => (
          <PaginationItem
            component="button"
            {...item}
            onClick={() =>
              setFilterOption({
                ...filterOption,
                page: item.page,
              })
            }
          />
        )}
      />
    );
  };
  const tableHeadStyle = {
    background: tableHeadColor ? tableHeadColor : '#EBFFFB',
  };
  return (
    <>
      {isLoader && <Loader />}

      <Table elevation={0} component={Paper} className="mb-2" size="small">
        <TableHead style={tableHeadStyle}>
          <TableRow>
            {tableHeadings.map((heading, i) => (
              <TableCell
                key={i}
                className={classes.tableHeadCell}
                align={heading?.align ? heading?.align : "center"}
                onClick={(e) => {
                  if (heading?.sort) {
                    setFilterOption({
                      ...filterOption,
                      orderBy: heading?.sort,
                      orderIn: filterOption?.orderIn === 1 ? -1 : 1,
                    });
                    e.target.parentElement.childNodes.forEach((n) =>
                      n.classList.remove("active"),
                    );
                    e.target.classList.add("active");
                  }
                }}
              >
               <> {heading.label} {heading.sort && <img style={{cursor:'pointer',height:'auto'}} src={sortIcon} alt="sort-icon"/>}</>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      {/* <Pagination
        count={count}
        size="large"
        page={pagination?.page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      /> */}
      {/* {pagination && paginationContainer()} */}
      {pagination && (
        <Pagination
          style={{ marginTop: "10px", float: "right", marginBottom: "10px" }}
          color="primary"
          variant="outlined"
          shape="rounded"
          count={pagination.totalPages}
          page={pagination.currentPage}
          onChange={(event, page) => handlePageChange(page)}
          renderItem={(item) => (
            <PaginationItem
              component="button"
              {...item}
              onClick={() => handlePageChange(item.page)}
            />
          )}
          // boundaryCount={2}
          // siblingCount={2}
          // disabled={!pagination.hasPreviousPage && !pagination.hasNextPage}
          // hidePrevButton={!pagination.hasPreviousPage}
          // hideNextButton={!pagination.hasNextPage}
          // showFirstButton={pagination.currentPage !== 1}
          // showLastButton={pagination.currentPage !== pagination.totalPages}
          showFirstButton={true}
          showLastButton={true}
        />
      )}
    </>
  );
}
