import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { withRouter, useNavigate, useParams } from "react-router-dom";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";

import lock from "./Lock.svg";

import arrow from "./arrow.png";
import expireLink from "../../../assets/icons/checked.png";
import { useDispatch, useSelector } from "react-redux";
// api
import Validation from "../../hooks/Validation";
import { resetPassword } from "../../store/actions/adminAction";
import { useToasts } from "react-toast-notifications";
import Loader from "../../../component/common/loader/Loader";
import { getOrgData } from "../../../store/actions/reviewsAction";
const formElements = [
  {
    key: "newPassword",
    label: "Password",
    placeHolder: "Enter password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter confirm password",
    type: "password",
  },
];
function Reset() {
  var classes = useStyles();
  const { token } = useParams();
  const [orgDetails, setOrgDetails] = useState(null);
  const [passwordDetail, setPasswordDetail] = useState({
    newPassword: null,
    confirmPassword: null,
  });
  const [eyes, setEyes] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [checkLinkExpired, setCheckLinkExpired] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const navigate = useNavigate();
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation(passwordDetail);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);

  const {
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    if (token) {
      dispatch(
        resetPassword({
          token,
        }),
      ).then((payload) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.payload?.data?.success) {
          setCheckLinkExpired(false);
        } else {
          setCheckLinkExpired(true);
        }
      });
    }
  }, [token]);
  const submitHandler = (e) => {
    console.log("actioncalled");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    console.log("actioncalled 58", isActionCalled);
    if (!isError() && error !== null && isActionCalled) {
      console.log("actioncalled 57", isActionCalled);
      const { newPassword, ...rest } = passwordDetail;
      dispatch(
        resetPassword({
          // ...rest,
          password: newPassword,
          token,
        }),
      ).then((payload) => {
        console.log("payload-", payload);
        // setIsShow(true);
        if (payload?.payload?.data?.success) {
          const toastHeight =
            payload?.payload?.data?.message.length > 35 ? "auto" : "34px";
          setSuccessMessage(payload?.payload?.message);
          addToast(payload?.payload?.data?.message, {
            appearance: "success",
            autoDismiss: true,
            style: { height: toastHeight },
          });
        } else {
          const toastHeight =
            payload?.payload?.data?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.payload?.message);
          addToast(payload?.payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });

          setSuccessMessage(null);
        }
      });
      setIsActionCalled(false);
    } else {
      console.log("actioncalled 76 else", isActionCalled);
      setSuccessMessage(null);
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  const hideHandlerRes = () => {
    setIsShow(false);
    if (successMessage) {
      navigate("/");
    } else {
      setSuccessMessage(null);
    }
  };

  return (
    <Grid className={classes.container}>
      <div
        className={classes.logotypeContainer}
        style={{ color: "#fff", textAlign: "center" }}
      >
        <span>{orgDetails?.name}</span>
      </div>
      {isLoader && <Loader />}
      {checkLinkExpired ? (
        <center style={{ marginTop: "24px" }}>
          <img
            style={{ height: "64px", marginTop: "24px" }}
            src={expireLink}
            alt="expire link"
          />
          <div style={{ textAlign: "center", marginTop: "16px" }}>
            <Typography
              style={{
                color: "#03997B",
                fontFamily: "Sanchez",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Link has expired.
              <br />
              <span style={{ fontSize: "26px" }}>
                You can close this page now.
              </span>
            </Typography>
          </div>
        </center>
      ) : (
        <div className={classes.formContainer} style={{ width: "100%" }}>
          <center>
            <Typography
              className={classes.subGreeting}
              style={{ color: "#03997B", fontFamily: "Sanchez" }}
            >
              <Box sx={{ textAlign: "center", m: 1, fontSize: "28px" }}>
                Reset your password
              </Box>
            </Typography>
            <Typography
              className={classes.greeting}
              style={{ color: "#03997B", fontFamily: "Sanchez" }}
            >
              <Box sx={{ textAlign: "center", m: 1 }}>
                Please choose your new password.
              </Box>
            </Typography>
          </center>
          <div
            elevation={1}
            className={classes.form}
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              width: "480px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <React.Fragment>
              {/* <Box sx={{ textAlign: "center", m: 1 }}>
              <img src={lock} alt="image"/>
            </Box> */}

              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  &nbsp;
                </Typography>
              </Fade>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Typography variant="h6" className={classes.txtHeading}>
                      {element.label} <span className="required">*</span>
                    </Typography>
                    <TextField
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputProps={{
                        classes: {
                          border: classes.textFieldline,
                          input: classes.textField,
                        },
                      }}
                      name={element.key}
                      type={eyes[element.key] ? "text" : element.type}
                      placeholder={element.placeHolder}
                      value={passwordDetail && passwordDetail[element.key]}
                      onChange={(e) => {
                        setPasswordDetail({
                          ...passwordDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                      onKeyUp={(e) => {
                        checkValidator(element.key);
                      }}
                      isInvalid={
                        error &&
                        error !== null &&
                        typeof error[element.key] !== "object" &&
                        error[element.key]
                      }
                    />
                    <div
                      className={classes.eyeIcon}
                      onClick={(e) =>
                        setEyes({
                          ...eyes,
                          [element.key]: !eyes[element.key],
                        })
                      }
                    >
                      {eyes[element.key] ? (
                        <Icons.Visibility />
                      ) : (
                        <Icons.VisibilityOff />
                      )}
                    </div>
                    <Fade in={error} type="invalid">
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error &&
                          error !== null &&
                          typeof error[element.key] !== "object" &&
                          error[element.key]}
                      </Typography>
                    </Fade>

                    {element.key === "newPassword" && (
                      <Typography
                        className={`passwordRules ${
                          error && passwordDetail[element.key] && "valid"
                        }`}
                      >
                        <h4>Your password must have:</h4>
                        <table className={classes.warningMessage}>
                          <tr style={{ display: "flex", alignItems: "center" }}>
                            <td>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.lowercase &&
                                      error[element.key]?.lowercase
                                      ? "invalid"
                                      : "valid"
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.lowercase ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one lowercase letter
                                </span>
                              </p>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <p
                                style={{
                                  margin: "0px",
                                  marginLeft: "12px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.uppercase &&
                                      error[element.key]?.uppercase
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.uppercase ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one UPPERCASE letter
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ display: "flex", alignItems: "center" }}>
                            <td>
                              <p
                                style={{
                                  margin: "0px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.number &&
                                      error[element.key]?.number
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.number ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one number
                                </span>
                              </p>
                            </td>
                            <td>
                              <p
                                style={{
                                  margin: "0px",
                                  marginLeft: "60px",
                                  marginTop: "0px",
                                  textAlign: "center",
                                }}
                                className={
                                  passwordDetail[element.key]
                                    ? error &&
                                      error?.newPassword?.special &&
                                      error[element.key]?.special
                                      ? "invalid"
                                      : ""
                                    : ""
                                }
                              >
                                {!passwordDetail?.newPassword ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : error?.newPassword?.special ? (
                                  <Icons.Cancel
                                    style={{ color: "red", width: "16px" }}
                                  />
                                ) : (
                                  <Icons.CheckCircle
                                    style={{ color: "green", width: "16px" }}
                                  />
                                )}{" "}
                                <span
                                  style={{
                                    marginTop: "0px",
                                    marginLeft: "4px",
                                    color: "#999999",
                                  }}
                                >
                                  At least one special character
                                </span>
                              </p>
                            </td>
                          </tr>
                          <p
                            style={{ marginTop: "0px" }}
                            className={
                              passwordDetail[element.key]
                                ? error &&
                                  error?.newPassword?.length &&
                                  error[element.key]?.length
                                  ? "invalid"
                                  : ""
                                : ""
                            }
                          >
                            {!passwordDetail?.newPassword ? (
                              <Icons.Cancel
                                style={{ color: "red", width: "16px" }}
                              />
                            ) : error?.newPassword?.length ? (
                              <Icons.Cancel
                                style={{ color: "red", width: "16px" }}
                              />
                            ) : (
                              <Icons.CheckCircle
                                style={{ color: "green", width: "16px" }}
                              />
                            )}{" "}
                            <span
                              style={{
                                marginTop: "0px",
                                marginLeft: "4px",
                                color: "#999999",
                              }}
                            >
                              Minimum 8 characters
                            </span>
                          </p>
                        </table>
                      </Typography>
                    )}
                  </>
                );
              })}

              <div className={classes.creatingButtonContainer}>
                <Button
                  onClick={(e) => submitHandler(e)}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.createAccountButton}
                >
                  Reset Password
                </Button>
              </div>
              <Box
                style={{ marginTop: "10px" }}
                sx={{ textAlign: "center", m: 2 }}
              >
                <Link href="/" className={classes.Link} underline="none">
                  <img src={arrow} alt="img" />
                  &nbsp;&nbsp; Back to login
                </Link>
              </Box>
            </React.Fragment>
            {/* <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage2}>
              {apiError?.message}
            </Typography>
          </Fade> */}
          </div>
        </div>
      )}
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          updateMsg: successMessage,
          title: "Reset Password",
          errorRes: errorApiMessage,
        }}
      />
    </Grid>
  );
}

export default Reset;
