import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";

import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  InputBase,
  Typography,
} from "@material-ui/core";
import {
  Clear as ClearIcon,
  MoreVert as MoreIcon,
  Search as SearchIcon,
} from "@material-ui/icons";

import TableDesign from "../../../component/common/table/TableDesign";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";

import Ongoing from "./Ongoing";
import OngoingUser from "../../../user-admin/components/Ehiia/Ongoing";
// styles
import useStyles from "./styles";
import ManageEhiia from "./ManageEhiia";
import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
// API data imports
import { fetchOngoingProjectList } from "../../store/actions/ehiiaAction";
import Complete from "./Complete";
import CompleteUser from "../../../user-admin/components/Ehiia/Complete";
import classNames from "classnames";
import Ehiia from "../../../user-admin/pages/ehiia/Ehiia";
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function EhiiaList() {
  var classes = useStyles();
  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ID",
      sort: "projectUID",
    },
    {
      label: "Title",
      sort: "introduction.projectTitle",
    },
    {
      label: "Lead",
    },
    {
      label: "Line Manager",
    },
    {
      label: "Service Manager",
    },
    {
      label: "Sub-Org",
    },
    {
      label: "Created on",
    },
    {
      label: "Last Updated",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [mainActiveTabId, setMainActiveTabId] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/ehiia-edit`);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
  };

  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "introduction.projectTitle",
    orderIn: -1,
  });

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  // local
  var [activeTabId, setActiveTabId] = useState(0);
  const handleOnSubmit = () => {
    navigate(`/admin/add-ehiia`);
  };
  return (
    <>
      <ManageEhiia open={open} onHide={hideHandle} title={"Add Ehiia"} />
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          EHIA Module
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
            {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={handleOnSubmit}
          >
            Add New EHIA Case
          </Button>
        </div>
      </div>
      <Tabs
        TabIndicatorProps={{
          style: { background: "none" },
        }}
        indicatorColor="none"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={mainActiveTabId}
        onChange={(e, id) => setMainActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab label="My EHIA Projects" classes={{ root: classes.tabBg }} />
        <Tab label="Other EHIA Projects" classes={{ root: classes.tabBg }} />
      </Tabs>
      {mainActiveTabId === 0 && (
      <>
      <Tabs
        TabIndicatorProps={{
          style: { background: "#0445AF" },
        }}
        textColor="primary"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab label="Ongoing Projects" classes={{ root: classes.tab }} />
        <Tab label="Completed Projects" classes={{ root: classes.tab }} />
      </Tabs>

      {activeTabId === 0 && (
        <OngoingUser
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          filterHandler={filterHandler}
        />
      )}
      {activeTabId === 1 && (
        <CompleteUser
          filterOption={filterOption}
          setFilterOption={setFilterOption}
          filterHandler={filterHandler}
        />
      )}
    </>
      )}
      {mainActiveTabId === 1 && (
        <>
          <Tabs
            TabIndicatorProps={{
              style: { background: "#0445AF" },
            }}
            textColor="primary"
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            className={classes.iconsBar}
          >
            <Tab label="Ongoing Projects" classes={{ root: classes.tab }} />
            <Tab label="Completed Projects" classes={{ root: classes.tab }} />
          </Tabs>

          {activeTabId === 0 && (
            <Ongoing
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              filterHandler={filterHandler}
            />
          )}
          {activeTabId === 1 && (
            <Complete
              filterOption={filterOption}
              setFilterOption={setFilterOption}
              filterHandler={filterHandler}
            />
          )}
        </>
      )}
    </>
  );
}
