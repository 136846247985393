import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Typography, TextField, Button, Fade } from "@material-ui/core";
import moment from "moment";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import Validation from "../../../hooks/EhiiaValidation";
import Loader from "../../../../component/common/loader/Loader";
import {
  getEhiiaVersionListByVersionId,
  createUpdateEhiiaVersion,
} from "../../../store/actions/ehiiasAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";

// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function EhiiaEdit() {
  const { versionId } = useParams();
  console.log("versionId", versionId);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const inputRef = useRef();
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange1 = (event) => {
    setValue(event.target.value);
  };
  const [ehiiaDetail, setEhiiaDetail] = useState({
    versionId: null,
    name: null,
    description: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
    status: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      versionId: ehiiaDetail?.versionId,
      name: ehiiaDetail?.name,
      description: ehiiaDetail?.description,
    });
  const dispatch = useDispatch();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.ehiias);

  useEffect(() => {
    dispatch(getEhiiaVersionListByVersionId({ versionId })).then(
      ({ payload }) => {
        console.log("data", payload);
        if (payload?.success) {
          setEhiiaDetail(payload?.data?.version);
        }
        console.log("ehiiaDetail", ehiiaDetail);
      },
    );
  }, []);
  // record updated
  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getEhiiaVersionListByVersionId({ versionId })).then(
        ({ payload }) => setEhiiaDetail(payload?.data?.version),
      );
    }
  }, [isRecordUpdated]);
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    let formData = {
      versionId: ehiiaDetail?.versionId,
      name: ehiiaDetail?.name,
      description: ehiiaDetail?.description,
    };
    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(createUpdateEhiiaVersion(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          hideHandler();
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          // setIsShow(true);
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
          // setIsShow(true);
        }
      });
      setIsActionCalled(false);
    }
    console.log("error-", error);
    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandler = () => {
    resetAllValidator();
  };
  //
  const hideHandlerRes = () => {
    setIsShow(false);
    setErrorApiMessage(null);
    setSuccessMessage(null);
  };
  const cancelHandler = () => {
    navigate(`/super-admin/ehiia-details/${versionId}`);
  };
  return (
    <>
    
      {isLoader && <Loader />}
      <PageTitle
        subtitle={ehiiaDetail?.name}
        urlLink={`/super-admin/ehiia-details/${versionId}`}
        title="> Edit"
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Version ID</Typography>
            <TextField
              size="small"
              name="versionId"
              className={classes.txtForm}
              fullWidth
              disabled
              value={ehiiaDetail && ehiiaDetail?.versionId}
              placeholder="Enter version id"
              variant="outlined"
              onChange={async (e) => {
                setEhiiaDetail({
                  ...ehiiaDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.versionId}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.versionId}
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Version Name
            </Typography>
            <TextField
              name="name"
              size="small"
              className={classes.txtForm}
              fullWidth
              value={
                ehiiaDetail && ehiiaDetail?.name === null
                  ? ""
                  : ehiiaDetail && ehiiaDetail?.name
              }
              placeholder="Enter version name"
              variant="outlined"
              onChange={async (e) => {
                setEhiiaDetail({
                  ...ehiiaDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.name}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.name}
              </Typography>
            </Fade>
          </Grid>
         
          <Grid item xs={12} md={12} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Description:
            </Typography>
            <TextField
              name="description"
              size="small"
              className={classes.txtForm}
              value={ehiiaDetail && ehiiaDetail?.description}
              fullWidth
              placeholder="Type here...."
              variant="outlined"
              multiline
              rows={4}
              onChange={async (e) => {
                setEhiiaDetail({
                  ...ehiiaDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.description}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.description}
              </Typography>
            </Fade>
          </Grid>
        </Grid>
      </Widget>
      <div className={classes.footerBox}>
        <Button
          onClick={(e) => cancelHandler(e)}
          variant="outlined"
          size="large"
          className={classes.leftButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
          onClick={(e) => submitHandler(e)}
        >
          Update
        </Button>
      </div>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Update EHIA Module",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
