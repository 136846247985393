import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

import { Grid,Typography } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,  InputBase,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import ManageClient from "./ManageAdmin";
import TableDesign from "../../../component/common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
import { resetPopup } from "../../store/reducerSlice/ehiiaversionsClient";
import { fetchProjectRepoList } from "../../store/actions/ehiiaAction";
import RemoveRepositoryPopup from "./RemoveRepositoryPopup";
import classNames from "classnames";
import Loader from "../../../component/common/loader/Loader";
export default function ProjectList() {
  const navigate = useNavigate();
  const [selectRow, setSelectRow] = useState(null);
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [message, setMessage] = useState(null);
  const searchInputRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/admin/project-details/:projectId`);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ID",
      sort:'projectUID'
      // align: "left !important",
    },
    {
      label: "Title",
      // align: "left",
      sort: "introduction.projectTitle"
    },
    {
      label: "Lead",
      // align: "left",
    },
    {
      label: "Sub-Org",
    },
    {
      label: "Date Completed",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  //
  const [value, setValue] = React.useState("");
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [repositoryModalShow, setRepositoryModalShow] = useState(false);
  const [projectIdRec, setProjectIdRec] = useState(null);
  const debounce = (func, timeout = 500) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "introduction",
    orderIn: -1,
  });
  // / Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.ehiiaversionsClient;
  });

  const page = pagination?.currentPage;

  const cc = list || filtered;
  useEffect(() => {
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);

    console.log("filterOption", filterOption);
    dispatch(fetchProjectRepoList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  const removeRepoEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    dispatch(resetPopup());
    console.log("rec", rec);
    setRepositoryModalShow(true);
    setProjectIdRec(rec?.projectId);
    setShowResult(true);
   
    
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
    
      <RemoveRepositoryPopup
        onHide={hideHandle}
        setRepositoryModalShow={setRepositoryModalShow}
        repositoryModalShow={repositoryModalShow}
        projectIdRec={projectIdRec}
      />
      <ManageClient
        open={open}
        onHide={hideHandle}
        title={"Add Client"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />
      {/* <PageTitle title="Project Repositories" search="true" /> */}
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          {/* <Link
          href="manage-client"
          className={classes.typoGray}
          underline="none"
        >
          {props.subtitle}{" "}
        </Link>{" "} */}
          Project Repositories
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
             {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";                  
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

        
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {/* {isLoader && <Loader />} */}
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="center"
                          onClick={() =>
                            navigate(
                              `/admin/project-details/${row.projectId}`,
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.projectUID}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.introduction?.projectTitle}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.introduction?.projectLead}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.introduction?.organization}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {formatedDate(row.updatedAt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.isCompleted ? (
                            <span
                              style={{
                                color: "#10940D",
                                fontWeight: 500,
                              }}
                            >
                              Complete
                            </span>
                          ) : (
                            <span style={{ color: "#F8A80C" }}>
                              In-Progress
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() =>
                                navigate(
                                  `/admin/project-details/${selectRow?.projectId}`,
                                )
                              }
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Details
                            </MenuItem>
                            {/* <MenuItem
                              className={classes.menuItem}
                              onClick={handleEdit}
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Report
                            </MenuItem> */}
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => removeRepoEventHandler(selectRow)}
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Remove from Repositories
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      {/* {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )} */}
    </>
  );
}
