import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

import Widget from "../../../component/Widget";
import PageTitle from "../../../component/PageTitle";
import { Typography } from "../../../component/Wrappers";
import { getDashboard } from "../../store/actions/dashboardsAction";
import Loader from "../../../component/common/loader/Loader";
import cubes from "./cubes.svg";

export default function Dashboard() {
  var classes = useStyles();
  var theme = useTheme();

  const dispatch = useDispatch();
  const {
    dashboards,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.dashboards;
  });
  console.log("dashboards", dashboards);
  // Extracting data from the response JSON
  const stats = dashboards?.data?.stats || [];

  useEffect(() => {
    console.log("dashboards", dashboards);

    dispatch(getDashboard());
  }, [isRecordUpdated]);

  return (
    <>
    {isLoader && <Loader />}
      <PageTitle title="Dashboard" />
      <Grid container spacing={4}>
        {stats.map((stat, index) => (
          <Grid item lg={3} md={8} sm={6} xs={12} key={index}>
            <Widget
              title={stat.value}
              weight="medium"
              upperTitle
              className={classes.card}
              bodyClass={classes.fullHeightBody}
            >
              <img className={classes.icons} src={stat.icon} alt={stat.title} />
              <div className={classes.progressSection}>
                <Typography className={classes.progressSectionTitle}>
                  {stat.title}
                </Typography>
              </div>
            </Widget>
          </Grid>
        ))}
      </Grid>
    </>
  );
}


