import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import Loader from "../../../component/common/loader/Loader";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { sendActivationLink } from "../../store/actions/subAdminAction";
import { Alert } from "@material-ui/lab";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ActivationLinkClientPopup({
  activationLinkRec,
  toggleActivateStatus,
  onHide,
  activationLinkModalShow,
  setActivationLinkModalShow,
}) {
  //
  console.log("toggleActivateStatus", toggleActivateStatus);
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.subadmins);
  const submitHandler = (mode) => {
    setIsAction(false);
    return dispatch(
      sendActivationLink({
        adminId: activationLinkRec,
      }),
    ).then((payload) => {
      if (payload?.payload?.success) {
        const toastHeight =
          payload?.payload?.message.length > 35 ? "auto" : "34px";
        // return;
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: { height: toastHeight },
        });
      } else {
        const toastHeight = payload?.payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }
      setIsDone(true);
      onHide();
      setActivationLinkModalShow(false);
    });
  };
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setActivationLinkModalShow(false);
    }
  }, [isRecordUpdated]);
  var classes = useStyles();

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={activationLinkModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {/* {apiError ? (
            <>
             <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              Ok
            </Button>

           
            </>
          ):( */}
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={(e) => onHide()}
              >
                No
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Yes
              </Button>
            </>
            {/* )} */}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center>
              <img src={alertIcon} alt="image" />
              {isLoader && <Loader />}
              {/* {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
                </Alert>
            ) : ( */}
              <Typography className={classes.formActiveHeading}>
                {toggleActivateStatus ? (
                  <>
                    Are you sure you want to resend reset password link to this
                    admin?
                  </>
                ) : (
                  <>
                    Are you sure you want to resend activation link to this
                    admin?
                  </>
                )}
              </Typography>
              {/* )} */}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
