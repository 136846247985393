import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
// styles
import useStyles from "./styles";
import Loader from "../../../../component/common/loader/Loader";
export default function AlertConfirmation({
  onHide,
  alertModalShow,
  setAlertModalShow,
  setIsActionCalled,
}) {
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setIsAction(true);
    setAlertModalShow(false);
  };
  const hideHandle = () => {
    setAlertModalShow(false);
  };

  return (
    <>
      <Popup
        onHide={hideHandle}
        open={alertModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                No
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
                evalution={0}
              >
                Yes
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />

            <Typography className={classes.formActiveHeading}>
              If you edit this question, all your next questions will be reset
              and you will have to answer again.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
