import { createSlice } from '@reduxjs/toolkit'
import { 
  createUpdatePortalUser,
  updatePortalUser, 
  fetchPortalUserList, 
  deletePortalUser,
  toggleActivation, 
  sendActivationLink, 
  getPortalUserByUserId  } from '../actions/portalUserAction';

export const portalusers = createSlice({
  name: 'portalusers',
  initialState: {
    portalusers: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
    // isRecordAdded: false,
    // isRecordUpdated: false,
    // isRecordLoaded:false,
  },
  reducers: {
    userReset: state => {
      // state.users= null;
      state.error = null;
    },
    resetPopup: state => {
      state.isLoader= false;
      // state.isRecordAdded=false;
      state.isRecordUpdated= false;
      state.error=null;
    },
  },
  extraReducers: {
    [fetchPortalUserList.pending]: (state, payload) => {
      state.error=null;
      state.isLoader= true;
    },
    [fetchPortalUserList.fulfilled]: (state, {payload}) => {
      console.log("payload",payload);
      state.error=null;
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false
      }
    },
    [fetchPortalUserList.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isLoader= false;
    },
    // create
    [createUpdatePortalUser.pending]: (state, payload) => {
      // console.log("payload-users",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [createUpdatePortalUser.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [createUpdatePortalUser.rejected]: (state, {payload}) => {
      console.log("payload-SubClients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    // update
    [updatePortalUser.pending]: (state, payload) => {
      // console.log("payload-SubClients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [updatePortalUser.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [updatePortalUser.rejected]: (state, {payload}) => {
      console.log("payload-SubClients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    [deletePortalUser.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [deletePortalUser.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [deletePortalUser.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [toggleActivation.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [toggleActivation.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [sendActivationLink.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [sendActivationLink.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [sendActivationLink.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
   
    [getPortalUserByUserId.pending]: (state, payload) => {
      state.error=null;
      state.isRecordLoaded= false;
      state.isLoader= true;
      
    },
    [getPortalUserByUserId.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordLoaded= true
      state.isLoader= false;
    },
    [getPortalUserByUserId.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordLoaded= false;
      state.isLoader= false;
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup} = portalusers.actions

export default portalusers.reducer