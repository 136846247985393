import { createSlice } from "@reduxjs/toolkit";
import {
  fetchOngoingProjectList,
  fetchCompleteProjectList,
  getEhiiaProjectByProjectId,fetchProjectRepoList,toggleProjectRepoList

} from "../actions/ehiiaAction";

export const ehiiaversionsClient = createSlice({
  name: "ehiiaversionsClient",
  initialState: {
    ehiiaversionsClient: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchOngoingProjectList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchOngoingProjectList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log("payload", payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchOngoingProjectList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;

    },
    [fetchCompleteProjectList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
      // state.isRecordUpdated = false;
    },
    [fetchCompleteProjectList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
        // isRecordUpdated: true,
      };
    },
    [fetchCompleteProjectList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
      // state.isRecordUpdated = false;
    },

    [fetchProjectRepoList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;

    },
    [fetchProjectRepoList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchProjectRepoList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },

    [toggleProjectRepoList.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [toggleProjectRepoList.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordLoaded = true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [toggleProjectRepoList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

    [getEhiiaProjectByProjectId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [getEhiiaProjectByProjectId.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordLoaded = true;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [getEhiiaProjectByProjectId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
  
   
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
  ehiiaversionsClient.actions;

export default ehiiaversionsClient.reducer;
