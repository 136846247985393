import React from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import notes from "./noteEdit.svg";
import useStyles from "../styles";
export default function AssessmentTwo() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />

        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q14. How large is your project?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">one ward or up to 10 Patients</MenuItem>
              <MenuItem value="2">
                More than one ward orup to 50 patients
              </MenuItem>
              <MenuItem value="3">One Specialty or up to 100 patients</MenuItem>
              <MenuItem value="4">
                More than one specialty or site or up to 500 patients
              </MenuItem>
              <MenuItem value="5">Trust-Wide</MenuItem>
              <MenuItem value="6">System-wide</MenuItem>
              <MenuItem value="7">All Patients</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
