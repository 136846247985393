import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  Fade,
} from "@material-ui/core";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";

import {
  getSubAdminBySubAdminId,
  updateSubAdmin,
} from "../../../store/actions/subAdminAction";

import { resetPopup } from "../../../store/reducerSlice/subadmins";
import AdminValidation from "../../../hooks/AdminValidation";
import moment from "moment";
// styles
import useStyles from "./styles";
import ToggleActivationPopup from "../ToggleActivationPopup";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import { useToasts } from "react-toast-notifications";
import { difference as _difference } from "lodash";
export default function AdminEdit() {
  const navigate = useNavigate();
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  const { adminId } = useParams();
  var classes = useStyles();
  const { addToast } = useToasts();
  const [datevalue, setDateValue] = React.useState(null);
  const [organizationName, setOrganizationName] = React.useState([]);
  const [isShow, setIsShow] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [value, setValue] = React.useState("");
  const [orgDataValue, setOrgDataValue] = React.useState([]);

  const [orgOption, setOrgOption] = useState(null);
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "subOrg") {
      setOrgDataValue(value);
    } else {
      setClientDetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);
  const [clientDetail, setClientDetail] = useState(null);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    AdminValidation({
      name: clientDetail?.name,
      email: clientDetail?.email,
      subOrg: orgDataValue,
    });

  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  useEffect(() => {
    console.log("adminDetail", adminDetail);
    console.log(
      "adminDetail.data.subOrg.ehiiaVersion",
      adminDetail?.data?.subOrg?.ehiiaVersion,
    );
    const subOrgOptions = adminDetail?.data?.subOrg?.ehiiaVersion || [];
    if (Array.isArray(subOrgOptions)) {
      console.log("subOrgOptions", subOrgOptions);

      setOrgOption(subOrgOptions);
    }
  }, [adminDetail]);
  useEffect(() => {
    dispatch(getSubAdminBySubAdminId({ adminId })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        const clientDetails = payload?.data?.data?.user;
        setClientDetail(clientDetails); // Update clientDetail state with clientDetails

        // Update other state variables if needed
        setOrgDataValue(clientDetails?.subOrg?.ehiiaVersion);
      }
    });
  }, []);

  const [diffSubOrg, setDiffSubOrg] = useState([]);
  const [diffSubNewOrg, setDiffSubNewOrg] = useState([]);
  useEffect(() => {
    console.log("clientDetail", clientDetail);
    console.log("orgDataValue",orgDataValue);
    console.log("orgOption",orgOption);
    if (clientDetail || orgDataValue) {
      const newDiffSubOrg = _difference(
        clientDetail?.subOrg?.ehiiaVersion || orgDataValue || [],
      );

      setDiffSubOrg(newDiffSubOrg);
      
      const newDiffOrg = _difference(orgDataValue || [],orgOption || []);
      setDiffSubNewOrg(newDiffOrg);
    }
  }, [clientDetail, orgDataValue]);
  useEffect(() => {
    console.log("diffSubOrg", diffSubOrg);
    console.log("diffSubNewOrg", diffSubNewOrg);
  }, [diffSubOrg, diffSubNewOrg]);
  const hideHandle = () => {
    console.log("hidehandle called");

    setToggleActivateModalShow(false);
  };
  const hideHandler = () => {
    resetAllValidator();
  };
  //

  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
    setIsShow(false);
    setErrorApiMessage(null);
    setSuccessMessage(null);
  };
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    const formData = {
      adminId: adminId,
      name: clientDetail?.name,
      email: clientDetail?.email,
      subOrg: {
        ehiiaVersion: orgDataValue,
      },
    };
    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateSubAdmin(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          // setClientDetail(null);
        } else {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setErrorApiMessage(payload?.message);

          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
        }
      });
      setIsActionCalled(false);
    }
    console.log("error-", error);
    setIsActionCalled(false);
  }, [isActionCalled]);

  const cancelHandler = () => {
    navigate(`/admin/admin-details/${adminId}`);
  };
  return (
    <>
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <PageTitle
        subtitle="Manage Admins >"
        urlLink="/admin/manage-admin"
        title="Edit"
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Admin Name:</Typography>
            <TextField
              name="name"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter first name"
              variant="outlined"
              value={clientDetail && clientDetail?.name}
              onChange={async (e) => {
                setClientDetail({
                  ...clientDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.name}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.name}
              </Typography>
            </Fade>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Email Id:</Typography>
            <TextField
              disabled
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Enter email id"
              variant="outlined"
              value={clientDetail && clientDetail?.email}
              onChange={async (e) => {
                setClientDetail({
                  ...clientDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.email}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.email}
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status:</Typography>
            <TextField
              disabled
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder=""
              variant="outlined"
              value={
                clientDetail?.isActive
                  ? clientDetail?.isActivated
                    ? "Active"
                    : "Pending"
                  : "Inactive"
              }
              InputProps={{
                style: {
                  color: clientDetail?.isActive
                    ? clientDetail?.isActivated
                      ? "#10940D" // Active - Green color
                      : "#F8A80C" // Pending - Orange color
                    : "red", // Inactive - Red color
                  fontWeight: 500,
                },
              }}
            />

            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.status}
              </Typography>
            </Fade>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created on:</Typography>
            <TextField
              disabled
              type="date"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder="Select date"
              variant="outlined"
              value={
                clientDetail &&
                moment(clientDetail?.createdAt).format("YYYY-MM-DD")
              }
              onChange={async (e) => {
                setClientDetail({
                  ...clientDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.createdAt}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.createdAt}
              </Typography>
            </Fade>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Sub-Organisations:
            </Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                disabled={diffSubNewOrg.length>0}
                name="subOrg"
                className={classes.selectDropdown}
                value={orgDataValue}
                onChange={handleChange}
                renderValue={(selected) =>
                  selected
                    .map((value) => (diffSubOrg.includes(value) ? value : ""))
                    .join(", ")
                }
                multiple
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {orgOption && orgOption.length > 0 ? (
                  orgOption.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Checkbox checked={orgDataValue.indexOf(item) > -1} />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No sub-organizations available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Widget>
      <div className={classes.footerBox}>
        <Button
          variant="outlined"
          size="large"
          className={classes.leftButton}
          onClick={(e) => cancelHandler(e)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
          onClick={(e) => submitHandler(e)}
        >
          Update
        </Button>
      </div>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Update Admin Details",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
