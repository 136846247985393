import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  // tabs
  boxDiv:{
    marginBottom:theme.spacing(4),
  },
  iconsBar: {
    marginBottom: theme.spacing(4),
    borderBottom: "1px solid ",
    borderBottomColor: theme.palette.text.hint + "80",
    background:'#fff',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginTop:theme.spacing(4),
    
  },
  tab: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
    "&:hover": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&:active": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#03997B'
    }
  },
  // form
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    },
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  uploadIcon: {
    position: "absolute",
    marginTop: "-36px",
    marginLeft: "36px",
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#969DAB",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
    flexGrow: 1,
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  txtForm: {
    padding: "0px 4px",
  },
  textGroup: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  rightButton: {
    marginLeft: theme.spacing(2),
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },

  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#201F1E",
    fontSize: "16px",
    fontStyle: "normal",
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  formLinkHeading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#2A2338",
    fontSize: "14px",
    fontStyle: "normal",
  },
  formLinkHeadingValue: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#0445AF",
    fontSize: "15px",
    fontStyle: "normal",
  },
  cardHeading: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#2A2338",
    fontSize: "16px",
    fontStyle: "normal",
  },
}));
