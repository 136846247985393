import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../store/actions/portalUserAction";
import Loader from "../../../component/common/loader/Loader";
import { Alert } from "@material-ui/lab";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  console.log("userId", toggleActivateRec);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  console.log("toggleActivateStatus", toggleActivateStatus);
  const { isRecordUpdated,error: apiError, isLoader } = useSelector(
    (state) => state.portalusers,
  );
  const [open, setOpen] = useState(false);
  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        userId: toggleActivateRec,
        // status:toggleActivateStatus,
      }),
    ).then((payload) => {
      if (payload?.payload?.success) {
        // return;
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:'34px'
          },
        });
      }else{
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setIsDone(true);
      onHide();
      setToggleActivateModalShow(false);
    });
  };
  useEffect(() => {
    console.log("apiError", apiError);
  }, [apiError]);
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setToggleActivateModalShow(false);
    }
  }, [isRecordUpdated]);
  var classes = useStyles();

  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDone, setIsDone] = useState(false);


  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {apiError ? (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  Ok
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
              </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
                {toggleActivateStatus ? (
                  <>Are you sure, you want to deactivate this staff?</>
                ) : (
                  <>Are you sure, you want to activate this staff?</>
                )}
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
