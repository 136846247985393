export const manageClientSchema = {
    businessName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter business name",
            noLeadingSpace: "Business name cannot start with a space",
        }
    },
    clientName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter client name",
            noLeadingSpace: "Client name cannot start with a space",
        }
    },
    name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter organisation name",
            noLeadingSpace: "Organisation name cannot start with a space",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    clientOrgShortName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter organisation short name",
            noLeadingSpace: "Organisation short name cannot start with a space",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    subdomain: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter organisation short name",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    contactName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter contact name",
            noLeadingSpace: "Contact name cannot start with a space",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    contactPerson: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter contact name",
            noLeadingSpace: "Contact name cannot start with a space",
            atLeastOneAlpha: "At least one alphabet character is required",
        }
    },
    clientEmail: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    ehiiaVersionId: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select version",
        }
    },
    module: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select module",
        }
    },
    email: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    numPlans: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter no. of plans"
        }
    },
    currentPassword:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter current password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            }
        }
    },
    password: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            }
        }
    },
    oldPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter old password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            }
        }
    },
    newPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter new password",
            // length: 8,
            // lowercase: true,
            // uppercase: true,
            // special: true,
        }
    },
    confirmPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter confirm password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            },
            match: "Passwords in the two sections must match" //"Password should be match"
        }
    },
   
    firstName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter plan name"
        }
    },
    lastName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter plan name"
        }
    },
    description: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter description"
        }
    },
    dayZIndicator: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter Day-0 indicator name"
        }
    },
    minAge: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter min. time from Day-0"
        }
    },
    maxAge: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter max. time from Day-0"
        }
    },
    minGap: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter min. gap from previous event"
        }
    },
    subEvents: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter list of sub events"
        }
    },
    startDate: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter start date"
        }
    },
    endDate: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter end start"
        }
    },
    reportGroupType: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select report group type"
        }
    },
    practice: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a location"
        }
    },
    s3Key: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a file"
        }
    },
    file: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a file"
        }
    },
}