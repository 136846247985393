import React from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
} from "@material-ui/core";
// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
// styles
import { Alert } from "@material-ui/lab";
import useStyles from "./styles";
import closeIcon from "./closeIcon.png";
export default function Popup({ children, ...props }) {
  const { title } = props;
  //
  console.log("title", props.title);
  console.log("alert", props.alert);
  var classes = useStyles();

  return (
    <Dialog
      {...props}
      keepMounted
      disableEnforceFocus
      aria-describedby="alert-dialog-slide-description"
      title=""
    >
      {title && 
      <DialogTitle className={classes.popupTitle}>
        {title}
        <img
          onClick={props.onHide}
          src={closeIcon}
          className={classes.closeicons}
          alt="icon"
        />
      </DialogTitle>
      }
      <DialogContent>
        <DialogContentText className={classes.formBox}>
          {children}
        </DialogContentText>
      </DialogContent>
      {props.alert && props.alert.props.children ? (
        <p>
          <Alert severity="error">{props.alert}</Alert>
        </p>
      ) : (
        ""
      )}
      <DialogActions className={classes.modelFooter}>
        {props.footer || (
          <Button variant="outlined" size="large" onClick={props.onHide}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
