import React, { useState } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./styles";

import DataReviewOne from "./DataReviewPages/DataReviewOne";
import DataReviewTwo from "./DataReviewPages/DataReviewTwo";
import DataReviewThree from "./DataReviewPages/DataReviewThree";
import DataReviewFour from "./DataReviewPages/DataReviewFour";
export default function DataReview() {
  var classes = useStyles();
  var [activeComponent, setActiveComponent] = useState(0);
  const nextPage = () => {
    if (activeComponent < 3) {
      setActiveComponent(activeComponent + 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };
  const previewsPage = () => {
    if (activeComponent > 0) {
      setActiveComponent(activeComponent - 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };
  return (
    <>
      {activeComponent === 0 && <DataReviewOne />}
      {activeComponent === 1 && <DataReviewTwo />}
      {activeComponent === 2 && <DataReviewThree />}
      {activeComponent === 3 && <DataReviewFour />}
      <div className={classes.buttonGroup}>
        <Button
          onClick={previewsPage}
          className={classes.leftBluetButton}
          variant="contained"
          size="large"
        >
          Previews
        </Button>
        <Button
          className={classes.rightButton}
          onClick={nextPage}
          variant="contained"
          size="large"
          color="secondary"
        >
          Next
        </Button>
      </div>
    </>
  );
}
