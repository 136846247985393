import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
   
    formBox:{
        paddingLeft:theme.spacing(4),
        paddingRight:theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft:theme.spacing(0),
          paddingRight:theme.spacing(0),
          }
    },
    avatar:{
        width:"85.34px",
        height:"85.34px"
    },
    uploadIcon: {
        position: "absolute",
        marginTop: "-36px",
        marginLeft: "36px",
      },
    formHeading:{
        marginLeft:theme.spacing(0.5),
        // marginTop:theme.spacing(2),
        marginBottom:theme.spacing(0.5),
        fontFamily:'Poppins',
        fontSize:'14px !important',
        fontWeight:500,
        color:'#101010',
    },
    txtForm:{
        padding:"0px 4px"
    },
    modelFooter:{
        padding:theme.spacing(2),
        
    },
    errorMessage: {
        fontSize: "12px",
        color: "red",
        paddingLeft: "6px",
      },
    blueButton:{
        background:"#0078D4",
        color:"#fff",
        fontWeight:400,
        marginTop:theme.spacing(2),
        "&:hover": {
            background:"#0078D4",
        color:"#fff",
        }
    },
    gridBox:{
        // display:"flex",
        // justifyItems:"center",
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4)
    },
    btnGroup:{
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(2),
        
    },
    leftButton:{
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:400,
        textTransform:'none',
        height:'38px',
        borderRadius:'2px'
    },
    rightButton:{
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:500,
        marginLeft:theme.spacing(2),
        textTransform:'none',
        height:'38px',
        borderRadius:'2px'
    },
    widgetBox:{
        marginTop:theme.spacing(2)
    },
    passwordBox:{
        display:"flex",
    },
    radioGroup:{
        border:'1px solid #DADFEB',
        borderRadius:'5px',
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2)
    }
}));
