import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tabs, Tab, Paper, Button } from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";

// styles
import useStyles from "./styles";

import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
import ApproverTeam from "./ApproverTeam";
import EngagementTeam from "./EngagementTeam";
// API data imports
import { fetchApprovalList } from "../../store/actions/approvalAction";
import { resetPopup } from "../../store/reducerSlice/approvals";
import TeamList from "./TeamList";
import ManageTeam from "./ManageTeam";
import Loader from "../../../component/common/loader/Loader";
import ManageProject from "./ManageProject";
import ProjectType from "./ProjectType";
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function ApprovalList() {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  const [activeParentTabId, setActiveParentTabId] = useState(0);
  const [activeEnabled, setActiveEnabled] = useState(false);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    navigate(`/client-details`);
  };
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "createdAt",
    orderIn: -1,
  });

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    team,
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.approvals;
  });

  const page = pagination?.currentPage;

  const cc = filtered || team;
  const setParentActiveTabFunc = () => {
    let activeIndexSet = false;
    if (cc && Object?.keys(cc)?.length) {
      for (
        let orgTabIndex = 0;
        orgTabIndex < Object?.keys(cc)?.length;
        orgTabIndex++
      ) {
        if (activeIndexSet) {
          break;
        }
        const elOrgTabData = cc?.[Object?.keys(cc)?.[orgTabIndex]];
        console.log(`orgTabIndex`, orgTabIndex, `elOrgTabData`, elOrgTabData);
        // setActiveEnabled(elOrgTabData?.enabled);
        if (elOrgTabData?.enabled) {
          activeIndexSet = true;
          setActiveParentTabId(orgTabIndex);
        }
      }
    }
  };
  const [againLoad, setAgainLoad]= useState(false);
  useEffect(() => {
    if(!againLoad){
      setParentActiveTabFunc();
    }
  }, [cc,againLoad]);

  useEffect(() => {
    console.log("activeEnabled", activeEnabled);
  }, [activeEnabled]);
  console.log("cc", cc);
  useEffect(() => {
    console.log("pagination", pagination);
    console.log("team", team);
    console.log("filterOption", filterOption);
    dispatch(fetchApprovalList(filterOption));
  }, [filterOption, isRecordUpdated]);

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);

  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setAgainLoad(true);
    setToggleActivateModalShow(false);
  };
  const [type, setType] = useState("");
  const addProject = (e, subStageKey) => {
    console.log("subStageKey", subStageKey);
    setType(subStageKey);
    setOpen(true);
  };
  const renderTabs = (data, stageKey) => {
    console.log("data-101", data);
    console.log("stageKey", stageKey);
    const filteredTabs = Object.keys(data).filter(
      (subStageKey) => subStageKey !== "enabled",
    );

    console.log("filteredTabs", filteredTabs);
    return (
      <>
        <ManageProject
          open={open}
          onHide={hideHandle}
          title={"Add Project Type"}
          selectclient={selectclient}
          setModalShow={setModalShow}
          subOrg={stageKey}
          type={type}
        />
        <Paper className={classes.iconsContainer}>
          <Tabs
            indicatorColor="none"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            className={classes.iconsBgBar}
          >
            {filteredTabs.map((subStageKey, index) => (
              <Tab
                key={index}
                label={subStageKey}
                style={{ textTransform: "capitalize" }}
                classes={{ root: classes.tabBg }}
              />
            ))}
          </Tabs>
          {Object.keys(data).map(
            (subStageKey, index) =>
              activeTabId === index && (
                <div style={{ padding: "20px 0px" }} key={index}>
                  <Button
                    style={{ float: "right", textTransform:'capitalize' }}
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    onClick={(e) => addProject(e, subStageKey)}
                  >
                    Add Project Type
                  </Button>

                  <ProjectType
                    stage={stageKey}
                    subStage={subStageKey}
                    projectLevel={data[subStageKey]?.finalList}
                    enabled={data?.enabled}
                  />
                  {/* <EngagementTeam
                  stage={stageKey}
                  subStage={subStageKey}
                  engagement={data[subStageKey]?.engagement}
                  enabled={data?.enabled}
                  projectLevel={data[subStageKey]?.projectLevels}
                />
                <ApproverTeam
                  stage={stageKey ? stageKey : ""}
                  subStage={subStageKey ? subStageKey : ""}
                  data={data[subStageKey]?.approver}
                  enabled={data?.enabled}
                  projectLevel={data[subStageKey]?.projectLevels}
                /> */}
                </div>
              ),
          )}
        </Paper>
      </>
    );
  };
  return (
    <>
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />
      <PageTitle title="Manage Approval" />
      {isLoader && <Loader />}
      {cc && Object.keys(cc).length ? (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={activeParentTabId}
              onChange={(e, id) => setActiveParentTabId(id)}
              className={classes.iconsBar}
            >
              {Object.keys(cc).map((orgKey, index) => (
                <Tab
                  key={index}
                  label={orgKey}
                  style={{
                    cursor: cc[orgKey]?.enabled ? "pointer" : "not-allowed",
                    backgroundColor: cc[orgKey]?.enabled ? "" : "#EEEEEE",
                  }}
                  classes={{ root: classes.tab }}
                  disabled={!cc[orgKey]?.enabled}
                />
              ))}
            </Tabs>
          </Paper>
          {Object.keys(cc).map((orgKey, index) => (
            <div key={index}>
              {activeParentTabId === index && (
                <>{renderTabs(cc[orgKey], orgKey)}</>
              )}
            </div>
          ))}
        </>
      ) : (
        "No data found"
      )}
    </>
  );
}
