import { createSlice } from '@reduxjs/toolkit'
import { 
  createUpdateSubClient,
  updateSubClient, 
  fetchSubClientList, 
  deleteSubClient,
  toggleActivation, 
  sendActivationLink, 
  sendResetPasswordLink,
  getSubClientBySubClientId  } from '../actions/subClientAction'

export const subclients = createSlice({
  name: 'clients',
  initialState: {
    clients: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
    // isRecordAdded: false,
    // isRecordUpdated: false,
    // isRecordLoaded:false,
  },
  reducers: {
    userReset: state => {
      // state.clients= null;
      state.error = null;
    },
    resetPopup: state => {
      state.isLoader= false;
      // state.isRecordAdded=false;
      state.isRecordUpdated= false;
      state.error=null;
    },
  },
  extraReducers: {
    [fetchSubClientList.pending]: (state, payload) => {
      state.error=null;
      state.isLoader= true;
    },
    [fetchSubClientList.fulfilled]: (state, {payload}) => {
      state.error=null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false
      }
    },
    [fetchSubClientList.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isLoader= false;
    },
    // create
    [createUpdateSubClient.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [createUpdateSubClient.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [createUpdateSubClient.rejected]: (state, {payload}) => {
      console.log("payload-SubClients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    // update
    [updateSubClient.pending]: (state, payload) => {
      // console.log("payload-SubClients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [updateSubClient.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [updateSubClient.rejected]: (state, {payload}) => {
      console.log("payload-SubClients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    [deleteSubClient.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [deleteSubClient.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [deleteSubClient.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [toggleActivation.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [toggleActivation.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [sendActivationLink.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [sendActivationLink.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [sendActivationLink.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [sendResetPasswordLink.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [sendResetPasswordLink.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [sendResetPasswordLink.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [getSubClientBySubClientId.pending]: (state, payload) => {
      state.error=null;
      state.isRecordLoaded= false;
      state.isLoader= true;
      
    },
    [getSubClientBySubClientId.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordLoaded= true
      state.isLoader= false;
    },
    [getSubClientBySubClientId.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordLoaded= false;
      state.isLoader= false;
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup} = subclients.actions

export default subclients.reducer