import axios from "axios";
import { constant } from "./constant";
const { path } = constant;

const getSubDomain = () => {
  const subdomain = window?.location?.hostname?.split(".")?.[0];
  return subdomain?.length > 0 ? subdomain : "";
  
};

const AxiosInstance = axios?.create({
  baseURL: path
});

// request interceptos
AxiosInstance?.interceptors?.request?.use((config) => {
  // modify the request before sending out
  if(localStorage?.getItem("authorization")) {
    config.headers.Authorization = localStorage?.getItem("authorization");
  }

  const authorization = localStorage?.getItem("authorization");
  // Parse the URL to get the "key" value from the query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("key");

  // Set the "authorization" header if available in localStorage
  if (authorization) {
    config.headers.Authorization = authorization;
  }

  // Set the "key" value in the Authorization header if available in URL
  if (key) {
    config.headers.Authorization = key;
  }

  // update the base-url
  config.baseURL = config?.baseURL?.replace(`{subdomain}`, getSubDomain());
  return config;
}, (error) => {
  return Promise.reject(error);
});

// response interceptos
AxiosInstance?.interceptors?.response?.use((config) => {
  if(config?.headers?.["authorization"]) {
    localStorage?.setItem("authorization", config?.headers?.["authorization"]);
  }
  return config;
}, (error) => {
  console.log("error", error);
  const { data } = error.response;
   if(error?.response?.status == 401) {
    // logout the user from here
    window.localStorage.clear();
  }
  return Promise.reject(error);
  // return rejectWithValue(error.response.data);
});

export default AxiosInstance;