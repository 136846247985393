import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;



export const reviewEngagementModule = createAsyncThunk(
  "reviews/reviewEngagementModule",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post(`user/review/v1/engagement/action/${arg?.projectId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const reviewFinalApprovalModule = createAsyncThunk(
  "reviews/reviewFinalApprovalModule",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post(`user/review/v1/final-approval/action/${arg?.projectId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
)
