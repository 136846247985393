import React from 'react'

import ApprovalResultRequest from '../../component/Reports/ApprovalResultRequest'

export default function ApprovalResultPage() {
  return (
    <>
    <ApprovalResultRequest/>
    </>
  )
}
