export default {
  table: [
    {
      projectId: "EQA0001",
      projectTitle: "Title",
      leadName: "lead",
      number: "10",
      date:'25/11/2022',
      status:'Active'
    },
    {
      projectId: "EQA0001",
      projectTitle: "Title",
      leadName: "lead",
      number: "10",
      date:'25/11/2022',
      status:'Active'
    },
    {
      projectId: "EQA0001",
      projectTitle: "Title",
      leadName: "lead",
      number: "10",
      date:'25/11/2022',
      status:'Active'
    },
    {
      projectId: "EQA0001",
      projectTitle: "Title",
      leadName: "lead",
      number: "10",
      date:'25/11/2022',
      status:'Active'
    },

   
  ],
  accounts: [
    {
      email: "ronald@yopmail.com",
      date: "22/112022, 12:30 AM",
      performance: "user added",
      performed: "Ronaldo Richards",
     
    },
    {
      email: "ronald@yopmail.com",
      date: "22/112022, 12:30 AM",
      performance: "user added",
      performed: "Ronaldo Richards",
     
    },
    {
      email: "ronald@yopmail.com",
      date: "22/112022, 12:30 AM",
      performance: "user added",
      performed: "Ronaldo Richards",
     
    },
    {
      email: "ronald@yopmail.com",
      date: "22/112022, 12:30 AM",
      performance: "user added",
      performed: "Ronaldo Richards",
     
    },

   
  ],
};
