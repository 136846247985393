import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboard
} from "../actions/dashboardsAction";

export const dashboards = createSlice({
  name: "dashboards",
  initialState: {
    dashboards: null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordAdded: false,
    isRecordUpdated: false,
    isRecordLoaded: false,
  },
  reducers: {
    checkAdmin: (state, { payload }) => {
      state.isAdminLoggedIn = payload ? true : false;
    },
    adminManage: (state) => {
      state.isAdminLoggedIn = true;
    },
    adminReset: (state) => {
      // state.isAdminLoggedIn = false;
      // state.adminDetail = null;
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = false;
    },
    resetError: (state) => {
      state.error = null;
      state.isLoader = false;
    },
  },
  extraReducers: {
    
    [getDashboard.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [getDashboard.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      // state.adminDetail= payload?.data;
      state.error = null;
      state.isLoader = false;
      state.isAdminLoggedIn = true;
      state.dashboards = payload?.data;
    },
    [getDashboard.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isAdminLoggedIn = false;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { adminManage, adminReset, checkAdmin, resetError } =
  dashboards.actions;

export default dashboards.reducer;
