import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, InputBase, Typography } from "@material-ui/core";
import {
  Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import PageTitle from "../../../component/PageTitle/PageTitle";
import downloadIcon from "../../../assets/download-btn.svg";
import downloadBoxIcon from '../../../assets/download-icon.svg';
import { Tooltip } from "react-tooltip";
// styles
import useStyles from "./styles";
import {
  downloadDataContentList,
  fetchprivateDataList,
} from "../../store/actions/privateDataAction";
import { resetPopup } from "../../store/reducerSlice/privatedata";
import TableDesign from "../../../component/common/table/TableDesign";
import TableWidget from "../../../component/Widget/TableWidget";
import classNames from "classnames";
import ManageDataItam from "./ManageDataItem";
import { useDispatch, useSelector } from "react-redux";
import ToggleActivationPopup from "./ToggleActivationPopup";
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function PrivateDataRepo({ data }) {
  const navigate = useNavigate();
  var classes = useStyles();
  const [showResult, setShowResult] = useState(false);
  const [message, setMessage] = useState(null);
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateOrg, setToggleActivateOrg] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
      align: "left",
    },
    {
      label: "Data Title",
      sort: "title",
      
    },
    {
      label: "Added By",
    },
    {
      label: "Groups Attached",
      // sort: 'identifiedGroups',
    },
    {
      label: "Assessment Type",
      sort: "userType",
    },
    {
      label: "Last Updated On",
      sort: "updatedAt",
    },
    {
      label: "Status",
    },

    {
      label: "Action",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [selectOrg, setSelectOrg] = useState(data);
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "",
    orderIn: -1,
    organization: selectOrg,
  });

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.privatedata;
  });
  const page = pagination?.currentPage;

  const cc = filtered || list;
  console.log("cc", cc);
  useEffect(() => {
    console.log("pagination", pagination);
    console.log("list", list);
    console.log("filterOption", filterOption);
    dispatch(fetchprivateDataList(filterOption));
  }, [filterOption, isRecordUpdated]);
  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);
  const addDataItem = () => {
    setselectclient(null);
    setTitle("Add New Data Item");

    setOpen(true);
  };
  const updateDataItem = (selectRow) => {
    setselectclient(selectRow);
    setTitle("Update New Data Item");
    setOpen(true);
    setAnchorEl(null);
  };
  const toggleActivateEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateRec(rec?.id);
    setToggleActivateOrg(rec?.organization);
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateModalShow(true);

    setShowResult(true);
    if (rec?.isActive) {
      setTitle("Data content");
      setMessage("Data content de-activated successfully");
    } else {
      setTitle("Data content");
      setMessage("Data content activated successfully");
    }
  };

  const hideHandle = () => {
    setselectclient(null);
    setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
    setToggleActivateRec(null);
    setToggleActivateStatus(null);
    setToggleActivateOrg(null);
  };
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  const downloadCurrentList = () => {
    dispatch(downloadDataContentList({ organization: selectOrg }))
      .unwrap()
      .then((response) => {
        const fileContent = response.data; // Assuming the response data is stored in 'response.data'

        // Set the appropriate headers for the file download
        const headers = response.headers;
        const filename = "dataContent.csv"; // Provide a filename for the downloaded file
        const contentType =
          headers["content-type"] || "application/octet-stream";

        const blob = new Blob([fileContent], { type: contentType });

        // Create a temporary URL for the blob
        const blobURL = URL.createObjectURL(blob);

        // Create a link element and click it to trigger the download
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = filename;
        link.click();

        // Clean up the temporary URL
        URL.revokeObjectURL(blobURL);
      })
      .catch((error) => {
        // Handle error here
        console.error("Error occurred while downloading client list:", error);
      });
  };
  return (
    <>
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
        toggleActivateOrg={toggleActivateOrg}
      />
      <ManageDataItam
        open={open}
        onHide={hideHandle}
        title={title}
        selectclient={selectclient}
        setModalShow={setModalShow}
        selectOrg={selectOrg}
      />
      <div className={classes.pageTitleContainer}>
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            // onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            ref={searchInputRef}
            placeholder="Search…"
            onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          {searchText?.length > 0 && (
            <IconButton style={{background:'none'}}
              onClick={(e) => {
                setSearchText("");
                searchInputRef.current.children[0].value = "";
              }}
              className={classes.searchSvg2}
            >
              <ClearIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.groupBtn}>
          {cc && cc.length > 0 && (
            <>
              <img
                onClick={downloadCurrentList}
                src={downloadBoxIcon}
                style={{
                  // backgroundColor: "#FEAA00",
                  color: "#fff",
                  textDecoration: "none",
                  padding: "4px 12px",
                  borderRadius: "2px",
                  marginRight: "10px",
                  marginTop:'-4px',
                  cursor: "pointer",
                }}
                data-tip="Click to download"
                alt="img"
              />
              <Tooltip />
            </>
            // <Button
            //   onClick={downloadCurrentList}
            //   classes={{ root: classes.button }}
            //   style={{
            //     backgroundColor: "#FEAA00",
            //     color: "#fff",
            //     textDecoration: "none",
            //     padding: "6px 15px",
            //     borderRadius: "2px",
            //     marginRight: "10px",
            //   }}
            // >
            //   Download
            // </Button>
          )}

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={addDataItem}
          >
            Add Data Items
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell style={{textAlign:'left'}}
                          onClick={() =>
                            navigate(
                              `/admin/data-review-details/${data}/${row?.id}`,
                            )
                          }
                          className={classes.tableCell}
                        >
                          {row.title}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.addedBy?.email}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.identifiedGroups.length}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.userType}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {formatedDate(row.updatedAt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.isActive ? (
                            <span style={{ color: "#10940D", fontWeight: 500 }}>
                              Active
                            </span>
                          ) : (
                            <span style={{ color: "#AFAEAD" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                navigate(
                                  `/admin/data-review-details/${data}/${selectRow?.id}`,
                                )
                              }
                              className={classes.menuItem}
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View
                            </MenuItem>
                            <MenuItem
                              onClick={() => updateDataItem(selectRow)}
                              className={classes.menuItem}
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                toggleActivateEventHandler(selectRow)
                              }
                              className={classes.menuItem}
                            >
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              {selectRow?.isActive ? "Deactivate" : "Activate"}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
