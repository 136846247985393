import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Fade,
  Button,
  IconButton,
} from "@material-ui/core";
// components
import Widget from "../../../component/Widget";
import PageTitle from "../../../component/PageTitle/PageTitle";
import editIcon from "../../../images/imgEdit.svg";

// styles
import useStyles from "./styles";

// api
import Validation from "../../hooks/Validation";
import Loader from "../../../component/common/loader/Loader";
import { updateProfile, getProfile } from "../../store/actions/adminAction";
import { adminReset } from "../../store/reducerSlice/admin";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import { useToasts } from "react-toast-notifications";
export default function ProfileDetails() {
  var classes = useStyles();
  // const [profileImage, setLogo] = useState(null);
  const [logo, setLogo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const inputRef = useRef();
  const [successMessage, setSuccessMessage] = useState(null);
  const [adminDetail, setAdminDetail] = useState({
    name: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      name: adminDetail.name,
    });
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(getProfile()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setAdminDetail(payload?.data?.data);
      }
      console.log("adminDetail", adminDetail);
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  //Api call
  useEffect(() => {
    setIsUploading(true);
    console.log("file logo", logo);
    let formData = new FormData();
    if (adminDetail?.adminId) {
      formData.append("clientId", adminDetail?.adminId);
    }
    formData.append("name", adminDetail?.name);

    if (logo) {
      formData.append("logo", logo);
    }
    console.log("formData-", ...formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateProfile(formData)).then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.data?.success) {
          const toastHeight = payload?.data?.message.length > 35 ? 'auto' : '34px';
          setIsUploading(true);
          setSuccessMessage(payload?.data?.message);
          addToast(payload?.data?.message, {
            appearance: "success",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
          // hideHandler();
          // setIsShow(true);
        } else {
          const toastHeight = payload?.data?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.data?.message);
          // setIsShow(true);
          addToast(payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
        }
      });
      setIsActionCalled(false);
      // inputRef.current.value = "";
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  // logo upload
  const upload = (e) => {
    setIsWrongFileType(false);
    console.log(e.target.files[0]);
    var fileName = e.target.files[0]?.name;
    let idxDot = fileName.lastIndexOf(".") + 1;
    let extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      //TO DO
      setLogo(e.target.files[0]);
    } else {
      console.log("Only jpg/jpeg and png files are allowed!");

      setIsWrongFileType(true);
      setTimeout(() => {
        setIsWrongFileType(false);
      }, 3000);
    }
  };
  // call logo
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);
  const cancelHandler = () => {
    navigate(`/dashboard`);
  };
  const hideHandlerRes = () => {
    // if (isDone) {
    dispatch(adminReset());
    // navigate("/dashboard");
    // }
    setIsShow(false);
  };
  // useEffect(() => {
  //   return () => {
  //     dispatch(adminReset());
  //   };
  // }, []);
  return (
    <>
    
      {isLoader && <Loader />}
      <PageTitle title="Profile" />
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            {logoUrl ? (
              <Avatar className={classes.avatar} src={logoUrl} />
            ) : adminDetail?.profileImage ? (
              <Avatar
                className={classes.avatar}
                src={adminDetail?.profileImage}
              />
            ) : (
              <Avatar className={classes.avatar} src="/photo.svg" />
            )}

            {/* <IconButton
              className={classes.uploadIcon}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                onChange={(e) => upload(e)}
                ref={inputRef}
                name="myImage"
                accept="image/*"
                type="file"
              />
              <img src={editIcon} />
            </IconButton> */}
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Name</Typography>
                <TextField
                  size="small"
                  name="name"
                  className={classes.txtForm}
                  value={adminDetail?.name}
                  fullWidth
                  placeholder="Enter Name"
                  variant="outlined"
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.name}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.name}
                  </Typography>
                </Fade>
              </Grid>

              {/* <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Last Name
                </Typography>
                <TextField
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  value={adminDetail?.lastName}
                  name="lastName"
                  placeholder="Last Name"
                  variant="outlined"
                  onChange={(e) => {
                    setAdminDetail({
                      ...adminDetail,
                      [e.target.name]: e.target.value,
                    });
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.lastName}
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.lastName}
                  </Typography>
                </Fade>
              </Grid> */}

              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Email ID</Typography>
                <TextField
                  disabled
                  size="small"
                  value={adminDetail?.email}
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Email Id"
                  variant="outlined"
                />
              </Grid>

              <Grid
                className={classes.btnGroup}
                container
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button
                  onClick={(e) => cancelHandler(e)}
                  className={classes.leftButton}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  onClick={(e) => submitHandler(e)}
                  className={classes.rightButton}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Update
                </Button>
              </Grid>
              <ApiResponsePopup
                {...{
                  hideHandlerRes,
                  isRecordUpdated: isShow,
                  title: "Update Profile",
                  updateMsg: "Profile successfully updated!",
                  successMsg: successMessage,
                  errorRes: apiError?.message,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Widget>
    </>
  );
}
