import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";

// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";
import cubes from "./cubes.svg";
import edit from "./whiteEdit.svg";
import editIcon from "../../../images/imgEdit.svg";
import { getPortalUserByUserId } from "../../../store/actions/portalUserAction";
import Loader from "../../../../component/common/loader/Loader";
export default function ClientDetail() {
  const { userId } = useParams();
  console.log("userId", userId);
  const navigate = useNavigate();
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var classes = useStyles();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.portalusers);
  const [clientDetail, setClientDetail] = useState(null);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPortalUserByUserId({ userId })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.success) {
        setClientDetail(payload?.data?.user);
      }
    });
  }, []);
  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getPortalUserByUserId({ userId })).then(({ payload }) =>
        setClientDetail(payload?.data?.user),
      );
    }
  }, [isRecordUpdated]);
  const handleEdit = () => {
    navigate(`/admin/client-edit/${userId}`);
  };
  return (
    <>
    {isLoader && <Loader />}
      <PageTitle
        subtitle="Manage Staff >"
        title="Details"
        button="Edit"
        urlLink="/admin/manage-client"
        editIcon="true"
        onClick={handleEdit}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Name:</Typography>
            <Typography className={classes.formHeadingValue}>
              {clientDetail?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Email Id:</Typography>
            <Typography className={classes.formHeadingValue}>
              {clientDetail?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status:</Typography>
            <Typography className={classes.formHeadingValue}>
              {clientDetail?.isActive ? (
                clientDetail?.isActivated ? (
                  <span style={{ color: "#10940D", fontWeight: 500 }}>
                    Active
                  </span>
                ) : (
                  <span style={{ color: "#F8A80C" }}>Pending</span>
                )
              ) : (
                <span style={{ color: "red" }}>Inactive</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Sub-Organisations:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {clientDetail?.subOrg?.ehiiaVersion.join(", ")}
            </Typography>
          </Grid>

          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created on:</Typography>
            <Typography className={classes.formHeadingValue}>
              {formatedDate(clientDetail?.createdAt)}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Activated on:
            </Typography>
            <Typography className={classes.formHeadingValue}></Typography>
          </Grid> */}
        </Grid>
      </Widget>
    </>
  );
}
