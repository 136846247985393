import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteClient } from "../../../store/actions/clientsAction";
// Model
import {Typography, Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
// styles
import useStyles from "./styles";

export default function DeletePopup({deleteRec, onHide, deleteModalShow, setDeleteModalShow}) {
  console.log("deleteModalShow", deleteModalShow);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
} = useSelector(state => {return state.clients});
const [isAction, setIsAction] = useState(false)
    const [isDone, setIsDone] = useState(false)
const dispatch = useDispatch();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setIsAction(true);
    return dispatch(deleteClient({
            clientId: deleteRec,
        }))
        
}
useEffect(() => {
    if(isRecordUpdated){
        setIsDone(true);
        onHide()
        setDeleteModalShow(false)
        }  
}, [isRecordUpdated])

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center>
            <img src={alertIcon} alt="img"/>
            <Typography className={classes.formActiveHeading}>
              Are you sure, you want to delete this client?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
