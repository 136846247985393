import React from 'react'

import ReportList from '../../components/Report/ReportList'

export default function Reports() {
  return (
    <div>
      <ReportList/>
    </div>
  )
}
