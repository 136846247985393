import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePortalUser } from "../../store/actions/portalUserAction";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "./styles";
import Loader from "../../../component/common/loader/Loader";
import { useToasts } from "react-toast-notifications";
export default function DeletePopup({
  deleteRec,
  onHide,
  deleteModalShow,
  setDeleteModalShow,
}) {
  console.log("deleteModalShow", deleteModalShow);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => {
    return state.portalusers;
  });

  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setIsAction(true);
    return dispatch(
      deletePortalUser({
        userId: deleteRec,
      }),
    ).then(({payload}) => {
      console.log("deleteSubAdmin", payload);
      if(payload?.success){
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:toastHeight
          },
        });
      }else{
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "error",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }
      
    });;
  };
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setDeleteModalShow(false);
    }
  }, [isRecordUpdated]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
          {apiError ? (
            <>
             <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              Ok
            </Button>

           
            </>
          ):(
            <>
             <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
            </>
          )}
           
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
                </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
              Are you sure, you want to delete this staff?
            </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
