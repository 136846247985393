import React from "react";
import LoaderImage from "./loader.svg";
// styles
import useStyles from "./styles";
export default function Loader({ message }) {
  console.log("message", message);
  var classes = useStyles();
  return (
    <div className={classes.loader}>
      <img src={LoaderImage} className={classes.img} alt="" width={50} />
      {message && (
        <div className={classes.message} style={{marginTop:'36px'}}>{message}</div>
      ) 
      // : (
      //   <img src={LoaderImage} className={classes.img} alt="" width={50} />
      // )
      }
    </div>
  );
}
