import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const adminLogin = createAsyncThunk(
  "admin/adminLogin",
  // (arg, { rejectWithValue }) => DataService.post(arg, path+'/root/auth/v1/login', rejectWithValue)
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post(`/user/auth/v1/login`, arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const forgotPassword = createAsyncThunk(
  "admin/forgotPassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/auth/v1/forgot", arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const changePassword = createAsyncThunk(
  "admin/changePassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/my/v1/password", arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const resetPassword = createAsyncThunk(
  "admin/resetPassword",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/auth/v1/reset", arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const updateProfile = createAsyncThunk(
  "admin/updateProfile",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/my/v1/profile", arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);

export const getProfile = createAsyncThunk(
  "admin/getProfile",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get("/user/my/v1/profile", rejectWithValue),
);
export const activateUser = createAsyncThunk(
  "admin/activateUser",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/user/auth/v1/activate", arg)
      .then((response) => response)
      .catch((error) => rejectWithValue(error.response)),
);
