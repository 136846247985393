import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    formBox:{
        paddingLeft:theme.spacing(4),
        paddingRight:theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            paddingLeft:theme.spacing(0),
        paddingRight:theme.spacing(0),
          },
    },
    avatar:{
        width:"64px",
        height:"64px"
    },
    formHeading:{
        marginRight:theme.spacing(0),
        marginBottom:theme.spacing(0),
        color:'#969DAB',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:400,
        textAlign:'left',
        flexGrow: 1,
       width:'40%',
       [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    formHeadingValue:{
        marginTop:theme.spacing(0),
        marginBottom:theme.spacing(0),
        color:'#2A2338',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:500,
        textAlign:'left',
        width:'60%',
        [theme.breakpoints.down("md")]: {
            width: "100%",
          },
    },

    txtForm:{
        padding:"0px 4px",
        [theme.breakpoints.down("md")]: {
            padding:"0px",
          },
    },
    textGroup:{
        display:"flex",
        justifyContent: 'flex-start',
        [theme.breakpoints.down("md")]: {
            display: "inline",
          },
    },
    textDesGroup:{
        display:"flex-wrap",
        justifyContent: 'flex-start',
    },
    formHeadingDesValue:{
        marginTop:theme.spacing(0),
        marginBottom:theme.spacing(0),
        color:'#2A2338',
        fontSize:'14px',
        fontFamily:'Poppins',
        fontWeight:500,
        textAlign:'left',
        
    },
    modelFooter:{
        padding:theme.spacing(2),
        
    },
    blueButton:{
        background:"#0445AF",
        color:"#fff",
        fontFamily:'Poppins',
        fontSize:14,
        fontWeight:500,
        textTransform:'none',
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
          },
        marginTop:theme.spacing(2),
        "&:hover": {
            background:"#0078D4",
        color:"#fff",
        }
        
    },
    gridBox:{
        // display:"flex",
        // justifyItems:"center",
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4)
    },
    btnGroup:{
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(2),
    },
    rightButton:{
        marginLeft:theme.spacing(2),
        backgroundColor:'#0445AF',
        color:'#fff',
        fontFamily:'Poppins',
        fontSize:14,
        fontWeight:500,
    },
    widgetBox:{
        marginTop:theme.spacing(2)
    },
    passwordBox:{
        display:"flex",
        alignItems:'center',
    },
    Link:{
        color:'#0445AF',
        marginLeft:theme.spacing(2),
        marginRight:theme.spacing(4)
    },
    AssignHeading:{
        marginBottom:theme.spacing(-2),
        marginTop:theme.spacing(2)
    },
    
    icons:{
        width:'32.17px',
        height:'32.17px',
        position:'absolute',
        // marginLRight:theme.spacing(2),
        marginTop:'-40px',
        right:'10px'
      },
    card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        fontSize:'26px !important',
        fontWeight:500,
        fontFamily:'Poppins',
        color:'#2A2338'
        
      },
      fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        position:'relative'
      },
      progressSectionTitle:{
        color:'#2A2338',
        fontFamily:'Poppins'
        
      },
}))