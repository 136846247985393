import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchModuleList = createAsyncThunk(
    'modules/fetchModule',
    (arg, { rejectWithValue }) => DataService.get(arg,  path+'/modules', rejectWithValue)
)

export const createUpdateModule = createAsyncThunk(
    'modules/createUpdateModule',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/modules', rejectWithValue)
)


export const updateModule = createAsyncThunk(
    'modules/createUpdateModule',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/modules/update', rejectWithValue)
)

export const deleteModule = createAsyncThunk(
    'modules/deleteModule',
    (arg, { rejectWithValue }) => DataService.delete(arg, path+`/modules/${arg?.moduleId}`, rejectWithValue)
)
export const getModuleByModuleId = createAsyncThunk(
    'modules/getModuleByModuleId',
    (arg, { rejectWithValue }) => DataService.get(arg, path+`/modules/${arg?.moduleId}`, rejectWithValue)
)

