import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchSubAdminList = createAsyncThunk(
  "subadmins/fetchSubAdminList",
  (arg, { rejectWithValue }) => AxiosInstance?.get("/root/super-admins/v1", rejectWithValue),
);

export const createUpdateSubAdmin = createAsyncThunk(
  "subadmins/createUpdateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/root/super-admins/v1",arg, rejectWithValue),
);
export const updateSubAdmin = createAsyncThunk(
  "subadmins/updateSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put( "/root/super-admins/v1", arg, rejectWithValue),
);

export const deleteSubAdmin = createAsyncThunk(
  "subadmins/deleteSubAdmin",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(
      `/root/super-admins/v1/${arg?.rootAdminId}`,arg,
      rejectWithValue,
    ),
);

export const toggleActivation = createAsyncThunk(
  "subadmins/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/root/super-admins/v1/toggle-status/${arg?.rootAdminId}`,arg,
      rejectWithValue,
    ),
);

export const sendActivationLink = createAsyncThunk(
  "subadmins/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/clients/resend-activation/${arg?.rootAdminId}`,
      rejectWithValue,
    ),
);

export const sendResetPasswordLink = createAsyncThunk(
  "subadmins/sendResetPasswordLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/clients/reset-password/${arg?.rootAdminId}`,
      rejectWithValue,
    ),
);
export const sendActivationMail = createAsyncThunk(
  "clients/sendActivationMail",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/root/super-admins/v1/resend-account-mail/${arg?.rootAdminId}`,
      arg,
      rejectWithValue,
    ),
);

export const getSubAdminBySubAdminId = createAsyncThunk(
  "clients/getSubAdminBySubAdminId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/root/super-admins/v1/${arg?.rootAdminId}`, rejectWithValue),
);
