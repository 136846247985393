import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const fetchApprovalList = createAsyncThunk(
  "approvals/fetchApprovalList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/admin/approval-team/v1`, rejectWithValue),
);

export const createUpdateApproval = createAsyncThunk(
  "approvals/createUpdateApproval",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.post("/admin/approval-team/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const createUpdateProjectLevel = createAsyncThunk(
  "approvals/createUpdateProjectLevel",
  ({ subOrg, type, ...formData }, { rejectWithValue }) =>
    AxiosInstance?.put(
      `/admin/approval-team/v1/update-details/${subOrg}/${type}`,
      formData,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const deleteProjectLevel = createAsyncThunk(
  "approvals/deleteProjectLevel",
  ({ subOrg, type, level}, { rejectWithValue }) =>
    AxiosInstance?.delete(
      `/admin/approval-team/v1/delete-by-level/${subOrg}/${type}/${level}`,
      
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
