import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  changePassword,
  resetPassword,
  adminLogin,
  adminLoginWithEmail,
  updateProfile,
  getProfile,
  activateUser,
} from "../actions/adminAction";

export const admin = createSlice({
  name: "admin",
  initialState: {
    isAdminLoggedIn: false,
    Host:null,
    adminDetail: null,
    error: null,
    isLoader: false,
    isRecordUpdated: false,
    invalidJwtToken: false,
  },
  reducers: {
    checkAdmin: (state, { payload }) => {
      state.isAdminLoggedIn = payload ? true : false;
    },
    adminManage: (state) => {
      state.isAdminLoggedIn = true;
    },
    adminReset: (state) => {
      // state.isAdminLoggedIn = false;
      // state.adminDetail = null;
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = false;
    },
    resetError: (state) => {
      state.error = null;
      state.isLoader = false;
    },
  },
  extraReducers: {
    [adminLoginWithEmail.pending]: (state, payload) => {
      state.error = null;
      state.isAdminLoggedIn = false;
    },
    [adminLoginWithEmail.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = null;
      // state.isAdminLoggedIn = true;
      // state.adminDetail = payload;
    },
    [adminLoginWithEmail.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.adminDetail = null;
      state.isAdminLoggedIn = false;
      state.error = payload;
    },
    [adminLogin.pending]: (state, payload) => {
      state.error = null;
      state.isAdminLoggedIn = false;
    },
    [adminLogin.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = null;
      state.isAdminLoggedIn = true;
      state.adminDetail = payload;
    },
    [adminLogin.rejected]: (state, { payload }) => {
      state.adminDetail = null;
      state.isAdminLoggedIn = false;
      state.error = payload;
    },
    [updateProfile.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = true;
    },
    [updateProfile.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [getProfile.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      // state.adminDetail= payload?.data;
      state.error = null;
      state.isLoader = false;
      state.isAdminLoggedIn = true;
      state.adminDetail = payload.data;
    },
    [getProfile.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isAdminLoggedIn = false;
      state.isLoader = false;
    },
    [forgotPassword.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = true;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [changePassword.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = true;
    },
    [changePassword.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = payload?.payload;
      state.isLoader = false;
    },
    [resetPassword.pending]: (state, payload) => {
      
      state.error = null;
      state.isLoader = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = true;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      console.log("payload",payload);
      state.error = payload;
      state.isLoader = false;
    },
    [activateUser.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [activateUser.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isLoader = false;
      state.isRecordUpdated = true;
    },
    [activateUser.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { adminManage, adminReset, checkAdmin, resetError } =
admin.actions;

export default admin.reducer;
