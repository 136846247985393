import React from 'react'
import ApprovalRequest from '../../component/Reports/ApprovalRequest'

export default function ApprovalPage() {
  return (
    <>
    <ApprovalRequest/>
    </>
  )
}
