import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Grid, Typography, Avatar, Button, Link } from "@material-ui/core";

// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";

//
import { getClientByClientId } from "../../../store/actions/clientsAction";
import AssignModuleList from "./AssignModuleList";
import Loader from "../../../../component/common/loader/Loader";
export default function ClientDetail() {
  const { orgId } = useParams();
  console.log("orgId", orgId);
  const navigate = useNavigate();
  var classes = useStyles();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);
  const [clientDetail, setClientDetail] = useState(null);
  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientByClientId({ orgId })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.success) {
        setClientDetail(payload?.data?.organization);
      }
    });
  }, []);

  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getClientByClientId({ orgId })).then(({ payload }) =>
        setClientDetail(payload.data.organization),
      );
    }
  }, [isRecordUpdated]);

  // const hideHandle = () => {
  //   setModalShow(false);
  // };
  const handleEdit = () => {
    navigate(`/super-admin/client-edit/${orgId}`);
  };
  return (
    <>
      {isLoader && <Loader />}
      <PageTitle
        subtitle="Manage Clients >"
        title="Details"
        button="Edit"
        urlLink="/super-admin/manage-client"
        editIcon="true"
        onClick={handleEdit}
      />

      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            {clientDetail?.logo ? (
              <Avatar className={classes.avatar} src={clientDetail?.logo} />
            ) : (
              <Avatar className={classes.avatar} src="/photo.svg" />
            )}
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={6} className={classes.textGroup} >
                <Typography className={classes.formHeading}>
                  Organisation:
                </Typography>
                <Typography className={classes.formHeadingValue} style={{width:'80%'}}>
                  {clientDetail?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textGroup} >
                <Typography className={classes.formHeading}>Admin:</Typography>
                <Typography className={classes.formHeadingValue} style={{width:'80%'}}>
                  {clientDetail?.contactPerson}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textGroup} >
                <Typography className={classes.formHeading}>
                  Short Name:
                </Typography>
                <Typography className={classes.formHeadingValue} style={{width:'80%'}}>
                  {clientDetail?.subdomain}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textGroup} >
                <Typography className={classes.formHeading}>Email:</Typography>
                <Typography className={classes.formHeadingValue} style={{width:'80%'}}>
                  {clientDetail?.email}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.textGroup} >
                <Typography className={classes.formHeading}>Status:</Typography>
                <Typography className={classes.formHeadingValue} style={{width:'80%'}}>
                  {clientDetail?.isActive ? (
                    clientDetail?.isActivated ? (
                      <span style={{ color: "#10940D", fontWeight: 500 }}>
                        Active
                      </span>
                    ) : (
                      <span style={{ color: "#F8A80C" }}>Pending</span>
                    )
                  ) : (
                    <span style={{ color: "red" }}>Inactive</span>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Widget>

      <AssignModuleList
        clientDetail={clientDetail}
        setClientDetail={setClientDetail}
      />
    </>
  );
}
