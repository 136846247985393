import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Tabs,
  Typography,
  Tab,
  Paper,
  Button,
  Avatar,
} from "@material-ui/core";

import notes_bg from "../../../../../images/notes_bg.png";
import watch from "../../../../../images/watch.png";
import checked from "../../../../../images/checked.png";
import blue_edit from "../../../../../images/blue_edit.png";
import useStyles from "./styles";

import { formatDate } from "../../../../../helperHooks/helper";

import moment from "moment";

export default function Approval({ setUpdated, stage, subStage, data }) {
  console.log("data", data);
  console.log("stage", stage);
  console.log("subStage", subStage);

  const [uploadSuccessData, setUploadSuccessData] = useState(null);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [stageValue, setStageValue] = useState(stage);
  const [stageTypeValue, setStageTypeValue] = useState(subStage);
  const [stageWiseData, setStageWiseData] = useState(data);

  const [isShow, setIsShow] = useState(false);
  var classes = useStyles();
  const sendApproval = (e) => {
    setAlertModalShow(true);
  };
  const sendReview = () => {
    setReviewModalShow(true);
  };

  return (
    <>
     
      <div
        style={{
          padding: "16px",
          backgroundColor: "#F8FFFE",
          border: "2px solid #03997B",
          borderRadius: "5px",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            <Avatar className={classes.avatar} src={notes_bg} />
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
            <Grid item xs={12} md={11} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>
                  Project Title:
                </Typography>
                <Typography className={classes.formHeadingValue}>
                  {data?.projectTitle}
                </Typography>
              </Grid>
              
             
             
              <Grid item xs={12} md={4} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>
                  Project Id:
                </Typography>
                <Typography className={classes.formHeadingValue}>
                  {data?.projectUID}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>
                {subStage === "stage1" && "Equality Sign-off"}{" "}
                  {subStage === "stage2" && "Report Sign-off"} Approver Email:
                </Typography>
                <Typography className={classes.formHeadingValue}>
                  {data?.approverEmail}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>
                  Submitted on:
                </Typography>
                <Typography className={classes.formHeadingValue}>
                  {data?.submittedOn === "-"
                    ? data?.submittedOn
                    : formatDate(data?.submittedOn)}
                </Typography>
              </Grid>
             
              <Grid item xs={12} md={8} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>Status:</Typography>
                <Typography className={classes.formHeadingValue}>
                  {data?.status === "notSent" ? (
                    <span style={{ color: "#FEAA00" }}>
                      Not sent for approval
                    </span>
                  ) : data?.status === "pending" ? (
                    <span style={{ color: "#FEAA00" }}>Pending</span>
                  ) : data?.status === "approved" ? (
                    <span style={{ color: "#03997B" }}>Approved</span>
                  ) : (
                    <span></span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup_inline}>
                <Typography className={classes.formHeading}>
                  Project Level:
                </Typography>
                <Typography
                  className={classes.formHeadingValue}
                  style={{ color: "#03997B" }}
                >
                  {data?.projectLevel}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} className={classes.textGroup_inline}>
                {data?.approvedAt && (
                  <>
                    <Typography className={classes.formHeading}>
                      Approved By:
                    </Typography>
                    <Typography className={classes.formHeadingValue}>
                      {data?.name}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup_inline}>
                {data?.history.length > 0 &&
                  data?.history.map((item, index) => (
                    <>
                      {item?.byEntity === "team" && item?.approvalType && (
                        <>
                          <Typography className={classes.formHeading}>
                            Approval Type:
                          </Typography>
                          <Typography style={{textTransform:'capitalize'}}
                            className={classes.formHeadingValue}
                          >
                            {item?.approvalType}
                          </Typography>
                        </>
                      )}
                    </>
                  ))}
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup_inline}>
                {data?.history.length > 0 &&
                  data?.history.map((item, index) => (
                    <>
                      {item?.byEntity === "team" && item?.approvalDate && (
                        <>
                          <Typography className={classes.formHeading}>
                            Approved on:
                          </Typography>
                          <Typography
                            className={classes.formHeadingValue}
                          >
                            {moment(item?.approvalDate).format("lll")}
                          </Typography>
                        </>
                      )}
                    </>
                  ))}
              </Grid>
              
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ borderTop: "1px solid #CCE4DF", paddingTop: "10px" }}
            >
              <>
                {data?.status === "notSent" ? (
                  <>
                    <Grid item xs={12} md={7}>
                      <Typography className={classes.formHeading}>
                        You have filled all the required details successfully
                        for this case.
                        <br /> Now, its time to submit your EHIA case to the
                        approver.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className={classes.btnGroup}
                      style={{ direction: "rtl" }}
                    >
                      
                    </Grid>
                  </>
                ) : data?.status === "pending" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar style={{ borderRadius: "0px" }} src={watch} />
                      <Typography
                        style={{
                          marginLeft: "10px",
                          color: "#FF6D7A",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        Pending for approval.
                      </Typography>
                    </Grid>
                  </>
                ) : data?.status === "approved" ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography className={classes.formHeadingInline} >
                      {subStage === "stage1" &&
                          "Equality Sign-off is approved successfully. Please send your case for Report Sign-off Approval"}
                        {subStage === "stage2" &&
                          "Report Sign-off is approved successfully"}
                      </Typography>
                      <Avatar
                        style={{ borderRadius: "0px", marginLeft: "0px" }}
                        src={checked}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={7}>
                      <Typography className={classes.formHeading}>
                        Your case is sent back with comments by the approver.
                        Please take <br />
                        necessary actions and submit the case again for
                        approval.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      className={classes.btnGroup}
                      style={{ direction: "rtl", alignItems: "center" }}
                    >
                     
                    </Grid>
                  </>
                )}
              </>

              <>
                {/* comments */}
                {data?.history.length > 0 &&
                  data?.history.map((item, index) => (
                    <>
                      {item?.byEntity === "team" && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            backgroundColor: "#EDF8F6",
                            marginTop: "10px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography className={classes.formHeading}>
                              Comments from Approver:
                            </Typography>
                            <Typography
                              style={{
                                display: "contents",
                                color: "#52575C",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {moment(item?.timestamp).format("lll")}
                            </Typography>
                          </div>
                          <Typography
                            style={{
                              color: "#201F1E",
                              fontSize: "13px",
                              paddingLeft: "4px",
                              textAlign: "justify",
                              marginTop: "4px",
                            }}
                          >
                            {item?.comments}
                          </Typography>
                        </Grid>
                      )}
                      {/* answer */}
                      {item?.byEntity === "self" && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #03997B",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography className={classes.formHeading}>
                              Your Comment:
                            </Typography>
                            <Typography
                              style={{
                                display: "contents",
                                color: "#52575C",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {moment(item?.timestamp).format("lll")}
                            </Typography>
                          </div>
                          <Typography
                            style={{
                              color: "#201F1E",
                              fontSize: "13px",
                              paddingLeft: "4px",
                              textAlign: "justify",
                              marginTop: "4px",
                            }}
                          >
                            {item?.comments}
                            {/* <br />
                            <Avatar
                              onClick={(e) => sendReview(e)}
                              style={{
                                borderRadius: "0px",
                                width: "30px",
                                height: "30px",
                                float: "right",
                                cursor: "pointer",
                              }}
                              src={blue_edit}
                            /> */}
                          </Typography>
                        </Grid>
                      )}
                    </>
                  ))}
              </>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
