import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Avatar,
} from "@material-ui/core";
import Widget from "../../component/Widget";
// styles
import useStyles from "./styles";
import PatientReport from "./PatientReport";
import SendReview from "./SendReview";
import ApprovalStage from "./ApprovalStage";
import Overview from "./Overview";

export default function Approval({ approvalDetail, type,stage,projectSummaryData }) {
  var classes = useStyles();
  console.log("stage",stage);
  const [activeTabId, setActiveTabId] = useState(stage==='stage1'? 0 :1);
  const [activeParentTabId, setActiveParentTabId] = useState(1);
  

  const renderTabs = (stageData, stageKey) => {
    console.log("stageKey-15", stageKey);
    console.log("stageData",stageData);
    const isStage1Pending = stageData.stage1.status !== "approved";
    return (
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="none"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBgBar}
        >
          {Object.keys(stageData).map((subStageKey, index) => (
            <Tab
              key={index}
              label={
                subStageKey && subStageKey === "stage1" ? "Equality Sign-off" : "Report Sign-off"
              }
              classes={{ root: classes.tabBg }}
              disabled={subStageKey === "stage2" && isStage1Pending}
            />
          ))}
        </Tabs>
        {Object.keys(stageData).map(
          (subStageKey, index) =>
            activeTabId === index  && (
              <div style={{ padding: "0px",marginTop:'6px' }} key={index}>
                <ApprovalStage 
                isStage1Pending={isStage1Pending}
                  stage={stageKey}
                  subStage={subStageKey}
                  data={stageData[subStageKey]}
                />
              </div>
            ),
        )}
      </Paper>
    );
  };

  return (
    <>
      

      <>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeParentTabId}
          onChange={(e, id) => setActiveParentTabId(id)}
          className={classes.iconsBar}
        >
          {approvalDetail &&
            Object.keys(approvalDetail).map((tabLabel, index) => (
              <Tab
                key={index}
                label={tabLabel}
                style={{ textTransform: "capitalize" }}
                classes={{ root: classes.tab }}
              />
            ))}
        </Tabs>
        {approvalDetail &&
          Object.keys(approvalDetail).map(
            (tabLabel, index) =>
              activeParentTabId === index &&
              (tabLabel === "overview" ? (
                <div style={{ padding: "0px" }} key={index}>
                  <Overview
                    overviewData={approvalDetail[tabLabel]}
                    key={index} projectSummaryData={projectSummaryData}
                  />
                </div>
              ) : (
                renderTabs(approvalDetail[tabLabel], tabLabel)
              )),
          )}
      </>
    </>
  );
}
