import { createSlice } from '@reduxjs/toolkit'
import { 
  createUpdateClient,
  updateClient, 
  fetchClientList, 
  deleteClient,
  toggleActivation, 
  sendActivationLink, 
  sendResetPasswordLink,reassignClient,
  getClientByClientId  } from '../actions/clientsAction'

export const clients = createSlice({
  name: 'clients',
  initialState: {
    clients: null,
    organizations:null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
    // isRecordAdded: false,
    // isRecordUpdated: false,
    // isRecordLoaded:false,
  },
  reducers: {
    userReset: state => {
      // state.clients= null;
      state.error = null;
    },
    resetPopup: state => {
      state.isLoader= false;
      // state.isRecordAdded=false;
      state.isRecordUpdated= false;
      state.error=null;
    },
  },
  extraReducers: {
    [fetchClientList.pending]: (state, payload) => {
      console.log("payload",payload);
      state.error=null;
      state.isLoader= true;
    },
    [fetchClientList.fulfilled]: (state, {payload}) => {
      state.error=null;
      console.log("payload",payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false
      }
    },
    [fetchClientList.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isLoader= false;
    },
    // create
    [createUpdateClient.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [createUpdateClient.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [createUpdateClient.rejected]: (state, {payload}) => {
      console.log("payload-clients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    // reassign
    [reassignClient.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [reassignClient.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [reassignClient.rejected]: (state, {payload}) => {
      console.log("payload-clients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    // update
    [updateClient.pending]: (state, payload) => {
      // console.log("payload-clients",payload);
      state.error=null;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= true;
    },
    [updateClient.fulfilled]: (state, {payload}) => {  
      state.error=null; 
      // state.isRecordAdded=true;
      state.isRecordUpdated=true;
      state.isLoader= false;
    },
    [updateClient.rejected]: (state, {payload}) => {
      console.log("payload-clients",payload);
      state.error= payload;
      // state.isRecordAdded=false;
      state.isRecordUpdated=false;
      state.isLoader= false;
    },
    [deleteClient.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [deleteClient.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [deleteClient.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [toggleActivation.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [toggleActivation.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [sendActivationLink.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [sendActivationLink.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [sendActivationLink.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [sendResetPasswordLink.pending]: (state, payload) => {
      state.error=null;
      state.isRecordUpdated= false;
      state.isLoader= true;
    },
    [sendResetPasswordLink.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordUpdated= true;
      state.isLoader= false;
    },
    [sendResetPasswordLink.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordUpdated= false;
      state.isLoader= false;
    },
    [getClientByClientId.pending]: (state, payload) => {
      state.error=null;
      state.isRecordLoaded= false;
      state.isLoader= true;
      
    },
    [getClientByClientId.fulfilled]: (state, {payload}) => {   
      state.error=null;
      state.isRecordLoaded= true
      state.isLoader= false;
    },
    [getClientByClientId.rejected]: (state, {payload}) => {
      state.error= payload;
      state.isRecordLoaded= false;
      state.isLoader= false;
    },
    
  }
})

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup} = clients.actions

export default clients.reducer