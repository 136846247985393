import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteClient } from "../../store/actions/clientsAction";
import Loader from "../../../component/common/loader/Loader";
import { Alert } from "@material-ui/lab";
// Model
import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
// styles
import useStyles from "./styles";

export default function DeletePopup({
  deleteRec,
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  setShowSuccessMessage,
}) {
  console.log("deleteModalShow", deleteModalShow);
  const {
    isRecordUpdated,
    error: apiError,

    isLoader,
  } = useSelector((state) => {
    return state.clients;
  });
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
 
  const dispatch = useDispatch();
  var classes = useStyles();

  const submitHandler = (mode) => {
    setIsAction(true);
    const payload = {
      orgId: deleteRec,
    };
    dispatch(deleteClient(payload))
      .then((response) => {
        // Handle the response here
        console.log("Delete response:", response);
        setShowSuccessMessage(response?.payload?.message);
        // Perform any necessary actions based on the response
        setIsDone(true);
        onHide();
        setDeleteModalShow(false);
       
      })
      .catch((error) => {
        // Handle any errors that occurred during the delete request
        console.error("Delete error:", error);
      });
  };
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      
      onHide();
      setDeleteModalShow(false);
    }
  }, [isRecordUpdated]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {apiError ? (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  Ok
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
              </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
                Are you sure, you want to delete this client?
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
