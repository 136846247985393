import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TableRow, TableCell, Paper } from "@material-ui/core";
// Model
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import TableWidget from "../../../component/Widget/TableWidget";
// components

import TableDesign from "../../../component/common/table/TableDesign";

// API data imports
import { fetchCompleteProjectList } from "../../store/actions/ehiiaAction";

// styles
import useStyles from "./styles";
export default function Complete({
  filterOption,
  setFilterOption,
  filterHandler,
}) {
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var classes = useStyles();
  const [selectRow, setSelectRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleOnSubmit = () => {
    navigate(`/add-ehiia`);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEdit = () => {
    navigate(`/ehiia-details`);
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ID",
      sort: "projectUID"
    },
    {
      label: "Title",
      sort: "introduction.projectTitle"
    },
    {
      label: "Lead",
    },
    {
      label: "Line Manager",
    },
    {
      label: "Service Manager",
    },
    {
      label: "Sub-Org",
    },
    {
      label: "Created on",
    },
    {
      label: "Last Updated ",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const [searchText, setSearchText] = useState("");
 

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.ehiia;
  });

  const page = pagination?.currentPage;

  const cc = list || filtered;
  useEffect(() => {
    console.log("list", list);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);

    console.log("filterOption", filterOption);
    dispatch(fetchCompleteProjectList(filterOption));
  }, [filterOption, isRecordUpdated]);



  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);

    setClientAdminPath(clientAdminPath);
  }, []);
  return (
    <>
      <Paper className={classes.iconsContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of Ehiia Module",
                  tableHeadings,
                  filterHandler,
                  pagination,
                  filterOption,
                  setFilterOption,
                  isLoader,
                }}
              >
                {cc && cc.length ? (
                  cc.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            onClick={() =>
                              navigate(`/${clientAdminPath==="staff" ? 'staff' : 'admin'}/view-project/${row.projectId}`)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {row.projectUID}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              navigate(`/${clientAdminPath==="staff" ? 'staff' : 'admin'}/view-project/${row.projectId}`)
                            }
                            style={{ cursor: "pointer" }}
                            className={classes.tableCell}
                          >
                            {row?.introduction?.projectTitle}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.projectLead}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.lineManager}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.serviceManager}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.organization}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {formatedDate(row.createdAt)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {formatedDate(row.updatedAt)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.isCompleted ? (
                              <span
                                style={{
                                  color: "#10940D",
                                  fontWeight: 500,
                                }}
                              >
                                Complete
                              </span>
                            ) : (
                              <span style={{ color: "#F8A80C" }}>
                                In-Progress
                              </span>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <IconButton
                              id="basic-button"
                              aria-controls={
                                openDown ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openDown ? "true" : undefined}
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  navigate(
                                    `/${clientAdminPath==="staff" ? 'staff' : 'admin'}/view-project/${selectRow?.projectId}`,
                                  )
                                }
                              >
                                {" "}
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                View
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Record Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
