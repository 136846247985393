import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { Grid, Typography, TextField, Button, Fade } from "@material-ui/core";
import moment from "moment";
// components
import Widget from "../../../../component/Widget/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import Validation from "../../../hooks/EhiiaValidation";
import Loader from "../../../../component/common/loader/Loader";
import {
  getSubAdminBySubAdminId,
  updateSubAdmin,
} from "../../../store/actions/subAdminAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function AdminEdit() {
  const { rootAdminId } = useParams();
  console.log("rootAdminId", rootAdminId);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const inputRef = useRef();
  var classes = useStyles();
  const { addToast } = useToasts();
  const [value, setValue] = React.useState("");
  const handleChange1 = (event) => {
    setValue(event.target.value);
  };
  const [adminDetail, setAdminDetail] = useState({
    rootAdminId: null,
    name: null,
    email: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      rootAdminId: adminDetail?.rootAdminId,
      name: adminDetail?.name,
      email: adminDetail?.email,
    });
  const dispatch = useDispatch();
  const {
    isRecordAdded,
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.subadmins);

  useEffect(() => {
    dispatch(getSubAdminBySubAdminId({ rootAdminId })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.success) {
        setAdminDetail(payload?.data?.user);
      }
      console.log("adminDetail", adminDetail);
    });
  }, []);
  // record updated
  useEffect(() => {
    if (isRecordUpdated) {
      dispatch(getSubAdminBySubAdminId({ rootAdminId })).then(({ payload }) =>
        setAdminDetail(payload?.data?.user),
      );
    }
  }, [isRecordUpdated]);
  // Add client form functionality
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    let formData = {
      rootAdminId: adminDetail.rootAdminId,
      name: adminDetail?.name,
      email: adminDetail?.email,
    };

    console.log("formData-", formData);

    if (!isError() && error !== null && isActionCalled) {
      dispatch(updateSubAdmin(formData))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            hideHandler();
            const toastHeight = payload?.message.length > 38 ? 'auto' : '34px';
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: '99999',height:toastHeight
              },
            });
            // setIsShow(true);
            setAdminDetail(null);
          } else {
            const toastHeight = payload?.message.length > 38 ? 'auto' : '34px';
            setErrorApiMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style:{
                height: toastHeight
              }
            });
            // setIsShow(true);
          }
        })
        .catch((error) => console.log("error", error));
      setIsActionCalled(false);
    }
    console.log("error-", error);
    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandler = () => {
    resetAllValidator();
  };
  //
  const hideHandlerRes = () => {
    setIsShow(false);
    setErrorApiMessage(null);
    setSuccessMessage(null);
  };
  const cancelHandler = () => {
    navigate(`/ehiia-details/${rootAdminId}`);
  };
  return (
    <>
 
      {isLoader && <Loader />}
      <PageTitle
        subtitle="Manage Admin >"
        urlLink="/super-admin/manage-admin"
        title=" Edit"
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Name</Typography>
            <TextField
              name="name"
              size="small"
              className={classes.txtForm}
              fullWidth
              value={
                adminDetail && adminDetail?.name === null
                  ? ""
                  : adminDetail && adminDetail?.name
              }
              placeholder="Enter admin name"
              variant="outlined"
              onChange={async (e) => {
                setAdminDetail({
                  ...adminDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.name}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.name}
              </Typography>
            </Fade>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Created On</Typography>
            <TextField
              disabled
              name="createdAt"
              type="date"
              size="small"
              className={classes.txtForm}
              value={
                adminDetail &&
                moment(adminDetail?.createdAt).format("YYYY-MM-DD")
              }
              fullWidth
              placeholder="Enter created date"
              variant="outlined"
              onChange={async (e) => {
                setAdminDetail({
                  ...adminDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.createdAt}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.createdAt}
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Email ID</Typography>
            <TextField
              disabled
              size="small"
              name="email"
              className={classes.txtForm}
              fullWidth
              value={adminDetail && adminDetail?.email}
              placeholder="Enter version id"
              variant="outlined"
              onChange={async (e) => {
                setAdminDetail({
                  ...adminDetail,
                  [e.target.name]: e.target.value,
                });
                resetValidator(e.target.name);
              }}
              isInvalid={error?.email}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.email}
              </Typography>
            </Fade>
          </Grid>

          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Status</Typography>
            <TextField disabled
              size="small"
              className={`${classes.txtForm} ${
                adminDetail?.isActive
                  ? adminDetail?.isActivated
                    ? classes.active
                    : classes.pending
                  : classes.inactive
              }`}
              fullWidth
              placeholder="Enter created by"
              value={
                adminDetail?.isActive
                  ? adminDetail?.isActivated
                    ? "Active"
                    : "Pending"
                  : "Inactive"
              }
              variant="outlined"
              isInvalid={error?.createdBy}
              style={{
                color: adminDetail?.isActive
                  ? adminDetail?.isActivated
                    ? "#10940D" // Active color
                    : "#F8A80C" // Pending color
                  : "red", // Inactive color
              }}
            />
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error?.createdBy}
              </Typography>
            </Fade>
          </Grid>
        </Grid>
      </Widget>
      <div className={classes.footerBox}>
        <Button
          onClick={(e) => cancelHandler(e)}
          variant="outlined"
          size="large"
          className={classes.leftButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
          onClick={(e) => submitHandler(e)}
        >
          Update
        </Button>
      </div>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Update Manage Admin",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
