import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Paper } from "@material-ui/core";
import Overview from "./Overview"; // Import your Overview component here
import Approval from "./Approval"; // Import your Approval component here
import useStyles from "./styles";
import checked from "../../../../assets/checked_white.png";
import checked1 from "../../../../assets/checked_green.png";
import alert from "../../../../images/alert.png";
import {
  getEhiiaProjectByProjectIdwithSectionId,
  createEhiiaModule,
} from "../../../store/actions/ehiiaAction";
import Loader from "../../../../component/common/loader/Loader";

export default function Result({ projectIdValue, projectSummaryData }) {
  const [ehiiaFinalApprovalDetail, setEhiiaFinalApprovalDetail] =
    useState(null);

  console.log("projectIdValue", projectIdValue);
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeParentTabId, setActiveParentTabId] = useState(0);
  const [uploadSuccessData, setUploadSuccessData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const dispatch = useDispatch();
  // get approval filled file data
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      setIsLoading(false);
      if (payload?.success) {
        setEhiiaFinalApprovalDetail(payload?.data?.form?.finalApproval);
        setUpdated(false);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        setEhiiaFinalApprovalDetail(payload?.data?.form?.finalApproval);
        setUpdated(false);
      }
    });
  }, [updated]);

  const renderTabs = (stageData, stageKey) => {
    console.log("stageData", stageData);
    console.log("stageKey-15", stageKey);
    const isStage1Pending = stageData?.stage1?.status !== "approved";
    return (
      <Paper className={classes.iconsContainer}>
        <Tabs
          indicatorColor="primary2"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBgBar}
        >
          {Object.keys(stageData).map((subStageKey, index) => (
            <Tab
              key={index}
              label={
                subStageKey && subStageKey === "stage1"
                  ? "Equality Sign-off"
                  : "Report Sign-off"
              }
              classes={{ root: classes.tab }}
              disabled={subStageKey === "stage2" && isStage1Pending}
            />
          ))}
        </Tabs>
        {Object.keys(stageData).map(
          (subStageKey, index) =>
            activeTabId === index && (
              <div
                style={{ padding: "14px", marginBottom: "50px" }}
                key={index}
              >
                <Approval
                  updated={updated}
                  setUpdated={setUpdated}
                  stage={stageKey}
                  subStage={subStageKey}
                  data={stageData[subStageKey]}
                />
              </div>
            ),
        )}
      </Paper>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeParentTabId}
        onChange={(e, id) => {
          setActiveParentTabId(id);
          setActiveTabId(0); // Reset active subtab when parent tab changes
        }}
        className={classes.iconsBgBar}
      >
        {ehiiaFinalApprovalDetail &&
          Object.keys(ehiiaFinalApprovalDetail).map((tabLabel, index) => (
            <Tab
              key={index}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {tabLabel}{" "}
                  {tabLabel !== "overview" && ( <>
                  {ehiiaFinalApprovalDetail[tabLabel]?.stage1 && !ehiiaFinalApprovalDetail[tabLabel]?.stage2 &&
                  ehiiaFinalApprovalDetail[tabLabel]?.stage1?.status ===
                    "approved" ? (
                    <img
                      src={activeParentTabId ? checked: checked1}
                      alt="Image"
                      style={{ height: "26px", width: "26px",marginLeft:'6px' }}
                    />
                  ) : ehiiaFinalApprovalDetail[tabLabel]?.stage2 &&
                    ehiiaFinalApprovalDetail[tabLabel]?.stage2?.status ===
                      "approved" ? (
                    <img
                      src={activeParentTabId ? checked: checked1}
                      alt="Image"
                      style={{ height: "26px", width: "26px",marginLeft:'6px' }}
                    />
                  ) : (
                    <img
                      src={alert}
                      alt="Image"
                      style={{ height: "20px", width: "26px",marginLeft:'6px' }}
                    />
                  )}
                  </>)}
                  
                </div>
              }
              style={{ textTransform: "capitalize" }}
              classes={{ root: classes.tabBg }}
            />
          ))}
      </Tabs>
      {ehiiaFinalApprovalDetail &&
        Object.keys(ehiiaFinalApprovalDetail).map(
          (tabLabel, index) =>
            activeParentTabId === index &&
            (tabLabel === "overview" ? (
              <Overview 
                projectSummaryData={projectSummaryData}
                overviewData={ehiiaFinalApprovalDetail[tabLabel]}
                key={index}
              />
            ) : (
              renderTabs(ehiiaFinalApprovalDetail[tabLabel], tabLabel)
            )),
        )}
    </>
  );
}
