import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
import notes_bg from "../../../../images/notes_bg.png";
// styles
import useStyles from "./styles";
import Loader from "../../../../component/common/loader/Loader";
import { createEhiiaModule } from "../../../store/actions/ehiiaAction";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import { useToasts } from "react-toast-notifications";
export default function SendReview({
  onHide,
  reviewModalShow,
  setReviewModalShow,
  setIsActionCalled,
  setUpdated,
  stageTypeValue,
  stageValue,
  stageWiseData,
}) {
  const [isAction, setIsAction] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const { addToast } = useToasts();

  const [finalApprovalDetail, setFinalApprovalDetail] = useState({
    action: null,
    type: null,
    email: null,
    comments: null,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFinalApprovalDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    setErrorApiMessage("");
  };
  const submitHandler = () => {
    if (!finalApprovalDetail.comments) {
      setErrorApiMessage("Comment field is required");
      // setIsShow(true);
      return; // Stop the submission if the comments field is empty
    }
    const data = {
      projectId: stageWiseData?.projectId,
      sectionId: "approvalResult",
      questions: {
        approvalResult: {
          stage: stageTypeValue,
          type: stageValue,
          comments: finalApprovalDetail?.comments,
        },
      },
    };

    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          // setIsAction(true);
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setReviewModalShow(false);
          setUpdated(true);
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          setFinalApprovalDetail({});
        } else {
          // setIsAction(true);
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height:toastHeight
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const hideHandle = () => {
    setFinalApprovalDetail({});
    setReviewModalShow(false);
    setErrorApiMessage("");
  };
  const hideHandlerRes = () => {
    setIsShow(false);
    setFinalApprovalDetail({});
  };
  return (
    <>
      <Popup
        onHide={hideHandle}
        open={reviewModalShow}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                Cancel
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Submit
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={notes_bg}
                style={{
                  width: "52.96px",
                  height: "52.96px",
                  marginRight: "10px",
                }}
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#25282B",
                }}
              >
                Add Comment
              </Typography>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Typography className={classes.formHeading}>
                Comments from Approver:
              </Typography>

              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                type="textarea"
                name="comments"
                placeholder="Add your Comments:"
                variant="outlined"
                multiline
                rows={5}
                value={
                  finalApprovalDetail.comments
                    ? finalApprovalDetail.comments
                    : ""
                }
                onChange={handleInputChange}
                error={Boolean(errorApiMessage)}
                helperText={errorApiMessage && <span style={{marginLeft:'-12px'}}>{errorApiMessage}</span>}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Popup>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Send Approval ",
          successMsg: successMessage,
          errorRes: errorApiMessage,
        }}
      />
    </>
  );
}
