import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import Loader from "../../../component/common/loader/Loader";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { sendActivationMail } from "../../store/actions/clientsAction";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ActivationLinkClientPopup({
  activationLinkRec,
  toggleActivateStatus,
  onHide,
  activationLinkModalShow,
  setActivationLinkModalShow,
}) {
  //
  console.log("toggleActivateStatus", toggleActivateStatus);
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.clients);
  const submitHandler = (mode) => {
    setIsAction(false);
    return dispatch(
      sendActivationMail({
        orgId: activationLinkRec,
      }),
    ).then((payload) => {
      console.log("payload",payload);
      if (payload?.payload?.success) {
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:'34px'
          },
        });
        // return;
      }else{
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setIsDone(true);
      onHide();
      setActivationLinkModalShow(false);
    });
  };
  var classes = useStyles();

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={activationLinkModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          {isLoader && <Loader />}
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {apiError ? (
              <Typography className={classes.formActiveHeading}>
                {apiError?.message || "Access denied! Please try again"}
              </Typography>
            ) : (
              <Typography className={classes.formActiveHeading}>
                {toggleActivateStatus ? (
                  <>
                    Are you sure you want to resend reset password link to this
                    client?
                  </>
                ) : (
                  <>
                    Are you sure you want to resend activation link to this
                    client?
                  </>
                )}
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
