import React from 'react'
import AdminList from '../../components/Admin/AdminList'

export default function Admin() {
  return (
    <div>
      <AdminList/>
    </div>
  )
}
