import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../../../../component/common/modal/Popup";
import alertIcon from "../../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../../store/actions/clientsAction";
// styles
import useStyles from "./styles";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  const dispatch = useDispatch();
  console.log("toggleActivateStatus", toggleActivateStatus);
  const { error: apiError, isLoader } = useSelector((state) => state.clients);

  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        clientId: toggleActivateRec,
        status:toggleActivateStatus,
      }),
    ).then(() => {
      onHide();
      setToggleActivateModalShow(false);
    });
  };
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);
 

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent className={classes.dialogBoxContent}>
          <DialogContentText className={classes.formBox}>
            <center>
            <img src={alertIcon} alt="img"/>
            <Typography className={classes.formActiveHeading}>
            Are you sure, you want to deactivate this client?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
