import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { difference as _difference, cloneDeep as _cloneDeep } from "lodash";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Radio,
  RadioGroup,
  Paper,
  Avatar,
  Select,
  MenuItem,
  ListItemText,
  Drawer,
  Divider,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles";
import infoIcon from "../../../../assets/info-icon.svg";
import useStylesAdd from "../../../pages/ehiia/Cases/styles";
import useStylesDetail from "../../../components/Ehiia/Detail/styles";
import checked from "../../../../images/checked.png";
import removeIcon from "../../../../assets/delete-icon.svg";
import expand from "../../../../assets/expand.png";
import hide from "../../../../assets/hide.png";
import Widget from "../../../../component/Widget/Widget";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
// import notes from "./noteEdit.svg";
import plusIcon from "../../../../assets/icons/add.png";

import {
  createEhiiaModule,
  getEhiiaProjectByProjectIdwithSectionId,
} from "../../../store/actions/ehiiaAction";
import whiteEdit from "../../../../images/whiteEdit.png";
import Loader from "../../../../component/common/loader/Loader";
import ManageEngagement from "./ManageEngagement";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";
export default function Engagement({
  setIsUpdateCalled,
  nextQuestionsValue,
  setNextQuestionsValue,
  clientAdminPath,
  setProjectIdValue,
  setionStatus,
  projectIdValue,
  setActiveTabId = () => {},
  type = "",
}) {
  var classes = useStyles();
  var classesAdd = useStylesAdd();
  var classesDetail = useStylesDetail();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  console.log("nextQuestionsValue", nextQuestionsValue);
  const engagementSectionRef = useRef(null);
  const dispatch = useDispatch();
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [uploadSuccessData, setUploadSuccessData] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [nextQuestionsValueState, setNextQuestionsValueState] = useState(null);
  const [isEngagementCompleted, setIsEngagementCompleted] = useState(false);
  const [isApprovalCompleted, setIsApprovalCompleted] = useState(false);

  useEffect(()=>{
    if(setionStatus?.length>0){
      const approvalSection = setionStatus.find(section => section.value === "approvalResult");
        if (approvalSection) {
          setIsApprovalCompleted(approvalSection.completed);
         
        }
    }
  
   },[setionStatus])
  const handleEditClick = (question) => {
    console.log("question", question);

    setSelectedQuestion(question);
    setOpen(true);
  };
  useEffect(() => {
    console.log("hitApi1");
    if (nextSection === "engagement" && engagementSectionRef.current) {
      console.log("hitApi2");
      engagementSectionRef.current.scrollIntoView({ behavior: "smooth" });
      // Add a delay to wait for the smooth scroll to complete
      setTimeout(() => {
        // Add a top margin of 10 pixels after scrolling
        window.scrollBy(0, -50);
      }, 500); // Adjust the delay as needed
    }
  }, [updated, nextSection]);
  // get engagement filled file data
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "engagement",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        const filteredData = payload?.data?.form?.engagement?.questions;
        const nextQuestionData = payload?.data?.form?.nextQuestions;
        // nextQuestionData[0]["action"] = "nextQuestion";
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        setNextQuestionsValue(nextQuestionData);
        setIsEngagementCompleted(payload?.data?.form?.sectionCompleted);
        setUpdated(false);
      }
    });
  }, []);

  useEffect(() => {
    const questions = [];
    console.log("nextQuestionsValue-", nextQuestionsValue);
    nextQuestionsValue &&
      nextQuestionsValue.length &&
      nextQuestionsValue[0]?.type === "multipleActivityFindingsActionTable" &&
      nextQuestionsValue[0]?.findings.map((q) => {
        questions.push({
          groupName: q.groupName,
          values: q.values?.length ? q.values : [""],
          findings: q.findings,
          actionRequired: q.actionRequired,
        });
      });
    setNextQuestionsValueState(questions);
  }, [nextQuestionsValue]);

  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({
        projectId: projectIdValue,
        sectionId: "engagement",
      }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        // Filter out entries with 'questionId' as 'undefined'
        console.log(
          "payload?.data?.form?.engagement?.questions",
          payload?.data?.form?.engagement?.questions,
        );
        const filteredData = payload?.data?.form?.engagement?.questions;
        console.log("filteredData", filteredData);
        const nextQuestionData = payload?.data?.form?.nextQuestions;
        // nextQuestionData[0]["action"] = "nextQuestion";
        console.log("filteredData", filteredData);
        setUploadSuccessData(filteredData);
        // setNextQuestionsValue(payload?.data?.form?.nextQuestions);
        setNextQuestionsValue(nextQuestionData);
        setNextSection(payload?.data?.form?.nextSection);
        setIsEngagementCompleted(payload?.data?.form?.sectionCompleted);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setUpdated(false);
      }
    });
  }, [updated]);

  // useEffect(() => {
  //   console.log("uploadSuccessData", uploadSuccessData);
  //   console.log("updated", updated);
  // }, [uploadSuccessData, updated]);

  const submitDataTableHandler = (e, action = "") => {
    e.preventDefault();

    if (
      (nextQuestionsValue &&
        nextQuestionsValue.length > 0 &&
        nextQuestionsValue[0]?.type === "patientEngagementApproval") ||
      nextQuestionsValue[0]?.type === "staffEngagementApproval" ||
      isEngagementCompleted
    ) {
      return navigate(
        `/${
          clientAdminPath === "staff" ? "staff" : "admin"
        }/manage-module/ehiia`,
      );
    }

    let engagementPayload;
    if (
      nextQuestionsValue?.[0] &&
      nextQuestionsValue?.[0].type === "insightsActionPlanTable"
    ) {
      const insights = [];

      engagementPayload = {
        ...nextQuestionsValue?.[0],
        insights: nextQuestionsValue?.[0].insights.map((insight) => ({
          ...insight,
          engagementGroups: insight.engagementGroups
            ? insight.engagementGroups.map((group) =>
                group.replace("other-", ""),
              )
            : null,
        })),
      };
    } else {
      engagementPayload = nextQuestionsValue?.[0];
    }
    if (
      nextQuestionsValue?.[0] &&
      nextQuestionsValue?.[0].type === "actionPlanTable"
    ) {
      engagementPayload = {
        ...nextQuestionsValue?.[0],
        findings: nextQuestionsValue?.[0].findings.map((finding) => ({
          ...finding,
          expectedCompletionDate: finding.expectedCompletionDate
            ? finding.expectedCompletionDate
            : moment().format("YYYY-MM-DD"),
        })),
      };
    }
    console.log("engagementPayload-", nextQuestionsValue, engagementPayload);
    setIsLoading(true);
    const data = {
      projectId: projectIdValue,
      sectionId: "engagement",
      questions: {
        // engagement: nextQuestionsValue,
        engagement: engagementPayload,
      },
    };
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          // hideHandler();
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);

          setNextQuestionsValue(payload?.data?.nextQuestions);
          setIsUpdateCalled(true);
          setUpdated(true);
          // if(payload?.data?.nextSection === "approvalResult"){
          //   setActiveTabId(4);
          // }
          if (action === "save") {
            navigate(
              `/${
                clientAdminPath === "staff" ? "staff" : "admin"
              }/manage-module/ehiia`,
            );
          }
        } else {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
    setIsActionCalled(false);
  };
  const [engagementDetail, setEngagementDetail] = useState({
    comments_patient: null,
    comments_staff: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEngagementDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    setErrorApiMessage("");
  };
  const sendApprovalBasedOnType = (item) => {
    console.log("item", item);
    console.log("item-293", engagementDetail);
    // e.preventDefault();
    // if (!engagementDetail.comments) {
    //   console.log("Comments field is required");
    //   setErrorApiMessage("Comments field is required");
    //   return;
    // }
    const data = {
      projectId: projectIdValue,
      sectionId: "engagement",
      questions: {
        engagement: {
          questionId: item.questionId,
          type: item.type,
          comments:
            item.type === "patientEngagementApproval"
              ? engagementDetail?.comments_patient
              : engagementDetail?.comments_staff,
        },
      },
    };
    console.log("data-311", data);
    dispatch(createEhiiaModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          setSuccessMessage(payload?.message);
          // setIsShow(true);
          setErrorApiMessage(null);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });
          setNextQuestionsValue(payload?.data?.nextQuestions);
          setIsUpdateCalled(true);
          setUpdated(true);
        } else {
          const toastHeight = payload?.message.length > 35 ? "auto" : "34px";
          console.log("payload?.error", payload);
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              height: toastHeight,
            },
          });
          // setIsShow(true);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    setSelectedQuestion(null);

    setModalShow(false);
    setOpen(false);
    console.log("clear radio buttons");
  };

  // engagement handlers for insights plan question
  const insightsPlanActionsHandler = ({
    question,
    action = "",
    group,
    insightsIndex,
    setQuestion,
    questionIndex,
  }) => {
    console.log(
      "text called",
      question,
      action,
      group,
      insightsIndex,
      setQuestion,
      questionIndex,
    );
    let localQuestion;
    if (Array?.isArray(question) && questionIndex >= 0) {
      console.log("1st call if");
      localQuestion = question?.[questionIndex];
    } else {
      console.log("1st call else");
      localQuestion = question;
    }
    console.log("1st call", localQuestion);
    if (
      localQuestion?.insights[insightsIndex] &&
      localQuestion?.type == "insightsActionPlanTable"
    ) {
      console.log("2nd call");
      if (!localQuestion?.insights?.[insightsIndex]?.engagementGroups?.length) {
        localQuestion.insights[insightsIndex].engagementGroups = [];
      }
      switch (action) {
        case "add": {
          localQuestion?.insights?.[insightsIndex]?.engagementGroups?.push(
            group,
          );
          break;
        }
        case "remove": {
          localQuestion.insights[insightsIndex].engagementGroups =
            localQuestion?.insights?.[insightsIndex]?.engagementGroups?.filter(
              (x) => !x.includes(group), //x != group,
            );
          break;
        }
        case "update": {
          const diffs = _difference(
            localQuestion?.insights?.[insightsIndex]?.engagementGroups,
            localQuestion?.other?.engagementGroups,
          );
          localQuestion.insights[insightsIndex].engagementGroups =
            localQuestion?.insights?.[insightsIndex]?.engagementGroups?.filter(
              (x) => !diffs?.includes(x),
            );
          localQuestion?.insights?.[insightsIndex]?.engagementGroups?.push(
            group,
          );
        }
      }
      console.log("localQuestion-", localQuestion);
      if (Array?.isArray(question) && questionIndex >= 0) {
        const newQuestions = _cloneDeep(question);
        newQuestions[questionIndex] = localQuestion;
        setQuestion(_cloneDeep(newQuestions));
      } else {
        setQuestion(_cloneDeep(localQuestion));
      }
    }
  };

  // engagement action plan handlers for insights plan question
  const insightsPlanActionsHandlerNew = ({
    action = "",
    insights = [],
    insightIndex,
    groupIndex,
    details = "",
    groups = [],
  }) => {
    if (insightIndex > -1) {
      const groupElement = insights?.[insightIndex];
      if (groupElement?.groupName) {
        switch (action) {
          case "updateGroup": {
            if (groups?.length) {
              const removedGroupsIndex = [];
              groupElement?.engagementGroups?.forEach((x, index) => {
                if (!groups?.includes(x)) {
                  removedGroupsIndex?.push(index);
                }
              });
              if (removedGroupsIndex?.length) {
                removedGroupsIndex?.forEach((removedIndex) => {
                  groupElement?.engagementGroups?.splice(removedIndex, 1);
                  groupElement?.details?.splice(removedIndex, 1);
                });
              }
              groupElement.engagementGroups = [...groups];
              const detailsLengthDiff =
                groupElement?.engagementGroups?.length >
                groupElement?.details?.length;
              if (detailsLengthDiff) {
                groupElement.details = [
                  ...groupElement?.details,
                  ...Array(detailsLengthDiff)?.fill(""),
                ];
              }
            } else {
              groupElement.engagementGroups = [];
              groupElement.details = [];
            }
            break;
          }
          case "updateDetails": {
            if (groupIndex > -1) {
              groupElement.details[groupIndex] = details || ``;
            }
            break;
          }
          default: {
            break;
          }
        }
        insights[insightIndex] = groupElement;
        setNextQuestionsValue((prevValue) => {
          const newValue = [...prevValue];
          newValue[0].insights = insights;
          return newValue;
        });
      }
    }
  };
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const handleCheckboxChange = (checked, rowIndex) => {
    if (checked) {
      setEditableRowIndex(rowIndex);
    } else {
      setEditableRowIndex(null);
    }
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };

  const actionCancel = (e) => {
    navigate(
      `/${clientAdminPath === "staff" ? "staff" : "admin"}/manage-module/ehiia`,
    );
  };
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };

  const updateValues = (arr) => {
    let uArr = [];
    if (arr && arr.length) {
      arr.map((value) => {
        if (value?.includes("other-")) {
          value = "other-";
        }
        if (value) {
          uArr.push(value);
        }
      });
    }
    console.log("uArr-", arr, uArr);
    return uArr;
  };
  // info model
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("anchor", anchor);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const drawerWidth = 240;
  return (
    <Grid container spacing={4} style={{ marginBottom: "20px" }}>
      <Grid item xs={12}>
        <Widget noBodyPadding>
          <>
            {/* {open && ( */}
            <ManageEngagement
              open={open}
              onHide={hideHandle}
              title={"Edit Your Answer"}
              selectedQuestion={selectedQuestion}
              setSelectedQuestion={setSelectedQuestion}
              nextQuestionsValue={nextQuestionsValue}
              setNextQuestionsValue={setNextQuestionsValue}
              setUpdated={setUpdated}
              projectIdValue={projectIdValue}
              setModalShow={setModalShow}
              insightsPlanActionsHandler={insightsPlanActionsHandler}
            />
            {isLoading && (
              <Loader message={"Please wait, loading questions..."} />
            )}
            {/* )} */}
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{
                visibility: isLoading ? "hidden" : "visible",
                backgroundColor: isLoading ? "rgb(200 232 221 / 30%)" : "#fff",
              }}
            >
              <>
                <Grid
                  container
                  spacing={2}
                  className={classes.formBox}
                  style={{
                    visibility: isLoading ? "hidden" : "visible",
                    backgroundColor: isLoading
                      ? "rgb(200 232 221 / 30%)"
                      : "#fff",
                  }}
                >
                  {/* {uploadSuccessData && (
                    <img src={notes} className={classes.noteIcons} />
                  )} */}
                  {uploadSuccessData &&
                    uploadSuccessData.map((element, i) => {
                      const {
                        key,
                        title,
                        type,
                        questionId,
                        questionSerial,
                        value,
                        options,
                        action,
                        additionalQuesTitle,
                        optional,
                      } = element;

                      return (
                        <Grid
                          container
                          spacing={2}
                          style={{ padding: "0px 10px" }}
                        >
                          {/* <img src={notes} className={classes.noteIcons} /> */}
                          <Grid
                            item
                            xs={12}
                            md={11}
                            className={classes.textGroup}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "6px",
                                cursor: "pointer",
                              }}
                            >
                              <Typography
                                className={classes.formHeading}
                                style={{ width: "90%" }}
                              >
                                {type === "staffEngagementApproval" ||
                                type === "patientEngagementApproval" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "16px",
                                        marginTop: "16px",
                                        width: "100%",
                                      }}
                                    >
                                      <span
                                        style={{
                                          textAlign: "center",
                                          background: "#F3F3F3",
                                          padding: "6px 16px",
                                          fontSize: "16px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Please send the engagement plan for
                                        review to the Patient/Staff Engagement
                                        Specialist
                                      </span>
                                      {show &&
                                      checkQuestionId ===
                                        element?.questionId ? (
                                        <div
                                          style={{ marginLeft: "8px" }}
                                          onClick={() =>
                                            handleHideContent(questionId)
                                          }
                                        >
                                          <img
                                            src={hide}
                                            style={{ marginTop: "5px" }}
                                            alt="hide"
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          style={{ marginLeft: "8px" }}
                                          onClick={() =>
                                            handleShowContent(questionId)
                                          }
                                        >
                                          <img
                                            src={expand}
                                            style={{ marginTop: "5px" }}
                                            alt="expand"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {questionSerial} {title} {additionalQuesTitle && <span  dangerouslySetInnerHTML={{
                                  __html: additionalQuesTitle,
                                }}></span>}
                                    {""}
                                    {!optional && (
                                      <span
                                        style={{
                                          color: "#f50b0be1",
                                          fontSize: "11px",
                                        }}
                                      >
                                        *
                                      </span>
                                    )}{" "}
                                    {element.info && (
                                      <>
                                        <img
                                          onClick={toggleDrawer(
                                            element.info,
                                            true,
                                            element.info,
                                          )}
                                          style={{
                                            cursor: "pointer",
                                            marginTop: "6px",
                                            height: "16px",
                                          }}
                                          src={infoIcon}
                                          alt="img"
                                        />
                                        <Drawer
                                          style={{
                                            width: "240px",
                                            maxWidth: "240px",
                                          }}
                                          sx={{
                                            paddingRight: 4,
                                            "& .MuiDrawer-paper": {
                                              boxSizing: "border-box",
                                              width: drawerWidth,
                                            },
                                          }}
                                          elevation={0}
                                          anchor="right"
                                          open={state[element.info]}
                                          onClose={toggleDrawer(
                                            element.info,
                                            false,
                                            null,
                                          )}
                                        >
                                          <IconButton
                                            style={{
                                              position: "absolute",
                                              marginLeft: "-14px",
                                            }}
                                            onClick={toggleDrawer(
                                              element.info,
                                              false,
                                              element.info,
                                            )}
                                          >
                                            <ArrowForwardIosRounded />
                                          </IconButton>
                                          <p
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              paddingLeft: "24px",
                                              textAlign: "center",
                                              width: "260px",
                                            }}
                                          >
                                            {element.title}
                                          </p>
                                          <Divider />
                                          <span
                                            style={{
                                              padding: "6px 10px",
                                              width: "260px",
                                              fontSize: "12px",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: element?.info,
                                            }}
                                          >
                                        
                                          </span>
                                        </Drawer>
                                      </>
                                    )}
                                  </>
                                )}
                              </Typography>
                              {type === "staffEngagementApproval" ||
                              type === "patientEngagementApproval" ? (
                                ""
                              ) : (
                                <>
                                  {show &&
                                    checkQuestionId === element?.questionId &&
                                    action !== "nextQuestion" && (
                                      <>
                                        <div
                                          style={{
                                            backgroundColor: "#FEAA00",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() =>
                                            handleEditClick(element)
                                          }
                                        >
                                          <img
                                            style={{
                                              width: "14px",
                                              height: "14px",
                                            }}
                                            src={whiteEdit}
                                            alt="Edit Icon"
                                          />
                                        </div>
                                      </>
                                    )}

                                  {show &&
                                  checkQuestionId === element?.questionId ? (
                                    <div
                                      style={{ marginLeft: "8px" }}
                                      onClick={() =>
                                        handleHideContent(questionId)
                                      }
                                    >
                                      <img
                                        src={hide}
                                        style={{ marginTop: "5px" }}
                                        alt="hide"
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      style={{ marginLeft: "8px" }}
                                      onClick={() =>
                                        handleShowContent(questionId)
                                      }
                                    >
                                      <img
                                        src={expand}
                                        style={{ marginTop: "5px" }}
                                        alt="expand"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            <Grid item xs={12} md={12}>
                              {show &&
                                checkQuestionId === element?.questionId && (
                                  <>
                                    {type === "insightsActionTable" ? (
                                      <>
                                        <TableContainer
                                          style={{ marginTop: "16px" }}
                                          sx={{ boxShadow: 0 }}
                                          component={Paper}
                                        >
                                          <Table
                                            sx={{
                                              minWidth: "100%",
                                              boxShadow: 0,
                                            }}
                                            aria-label="simple table"
                                          >
                                            <TableHead
                                              style={{
                                                backgroundColor: "#EDFDFA",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Identified Groups
                                                </TableCell>

                                                <TableCell
                                                  align="center"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Engagement Required
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {element.insights.length > 0 ? (
                                                element.insights.map(
                                                  (row, rowIndex) => (
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        padding: "4px",
                                                      }}
                                                      key={row.group}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "60%",
                                                          fontSize: "14px",
                                                          padding: "10px",
                                                        }}
                                                      >
                                                        {row.groupName}
                                                      </TableCell>

                                                      <TableCell
                                                        align="center"
                                                        style={{
                                                          width: "40%",
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        }}
                                                      >
                                                        <FormControl>
                                                          <RadioGroup
                                                            style={{
                                                              display: "flex",
                                                              fontSize: "14px",
                                                            }}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="actionRequired"
                                                            value={
                                                              row.actionRequired
                                                            }
                                                            onChange={
                                                              action !==
                                                              "nextQuestion"
                                                                ? () => {}
                                                                : (e) => {
                                                                    const text =
                                                                      JSON.parse(
                                                                        e.target
                                                                          .value,
                                                                      );
                                                                    console.log(
                                                                      e.target
                                                                        .value,
                                                                    );
                                                                    console.log(
                                                                      "text",
                                                                      text,
                                                                    );
                                                                    const updatedFindingTable =
                                                                      nextQuestionsValue?.[0].insights.map(
                                                                        (
                                                                          t,
                                                                          i,
                                                                        ) => {
                                                                          if (
                                                                            i ===
                                                                            rowIndex
                                                                          ) {
                                                                            const updatedData =
                                                                              {
                                                                                ...t,
                                                                              };
                                                                            updatedData.actionRequired =
                                                                              text;
                                                                            return updatedData;
                                                                          }
                                                                          return t;
                                                                        },
                                                                      );
                                                                    const updatedNextQuestions =
                                                                      [
                                                                        ...nextQuestionsValue,
                                                                      ];
                                                                    updatedNextQuestions[0].insights =
                                                                      updatedFindingTable;
                                                                    setNextQuestionsValue(
                                                                      updatedNextQuestions,
                                                                    );
                                                                  }
                                                            }
                                                          >
                                                            <FormControlLabel
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              value={true}
                                                              control={
                                                                <Radio />
                                                              }
                                                              label="Yes"
                                                            />
                                                            <FormControlLabel
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              value={false}
                                                              control={
                                                                <Radio />
                                                              }
                                                              label="No"
                                                            />
                                                          </RadioGroup>
                                                        </FormControl>
                                                      </TableCell>
                                                    </TableRow>
                                                  ),
                                                )
                                              ) : (
                                                <>
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "60%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>

                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        width: "40%",
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    ) : type === "insightsActionPlanTable" ? (
                                      <>
                                        <TableContainer
                                          style={{ marginTop: "16px" }}
                                          sx={{ boxShadow: 0 }}
                                          component={Paper}
                                        >
                                          <Table
                                            sx={{
                                              minWidth: "100%",
                                              boxShadow: 0,
                                            }}
                                            aria-label="simple table"
                                          >
                                            <TableHead
                                              style={{
                                                backgroundColor: "#EDFDFA",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Identified Groups
                                                </TableCell>

                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Selected Engagement Options
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Details
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {element?.insights.length > 0 ? (
                                                element?.insights?.map(
                                                  (
                                                    engageGrp,
                                                    engageGrpIndex,
                                                  ) => {
                                                    const groupName =
                                                      engageGrp.groupName;
                                                    const engagementGroups =
                                                      engageGrp.engagementGroups ||
                                                      [];
                                                    const details =
                                                      engageGrp.details || [];
                                                    // Determine the number of rows needed
                                                    const numRows = Math.max(
                                                      engagementGroups.length,
                                                      details.length,
                                                    );
                                                    return Array.from({
                                                      length: numRows,
                                                    }).map((_, rowIndex) => (
                                                      <>
                                                        <TableRow
                                                          key={`${engageGrpIndex}-${rowIndex}`}
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          }}
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              {
                                                                border:
                                                                  "1px solid #B6B6B6",
                                                              },
                                                          }}
                                                        >
                                                          {rowIndex === 0 && (
                                                            <TableCell
                                                              component="th"
                                                              scope="row"
                                                              style={{
                                                                border:
                                                                  "1px solid #B6B6B6",
                                                                width: "30%",
                                                                fontSize:
                                                                  "14px",
                                                                verticalAlign:
                                                                  "top",
                                                              }}
                                                              rowSpan={numRows}
                                                            >
                                                              {groupName}
                                                            </TableCell>
                                                          )}
                                                          <TableCell
                                                            align="left"
                                                            style={{
                                                              border:
                                                                "1px solid #B6B6B6",
                                                              width: "30%",
                                                              verticalAlign:
                                                                "top",
                                                              padding: "10px",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {engagementGroups[
                                                              rowIndex
                                                            ] || "N/A"}
                                                          </TableCell>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                              border:
                                                                "1px solid #B6B6B6",
                                                              width: "30%",
                                                              padding: "10px",
                                                              verticalAlign:
                                                                "top",
                                                            }}
                                                          >
                                                            {details[
                                                              rowIndex
                                                            ] || "N/A"}
                                                          </TableCell>
                                                        </TableRow>
                                                      </>
                                                    ));
                                                  },
                                                )
                                              ) : (
                                                <>
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        },
                                                    }}
                                                  >
                                                    <TableCell
                                                      component="th"
                                                      scope="row"
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "20%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "25%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "auto",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    ) : type === "staffEngagementApproval" ||
                                      type === "patientEngagementApproval" ? (
                                      <>
                                        <TableContainer
                                          style={{ marginTop: "16px" }}
                                          component={Paper}
                                        >
                                          <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                          >
                                            <TableHead
                                              style={{
                                                backgroundColor: "#EDFDFA",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {element?.type ===
                                                  "patientEngagementApproval"
                                                    ? "Patient "
                                                    : "Staff "}
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              <TableRow
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border: 0,
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "50%",
                                                  }}
                                                  component="th"
                                                  scope="row"
                                                >
                                                  <>
                                                    <Typography
                                                      className={
                                                        classes.formHeading
                                                      }
                                                      style={{
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Sent On Date:{" "}
                                                      {moment(
                                                        element?.sentAt,
                                                      ).format("lll")}
                                                    </Typography>
                                                  </>
                                                  {element.history.map(
                                                    (review, reviewIndex) =>
                                                      review?.status ===
                                                        "pending" ||
                                                      review?.status ===
                                                        "approved" ? (
                                                        <div key={reviewIndex}>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            style={{
                                                              backgroundColor:
                                                                review?.byEntity ===
                                                                "team"
                                                                  ? "#EDF8F6"
                                                                  : "#ffffff",
                                                              // border: "1px solid #03997B",
                                                              marginTop: "10px",
                                                              borderRadius:
                                                                "5px",
                                                              padding: "8px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <Typography
                                                                className={
                                                                  classes.formHeading
                                                                }
                                                              >
                                                                Comment From{" "}
                                                                {review?.byEntity ===
                                                                "team"
                                                                  ? "Engagement Specialist"
                                                                  : "Applicant"}{" "}
                                                                :
                                                              </Typography>
                                                              <Typography
                                                                style={{
                                                                  display:
                                                                    "contents",
                                                                  color:
                                                                    "#52575C",
                                                                  fontWeight:
                                                                    "400",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              >
                                                                {moment(
                                                                  review?.timestamp,
                                                                ).format("lll")}
                                                              </Typography>
                                                            </div>
                                                            <Typography
                                                              style={{
                                                                color:
                                                                  "#201F1E",
                                                                fontSize:
                                                                  "13px",
                                                                paddingLeft:
                                                                  "4px",
                                                                textAlign:
                                                                  "justify",
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                            >
                                                              <>
                                                                {
                                                                  review?.comments
                                                                }
                                                              </>
                                                            </Typography>
                                                          </Grid>
                                                        </div>
                                                      ) : review?.status ===
                                                        "rejected" ? (
                                                        <div key={reviewIndex}>
                                                          <Grid
                                                            item
                                                            xs={12}
                                                            md={12}
                                                            style={{
                                                              backgroundColor:
                                                                "#EDF8F6",
                                                              marginTop: "10px",
                                                              padding: "8px",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <Typography
                                                                className={
                                                                  classes.formHeading
                                                                }
                                                              >
                                                                Comment From
                                                                {review?.byEntity ===
                                                                "team"
                                                                  ? "Engagement Specialist"
                                                                  : "Applicant"}{" "}
                                                                :
                                                              </Typography>
                                                              <Typography
                                                                style={{
                                                                  display:
                                                                    "contents",
                                                                  color:
                                                                    "#52575C",
                                                                  fontWeight:
                                                                    "400",
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                              >
                                                                {moment(
                                                                  review?.timestamp,
                                                                ).format("lll")}
                                                              </Typography>
                                                            </div>
                                                            <Typography
                                                              style={{
                                                                color:
                                                                  "#201F1E",
                                                                fontSize:
                                                                  "13px",
                                                                paddingLeft:
                                                                  "4px",
                                                                textAlign:
                                                                  "justify",
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                            >
                                                              <>
                                                                {
                                                                  review?.comments
                                                                }
                                                              </>
                                                            </Typography>
                                                          </Grid>
                                                        </div>
                                                      ) : null,
                                                  )}
                                                  {element?.status ===
                                                    "approved" && (
                                                    <>
                                                      <Typography
                                                        className={
                                                          classes.formHeading
                                                        }
                                                        style={{
                                                          fontSize: "16px",
                                                          display: "flex",
                                                          alignItems: "center",
                                                          marginTop: "8px",
                                                        }}
                                                      >
                                                        Your Engagement Plan Is
                                                        Approved Successfully{" "}
                                                        <Avatar
                                                          style={{
                                                            borderRadius: "0px",
                                                            marginLeft: "0px",
                                                          }}
                                                          src={checked}
                                                        />
                                                      </Typography>
                                                      <Typography
                                                        className={
                                                          classes.formHeading
                                                        }
                                                        style={{
                                                          fontSize: "16px",
                                                          marginTop: "6px",
                                                        }}
                                                      >
                                                        Engagement Plan Approval
                                                        Date:{" "}
                                                        <span
                                                          style={{
                                                            fontWeight: "400",
                                                          }}
                                                        >
                                                          {moment(
                                                            element?.approvedAt,
                                                          ).format("lll")}
                                                        </span>
                                                      </Typography>
                                                      <Typography
                                                        className={
                                                          classes.formHeading
                                                        }
                                                        style={{
                                                          fontSize: "16px",
                                                          marginTop: "6px",
                                                        }}
                                                      >
                                                        Engagement Plan Approved
                                                        By:{" "}
                                                        <span
                                                          style={{
                                                            fontWeight: "400",
                                                          }}
                                                        >
                                                          {element?.name}
                                                        </span>
                                                      </Typography>
                                                    </>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    ) : type ===
                                      "multipleActivityFindingsActionTable" ? (
                                      <>
                                        <TableContainer
                                          style={{ marginTop: "16px" }}
                                          sx={{ boxShadow: 0 }}
                                          component={Paper}
                                        >
                                          <Table
                                            sx={{
                                              minWidth: "100%",
                                              boxShadow: 0,
                                            }}
                                            aria-label="simple table"
                                          >
                                            <TableHead
                                              style={{
                                                backgroundColor: "#EDFDFA",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Identified Groups
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  List of Engagement Activity
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Findings
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Is Action Required ?
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {element.findings.length > 0 ? (
                                                element.findings.map(
                                                  (finding, rowIndex) => (
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      key={rowIndex}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "20%",
                                                          fontSize: "14px",
                                                          verticalAlign: "top",
                                                        }}
                                                      >
                                                        {finding.groupName}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "left",
                                                          fontSize: "14px",
                                                          padding: "0px",
                                                          width: "30%",overflowWrap:'anywhere'
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "grid",
                                                            flexWrap: "wrap",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {finding.values &&
                                                          finding.values
                                                            .length ? (
                                                            <ul>
                                                              {finding.values.map(
                                                                (
                                                                  value,
                                                                  linkIndex,
                                                                ) => (
                                                                  <li
                                                                    key={
                                                                      linkIndex
                                                                    }
                                                                  >
                                                                    {value}
                                                                  </li>
                                                                ),
                                                              )}
                                                            </ul>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </TableCell>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "30%",
                                                          fontSize: "14px",
                                                          verticalAlign: "top",overflowWrap:'anywhere'
                                                        }}
                                                      >
                                                        {finding?.findings ||
                                                          ""}
                                                      </TableCell>
                                                      <TableCell
                                                        align="center"
                                                        style={{
                                                          width: "20%",verticalAlign:'top'
                                                        }}
                                                      >
                                                        <FormControl>
                                                          <RadioGroup
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={
                                                              finding?.actionRequired ===
                                                              true
                                                                ? "yes"
                                                                : "no"
                                                            }
                                                          >
                                                            <FormControlLabel
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              value="yes"
                                                              control={
                                                                <Radio />
                                                              }
                                                              label="Yes"
                                                            />
                                                            <FormControlLabel
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              value="no"
                                                              control={
                                                                <Radio />
                                                              }
                                                              label="No"
                                                            />
                                                          </RadioGroup>
                                                        </FormControl>
                                                      </TableCell>
                                                    </TableRow>
                                                  ),
                                                )
                                              ) : (
                                                <>
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        },
                                                    }}
                                                  >
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "25%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "25%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "25%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "25%",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                        padding:
                                                          "0px 16px 16px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    ) : type === "multipleFreeTextTable" ? (
                                      <>
                                        <TableContainer
                                          style={{ marginTop: "16px" }}
                                          sx={{ boxShadow: 0 }}
                                          component={Paper}
                                        >
                                          <Table
                                            sx={{
                                              minWidth: "100%",
                                              boxShadow: 0,
                                            }}
                                            aria-label="simple table"
                                          >
                                            <TableHead
                                              style={{
                                                backgroundColor: "#EDFDFA",
                                              }}
                                            >
                                              <TableRow>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Identified Groups
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  List of Engagement Activity
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {element.findings.length > 0 ? (
                                                element.findings.map(
                                                  (finding, rowIndex) => (
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      key={rowIndex}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "30%",
                                                          fontSize: "14px",
                                                          verticalAlign: "top",
                                                        }}
                                                      >
                                                        {finding.groupName}
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "left",
                                                          fontSize: "14px",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "grid",
                                                            flexWrap: "wrap",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {finding.values &&
                                                          finding.values
                                                            .length ? (
                                                            <ul>
                                                              {finding.values.map(
                                                                (
                                                                  value,
                                                                  linkIndex,
                                                                ) => (
                                                                  <li
                                                                    key={
                                                                      linkIndex
                                                                    }
                                                                  >
                                                                    {value}
                                                                  </li>
                                                                ),
                                                              )}
                                                            </ul>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </TableCell>
                                                    </TableRow>
                                                  ),
                                                )
                                              ) : (
                                                <>
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        },
                                                    }}
                                                  >
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        width: "30%",
                                                        fontSize: "14px",
                                                        textAlign: "center",
                                                        // verticalAlign: "top",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                    <TableCell
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                        textAlign: "center",
                                                        fontSize: "14px",
                                                        padding: "0px",
                                                      }}
                                                    >
                                                      None
                                                    </TableCell>
                                                  </TableRow>
                                                </>
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </>
                                    ) : type === "findingsActionTable" ? (
                                      <>
                                        <Grid item xs={12} md={12}>
                                          <TableContainer
                                            style={{ marginTop: "16px" }}
                                            sx={{ boxShadow: 0 }}
                                            component={Paper}
                                          >
                                            <Table
                                              sx={{
                                                minWidth: "100%",
                                                boxShadow: 0,
                                              }}
                                              aria-label="simple table"
                                            >
                                              <TableHead
                                                style={{
                                                  backgroundColor: "#EDFDFA",
                                                }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Identified Groups
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    What are your Findings?
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Is Action Required?
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {element.findings.length > 0 ? (
                                                  element.findings.map(
                                                    (row, rowIndex) => (
                                                      <TableRow
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        }}
                                                        key={row.groupName}
                                                      >
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "30%",
                                                            fontSize: "14px",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {row.groupName}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "50%",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {row?.findings || ""}
                                                        </TableCell>
                                                        <TableCell
                                                          align="center"
                                                          style={{
                                                            width: "20%",
                                                          }}
                                                        >
                                                          <FormControl>
                                                            <RadioGroup
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                              row
                                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                                              name="row-radio-buttons-group"
                                                              value={
                                                                row?.actionRequired ===
                                                                true
                                                                  ? "yes"
                                                                  : "no"
                                                              }
                                                            >
                                                              <FormControlLabel
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                value="yes"
                                                                control={
                                                                  <Radio />
                                                                }
                                                                label="Yes"
                                                              />
                                                              <FormControlLabel
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                value="no"
                                                                control={
                                                                  <Radio />
                                                                }
                                                                label="No"
                                                              />
                                                            </RadioGroup>
                                                          </FormControl>
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )
                                                ) : (
                                                  <>
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "30%",
                                                          fontSize: "14px",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "center",
                                                          fontSize: "14px",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          textAlign: "center",
                                                          fontSize: "14px",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </>
                                    ) : type === "actionPlanTable" ? (
                                      <>
                                        <Grid item xs={12} md={12}>
                                          <TableContainer
                                            style={{ marginTop: "16px" }}
                                            component={Paper}
                                          >
                                            <Table
                                              sx={{ minWidth: 650 }}
                                              aria-label="simple table"
                                            >
                                              <TableHead
                                                style={{
                                                  backgroundColor: "#EDFDFA",
                                                }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Identified Groups
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Findings
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Issues
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Action Required
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      textAlign: "center",
                                                    }}
                                                    align="center"
                                                  >
                                                    Expected Date of Completion
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {element.findings.length > 0 ? (
                                                  element.findings.map(
                                                    (row, rowIndex) => (
                                                      <TableRow
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        }}
                                                        key={rowIndex}
                                                      >
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "20%",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                        >
                                                          {row?.groupName}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "20%",verticalAlign:'top',whiteSpace:'normal'
                                                          }}
                                                        >
                                                          {row?.findings}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "20%",verticalAlign:'top'
                                                          }}
                                                        >
                                                          {row?.issues}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",verticalAlign:'top'
                                                          }}
                                                        >
                                                          {/* <TextField
                                                    size="small"
                                                    style={{ marginTop: "4px" }}
                                                    className={`${classes.txtForm} ${classes.disabledTextField}`}
                                                    fullWidth
                                                    placeholder="Please enter your action plan"
                                                    variant="outlined"
                                                    name="actionPlan"
                                                    multiline
                                                    value={
                                                      row?.actionPlan || ""
                                                    }
                                                  /> */}
                                                          {row?.actionPlan ||
                                                            ""}
                                                        </TableCell>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "20%",
                                                            textAlign: "center",verticalAlign:'top'
                                                          }}
                                                        >
                                                          {
                                                            row?.expectedCompletionDate
                                                          }
                                                        </TableCell>
                                                      </TableRow>
                                                    ),
                                                  )
                                                ) : (
                                                  <>
                                                    <TableRow
                                                      style={{
                                                        border:
                                                          "1px solid #B6B6B6",
                                                      }}
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          {
                                                            border:
                                                              "1px solid #B6B6B6",
                                                          },
                                                      }}
                                                    >
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                      <TableCell
                                                        style={{
                                                          border:
                                                            "1px solid #B6B6B6",
                                                          width: "25%",
                                                          textAlign: "center",
                                                          // verticalAlign: "top",
                                                          padding:
                                                            "0px 16px 16px",
                                                        }}
                                                      >
                                                        None
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </>
                                    ) : (
                                      "Not Available"
                                    )}
                                  </>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </>
              <>
                {nextSection === "engagement" &&
                  nextQuestionsValue.map((element, i) => {
                    const {
                      key,
                      title,
                      type,
                      questionId,
                      questionSerial,
                      value,
                      options,
                      status,
                      sendAt,
                      optional,additionalQuesTitle
                    } = element;

                    return (
                      <>
                        <Grid
                          ref={engagementSectionRef}
                          container
                          spacing={2}
                          className={classes.formBox}
                          key={`${questionSerial}-${i}`}
                        >
                          {/* <img src={notes} className={classes.noteIcons} /> */}
                          <Grid
                            item
                            xs={12}
                            md={11}
                            className={classes.textGroup}
                          >
                            {type !== "patientEngagementApproval" &&
                              type !== "staffEngagementApproval" && (
                                <>
                                  <Typography className={classes.formHeading}>
                                    {questionSerial}
                                    {title} {additionalQuesTitle && <span  dangerouslySetInnerHTML={{
                                  __html: additionalQuesTitle,
                                }}></span>}
                                    {""}
                                    {!optional && (
                                      <span
                                        style={{
                                          color: "#f50b0be1",
                                          fontSize: "11px",
                                        }}
                                      >
                                        *
                                      </span>
                                    )}
                                    {element.info && (
                                      <>
                                        <img
                                          onClick={toggleDrawer(
                                            element.info,
                                            true,
                                            element.info,
                                          )}
                                          style={{
                                            cursor: "pointer",
                                            marginTop: "6px",
                                            height: "16px",
                                          }}
                                          src={infoIcon}
                                          alt="img"
                                        />
                                        <Drawer
                                          style={{
                                            width: "240px",
                                            maxWidth: "240px",
                                          }}
                                          sx={{
                                            paddingRight: 4,
                                            "& .MuiDrawer-paper": {
                                              boxSizing: "border-box",
                                              width: drawerWidth,
                                            },
                                          }}
                                          elevation={0}
                                          anchor="right"
                                          open={state[element.info]}
                                          onClose={toggleDrawer(
                                            element.info,
                                            false,
                                            null,
                                          )}
                                        >
                                          <IconButton
                                            style={{
                                              position: "absolute",
                                              marginLeft: "-14px",
                                            }}
                                            onClick={toggleDrawer(
                                              element.info,
                                              false,
                                              element.info,
                                            )}
                                          >
                                            <ArrowForwardIosRounded />
                                          </IconButton>
                                          <p
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "13px",
                                              paddingLeft: "24px",
                                              textAlign: "center",
                                              width: "260px",
                                            }}
                                          >
                                            {element.title}
                                          </p>
                                          <Divider />
                                          <span
                                            style={{
                                              padding: "6px 10px",
                                              width: "260px",
                                              fontSize: "12px",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html: element?.info,
                                            }}
                                          >
                                          </span>
                                        </Drawer>
                                      </>
                                    )}
                                  </Typography>
                                </>
                              )}
                            <Grid item xs={12} md={12}>
                              {type === "insightsActionTable" ? (
                                <>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    sx={{ boxShadow: 0 }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: "100%", boxShadow: 0 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{ backgroundColor: "#EDFDFA" }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>

                                          <TableCell
                                            align="center"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Engagement Required
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element.insights.length > 0 ? (
                                          element.insights.map(
                                            (row, rowIndex) => (
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                key={row.group}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "60%",
                                                  }}
                                                >
                                                  {row.groupName}
                                                </TableCell>

                                                <TableCell
                                                  align="center"
                                                  style={{
                                                    width: "40%",
                                                    border: "1px solid #B6B6B6",
                                                  }}
                                                >
                                                  <FormControl>
                                                    <RadioGroup
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      row
                                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                                      name="actionRequired"
                                                      value={row.actionRequired}
                                                      onChange={(e) => {
                                                        const text = JSON.parse(
                                                          e.target.value,
                                                        );
                                                        console.log(
                                                          e.target.value,
                                                        );
                                                        console.log(
                                                          "text",
                                                          text,
                                                        );
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].insights.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.actionRequired =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].insights =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    >
                                                      <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Yes"
                                                      />
                                                      <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="No"
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>
                                            ),
                                          )
                                        ) : (
                                          <TableRow
                                            style={{
                                              border: "1px solid #B6B6B6",
                                            }}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: "1px solid #B6B6B6",
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                width: "60%",
                                              }}
                                            >
                                              None
                                            </TableCell>

                                            <TableCell
                                              align="center"
                                              style={{
                                                width: "40%",
                                                border: "1px solid #B6B6B6",
                                              }}
                                            >
                                              None
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              ) : type === "insightsActionPlanTable" ? (
                                <>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    sx={{ boxShadow: 0 }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: "100%", boxShadow: 0 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{ backgroundColor: "#EDFDFA" }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>

                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Select Engagement Options
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Selected Engagement Options
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Details
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element?.insights?.length > 0 ? (
                                          element?.insights?.map(
                                            (engageGrp, engageGrpIndex) => {
                                              const groupName =
                                                engageGrp.groupName;
                                              const engagementGroups =
                                                engageGrp.engagementGroups?.length>0 ? engageGrp.engagementGroups :
                                                [""];
                                              const details =
                                                engageGrp.details.length>0 ? engageGrp.details : [];
                                              // Determine the number of rows needed
                                              const numRows = Math.max(
                                                engagementGroups.length ,
                                                details.length ,
                                              );
                                             
                                              return Array.from({
                                                length: numRows,
                                              }).map((_, rowIndex) => (
                                                <>
                                                  <TableRow
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    }}
                                                    key={`${engageGrpIndex}-${rowIndex}`}
                                                    sx={{
                                                      "&:last-child td, &:last-child th":
                                                        {
                                                          border:
                                                            "1px solid #B6B6B6",
                                                        },
                                                    }}
                                                  >
                                                    {rowIndex === 0 && (
                                                      <>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",
                                                            verticalAlign:
                                                              "top",
                                                          }}
                                                          rowSpan={numRows}
                                                        >
                                                          {groupName}
                                                        </TableCell>

                                                        <TableCell
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",
                                                            verticalAlign:
                                                              "top",
                                                            padding:
                                                              "0px 16px 16px",
                                                          }}
                                                          rowSpan={numRows}
                                                        >
                                                          <FormControl
                                                            fullWidth
                                                            className={
                                                              classes.dropdownFormControl
                                                            }
                                                          >
                                                            <Select
                                                              className={
                                                                classes.selectDropdown
                                                              }
                                                              style={{
                                                                maxWidth:
                                                                  "300px",
                                                                width: "300px",
                                                              }}
                                                              multiple
                                                              value={updateValues(
                                                                engageGrp.engagementGroups,
                                                              )}
                                                              renderValue={(
                                                                selected,
                                                              ) =>
                                                                selected.join(
                                                                  ", ",
                                                                )
                                                              }
                                                              onChange={(e) => {
                                                                insightsPlanActionsHandlerNew(
                                                                  {
                                                                    action:
                                                                      "updateGroup",
                                                                    insights:
                                                                      element?.insights,
                                                                    insightIndex:
                                                                      engageGrpIndex,
                                                                    groups:
                                                                      e?.target
                                                                        ?.value,
                                                                  },
                                                                );
                                                              }}
                                                            >
                                                              {element?.other
                                                                ?.engagementGroups
                                                                ? element?.other?.engagementGroups.map(
                                                                    (
                                                                      engageGrpOther,
                                                                      engageGrpOtherIndex,
                                                                    ) => (
                                                                      <MenuItem
                                                                        style={{
                                                                          maxWidth:
                                                                            "300px",whiteSpace:'normal',padding:'0px'
                                                                        }}
                                                                        value={
                                                                          engageGrpOther
                                                                        }
                                                                        key={
                                                                          engageGrpOtherIndex
                                                                        }
                                                                      >
                                                                        <Checkbox
                                                                          checked={(
                                                                            engageGrp?.engagementGroups ||
                                                                            []
                                                                          ).includes(
                                                                            engageGrpOther,
                                                                          )}
                                                                        />
                                                                        <ListItemText
                                                                          primary={
                                                                            engageGrpOther
                                                                          }
                                                                        />
                                                                      </MenuItem>
                                                                    ),
                                                                  )
                                                                : []}
                                                              <MenuItem style={{padding:'0px'}}
                                                                value={"other-"}
                                                                key={`other-${engageGrpIndex}`}
                                                              >
                                                                <Checkbox
                                                                  checked={(
                                                                    engageGrp?.engagementGroups ||
                                                                    []
                                                                  ).some(
                                                                    (e) =>
                                                                      e &&
                                                                      e?.includes(
                                                                        "other-",
                                                                      ),
                                                                  )}
                                                                />
                                                                <ListItemText
                                                                  primary={
                                                                    "Other"
                                                                  }
                                                                />
                                                              </MenuItem>
                                                            </Select>
                                                          </FormControl>

                                                          {(
                                                            engageGrp?.engagementGroups ||
                                                            []
                                                          ).some(
                                                            (e) =>
                                                              e &&
                                                              e.includes(
                                                                "other-",
                                                              ),
                                                          ) && (
                                                            <TextField
                                                              size="small"
                                                              style={{
                                                                marginTop:
                                                                  "4px",padding:'0px',
                                                                maxWidth:
                                                                  "300px",
                                                              }}
                                                              className={
                                                                classes.txtForm
                                                              }
                                                              fullWidth
                                                              multiline
                                                              InputProps={{
                                                                rows: 3,
                                                              }}
                                                              placeholder="Text"
                                                              variant="outlined"
                                                              onChange={
                                                                engageGrp?.engagementGroups &&
                                                                engageGrp
                                                                  ?.engagementGroups
                                                                  .length &&
                                                                engageGrp?.engagementGroups.find(
                                                                  (e) =>
                                                                    e &&
                                                                    e.includes(
                                                                      "other-",
                                                                    ),
                                                                )
                                                                  ? (e) =>
                                                                      insightsPlanActionsHandler(
                                                                        {
                                                                          question:
                                                                            nextQuestionsValue,
                                                                          action:
                                                                            "update",
                                                                          group:
                                                                            "other-" +
                                                                            e
                                                                              ?.target
                                                                              ?.value,
                                                                          insightsIndex:
                                                                            engageGrpIndex,
                                                                          setQuestion:
                                                                            setNextQuestionsValue,
                                                                          questionIndex:
                                                                            i,
                                                                        },
                                                                      )
                                                                  : () => {}
                                                              }
                                                              value={_difference(
                                                                engageGrp?.engagementGroups,
                                                                element?.other
                                                                  ?.engagementGroups,
                                                              )
                                                                ?.join(",")
                                                                .replace(
                                                                  "other-",
                                                                  "",
                                                                )}
                                                             
                                                            />
                                                          )}
                                                        </TableCell>
                                                      </>
                                                    )}
                                                    {rowIndex >= 0 &&
                                                      rowIndex <=
                                                        engagementGroups.length && (
                                                        <TableCell
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",textTransform:'capitalize',
                                                            verticalAlign:
                                                              "middle",
                                                            padding: "0px 16px",
                                                          }}
                                                        >
                                                          {
                                                            engagementGroups[
                                                              rowIndex
                                                            ]
                                                          }
                                                          
                                                        </TableCell>
                                                      )}
                                                    {rowIndex >= 0 &&
                                                      rowIndex <=
                                                        details.length && (
                                                        <TableCell
                                                          style={{
                                                            border:
                                                              "1px solid #B6B6B6",
                                                            width: "25%",
                                                            verticalAlign:
                                                              "middle",
                                                            padding: "0px 16px",
                                                          }}
                                                        >
                                                          <TextField
                                                            key={`${engageGrpIndex}-${rowIndex}`}
                                                            size="small"
                                                            style={{
                                                              marginTop: "4px",
                                                              maxWidth: "300px",
                                                            }}
                                                            fullWidth
                                                            multiline
                                                            placeholder="Enter details"
                                                            variant="outlined"
                                                            value={
                                                              details[rowIndex] || ""
                                                            }
                                                            onChange={(e) => {
                                                              insightsPlanActionsHandlerNew(
                                                                {
                                                                  action:
                                                                    "updateDetails",
                                                                  insights:
                                                                    element?.insights,
                                                                  insightIndex:
                                                                    engageGrpIndex,
                                                                  groupIndex:
                                                                    rowIndex,
                                                                  details:
                                                                    e.target
                                                                      .value,
                                                                },
                                                              );
                                                            }}
                                                          />
                                                        </TableCell>
                                                      )}
                                                  </TableRow>
                                                </>
                                              ));
                                            },
                                          )
                                        ) : (
                                          <>
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "20%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "30%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              ) : type ===
                                "multipleActivityFindingsActionTable" ? (
                                <>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    sx={{ boxShadow: 0 }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: "100%", boxShadow: 0 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{ backgroundColor: "#EDFDFA" }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            List of Engagement Activity
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",verticalAlign:'top'
                                            }}
                                          >
                                            Findings
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Is Action Required ?
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element?.findings?.length > 0 ? (
                                          element.findings.map(
                                            (row, rowIndex) => (
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                key={rowIndex}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "20%",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {row.groupName}
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                    width: "30%",overflowWrap:'anywhere'
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "grid",
                                                      flexWrap: "wrap",
                                                    }}
                                                  >
                                                    {nextQuestionsValueState &&
                                                      nextQuestionsValueState[
                                                        rowIndex
                                                      ]?.values.map(
                                                        (value, linkIndex) => (
                                                          <span
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <TextField
                                                              size="small"
                                                              key={`${rowIndex}-${linkIndex}`}
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              className={
                                                                classes.txtForm
                                                              }
                                                              fullWidth
                                                              multiline
                                                              placeholder="Text"
                                                              variant="outlined"
                                                              value={
                                                                value || ""
                                                              }
                                                              onChange={(e) => {
                                                                const updatedInsights =
                                                                  [
                                                                    ...element.findings,
                                                                  ];
                                                                updatedInsights[
                                                                  rowIndex
                                                                ].values[
                                                                  linkIndex
                                                                ] =
                                                                  e.target.value;
                                                                const updatedNextQuestions =
                                                                  [
                                                                    ...nextQuestionsValue,
                                                                  ];
                                                                updatedNextQuestions[
                                                                  i
                                                                ].findings =
                                                                  updatedInsights;
                                                                setNextQuestionsValue(
                                                                  updatedNextQuestions,
                                                                );
                                                              }}
                                                            />
                                                            {nextQuestionsValueState[
                                                              rowIndex
                                                            ]?.values.length >
                                                              1 && (
                                                              <img
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  const updatedInsights =
                                                                    [
                                                                      ...element.findings,
                                                                    ];
                                                                  updatedInsights[
                                                                    rowIndex
                                                                  ].values.splice(
                                                                    linkIndex,
                                                                    1,
                                                                  );
                                                                  const updatedNextQuestions =
                                                                    [
                                                                      ...nextQuestionsValue,
                                                                    ];
                                                                  updatedNextQuestions[
                                                                    i
                                                                  ].findings =
                                                                    updatedInsights;
                                                                  setNextQuestionsValue(
                                                                    updatedNextQuestions,
                                                                  );
                                                                }}
                                                                src={removeIcon}
                                                                alt="remove"
                                                              />
                                                            )}
                                                          </span>
                                                        ),
                                                      )}

                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={9}
                                                      style={{
                                                        marginTop: "20px",
                                                        display: "flex",
                                                        marginLeft: "4px",
                                                        cursor: "pointer",
                                                      }}
                                                      // onClick={() => addTextField()}
                                                      onClick={() => {
                                                        const updatedInsights =
                                                          [...element.findings];
                                                        updatedInsights[
                                                          rowIndex
                                                        ].values.push(""); // Add an empty text field
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[
                                                          i
                                                        ].findings =
                                                          updatedInsights;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={plusIcon}
                                                        alt="plus-icon"
                                                      />
                                                      <Typography
                                                        className={
                                                          classes.formHeading
                                                        }
                                                      >
                                                        Add More
                                                      </Typography>
                                                    </Grid>
                                                  </div>
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "30%",
                                                    verticalAlign: "top",overflowWrap:'anywhere'
                                                  }}
                                                >
                                                  <TextField
                                                    size="small"
                                                    style={{
                                                      marginTop: "4px",
                                                    }}
                                                    name="findings"
                                                    className={classes.txtForm}
                                                    fullWidth
                                                    placeholder="What are your Findings?"
                                                    variant="outlined"
                                                    multiline
                                                    value={row.findings || ""}
                                                    onChange={(e) => {
                                                      const text =
                                                        e.target.value;
                                                      const updatedFindingTable =
                                                        nextQuestionsValue?.[0].findings.map(
                                                          (t, i) => {
                                                            if (
                                                              i === rowIndex
                                                            ) {
                                                              const updatedData =
                                                                {
                                                                  ...t,
                                                                };
                                                              updatedData.findings =
                                                                text;
                                                              return updatedData;
                                                            }
                                                            return t;
                                                          },
                                                        );
                                                      const updatedNextQuestions =
                                                        [...nextQuestionsValue];
                                                      updatedNextQuestions[0].findings =
                                                        updatedFindingTable;
                                                      setNextQuestionsValue(
                                                        updatedNextQuestions,
                                                      );
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  align="center"
                                                  style={{ width: "20%",verticalAlign:'top' }}
                                                >
                                                  <FormControl>
                                                    <RadioGroup
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                      row
                                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                                      name="actionRequired"
                                                      value={row.actionRequired}
                                                      onChange={(e) => {
                                                        const text = JSON.parse(
                                                          e.target.value,
                                                        );
                                                        console.log(
                                                          e.target.value,
                                                        );
                                                        console.log(
                                                          "text",
                                                          text,
                                                        );
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].findings.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.actionRequired =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].findings =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    >
                                                      <FormControlLabel
                                                        value={true}
                                                        control={<Radio />}
                                                        label="Yes"
                                                      />
                                                      <FormControlLabel
                                                        value={false}
                                                        control={<Radio />}
                                                        label="No"
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>
                                            ),
                                          )
                                        ) : (
                                          <>
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              ) : type === "multipleFreeTextTable" ? (
                                <>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    sx={{ boxShadow: 0 }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: "100%", boxShadow: 0 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{ backgroundColor: "#EDFDFA" }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            List of Engagement Activity
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element.findings.length > 0 ? (
                                          element.findings.map(
                                            (row, rowIndex) => (
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                key={rowIndex}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "30%",
                                                    verticalAlign: "top",
                                                  }}
                                                >
                                                  {row.groupName}
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "grid",
                                                      flexWrap: "wrap",overflowWrap:'anywhere'
                                                    }}
                                                  >
                                                    {nextQuestionsValueState &&
                                                      nextQuestionsValueState[
                                                        rowIndex
                                                      ]?.values.map(
                                                        (value, linkIndex) => (
                                                          <span
                                                            style={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            <TextField
                                                              size="small"
                                                              key={`${rowIndex}-${linkIndex}`}
                                                              style={{
                                                                marginTop:
                                                                  "4px",
                                                              }}
                                                              className={
                                                                classes.txtForm
                                                              }
                                                              fullWidth
                                                              multiline
                                                              placeholder="Text"
                                                              variant="outlined"
                                                              value={
                                                                value || ""
                                                              }
                                                              onChange={(e) => {
                                                                const updatedInsights =
                                                                  [
                                                                    ...element.findings,
                                                                  ];
                                                                updatedInsights[
                                                                  rowIndex
                                                                ].values[
                                                                  linkIndex
                                                                ] =
                                                                  e.target.value;
                                                                const updatedNextQuestions =
                                                                  [
                                                                    ...nextQuestionsValue,
                                                                  ];
                                                                updatedNextQuestions[
                                                                  i
                                                                ].findings =
                                                                  updatedInsights;
                                                                setNextQuestionsValue(
                                                                  updatedNextQuestions,
                                                                );
                                                              }}
                                                            />
                                                            {nextQuestionsValueState[
                                                              rowIndex
                                                            ]?.values.length >
                                                              1 && (
                                                              <img
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  const updatedInsights =
                                                                    [
                                                                      ...element.findings,
                                                                    ];
                                                                  updatedInsights[
                                                                    rowIndex
                                                                  ].values.splice(
                                                                    linkIndex,
                                                                    1,
                                                                  );
                                                                  const updatedNextQuestions =
                                                                    [
                                                                      ...nextQuestionsValue,
                                                                    ];
                                                                  updatedNextQuestions[
                                                                    i
                                                                  ].findings =
                                                                    updatedInsights;
                                                                  setNextQuestionsValue(
                                                                    updatedNextQuestions,
                                                                  );
                                                                }}
                                                                src={removeIcon}
                                                                alt="remove"
                                                              />
                                                            )}
                                                          </span>
                                                        ),
                                                      )}

                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={9}
                                                      style={{
                                                        marginTop: "20px",
                                                        display: "flex",
                                                        marginLeft: "4px",
                                                        cursor: "pointer",
                                                      }}
                                                      // onClick={() => addTextField()}
                                                      onClick={() => {
                                                        const updatedInsights =
                                                          [...element.findings];
                                                        updatedInsights[
                                                          rowIndex
                                                        ].values.push(""); // Add an empty text field
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[
                                                          i
                                                        ].findings =
                                                          updatedInsights;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={plusIcon}
                                                        alt="plus-icon"
                                                      />
                                                      <Typography
                                                        className={
                                                          classes.formHeading
                                                        }
                                                      >
                                                        Add More
                                                      </Typography>
                                                    </Grid>
                                                  </div>
                                                </TableCell>
                                              </TableRow>
                                            ),
                                          )
                                        ) : (
                                          <>
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "20%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </>
                              ) : type === "findingsActionTable" ? (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <TableContainer
                                      style={{ marginTop: "16px" }}
                                      sx={{ boxShadow: 0 }}
                                      component={Paper}
                                    >
                                      <Table
                                        sx={{ minWidth: "100%", boxShadow: 0 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead
                                          style={{ backgroundColor: "#EDFDFA" }}
                                        >
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",
                                              }}
                                            >
                                              Identified Groups
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",
                                              }}
                                            >
                                              What are your Findings?
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",
                                              }}
                                            >
                                              Is Action Required?
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {element.findings.length > 0 ? (
                                            element.findings.map(
                                              (row, rowIndex) => (
                                                <TableRow
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                  }}
                                                  key={row}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "30%",verticalAlign:'top'
                                                    }}
                                                  >
                                                    {row.groupName}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "50%",verticalAlign:'top'
                                                    }}
                                                  >
                                                    {console.log(
                                                      "row.findings",
                                                      row,
                                                    )}
                                                    <TextField
                                                      size="small"
                                                      style={{
                                                        marginTop: "4px",
                                                      }}
                                                      name="findings"
                                                      className={
                                                        classes.txtForm
                                                      }
                                                      fullWidth
                                                      placeholder="What are your Findings?"
                                                      variant="outlined"
                                                      multiline
                                                      value={row.findings || ""}
                                                      onChange={(e) => {
                                                        const text =
                                                          e.target.value;
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].findings.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.findings =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].findings =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    align="center"
                                                    style={{ width: "20%",verticalAlign:'top' }}
                                                  >
                                                    <FormControl>
                                                      <RadioGroup
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="actionRequired"
                                                        value={
                                                          row.actionRequired
                                                        }
                                                        onChange={(e) => {
                                                          const text =
                                                            JSON.parse(
                                                              e.target.value,
                                                            );
                                                          console.log(
                                                            e.target.value,
                                                          );
                                                          console.log(
                                                            "text",
                                                            text,
                                                          );
                                                          const updatedFindingTable =
                                                            nextQuestionsValue?.[0].findings.map(
                                                              (t, i) => {
                                                                if (
                                                                  i === rowIndex
                                                                ) {
                                                                  const updatedData =
                                                                    {
                                                                      ...t,
                                                                    };
                                                                  updatedData.actionRequired =
                                                                    text;
                                                                  return updatedData;
                                                                }
                                                                return t;
                                                              },
                                                            );
                                                          const updatedNextQuestions =
                                                            [
                                                              ...nextQuestionsValue,
                                                            ];
                                                          updatedNextQuestions[0].findings =
                                                            updatedFindingTable;
                                                          setNextQuestionsValue(
                                                            updatedNextQuestions,
                                                          );
                                                        }}
                                                      >
                                                        <FormControlLabel
                                                          value={true}
                                                          control={<Radio />}
                                                          label="Yes"
                                                        />
                                                        <FormControlLabel
                                                          value={false}
                                                          control={<Radio />}
                                                          label="No"
                                                        />
                                                      </RadioGroup>
                                                    </FormControl>
                                                  </TableCell>
                                                </TableRow>
                                              ),
                                            )
                                          ) : (
                                            <>
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "20%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "25%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "30%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </>
                              ) : type === "actionPlanTable" ? (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <TableContainer
                                      style={{ marginTop: "16px" }}
                                      component={Paper}
                                    >
                                      <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead
                                          style={{ backgroundColor: "#EDFDFA" }}
                                        >
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",
                                              }}
                                            >
                                              Identified Groups
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",overflowWrap:'anywhere'
                                              }}
                                              align="center"
                                            >
                                              Findings
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",overflowWrap:'anywhere'
                                              }}
                                              align="center"
                                            >
                                              Issues
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",overflowWrap:'anywhere'
                                              }}
                                              align="center"
                                            >
                                              Action Required
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "center",
                                              }}
                                              align="center"
                                            >
                                              Expected Date of Completion
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {element.findings.length > 0 ? (
                                            element.findings.map(
                                              (row, rowIndex) => (
                                                <TableRow
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                  }}
                                                  key={rowIndex}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "20%",verticalAlign:'top'
                                                    }}
                                                  >
                                                    {row.groupName}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "20%",verticalAlign:'top',overflowWrap:'anywhere'
                                                    }}
                                                  >
                                                    {row.findings}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "20%",verticalAlign:'top',overflowWrap:'anywhere'
                                                    }}
                                                  >
                                                    <TextField
                                                      size="small"
                                                      style={{
                                                        marginTop: "4px",
                                                      }}
                                                      className={
                                                        classes.txtForm
                                                      }
                                                      fullWidth
                                                      placeholder="Please enter issues"
                                                      variant="outlined"
                                                      multiline
                                                      name="issues"
                                                      value={
                                                        row.issues || ""
                                                      }
                                                      onChange={(e) => {
                                                        const text =
                                                          e.target.value;
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].findings.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.issues =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].findings =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "20%",verticalAlign:'top'
                                                    }}
                                                  >
                                                    <TextField
                                                      size="small"
                                                      style={{
                                                        marginTop: "4px",
                                                      }}
                                                      className={
                                                        classes.txtForm
                                                      }
                                                      fullWidth
                                                      placeholder="Please enter action required"
                                                      variant="outlined"
                                                      multiline
                                                      name="actionPlan"
                                                      value={
                                                        row.actionPlan || ""
                                                      }
                                                      onChange={(e) => {
                                                        const text =
                                                          e.target.value;
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].findings.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.actionPlan =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].findings =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    />
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      border:
                                                        "1px solid #B6B6B6",
                                                      width: "20%",verticalAlign:'top'
                                                    }}
                                                  >
                                                    <TextField
                                                      size="small"
                                                      style={{
                                                        marginTop: "4px",
                                                      }}
                                                      className={
                                                        classes.txtForm
                                                      }
                                                      InputProps={{
                                                        style: {
                                                          fontSize: "12px",
                                                        },
                                                      }}
                                                      InputLabelProps={{
                                                        style: {
                                                          fontSize: "12px",
                                                        },
                                                      }}
                                                      type="date"
                                                      fullWidth
                                                      placeholder="select"
                                                      variant="outlined"
                                                      // multiline
                                                      name="expectedCompletionDate"
                                                      value={
                                                        row.expectedCompletionDate
                                                          ? row.expectedCompletionDate
                                                          : moment().format(
                                                              "YYYY-MM-DD",
                                                            )
                                                      }
                                                      onChange={(e) => {
                                                        const text =
                                                          e.target.value;
                                                        const updatedFindingTable =
                                                          nextQuestionsValue?.[0].findings.map(
                                                            (t, i) => {
                                                              if (
                                                                i === rowIndex
                                                              ) {
                                                                const updatedData =
                                                                  {
                                                                    ...t,
                                                                  };
                                                                updatedData.expectedCompletionDate =
                                                                  text;
                                                                return updatedData;
                                                              }
                                                              return t;
                                                            },
                                                          );
                                                        const updatedNextQuestions =
                                                          [
                                                            ...nextQuestionsValue,
                                                          ];
                                                        updatedNextQuestions[0].findings =
                                                          updatedFindingTable;
                                                        setNextQuestionsValue(
                                                          updatedNextQuestions,
                                                        );
                                                      }}
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              ),
                                            )
                                          ) : (
                                            <>
                                              <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "25%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "25%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "25%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "25%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
              </>
              {/* for approver form */}
              {nextSection === "engagement" &&
              nextQuestionsValue.some(
                (item) =>
                  item.type === "patientEngagementApproval" ||
                  item.type === "staffEngagementApproval",
              ) ? (
                <>
                  <Grid
                    container
                    spacing={2}
                    className={classes.formBox}
                    style={{
                      visibility: isLoading ? "hidden" : "visible",
                      backgroundColor: isLoading
                        ? "rgb(200 232 221 / 30%)"
                        : "#fff",
                    }}
                  >
                    {/* <img src={notes} className={classes.noteIcons} /> */}
                    <Grid item xs={12} md={11} className={classes.textGroup}>
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "0px",
                            marginBottom: "16px",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              textAlign: "center",
                              background: "#F3F3F3",
                              padding: "6px 16px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Please send the engagement plan for review to the
                            Patient/Staff Engagement Specialist
                          </span>
                        </div>
                      </>

                      <Grid item xs={12} md={12}>
                        <TableContainer
                          style={{ marginTop: "6px" }}
                          component={Paper}
                        >
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                              <TableRow>
                                {nextQuestionsValue.length > 0
                                  ? nextQuestionsValue.map((item, index) => (
                                      <TableCell
                                        key={index}
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        {item?.type ===
                                        "patientEngagementApproval"
                                          ? "Patient "
                                          : "Staff "}
                                      </TableCell>
                                    ))
                                  : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                {nextQuestionsValue.length > 0 ? (
                                  <>
                                    {nextQuestionsValue.map((item, index) => (
                                      <TableCell
                                        key={index}
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width:
                                            nextQuestionsValue.length == 2
                                              ? "50%"
                                              : "100%",
                                        }}
                                      >
                                        <div>
                                          {item?.status &&
                                            item?.status !== "notSent" &&
                                            item?.status !== "expired" && (
                                              <Typography
                                                className={classes.formHeading}
                                                style={{ fontSize: "16px" }}
                                              >
                                                Sent On Date:{" "}
                                                {moment(item?.sentAt).format(
                                                  "lll",
                                                )}
                                              </Typography>
                                            )}
                                          {(item?.status === "notSent" ||
                                            item?.status === "expired") && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Button
                                                style={{
                                                  background:
                                                    item.type ===
                                                    "patientEngagementApproval"
                                                      ? ""
                                                      : "#0445AF",
                                                }}
                                                variant="contained"
                                                color="secondary"
                                                className={classes.largeBtn}
                                                elevation={0}
                                                onClick={(e) =>
                                                  sendApprovalBasedOnType(item)
                                                }
                                              >
                                                {item.type ===
                                                "patientEngagementApproval"
                                                  ? "Send To Patient Engagement Specialist "
                                                  : "Send To Staff Engagement Specialist "}
                                              </Button>
                                            </div>
                                          )}{" "}
                                          {item?.status === "pending" && (
                                            <>
                                              <Typography
                                                className={classes.formHeading}
                                                style={{
                                                  fontSize: "16px",
                                                  marginTop: "6px",
                                                }}
                                              >
                                                Status: Pending for approval
                                                from engagement specialist
                                              </Typography>
                                              <Typography
                                                className={classes.formHeading}
                                                style={{
                                                  fontWeight: "400",
                                                  marginTop: "8px",
                                                }}
                                              >
                                                The engagement specialist will now review your plan and advise/support your engagement. You will receive an email notification once the engagement specialist has confirmed the review outcome. Please come back to this section when the review is completed.
                                              </Typography>
                                            </>
                                          )}
                                          <>
                                            {item.history.map(
                                              (review, reviewIndex) => (
                                                <div key={reviewIndex}>
                                                  {review?.byEntity ===
                                                    "team" && (
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={12}
                                                      style={{
                                                        backgroundColor:
                                                          "#EDF8F6",
                                                        marginTop: "10px",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Typography
                                                          className={
                                                            classes.formHeading
                                                          }
                                                        >
                                                          Comment From
                                                          Engagement Specialist:
                                                        </Typography>
                                                        <Typography
                                                          style={{
                                                            display: "contents",
                                                            color: "#52575C",
                                                            fontWeight: "400",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {moment(
                                                            review?.timestamp,
                                                          ).format("lll")}
                                                        </Typography>
                                                      </div>
                                                      <Typography
                                                        style={{
                                                          color: "#201F1E",
                                                          fontSize: "13px",
                                                          paddingLeft: "4px",
                                                          textAlign: "justify",
                                                          marginTop: "4px",
                                                        }}
                                                      >
                                                        <>{review?.comments}</>
                                                      </Typography>
                                                    </Grid>
                                                  )}
                                                  {(review?.byEntity ===
                                                    "self" ||
                                                    (review?.byEntity ===
                                                      "team" &&
                                                      item.history.length -
                                                        1 ===
                                                        reviewIndex &&
                                                      review?.status ===
                                                        "rejected") ||
                                                    review?.status ===
                                                      "pending") && (
                                                    <Grid
                                                      item
                                                      xs={12}
                                                      md={12}
                                                      style={{
                                                        backgroundColor:
                                                          "#ffffff",
                                                        // border: "1px solid #03997B",
                                                        marginTop: "10px",
                                                        borderRadius: "5px",
                                                        padding: "8px",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Typography
                                                          className={
                                                            classes.formHeading
                                                          }
                                                        >
                                                          Comment From
                                                          Applicant:
                                                        </Typography>
                                                        <Typography
                                                          style={{
                                                            display: "contents",
                                                            color: "#52575C",
                                                            fontWeight: "400",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {moment(
                                                            review?.timestamp,
                                                          ).format("lll")}
                                                        </Typography>
                                                      </div>
                                                      {review.byEntity ===
                                                      "self" ? (
                                                        <>
                                                          <Typography
                                                            style={{
                                                              color: "#201F1E",
                                                              fontSize: "13px",
                                                              paddingLeft:
                                                                "4px",
                                                              textAlign:
                                                                "justify",
                                                              marginTop: "4px",
                                                            }}
                                                          >
                                                            <>
                                                              {review?.comments}
                                                            </>
                                                          </Typography>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <TextField
                                                            name={
                                                              item?.type ===
                                                              "patientEngagementApproval"
                                                                ? "comments_patient"
                                                                : "comments_staff"
                                                            }
                                                            // name="comments"
                                                            size="small"
                                                            style={{
                                                              color: "#201F1E",
                                                              fontSize: "10px",
                                                              paddingLeft:
                                                                "4px",
                                                              textAlign:
                                                                "justify",
                                                              marginTop: "4px",
                                                            }}
                                                            className={
                                                              classes.txtForm
                                                            }
                                                            fullWidth
                                                            placeholder="Text"
                                                            variant="outlined"
                                                            multiline
                                                            rows={8}
                                                            value={
                                                              review.byEntity ===
                                                              "self"
                                                                ? review.comments
                                                                : item?.type ===
                                                                  "patientEngagementApproval"
                                                                ? engagementDetail?.comments_patient
                                                                : engagementDetail?.comments_staff
                                                            }
                                                            onChange={
                                                              review.byEntity ===
                                                              "self"
                                                                ? () => {}
                                                                : handleInputChange
                                                            }
                                                            // error={Boolean(
                                                            //   errorApiMessage,
                                                            // )}
                                                            // helperText={
                                                            //   errorApiMessage
                                                            // }
                                                          />
                                                        </>
                                                      )}
                                                    </Grid>
                                                  )}
                                                </div>
                                              ),
                                            )}
                                            {item?.status === "rejected" && (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "end",
                                                  paddingTop: "10px",
                                                }}
                                              >
                                                <Button
                                                  elevation={0}
                                                  onClick={(e) =>
                                                    sendApprovalBasedOnType(
                                                      item,
                                                    )
                                                  }
                                                  className={
                                                    classes.rightButton
                                                  }
                                                  variant="contained"
                                                  size="large"
                                                  color="secondary"
                                                  style={{
                                                    backgroundColor: "#FEAA00",
                                                  }}
                                                >
                                                  Send For Review Again
                                                </Button>
                                              </div>
                                            )}
                                          </>
                                          {item?.status === "approved" && (
                                            <>
                                              <Typography
                                                className={classes.formHeading}
                                                style={{
                                                  fontSize: "16px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginTop: "8px",
                                                }}
                                              >
                                                Your Plan Is Approved
                                                Successfully{" "}
                                                <Avatar
                                                  style={{
                                                    borderRadius: "0px",
                                                    marginLeft: "0px",
                                                  }}
                                                  src={checked}
                                                />
                                              </Typography>
                                              <Typography
                                                className={classes.formHeading}
                                                style={{
                                                  fontSize: "16px",
                                                  marginTop: "6px",
                                                }}
                                              >
                                                Plan Approval Date: 12 Aug 2023,
                                                11:00 AM
                                              </Typography>
                                            </>
                                          )}
                                        </div>
                                      </TableCell>
                                    ))}
                                  </>
                                ) : null}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {/* Next question was here */}
              {isEngagementCompleted && (nextSection==='approvalResult' || isApprovalCompleted) && (
            <Grid
              style={{
                borderBottom: "0px solid #B6B6B6",
                backgroundColor: "#F8FFFE",
                padding: "10px",
              }}
              container
              xs={12}
              md={12}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                <span style={{ color: "red" }}>Notes:-</span> You have completed
                the questions section. Please now move on to the Approval &
                Result section to continue your EHIA
              </Typography>
            </Grid>
          )}
              {/* submit buttons */}
              <>
                {nextSection === "engagement" &&
                nextQuestionsValue &&
                nextQuestionsValue.length > 0 &&
                nextQuestionsValue[0]?.type != "patientEngagementApproval" &&
                nextQuestionsValue[0]?.type != "staffEngagementApproval" ? (
                  <Grid
                    item
                    xs={12}
                    md={11}
                    style={{
                      visibility: isLoading ? "hidden" : "visible",
                      backgroundColor: isLoading
                        ? "rgb(200 232 221 / 30%)"
                        : "#fff",
                    }}
                  >
                    <Button
                      style={{ float: "right", marginBottom: "20px" }}
                      className={classes.rightButton}
                      onClick={(e) => submitDataTableHandler(e)}
                      variant="contained"
                      size="large"
                      color="secondary"
                      elevation={0}
                    >
                      Next Question
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </>
            </Grid>
            <ApiResponsePopup
              {...{
                hideHandlerRes,
                isRecordUpdated: isShow,
                title: "Update Enagagement",
                successMsg: successMessage,
                errorRes: errorApiMessage,
              }}
            />
          </>
          
        </Widget>
      </Grid>
      {type === "add" ? (
        <Grid className={classesAdd.btnGroup} item xs={12}>
          <Button
            className={classesAdd.leftBluetButton}
            variant="outlined"
            size="large"
            onClick={(e) => setActiveTabId(2)}
          >
            Previous
          </Button>
          <Button
            className={classesAdd.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitDataTableHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isEngagementCompleted && (
            <Button
              className={classesAdd.btnlRightDark}
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => setActiveTabId(4)}
            >
              Go to Approval & Result Section
            </Button>
          )}
        </Grid>
      ) : (
        <Grid className={classesDetail.btnGroup} item xs={12}>
          <Button
            className={classesDetail.btnleftOutline}
            variant="outlined"
            style={{ marginRight: "10px" }}
            // onClick={(e) => setActiveTabId(3)}
            onClick={(e) => actionCancel(e)}
          >
            Cancel
          </Button>
          <Button
            className={classesDetail.btnlRightDark}
            variant="contained"
            style={{ background: "#FEAA00" }}
            color="secondary"
            onClick={(e) => submitDataTableHandler(e, "save")}
          >
            Save & Exit
          </Button>
          {isEngagementCompleted && (
            <Button
              className={classesDetail.btnlRightDark}
              variant="contained"
              color="secondary"
              onClick={(e) => setActiveTabId(4)}
            >
              Go to Approval & Result Section
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}
