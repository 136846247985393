import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
// import { getProfile } from "../super-admin/store/actions/adminAction";
import { getProfile as getSuperAdminProfile } from "../super-admin/store/actions/adminAction";
import { getProfile as getClientAdminProfile } from "../client-admin/store/actions/adminAction";
import { getProfile as getUserAdminProfile } from "../user-admin/store/actions/adminAction";
import admin, { checkAdmin } from "../super-admin/store/reducerSlice/admin";

import { superAdminReducers } from "../super-admin/store/superAdminReducers";
import { clientAdminReducers } from "../client-admin/store/clientAdminReducers";
import { userAdminReducers } from "../user-admin/store/userAdminReducers";
import { reviews } from "./reducerSlice/reviews";
const store = configureStore({
  reducer: {
    ...superAdminReducers,
    ...clientAdminReducers,
    ...userAdminReducers,
    reviews
  },
});

console.log("store---> ", store.getState());
const authDetail = localStorage.getItem("authorization");
store.dispatch(checkAdmin(authDetail));
const url = window.location.href;
console.log("URL:", url);

const hostname = window.location.hostname;
console.log("Hostname:", hostname);

const subdomain = hostname.split(".")[0];
console.log("Subdomain:", subdomain);

const path = window.location.pathname;
console.log("Path:", path);

const pathSegments = path.split("/");
const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
console.log("Client Admin Path 1:", clientAdminPath);
if (authDetail) {
  // store.dispatch(getProfile());
  if (clientAdminPath === "client-admin" || clientAdminPath === 'admin') {
    store.dispatch(getClientAdminProfile());
  } else if (clientAdminPath === "super-admin") {
    store.dispatch(getSuperAdminProfile());
  }else if (clientAdminPath === "user-admin" || clientAdminPath === "user") {
    store.dispatch(getUserAdminProfile());
  }
}

export default store;
