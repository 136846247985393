import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Typography, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import notes_bg from "../../images/notes_bg.png";
import { reviewEngagementModule } from "../../user-admin/store/actions/reviewAction";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
import Loader from "../../component/common/loader/Loader";
export default function SendReview({
  onHide,
  reviewModalShow,
  setReviewModalShow,
  setIsActionCalled,
  projectId,
  type,
}) {
  console.log("projectId", projectId);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [isAction, setIsAction] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const [engagementDetail, setEngagementDetail] = useState({
    action: null,
    type: null,
    email: null,
    comments: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEngagementDetail((prevDetail) => ({
      ...prevDetail,
      [name]: value,
    }));
    setErrorApiMessage('')
  };

  const submitHandler = (mode) => {
    setIsAction(true);
    if (!engagementDetail.comments) {
      setErrorApiMessage("Comment field is required");
      // setIsShow(true);
      return; // Stop the submission if the comments field is empty
    }
    const data = {
      projectId: projectId,
      action: "rejected",
      type: type,
      comments: engagementDetail?.comments,
    };
    console.log("data", data);
    dispatch(reviewEngagementModule(data))
      .then(({ payload }) => {
        console.log("payload-", payload);
        if (payload?.success) {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setReviewModalShow(false);
          setEngagementDetail({});
          setSuccessMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "success",
            autoDismiss: true,
            style: {
              zIndex: '99999',height:toastHeight
            },
          });
          // setIsShow(true);
          setErrorApiMessage(null);
          setEngagementDetail({});
          setTimeout(() => {
            navigate("/staff/approval-complete");
          }, 2000);
        } else {
          const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
          setErrorApiMessage(payload?.message);
          addToast(payload?.message, {
            appearance: "error",
            autoDismiss: true,
            style:{
              height: toastHeight
            }
          });
          // setIsShow(true);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const hideHandle = () => {
    setEngagementDetail({});
    setErrorApiMessage(null);
    setReviewModalShow(false);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
    setEngagementDetail({});
  };
  return (
    <>
      <Popup
        onHide={hideHandle}
        open={reviewModalShow}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <>
              <Button
                className={classes.outlineBtn}
                variant="outlined"
                size="medium"
                onClick={hideHandle}
              >
                Cancel
              </Button>

              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={(e) => submitHandler()}
              >
                Submit
              </Button>
            </>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={notes_bg}
                style={{
                  width: "52.96px",
                  height: "52.96px",
                  marginRight: "10px",
                }}
                alt="icon"
              />
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#25282B",
                }}
              >
                Add Comment
              </Typography>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Typography
                style={{
                  margin: "16px 6px",
                  fontSize: "15px",
                  fontWeight: "600",
                  color: "#201F1E",
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    color: "#52575C",
                    marginRight: "6px",
                  }}
                >
                  Engagement plan review outcome:
                </span>
                 Further work needed
              </Typography>

              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                type="textarea"
                name="comments"
                placeholder="Add your Comments:"
                variant="outlined"
                multiline
                rows={5}
                value={
                  engagementDetail.comments ? engagementDetail.comments : ""
                }
                onChange={handleInputChange}
                error={Boolean(errorApiMessage)}
                helperText={errorApiMessage}
                
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Send Approval ",
            successMsg: successMessage,
            errorRes: errorApiMessage,
          }}
        />
      </Popup>
    </>
  );
}
