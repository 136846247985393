import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Tabs, Tab, Paper, Button } from "@material-ui/core";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import Introduction from "../EhiiaCases/Introduction";
import Assessment from "../EhiiaCases/Assessment";

import DataReviewExample from "../EhiiaCases/DataReviewExample";
import Engagement from "../../../components/Ehiia/EhiiaCases/Engagement";
import Result from "../../../components/Ehiia/EhiiaCases/Result";

import {
  getEhiiaProjectByProjectIdwithSectionId,
  getCareGroupByOrganization,
  createEhiiaModule,
} from "../../../store/actions/ehiiaAction";
// styles
import useStyles from "./styles";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../../component/common/loader/Loader";
import Validation from "../../../hooks/EhiiaValidation";
import { useToasts } from "react-toast-notifications";

export default function EhiiaDetail() {
  const { projectId } = useParams();
  console.log("projectId", projectId);
  // scrolling

  // scrolling
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isSaveExitCalled, setIsSaveExitCalled] = useState(false);
  const [nextQuestionsValue, setNextQuestionsValue] = useState([]);
  const [projectIdValue, setProjectIdValue] = useState(projectId);
  const [formState, setFormState] = React.useState({}); // Initialize form state
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [orgOption, setOrgOption] = useState(null);
  const [selectOrg, setSelectOrg] = useState(null);
  const [careGroupList, setCareGroupList] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [setionStatus, setSectionStatus] = useState([]);
  // form data binding
  const [introductionForm, setIntroductionForm] = useState({});
  const [assessmentForm, setAssessmentForm] = useState({});
  const [isUpdateCalled, setIsUpdateCalled] = useState(false);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.ehiia);
  //get admin details on store
  const { addToast } = useToasts();
  const [clientAdminPath, setClientAdminPath] = useState(""); // Default value is an empty string
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    const clientAdminPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);

    setClientAdminPath(clientAdminPath);
  }, []);
  useEffect(() => {
    console.log("selectOrg", selectOrg);
    dispatch(getCareGroupByOrganization({ organizartion: selectOrg }))
      .then((response) => {
        console.log("response", response);
        const { data } = response?.payload?.data;
        setCareGroupList(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [selectOrg, isRecordUpdated]);

  const goBack = () => {
    navigate(`/${clientAdminPath==='staff' ?'staff':'admin'}/manage-module/ehiia`);
  };
  useEffect(() => {
    console.log("careGroupList", careGroupList);
  }, [careGroupList]);

  var [activeTabId, setActiveTabId] = useState(0);
  var classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [ehiiaIntroductionDetail, setEhiiaIntroductionDetail] = useState(null);
  const [ehiiaFinalApprovalDetail, setEhiiaFinalApprovalDetail] =
    useState(null);
  const [projectSummaryData, setProjectSummaryData] = useState([]);
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
    ).then(({ payload }) => {
      console.log("payload", payload);
      if (payload?.success) {
        console.log("payload?.data", payload?.data);
        console.log("payload?.data?.form", payload?.data?.form);
        setProjectSummaryData(payload?.data?.projectSummary);
        setEhiiaProjectDetail(payload?.data?.form);
        setIntroductionForm(payload?.data?.form?.introduction);
        setEhiiaFinalApprovalDetail(payload?.data?.form?.finalApproval);
        setAssessmentForm(payload?.data?.form?.assessment?.questions);
        setSectionStatus(payload?.data?.form?.sections);
        setNextSection(payload?.data?.form?.nextSection);
        const sections = payload?.data?.form?.sections;
        const secIndex = sections.findIndex(
          (sec) => sec.value === payload?.data?.form?.nextSection,
        );
        setActiveTabId(secIndex >= 0 ? secIndex : 0);
      }
    });
  }, []);

  useEffect(() => {
    if (isRecordUpdated || isUpdateCalled) {
      dispatch(
        getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
      ).then(({ payload }) =>{
console.log("payload",payload);
if(payload?.success){
  setEhiiaProjectDetail(payload?.data?.form);
  setSectionStatus(payload?.data?.form?.sections);
  setNextSection(payload?.data?.form?.nextSection);
}
      });
    }
    setIsUpdateCalled(false);
  }, [isRecordUpdated, isUpdateCalled]);

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      projectTitle: introductionForm?.projectTitle?.value,
      projectLead: introductionForm?.projectLead?.value,
      lineManager: introductionForm?.lineManager?.value,
      lineManagerEmail: introductionForm.lineManagerEmail?.value,
      lineManagerRole: introductionForm?.lineManagerRole?.value,
      serviceManager: introductionForm?.serviceManager?.value,
      serviceManagerRole: introductionForm?.serviceManagerRole?.value,
      organization: introductionForm?.organization?.value,
      careGroup: introductionForm?.careGroup?.value,
      mainPurpose: introductionForm?.mainPurpose?.value,
      newOrExisting: introductionForm?.newOrExisting?.value,
      // commissioningStatus: introductionForm?.commissioningStatus?.value,
      trainingCompleted: introductionForm?.trainingCompleted?.value,
    });
  // Add introduction form functionality
  const submitUpdateHandler = (e) => {
    console.log("check-validation");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    console.log("enter api hitting");
    const data = {
      projectId: projectId,
      sectionId: "introduction",
      questions: {
        introduction: {
          projectTitle: introductionForm.projectTitle?.value,
          projectLead: introductionForm.projectLead?.value,
          projectLeadRole:introductionForm?.projectLeadRole?.value,
          projectLeadEmail: introductionForm.projectLeadEmail?.value,
          lineManager: introductionForm.lineManager?.value,
          lineManagerEmail: introductionForm.lineManagerEmail?.value,
          lineManagerRole: introductionForm.lineManagerRole?.value,
          serviceManager: introductionForm.serviceManager?.value,
          serviceManagerRole: introductionForm.serviceManagerRole?.value,
          serviceManagerEmail: introductionForm?.serviceManagerEmail?.value,
          organization: introductionForm.organization?.value,
          careGroup: introductionForm.careGroup?.value,
          mainPurpose: introductionForm.mainPurpose?.value,
          newOrExisting: introductionForm.newOrExisting?.value,
          searchTags:introductionForm?.searchTags?.value ? introductionForm?.searchTags?.value : introductionForm?.searchTags?.values,
          // commissioningStatus: introductionForm?.commissioningStatus?.value,
          trainingCompleted: introductionForm?.trainingCompleted?.value,
        },
      },
    };

    if (!isError() && error !== null && isActionCalled) {
      console.log("Done validation");
      dispatch(createEhiiaModule(data))
        .then(({ payload }) => {
          console.log("payload-", payload);
          if (payload?.success) {
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            hideHandler();
            setSuccessMessage(payload?.message);
            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: '99999',height:toastHeight
              },
            });
            // setIsShow(true);
            setErrorApiMessage(null);
            setEhiiaIntroductionDetail({});
            setNextSection(payload?.data?.nextSection);
            setNextQuestionsValue(payload?.data?.nextQuestions);
            if (isSaveExitCalled) {
              goBack();
            } else {
              if(payload?.data?.nextSection ==='assessment'){
                setActiveTabId(1);
              }         
            }
            setIsActionCalled(false);
            setIsSaveExitCalled(false);
          } else {
            console.log("payload?.error", payload);
            const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style: {
                zIndex: '99999',height:toastHeight
              },
            });
            setErrorApiMessage(payload?.message);
            // setIsShow(true);
            setEhiiaIntroductionDetail({});
            setIsActionCalled(false);
            setIsSaveExitCalled(false);
          }
        })
        .catch((error) => console.log("error", error));
      setIsActionCalled(false);
      setIsSaveExitCalled(false);
    } else {
      setIsActionCalled(false);
      setIsSaveExitCalled(false);
    }
  }, [isActionCalled]);

  const handleView = () => {
    navigate(`/${clientAdminPath==='staff' ?'staff':'admin'}/view-project/${projectId}`);
  };
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
  };
  const [isScrollingUp, setIsScrollingUp] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    console.log("scrollTop",scrollTop);
    setIsScrollingUp(scrollTop > 120); // Change the threshold as needed
    // setIsScrollingUp(scrollTop < 120 && scrollTop > 160 ? true : '');
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //
  // Find the "Assessment" section in the sections array
  const assessmentSection = setionStatus.find(
    (section) => section.value === "assessment",
  );
  const dataReviewSection = setionStatus.find(
    (section) => section.value === "dataReview",
  );
  const engagementSection = setionStatus.find(
    (section) => section.value === "engagement",
  );
  // Check if the "Assessment" section exists and is completed
  const isAssessmentCompleted =
    assessmentSection && assessmentSection.completed;
  const isDataReviewCompleted =
    dataReviewSection && dataReviewSection.completed;
  const isEngagementCompleted =
    engagementSection && engagementSection.completed;

  const saveExit = (e) => {
    navigate(`/${clientAdminPath==='staff' ?'staff':'admin'}/manage-module/ehiia`);
  };

  const submitExitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsSaveExitCalled(true);
    setIsActionCalled(true);
  };

  const renderTabContent = (id, tabKey) => {
    switch (tabKey) {
      case "introduction":
        return (
          <Grid container spacing={4}>
            <Grid className={classes.gridItem} item xs={12}>
              <Widget>
                {isLoader && <Loader />}
                <Introduction
                  setIntroductionForm={setIntroductionForm}
                  introductionForm={introductionForm}
                  setEhiiaIntroductionDetail={setEhiiaIntroductionDetail}
                  ehiiaIntroductionDetail={ehiiaIntroductionDetail}
                  error={error}
                  checkValidator={checkValidator}
                  resetAllValidator={resetAllValidator}
                  resetValidator={resetValidator}
                  isError={isError}
                  setOrgOption={setOrgOption}
                  orgOption={orgOption}
                  setSelectOrg={setSelectOrg}
                  selectOrg={selectOrg}
                  careGroupList={careGroupList}
                  setCareGroupList={setCareGroupList}
                />
              </Widget>
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                style={{ background: "#FEAA00" }}
                color="secondary"
                onClick={(e) => submitExitHandler(e)}
              >
                Save & Exit
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                color="secondary"
                onClick={(e) => submitUpdateHandler(e)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        );
      case "assessment":
        return (
          <Assessment
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            assessmentForm={{ questions: assessmentForm }}
            setAssessmentForm={setAssessmentForm}
            setFormState={setFormState}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setIsUpdateCalled={setIsUpdateCalled}
            setActiveTabId={setActiveTabId}
            setionStatus={setionStatus}
            type="detail"
          />
        );
      case "dataReview":
        return (
          <DataReviewExample
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            setFormState={setFormState}
            // formState={formState}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setActiveTabId={setActiveTabId}
            setIsUpdateCalled={setIsUpdateCalled}
            setionStatus={setionStatus}
            type="detail"
          />
        );
      case "engagement":
        return (
          <Engagement
            nextQuestionsValue={nextQuestionsValue}
            setNextQuestionsValue={setNextQuestionsValue}
            setProjectIdValue={setProjectIdValue}
            projectIdValue={projectIdValue}
            setActiveTabId={setActiveTabId}
            setIsUpdateCalled={setIsUpdateCalled}
            setionStatus={setionStatus}
            type="detail"
          />
        );
      case "approvalResult":
        return (
          <Grid container spacing={4} >
            <Grid item xs={12} >
              <Result projectSummaryData={projectSummaryData}
                setProjectIdValue={setProjectIdValue}
                projectIdValue={projectIdValue}
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PageTitle
        subtitle="Ongoing Projects >"
        title="Details"
        urlLink={`/${clientAdminPath==='staff' ?'staff':'admin'}/manage-module/ehiia`}
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          {projectSummaryData?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={index % 2 === 0 ? 4 : 5}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {" "}
                {item?.title}:
              </Typography>
              <Typography className={classes.formHeadingValue}>
                {item?.title === "Status" ? (
                  <>
                    {item?.value === "In-Progress" ? (
                      <span style={{ color: "#FEAA00" }}>{item?.value}</span>
                    ) : (
                      <span style={{ color: "#10940D" }}>{item?.value}</span>
                    )}
                  </>
                ) : (
                  <>{item?.value}</>
                )}
              </Typography>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            md={3}
            className={classes.textGroup}
            style={{ display: "block" }}
          >
            <Button
              style={{
                float: "right",
                backgroundColor: "#FEAA00",
                width: "110px",
                height: "34px",
                textTransform: "none",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={handleView}
            >
              View
            </Button>
          </Grid>
        </Grid>
      </Widget>

      <Paper className={classes.iconsContainer}>
        <div className={classes.fixedTabs}>
          <Tabs
            style={
              isScrollingUp
                ? {
                    position: "fixed",
                    top: "50px",
                    zIndex: 1,
                    backgroundColor: "white",
                    width: "80%",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0px 0px 10px 0 rgba(0, 0, 0, 0.19)",
                    // border: "1px solid #03997B",
                  }
                : {}
            }
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            className={classes.iconsBar}
          >
            {setionStatus.map((section, index) => (
              <Tab
                key={index}
                label={section.title}
                classes={{ root: classes.tab }}
                disabled={
                  section.completed
                    ? false
                    : section.value === nextSection
                    ? false
                    : true
                } // Disable the tab if the section is not completed
                onClick={() => setActiveTabId(index)}
              />
            ))}
          </Tabs>
        </div>
        <div>
          {setionStatus.map(
            (section, index) =>
              activeTabId === index && renderTabContent(index, section?.value),
          )}
        </div>
      </Paper>

      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Manage Module",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
