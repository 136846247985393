import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import notes from "./noteEdit.svg";
import useStyles from "../styles";
export default function AssessmentThree() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />

        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q16. Could the work help to eliminate unlawful discrimination,
            harrassment, victimisation to patients with a Disability or prevent
            any other conduct prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q17. Could the work help to advance equality of opportunity between
            people who have a disability and those who do not?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q18. Could the initiative help to foster good relations between
            people who have a disability and those who do not?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q19. Will the work impact either directly or indirectly on the
            ability of disabled people to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q20. Will the work impact either directly or indirectly on the
            ability of disabled people to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q21. Will the work impact either directly or indirectly on Health
            Outcomes for patients with a disability?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q22. Could the work help to advance equality of opportunity for
            patients of different ages or prevent and other conduct prohibited
            by the Act ?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q23. Could the initiative help to foster good relations between
            patients of different ages?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q24. Will the work impact either directly or indirectly on the
            ability of patients of different ages to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q25. Will the work impact either directly or indirectly on Health
            Outcomes for patients of different ages
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q26. Could the work help to eliminate unlawful discrimination,
            harrassment, victimisation to patients who have completed or are in
            the process of completing Gender Reassignment or prevent any other
            conduct prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q27. Could the work help to advance equality of opportunity for
            patinets who have completed or are in the process of completing
            Gender Reassignment and those people who are not?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q28. Could the initiative help to foster good relations between our
            patients who have completed or are in the process of completing
            Gender Reassignment and those people who are not?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q29. Will the work impact either directly or indirectly on the
            ability of patients with Gender Reassignment to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q30. Will the work impact either directly or indirectly on Health
            Outcomes for patients with Gender Reassignment?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q31. Could the work help to eliminate unlawful discrimination,
            harrassment, victimisation to patients who are, or are not in any
            marriage or civil partnership or prevent any other conduct
            prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q32. Could the work help to advance equality of opportunity for
            people who are, or are not married or in a civil partnership?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q33. Could the initiative help to foster good relations between
            people of different marital status'
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q34. Will the work impact either directly or indirectly on the
            ability of patients of different marital status to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q35. Will the work impact either directly or indirectly on Health
            Outcomes for patients of different marital status?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q36. Could the work help to eliminate unlawful discrimination,
            harrassment, victimisation to patients who are pregnant or in a
            period of maternity or prevent any other conduct prohibited by the
            Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q37. Could the work help to advance equality of opportunity for
            people who are pregnant or in a period of opportunity and those who
            are not?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q38. Could the initiative help to foster good relations between
            people who are pregnant or in a period of maternity and those who
            don't?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q39. Will the work impact either directly or indirectly on the
            ability of patients who are pregnant or in a period of maternity to
            Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q40. Will the work impact either directly or indirectly on Health
            Outcomes for patients who are pregnant or in a period of maternity?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q41. Could the work help to eliminate unlawful discrimination,
            harrassment, victimisation to patients of different race and/ or
            ethnicity or prevent any other conduct prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q42. Could the work help to advance equality of opportunity for
            patients of different race and ethnicity?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q43. Could the initiative help to foster good relations between
            people of different race and ethnicity
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q44. Will the work impact either directly or indirectly on the
            ability of patients of different race and/or ethnicity to Access
            Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q45. Will the work impact either directly or indirectly on Health
            Outcomes for patients of different race abd/or ethnicity to Access
            Servces?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q46. Could the work help to eliminate unlawful discrimination,
            harassment, victimisation to patients of different religions or
            different beliefs or prevent any other conduct prohibited by the
            Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q47. Could the work help to advance equality of opportunity between
            people of different religions or with different beliefs?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q48. Could the initiative help to foster good relations between
            people of different religions or with different beliefs?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q49. Will the work impact either directly or indirectly on the
            ability of patients of different religions or beliefs to Access
            Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q50. Will the work impact either directly or indirectly on Health
            Outcomes for patients of differentreligions or beliefs to Access
            Servces?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q51. Could the work help to eliminate unlawful discrimination,
            harassment, victimisation to patients of different genders or
            prevent any other conduct prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q52. Could the work help to advance equality of opportunity between
            people of different genders?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q53. Could the initiative help to foster good relations between
            people of different genders?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q54. Will the work impact either directly or indirectly on the
            ability of patients of different genders to Access Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q55. Will the work impact either directly or indirectly on Health
            Outcomes for patients of different genders to Access Servces?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q56. Could the work help to eliminate unlawful discrimination,
            harassment, victimisation to patients of different sexual
            orientations or prevent any other conduct prohibited by the Act?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q57. Could the work help to advance equality of opportunity between
            people of different sexual orientations.
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q58. Could the initiative help to foster good relations between
            people of different sexual orientations.
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q59. Will the work impact either directly or indirectly on the
            ability of patients of different sexual orientation to Access
            Services?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q60. Will the work impact either directly or indirectly on Health
            Outcomes for patients of different sexual orientation to Access
            Servces?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="1">Yes - Physical Disability</MenuItem>
              <MenuItem value="2">
                Yes - Mental Health or Learning Disability
              </MenuItem>
              <MenuItem value="3">No</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            className={classes.txtForm}
            fullWidth
            placeholder="Additional Info"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </>
  );
}
