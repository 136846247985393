import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../store/actions/subAdminAction";
import { Alert } from "@material-ui/lab";
import Loader from "../../../component/common/loader/Loader";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  console.log("toggleActivateStatus", toggleActivateStatus);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => state.subadmins);

  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        adminId: toggleActivateRec,
        // status:toggleActivateStatus,
      }),
    ).then(({payload}) => {
      if(payload?.success){
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }else{
        const toastHeight = payload?.message.length > 35 ? 'auto' : '34px';
        addToast(payload?.message, {
          appearance: "error",
          autoDismiss: true,
          style:{
            height: toastHeight
          }
        });
      }
      
    });
  };
  var classes = useStyles();
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  useEffect(() => {
    if (isRecordUpdated) {
      setIsDone(true);
      onHide();
      setToggleActivateModalShow(false);
    }
  }, [isRecordUpdated]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {/* {apiError ? (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  Ok
                </Button>
              </>
            ) : ( */}
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            {/* )} */}
          </>
        }
      >
        <DialogContent className={classes.dialogBoxContent}>
          <DialogContentText className={classes.formBox}>
            <center>
              {" "}
              <img src={alertIcon} alt="img"/>
              {isLoader && <Loader />}
              {/* {apiError ? (
                <Alert severity="error">
                  {apiError?.message || "Access denied! Please try again"}
                </Alert>
              ) : ( */}
                <Typography className={classes.formActiveHeading}>
                  {toggleActivateStatus ? (
                    <>Are you sure, you want to deactivate this admin?</>
                  ) : (
                    <>Are you sure, you want to activate this admin?</>
                  )}
                </Typography>
              {/* )} */}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
