import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  PeopleOutline,
  Cubes,
  Dashboard,
  DashboardOutlined,
} from "@material-ui/icons";
import dashboard from "../../assets/icons/dashboard.png";
import dashboardcolor from "../../assets/icons/color_dashboard.png";
import admin from "../../assets/icons/manageAdmin.png";
import admincolor from "../../assets/icons/managerAdmin_color.png";
import user from "../../assets/icons/manageUser.png";
import usercolor from "../../assets/icons/managerUser_color.png";
import report from "../../assets/icons/report.png";
import reportcolor from "../../assets/icons/tbc_color.png";
import project from "../../assets/icons/project.png";
import projectcolor from "../../assets/icons/project_color.png";
import cube from '../../assets/icons/cube.png';
import cubecolor from '../../assets/icons/cube_color.png';
import audit from "../../assets/icons/audit.png";
import auditcolor from "../../assets/icons/audit_color.png";
import content from "../../assets/icons/content.png";
import contentcolor from "../../assets/icons/content_color.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import profileImg from "../../assets/icons/photo-89.png";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllEhiiaVersionList } from "../../super-admin/store/actions/ehiiasAction";

import classNames from "classnames";
// icons sets
import "font-awesome/css/font-awesome.min.css";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

// const structure = [
//   {
//     id: 0,
//     label: "Dashboard",
//     link: "/super-admin/dashboard",
//     icon: <Dashboard />,
//   },

//   {
//     id: 1,
//     label: "Manage Module",
//     link: "/super-admin/manage-module/ehiia",
//     icon: <LibraryIcon />,
//     children: [],
//   },
//   {
//     id: 2,
//     label: "Manage Client",
//     link: "/super-admin/manage-client",
//     icon: <PeopleOutline />,
//   },
// ];
// for super-admin
const initialAdminStructure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/super-admin/dashboard",
    icon: <img src={dashboard} alt="dash" />,
    icon1: <img src={dashboardcolor} alt="dash" />,
  },
  {
    id: 1,
    label: "Manage Clients",
    link: "/super-admin/manage-client",
    icon: <img src={user} alt="user" />,
    icon1: <img src={usercolor} alt="user" />,
  },
  {
    id: 2,
    label: "EHIA Module",
    link: "/super-admin/manage-module/ehiia",
    icon: <img src={cube} alt="cube" />,
    icon1: <img src={cubecolor} alt="cube" />,
  },
  // {
  //   id: 3,
  //   label: "Equality Module",
  //   link: "/super-admin/manage-module/equality",
  //   icon: <i className="fa fa-cubes" />,
  // },
  {
    id: 4,
    label: "Manage Admins",
    link: "/super-admin/manage-admin",
    icon: <img src={admin} alt="admin" />,
    icon1: <img src={admincolor} alt="admin" />,
  },
];

const clientStructure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: <img src={dashboard} alt="dash" />,
    icon1: <img src={dashboardcolor} alt="dash" />,
  },

  {
    id: 3,
    label: "Manage Staff",
    link: "/admin/manage-client",
    icon: <img src={user} alt="user" />,
    icon1: <img src={usercolor} alt="user" />,
  },
  {
    id: 2,
    label: "Manage Admins",
    link: "/admin/manage-admin",
    icon: <img src={admin} alt="admin" />,
    icon1: <img src={admincolor} alt="admin" />,
  },
  {
    id: 2,
    label: "EHIA Projects",
    link: "/admin/manage-module/ehiia",
    icon: <img src={cube} alt="cube" />,
    icon1: <img src={cubecolor} alt="cube" />,
  },
  
  // {
  //   id: 4,
  //   label: "Reports",
  //   link: "/admin/report",
  //   icon: <img src={report} alt="report" />,
  //   icon1: <img src={reportcolor} alt="report" />,
  // },
  {
    id: 5,
    label: "Project Repositories",
    link: "/admin/project",
    icon: <img src={project} alt="project" />,
    icon1: <img src={projectcolor} alt="project" />,
  },
  {
    id: 6,
    label: "Configuration",
    link: "/admin/approval",
    icon: <img src={content} alt="project" />,
    icon1: <img src={contentcolor} alt="project" />,
    children: [
      {
        
        // icon: <img src={project} alt="project" />,
        // icon1: <img src={projectcolor} alt="project" />,
        label: "Approval Process",
        link: "/admin/approval",
      },
      {
        // icon: <img src={project} alt="project" />,
        // icon1: <img src={projectcolor} alt="project" />,
        label: "Data Repository",
        link: "/admin/approval/data-review-content",
      },
    ],
  },
];
const userStructure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/staff/dashboard",
    icon: <img src={dashboard} alt="dash" />,
    icon1: <img src={dashboardcolor} alt="dash" />,
  },

  {
    id: 1,
    label: "EHIA Module",
    link: "/staff/manage-module/ehiia",
    icon: <img src={cube} alt="cube" />,
    icon1: <img src={cubecolor} alt="cube" />,
    // children: [{ label: "EHIA Module", link: "/staff/manage-module/ehiia" }],
  },
  {
    id: 2,
    label: "Project Repositories",
    link: "/staff/project",
    icon: <img src={project} alt="project" />,
    icon1: <img src={projectcolor} alt="project" />,
  },
];

function Sidebar() {
  const [moduleOption, setModuleOption] = useState(null);
  const [adminStructure, setAdminStructure] = useState([
    ...initialAdminStructure,
  ]);

  const dispatch = useDispatch();

  let clientAdminPath;

  const { list } = useSelector((state) => {
    return state.ehiia;
  });
  // api
  const admin = useSelector((state) => state.admin);
  console.log("admin", admin);
  const { adminDetail } = admin;
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);

    const pathSegments = path.split("/");
    clientAdminPath = pathSegments[1]; // Assuming "admin" is the first segment of the path
    console.log("Client Admin Path:", clientAdminPath);
    if (!Array.isArray(list)) {
      if (clientAdminPath === "super-admin") {
        dispatch(fetchAllEhiiaVersionList())
          .then((response) => {
            const { data } = response;
            if (Array.isArray(data)) {
              const updatedStructure = [
                ...adminStructure.slice(0, 2),
                ...data.map((option) => ({
                  label: option.name,
                  link: `/super-admin/manage-module/${option.versionId}`,
                  icon: <i className="fa fa-cubes" />,
                })),
                ...adminStructure.slice(3),
              ];
              setModuleOption(data);
              setAdminStructure(updatedStructure);
            }
            console.log("moduleOption", moduleOption);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  }, []);

  // useEffect(()=>{

  //   if (clientAdminPath === "super-admin") {
  //     structure = initialAdminStructure;
  //   } else if (
  //     clientAdminPath === "admin" ||
  //     clientAdminPath === "admin"
  //   ) {
  //     structure = clientStructure;
  //   } else {
  //     structure = userStructure;
  //   }
  // },[clientAdminPath]);

  const updateAdminStructure = (list) => {
    const updatedStructure = [
      ...adminStructure.slice(0, 2),
      ...list.map((option) => ({
        label: option.name,
        link: `/super-admin/manage-module/${option.versionId}`,
        icon: <i className="fa fa-cubes" />,
      })),
      ...adminStructure.slice(3),
    ];
    setModuleOption(list);
    setAdminStructure(updatedStructure);
  };
  useEffect(() => {
    console.log("list", list);
    if (Array.isArray(list)) {
      updateAdminStructure(list);
    }
  }, [list]);

  const location = useLocation();
  var classes = useStyles();
  var theme = useTheme();
  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  // const isAdminPanel = location.pathname.includes("/super-admin/");

  // const structure = isAdminPanel ? initialAdminStructure : clientStructure ? clientAdminPath : userStructure;

  const isAdminPanel = location.pathname.includes("/super-admin/");
  const isClientAdmin = location.pathname.includes("/admin/");
  const isUser = !isAdminPanel && !isClientAdmin;

  let structure;

  if (isAdminPanel) {
    structure = initialAdminStructure;
  } else if (isClientAdmin) {
    structure = clientStructure;
  } else {
    structure = userStructure;
  }

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            moduleOption={moduleOption}
            isSidebarOpened={isSidebarOpened}
            
            children={link.children}
            {...link}
          />
        ))}
      </List>
      {!isAdminPanel && isSidebarOpened &&
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        justifyItems: "center",
        position: "absolute",
        bottom: "0",

        // marginBottom: "20px",
      }}
    >
      {adminDetail && adminDetail?.data?.org?.logo && (
        <>
          <img
            src={adminDetail?.data?.org?.logo.replace("rounded=true","rounded=false")}
            classes={{ root: classes.headerIcon }}
            alt="img"
            style={{
              width: "230px",
              height: "130px",
              borderTopRightRadius: "10px",
              objectFit:'contain'
              // border: "1px solid #03997B",
            }}
          />
        </>
      )}
    </div>
      }
      
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default Sidebar;
