import React, { useEffect, useState, useRef } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
// Model

import { Typography } from "../../../../component/Wrappers/Wrappers";
// components

import TableWidget from "../../../../component/Widget/TableWidget";

// styles
import useStyles from "./styles";
import TableDesign from "../../../../component/common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";
import ActivationLinkClientPopup from "./ActivationLinkClientPopup";

import DeletePopup from "./DeletePopup";

// API data imports
import { fetchClientList } from "../../../store/actions/clientsAction";
import { getEhiiaVersionListByVersionId } from "../../../store/actions/ehiiasAction";
import { resetPopup } from "../../../store/reducerSlice/clients";
import ApiResponsePopup from "../../../../component/common/modal/ApiResponsePopup";
import ManageAssignModule from "./ManageAssignModule";

export default function AssignModuleList({ clientDetail, setClientDetail }) {
  // const history = useHistory();
  console.log("clientDetail", clientDetail);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectVersionDetails, setSelectVersionDetails] = useState(null);

  //
  const [anchorE2, setAnchorE2] = React.useState(null);

  //

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Module Name",
      align: "left !important",
    },
    {
      label: "Version Name",
    },
    {
      label: "Created Date",
    },
    // {
    //   label: "Created By",
    // },
    {
      label: "Updated Date",
    },
    {
      label: "Status",
    },
    // {
    //   label: "Action",
    // },
  ];

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  //
  const [value, setValue] = React.useState("");
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [activationLinkRec, setActivationLinkRec] = useState(null);
  const [activationLinkModalShow, setActivationLinkModalShow] = useState(false);
  const [deleteRec, setDeleteRec] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [resetPasswordModalShow, setResetPasswordModalShow] = useState(false);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };

  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "createdAt",
    orderIn: -1,
  });
  const [message, setMessage] = useState(null);

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    clients,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.clients;
  });
  const { list } = useSelector((state) => {
    return state.ehiias;
  });
  const page = pagination?.currentPage;

  const cc = filtered || clients;

  useEffect(() => {
    console.log("clientDetail", clientDetail);
    console.log("clients", clients);
    console.log("filterOption", filterOption);
    dispatch(fetchClientList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  useEffect(() => {
    if (clientDetail) {
      const ehiiaVersionId = clientDetail.versionId.ehiia;
      setSelectedModule(ehiiaVersionId);
      console.log("selectedModule", selectedModule);
      dispatch(getEhiiaVersionListByVersionId({ versionId: ehiiaVersionId }))
        .then(({ payload }) => {
          console.log("payload", payload);
          setSelectVersionDetails(payload?.data?.version);
        })
        .catch((error) => {
          console.log("Error fetching version list:", error);
        });
    }
  }, [clientDetail, selectedModule]);

  useEffect(() => {
    console.log("selectVersionDetails", selectVersionDetails);
  }, [selectVersionDetails]);
  const hideHandle = () => {
    console.log("hidehandle called");
    setselectclient(null);
    setModalShow(false);
    setOpen(false);
    setDeleteModalShow(false);
    setDeleteRec(null);
    setToggleActivateModalShow(false);
    setActivationLinkModalShow(false);
    setResetPasswordModalShow(false);
  };

  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
      <ManageAssignModule
        open={open}
        onHide={hideHandle}
        title={"Assign Module"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <ActivationLinkClientPopup
        onHide={hideHandle}
        title={"Activation"}
        activationLinkRec={activationLinkRec}
        activationLinkModalShow={activationLinkModalShow}
        setActivationLinkModalShow={setActivationLinkModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        title={"Delete"}
        deleteRec={deleteRec}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />

      <div className={classes.pageTitleContainer} style={{ marginTop: "24px" }}>
        {selectVersionDetails && (
          <Typography className={classes.typo} weight="medium">
            Assigned Modules
          </Typography>
        )}
      </div>

      {selectVersionDetails ? (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TableWidget noBodyPadding>
                <TableDesign
                  {...{
                    title: "List of All Clients",
                    tableHeadings,
                    filterHandler,
                    // pagination,
                    filterOption,
                    setFilterOption,
                    isLoader,
                  }}
                >
                  <TableRow
                    key={selectVersionDetails.versionId}
                    className={classes.tableBodyCell}
                  >
                    <TableCell align="left" className={classes.tableBodyCell}>
                      1
                    </TableCell>

                    <TableCell align="left" style={{ cursor: "pointer" }}>
                      <span className={classes.tableCell}>
                        {selectVersionDetails.versionUID}
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectVersionDetails.name}
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      {formatedDate(selectVersionDetails.createdAt)}
                    </TableCell>
                    {/* <TableCell align="left" className={classes.tableCell}>
                      
                    </TableCell> */}
                    <TableCell align="left" className={classes.tableCell}>
                    {formatedDate(selectVersionDetails.updatedAt)}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {selectVersionDetails.isActive ? (
                        selectVersionDetails.isPublished ? (
                          <span
                            style={{
                              color: "#10940D",
                              fontWeight: 500,
                            }}
                          >
                            Active
                          </span>
                        ) : (
                          <span style={{ color: "#F8A80C" }}>Pending</span>
                        )
                      ) : (
                        <span style={{ color: "red" }}>Inactive</span>
                      )}
                    </TableCell>
                    {/* <TableCell className={classes.tableBodyCell}>
                      <div>
                        <IconButton
                          id={selectVersionDetails}
                          aria-controls={
                            openDown ? { selectVersionDetails } : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openDown ? "true" : undefined}
                          onClick={(e) => {
                            handleClick(e);
                            setSelectRow(selectVersionDetails);
                          }}
                        >
                          <MoreIcon />
                        </IconButton>

                        <Menu
                          className={classes.menuBox}
                          anchorEl={anchorEl}
                          open={openDown}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem
                            className={classes.menuItem}
                            onClick={() =>
                              navigate(`/client-edit/${selectRow?.clientId}`)
                            }
                          >
                            <Lens
                              style={{
                                height: "4px",
                                width: "4px",
                                marginRight: "4px",
                              }}
                            />
                            Unassigned
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell> */}
                  </TableRow>
                </TableDesign>
              </TableWidget>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}

      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
