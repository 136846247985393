import clients from "./reducerSlice/clients";
import modules from "./reducerSlice/modules";
import ehiiaversionsClient from "./reducerSlice/ehiiaversionsClient";
import versions from "./reducerSlice/versions";
import dashboards from "./reducerSlice/dashboards";
import organizations from "./reducerSlice/organizations";
import subclients from "./reducerSlice/subclients";
import subadmins from "./reducerSlice/subadmins";
import admin from "./reducerSlice/admin";
import approvals from "./reducerSlice/approvals";
import portalusers from "./reducerSlice/portalusers";
import privatedata from "./reducerSlice/privatedata";

export const clientAdminReducers = {
  admin,
  clients,
  modules,
  versions,
  dashboards,
  organizations,
  subclients,
  subadmins,
  ehiiaversionsClient,
  portalusers,
  approvals,
  privatedata,
};
