import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchSubClientList = createAsyncThunk(
    'clients/fetchSubClient',
    (arg, { rejectWithValue }) => DataService.get(arg,  path+'/sub-clients', rejectWithValue)
)

export const createUpdateSubClient = createAsyncThunk(
    'clients/createUpdateClient',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/sub-clients', rejectWithValue)
)
export const updateSubClient = createAsyncThunk(
    'clients/createUpdateSubClient',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/sub-clients/update', rejectWithValue)
)

export const deleteSubClient = createAsyncThunk(
    'clients/deleteSubClient',
    (arg, { rejectWithValue }) => DataService.delete(arg, path+`/sub-clients/${arg?.staffId}`, rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'clients/toggleActivation',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/sub-clients/toggle-activation/`, rejectWithValue)
)

export const sendActivationLink = createAsyncThunk(
    'clients/sendActivationLink',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/sub-clients/resend-activation/${arg?.staffId}`, rejectWithValue)
)

export const sendResetPasswordLink = createAsyncThunk(
    'clients/sendResetPasswordLink',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/sub-clients/reset-password/${arg?.staffId}`, rejectWithValue)
)

export const getSubClientBySubClientId = createAsyncThunk(
    'clients/getSubClientBySubClientId',
    (arg, { rejectWithValue }) => DataService.get(arg, path+`/sub-clients/${arg?.staffId}`, rejectWithValue)
)