import { createAsyncThunk } from "@reduxjs/toolkit";

import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;

export const fetchOngoingProjectList = createAsyncThunk(
  "ehiiaversionsClient/fetchOngoingProjectList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/list/ongoing?orderBy=${arg?.orderBy}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const fetchCompleteProjectList = createAsyncThunk(
  "ehiiaversionsClient/fetchCompleteProjectList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/list/completed?orderBy=${arg?.orderBy}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const fetchProjectRepoList = createAsyncThunk(
  "ehiiaversionsClient/fetchProjectRepoList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/list/repository?orderBy=${arg?.orderBy}&search=${
        arg?.search || ""
      }&page=${arg?.page}`,
      arg,
      rejectWithValue,
    ),
);

export const toggleProjectRepoList = createAsyncThunk(
  "ehiiaversionsClient/toggleProjectRepoList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/toggle-repository/${arg?.projectId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const getEhiiaProjectByProjectId = createAsyncThunk(
  "ehiiaversionsClient/getEhiiaProjectByProjectId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/pre-submission/${arg?.projectId || ""}`,
      rejectWithValue,
    ),
);
export const getEhiiaProjectByProjectIdwithSectionId = createAsyncThunk(
  "ehiiaversionsClient/getEhiiaProjectByProjectIdwithSectionId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/ehiia-project/v1/form-details/${arg?.projectId}/${
        arg?.sectionId || ""
      }`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);


