import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  tableCell: {
    // display: "flex",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  avatar: {
    width: "68px",
    height: "68px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  txtForm: {
    padding: "0px 4px",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  iconsBar: {
    marginBottom: theme.spacing(4),
    borderBottom: "1px solid ",
    borderBottomColor: theme.palette.text.hint + "80",
    background: "#fff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
  },
  tab: {
    color: "#201F1E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    marginRight:'2px',
    "&:hover": {
      borderBottom: "2px solid #0445AF",
      color: "#0445AF",
    },
    "&:active": {
      borderBottom: "2px solid #0445AF",
      color: "#0445AF",
    },
    "&.Mui-selected": {
      borderBottom: "2px solid ",
      borderBottomColor: "#0445AF",
      color: "#0445AF",
    },
    "& .MuiTabs-indicator": {
      borderBottom: "2px solid ",
      borderBottomColor: "#0445AF",
    },
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  //

  tableMainBox: {
    display: "flex",

    minHeight: "100%",
  },
  menuBox: {
    padding: "4px",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  menuItem:{
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "4px 10px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  // page title
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    // marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display:'inline-block',
      marginBottom: theme.spacing(2),
    }
  },
  typo: {
    color: "#2A2338",
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "20px !important",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "3px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    }
  },
  button1: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    backgroundColor: "#3CCB97",
    color: "#fff",
    marginRight: theme.spacing(2),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    borderRadius: "4px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
  typoGray: {
    color: "#ABAFB7",
  },
  icons: {
    // width:'12px',
    // height:'44px'
    // marginTop:theme.spacing(2),
    // paddingTop:theme.spacing(2)
  },
  groupBtn: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display:'inline-grid',
    }
  },
  search: {
    position: "relative",
    borderRadius: '2px',
    border: "1px solid #F0F0F0",
    paddingLeft: theme.spacing(2.5),
    width: 36,
    // backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
    },
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
    marginRight: theme.spacing(2),
    background: "#fff",
    height: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
  searchFocused: {
    // backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
    background: "#fff",
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
  },
  searchIcon: {
    width: 36,
    left: 0,
    color: "#0078D4",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    // backgroundColor:'#fff'
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    // height: 36,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    paddingLeft: "10px",
    fontSize:'14px',
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
    
  },
  downloadImg: {
    marginRight: theme.spacing(1),
  },
  editicons: {
    marginRight: theme.spacing(0.5),
  },
  // page title
}));
