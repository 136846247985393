export const manageEhiiaSchema = {
  versionName: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter version name",
      noLeadingSpace: "Version name cannot start with a space",
    },
  },
  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter admin name",
      noLeadingSpace: "Admin name cannot start with a space",
      atLeastOneAlpha: "At least one alphabet character is required",
    },
  },
  email: {
    type: "email",
    required: true,
    properties: {
        empty: "Please enter email",
        email: "Please enter a valid Email"
    }
},
  module: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select module",
    },
  },
  description: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter description",
    },
  },
  createdAt: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select created date",
    },
  },
  createdBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter created by",
    },
  },
  updatedAt: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select updated date",
    },
  },
  updatedBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter updated by",
    },
  },
  status: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select status",
    },
  },
};
