import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    height: "-webkit-fill-available",
    width: "100%",
    display: "flex-row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    overflow:'hidden',
    top: 0,
    left: 0,
    backgroundColor:'#F9F9F9',
    [theme.breakpoints.down("md")]: {
      height: "60%",
    },
    
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height:"48px",
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
   
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  topImage:{
    width: "151px",
    height: "38px",
    display: "flex",
    justifyContent:"left",
    alignItems: "left",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  loginTitle:{
    display: "flex",
    color:"white",
    fontWeight: 400,
    fontSize:"36px",
    marginBottom: theme.spacing(4),
    fontFamily:'Sanchez',
    justifyContent: "center",
    alignItems: "center",
  },
  logotypeImage: {
    width: "545px",
    height: "476px",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    justifyContent: "center",
    alignItems: "center",

  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  bottomImage:{
    width: "151px",
    height: "38px",
    display: "flex",
    justifyContent:"right",
    alignItems: "right",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  formContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  form: {
    width: 340,
    height:400,
    border:'#DADFEB',
    backgroundColor:'#fff',
    marginTop:theme.spacing(6),
    padding:'20px 70px',
    borderRadius:"5px",
    textAlign:'center'
    
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
    marginTop: theme.spacing(4),
    color:"#2D3748"
  },
  subGreeting: {
    fontSize: 30,
    fontWeight: 600,
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-4),
    color:"#2D3748"
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom:theme.spacing(4)
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "left",
    color:'red'
  },
  txtHeading:{
    color:"#4A5568",
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(-1),
    textAlign:'left',
    marginTop: theme.spacing(4),
  },
  textFieldline:{
    "&:before":{
      border:theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderColor: theme.palette.background.light,
    border:theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(6),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(0),
    },
  },
  Link:{
    marginTop:theme.spacing(4),
    textAlign:'center',
    color:'#2C5282'
  },
  errorMessage2:{
    color:'red'
  }
}));
