export const manageEhiiaSchema = {
  versionName: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter version name",
    },
  },
  description: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter description",
    },
  },
  createdDate: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select created date",
    },
  },
  createdBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter created by",
    },
  },
  updatedDate: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select updated date",
    },
  },
  updatedBy: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter updated by",
    },
  },
  status: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select status",
    },
  },
  projectTitle: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter project title",
    },
  },
  projectLead: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter project lead name",
    },
  },
  projectLeadRole:{
    type: "string",
    required: true,
    properties: {
      empty: "Please enter project lead role",
    },
  },
  projectLeadEmail:{
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },
  lineManager: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter line manager",
    },
  },
  lineManagerRole: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter line manager role",
    },
  },
  lineManagerEmail: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },
  serviceManager: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter service manager",
    },
  },
  serviceManagerRole: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter service manager role",
    },
  },
  serviceManagerEmail:{
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },
  searchTags:{
    type: "array",
    required: true,
    properties: {
      empty: "Please enter search criteria",
     
    },
  },
  organization: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select organisation",
    },
  },
  careGroup: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select care group",
    },
  },
  mainPurpose: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select purposes ",
    },
  },
  newOrExisting: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select new activity",
    },
  },
  commissioningStatus: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select commission status",
    },
  },
  trainingCompleted: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select status",
    },
  },
};
