import { createSlice } from "@reduxjs/toolkit";
import {
  createUpdateEhiiaVersion,
  fetchEhiiaVersionList,
  getEhiiaVersionListByVersionId,
  toggleActivation,
  fetchAllEhiiaVersionList,
} from "../actions/ehiiasAction";

export const ehiias = createSlice({
  name: "ehiias",
  initialState: {
    ehiias: null,
    list:null,
    error: null,
    sortBy: null,
    isLoader: false,
    isRecordUpdated: false,
  },
  reducers: {
    userReset: (state) => {
      // state.locations= null;
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: {
    [fetchEhiiaVersionList.pending]: (state, payload) => {
      console.log("payload", payload);
      state.error = null;
      state.isLoader = true;
    },
    [fetchEhiiaVersionList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log("payload", payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchEhiiaVersionList.rejected]: (state, { payload }) => {
      console.log("payload", payload);
      state.error = payload;
      state.isLoader = false;
    },
    [fetchAllEhiiaVersionList.pending]: (state, payload) => {
      state.error = null;
      state.isLoader = true;
    },
    [fetchAllEhiiaVersionList.fulfilled]: (state, { payload }) => {
      state.error = null;
      console.log(payload);
      return {
        ...state,
        ...payload?.data?.data,
        isLoader: false,
      };
    },
    [fetchAllEhiiaVersionList.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isLoader = false;
    },
    [createUpdateEhiiaVersion.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [createUpdateEhiiaVersion.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [createUpdateEhiiaVersion.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },

    [getEhiiaVersionListByVersionId.pending]: (state, payload) => {
      state.error = null;
      state.isRecordLoaded = false;
      state.isLoader = true;
    },
    [getEhiiaVersionListByVersionId.fulfilled]: (state, { payload, error }) => {
      console.log("payload", payload);
      console.log("error", error);
      state.error = null;
      state.isRecordLoaded = true;
      state.isLoader = false;
    },
    [getEhiiaVersionListByVersionId.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordLoaded = false;
      state.isLoader = false;
    },
    [toggleActivation.pending]: (state, payload) => {
      state.error = null;
      state.isRecordUpdated = false;
      state.isLoader = true;
    },
    [toggleActivation.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    },
    [toggleActivation.rejected]: (state, { payload }) => {
      state.error = payload;
      state.isRecordUpdated = false;
      state.isLoader = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userReset, userSort, userSearch, resetPopup } =
  ehiias.actions;

export default ehiias.reducer;
