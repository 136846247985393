import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TableRow, TableCell, Paper } from "@material-ui/core";
// Model
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import TableWidget from "../../../component/Widget/TableWidget";
// components

import TableDesign from "../../../component/common/table/TableDesign";
import { resetPopup } from "../../store/reducerSlice/ehiiaversionsClient";
// API data imports
import { fetchOngoingProjectList } from "../../store/actions/ehiiaAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
// styles
import useStyles from "./styles";
import ToggleActivationPopup from "./ToggleActivationPopup";
export default function Ongoing({
  orderBy,
  filterOption,
  setFilterOption,
  filterHandler,
}) {
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var classes = useStyles();
  const [selectRow, setSelectRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [message, setMessage] = useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.ehiiaversionsClient;
  });
  const page = pagination?.currentPage;

  const cc = list || filtered;
  useEffect(() => {
    console.log("list", list);
    console.log("page", page);
  }, []);
  useEffect(() => {
    console.log("pagination", pagination);
    console.log("cc", cc);
    console.log("filterOption", filterOption);

    dispatch(fetchOngoingProjectList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ID",
      sort:'projectUID'
    },
    {
      label: "Title",
      sort: "introduction.projectTitle"
    },
    {
      label: "Lead",
    },
    {
      label: "Line Manager",
    },
    {
      label: "Service Manager",
    },
    {
      label: "Sub-Org",
    },
    {
      label: "Created on",
    },
    {
      label: "Last Updated",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const toggleActivateEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateRec(rec?.projectId);
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateModalShow(true);

    setShowResult(true);
    if (rec?.isActive) {
      setTitle("Deactivate Project");
      setMessage("Project deactivated successfully!");
    } else {
      setTitle("Activate Project");
      setMessage("Project activated successfully!");
    }
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setselectclient(null);
    setModalShow(false);
    setOpen(false);

    setToggleActivateModalShow(false);
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
      <ToggleActivationPopup
        onHide={hideHandle}
        title={"Activation"}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <Paper className={classes.iconsContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of Ehiia Module",
                  tableHeadings,
                  filterHandler,
                  pagination,
                  filterOption,
                  setFilterOption,
                  isLoader,
                }}
              >
                {cc && cc.length ? (
                  cc.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            className={classes.tableCell}
                            onClick={() =>
                              navigate(
                                `/admin/other-ehiia-details/${row.projectId}`,
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {row.projectUID}
                          </TableCell>
                          <TableCell
                            onClick={() =>
                              navigate(
                                `/admin/other-ehiia-details/${row.projectId}`,
                              )
                            }
                            style={{ cursor: "pointer" }}
                            className={classes.tableCell}
                          >
                            {row?.introduction?.projectTitle}
                          </TableCell>

                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.projectLead}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.lineManager}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.serviceManager}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row?.introduction?.organization}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {formatedDate(row.createdAt)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {formatedDate(row.updatedAt)}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {row.isCompleted ? (
                              <span
                                style={{
                                  color: "#10940D",
                                  fontWeight: 500,
                                }}
                              >
                                Complete
                              </span>
                            ) : (
                              <span style={{ color: "#F8A80C" }}>
                                In-Progress
                              </span>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <IconButton
                              id="basic-button"
                              aria-controls={
                                openDown ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openDown ? "true" : undefined}
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              {/* <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  navigate(
                                    `/admin/ehiia-edit/${selectRow?.projectId}`,
                                  )
                                }
                              >
                                {" "}
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                               Edit
                              </MenuItem> */}
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  navigate(
                                    `/admin/other-ehiia-details/${selectRow?.projectId}`,
                                  )
                                }
                              >
                                {" "}
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                View
                              </MenuItem>
                              {/* <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  toggleActivateEventHandler(selectRow)
                                }
                              >
                                {" "}
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Deactivate
                              </MenuItem> */}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Record Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </Grid>
        </Grid>
      </Paper>
      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
