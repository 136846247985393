import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../store/actions/subAdminAction";
import Loader from "../../../component/common/loader/Loader";
import { Alert } from "@material-ui/lab";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ToggleActivationPopup({
  toggleActivateRec,

  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
  toggleActivateStatus
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  //  const { selectclient } = props;
  const { error: apiError, isLoader } = useSelector((state) => state.versions);
  const [isShow, setIsShow] = useState(false);
  const { addToast } = useToasts();
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        rootAdminId: toggleActivateRec,
      }),
    ).then((payload) => {
      if (payload?.payload?.success) {
        // return;
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:'34px'
          },
        });
      }else{
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
     
      onHide();
      setToggleActivateModalShow(false);
    })
  };
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setToggleActivateModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setToggleActivateModalShow(false);
    setOpen(false);
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            {apiError ? (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  Ok
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.outlineBtn}
                  variant="outlined"
                  size="medium"
                  onClick={(e) => onHide()}
                >
                  No
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitHandler()}
                >
                  Yes
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
              </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
                {toggleActivateStatus ? (
                  <>Are you sure, you want to deactivate this sub-admin?</>
                ) : (
                  <>Are you sure, you want to activate this sub-admin?</>
                )}
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
