import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Grid,
  Typography,
  Button,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
} from "@material-ui/core";

import {Clear as ClearIcon,
  MoreVert as MoreIcon,
  Lens,
  Search as SearchIcon,
} from "@material-ui/icons";

import TableDesign from "../../../component/common/table/TableDesign";
// components
import TableWidget from "../../../component/Widget/TableWidget";
import { fetchSubAdminList } from "../../store/actions/subAdminAction";
import { resetPopup } from "../../store/reducerSlice/subadmins";
import ActivationMailLinkClientPopup from "./ActivationMailLinkClientPopup";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import classNames from "classnames";

// styles
import useStyles from "./styles";

import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";

import ManageAdmin from "./ManageAdmin";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
export default function AdminList() {
  var classes = useStyles();
  // table Header
  const tableHeadings = [
    {
      label: "#",
    },

    {
      label: "Admin Name",
      align: "left !important",
      sort: "name"
    },
    {
      label: "Email",
      align: "left !important",
      sort: "email"
    },
    {
      label: "Created On",
      sort: "createdAt"
    },

    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  const [selectRow, setSelectRow] = useState(null);

  const [selectmodule, setSelectmodule] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [toggleActivateRec, setToggleActivateRec] = useState(null);
  const [toggleActivateStatus, setToggleActivateStatus] = useState(null);
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [activationLinkRec, setActivationLinkRec] = useState(null);
  const [activationLinkModalShow, setActivationLinkModalShow] = useState(false);
  const [deleteRec, setDeleteRec] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: "",
    orderBy: "createdAt",
    orderIn: -1,
  });
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };

  const [message, setMessage] = useState(null);

  // Get Client LIst
  const dispatch = useDispatch();
  const {
    list,
    filtered,
    pagination,
    isLoader,
    isRecordUpdated,
    error: apiError,
  } = useSelector((state) => {
    return state.subadmins;
  });
  const page = pagination?.currentPage;
  // const list = subadmins?.list || [];
  const cc = filtered || list;

  useEffect(() => {
    console.log("page", page);
    console.log("list", list);
    // console.log("filterOption", filterOption);
    dispatch(fetchSubAdminList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    filterHandler({ search: searchText, page: 1 });
  }, [searchText]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setSelectmodule(null);
    setModalShow(false);
    setOpen(false);
    setDeleteModalShow(false);
    setDeleteRec(null);
    setToggleActivateModalShow(false);
    setActivationLinkModalShow(false);
  };

  const toggleActivateEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setToggleActivateRec(rec?.rootAdminId);
    setToggleActivateStatus(rec?.isActive);
    setToggleActivateModalShow(true);

    // setShowResult(true);
    if (rec?.isActive) {
      setTitle("Deactivate SubAdmin");
      setMessage("SubAdmin Deactivated Successfully!");
    } else {
      setTitle("Activate SubAdmin");
      setMessage("SubAdmin Activated Successfully!");
    }
  };

  const activationLinkEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    console.log("clicked");
    console.log("rec", rec);
    dispatch(resetPopup());
    setActivationLinkRec(rec?.rootAdminId);
    setToggleActivateStatus(rec?.isActivated);
    setActivationLinkModalShow(true);
    setTitle("Send Activation Link");
    setMessage("Activation link sent successfully!");
    // setShowResult(true);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setAnchorE2(null);
    dispatch(resetPopup());
    setDeleteRec(rec?.rootAdminId);
    setDeleteModalShow(true);
    setTitle("Delete Client");
    setMessage("SubAdmin deleted successfully!");
    // setShowResult(true);
  };
  const hideHandlerRes = () => {
    dispatch(resetPopup());
    setTitle(null);
    setMessage(null);
    setShowResult(false);
  };
  return (
    <>
    <ToastContainer/>
      <ManageAdmin
        open={open}
        onHide={hideHandle}
        selectmodule={selectmodule}
        title={"Add Admin"}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateRec={toggleActivateRec}
        toggleActivateStatus={toggleActivateStatus}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <ActivationMailLinkClientPopup
        onHide={hideHandle}
        title={"Activation"}
        activationLinkRec={activationLinkRec}
        toggleActivateStatus={toggleActivateStatus}
        activationLinkModalShow={activationLinkModalShow}
        setActivationLinkModalShow={setActivationLinkModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        title={"Delete"}
        deleteRec={deleteRec}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
      />

      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Manage Admin
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              // onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search…"
              onKeyUp={debounce((e) => setSearchText(e.target.value), 200)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
             {searchText?.length > 0 && (
              <IconButton style={{background:'none'}}
                onClick={(e) => {
                  setSearchText("");
                  searchInputRef.current.children[0].value = "";                  
                }}
                className={classes.searchSvg2}
              >
                <ClearIcon />
              </IconButton>
            )}
          </div>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Add Admin
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of Admin",
                tableHeadings,
                filterHandler,
                pagination,
                filterOption,
                setFilterOption,
                isLoader,
              }}
            >
              {cc && cc.length ? (
                cc.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          className={classes.tableCell}
                          onClick={() =>
                            navigate(
                              `/super-admin/admin-details/${row.rootAdminId}`,
                            )
                          }
                          style={{ cursor: "pointer",textAlign: "left",paddingLeft:'12px' }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            navigate(
                              `/super-admin/admin-details/${row.rootAdminId}`,
                            )
                          }
                          style={{ cursor: "pointer",textAlign: "left",paddingLeft:'12px'  }}
                          className={classes.tableCell}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {formatedDate(row.createdAt)}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {row.isActive ? (
                            row.isActivated ? (
                              <span
                                style={{ color: "#10940D", fontWeight: 500 }}
                              >
                                Active
                              </span>
                            ) : (
                              <span style={{ color: "#F8A80C" }}>Pending</span>
                            )
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() =>
                                navigate(
                                  `/super-admin/admin-edit/${selectRow?.rootAdminId}`,
                                )
                              }
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Edit
                            </MenuItem>
                            {selectRow?.isActive ? (
                              selectRow.isActivated ? (
                                <>
                                  <MenuItem
                                    className={classes.menuItem}
                                    onClick={() =>
                                      activationLinkEventHandler(selectRow)
                                    }
                                  >
                                    <Lens
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                        marginRight: "4px",
                                      }}
                                    />{" "}
                                    Reset Password
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.menuItem}
                                    onClick={() =>
                                      toggleActivateEventHandler(selectRow)
                                    }
                                  >
                                    <Lens
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                        marginRight: "4px",
                                      }}
                                    />{" "}
                                    Deactivate
                                  </MenuItem>
                                </>
                              ) : (
                                <>
                                  <MenuItem
                                    className={classes.menuItem}
                                    onClick={() =>
                                      activationLinkEventHandler(selectRow)
                                    }
                                  >
                                    <Lens
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                        marginRight: "4px",
                                      }}
                                    />{" "}
                                    Resend activation link
                                  </MenuItem>
                                  <MenuItem
                                    className={classes.menuItem}
                                    onClick={() =>
                                      deleteEventHandler(selectRow)
                                    }
                                  >
                                    <Lens
                                      style={{
                                        height: "4px",
                                        width: "4px",
                                        marginRight: "4px",
                                      }}
                                    />{" "}
                                    Delete
                                  </MenuItem>
                                </>
                              )
                            ) : (
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  toggleActivateEventHandler(selectRow)
                                }
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Activate
                              </MenuItem>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      {(isRecordUpdated || isRecordUpdated) && showResult && (
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated,
            isRecordUpdated,
            successMsg: message,
            title: title,
          }}
        />
      )}
    </>
  );
}
