import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography, Tabs, Tab } from "@material-ui/core";
import { getEhiiaProjectByProjectIdwithSectionId } from "../../../store/actions/ehiiaAction";
// components
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
// styles
import useStyles from "./styles";
import Introduction from "../../Project/Cases/Introduction";
import Assessment from "../../Project/Cases/Assessment";
import DataReview from "../../Project/Cases/DataReview";
import Engagement from "../../Project/Cases/Engagement";
import Result from "../../Project/Cases/Result";
export default function EhiiaDetail() {
  const { projectId } = useParams();
  var [activeTabId, setActiveTabId] = useState(0);
  const dispatch = useDispatch();
  const [projectSummaryData, setProjectSummaryData] = useState([]);
  const [ehiiaProjectDetail, setEhiiaProjectDetail] = useState(null);
  const [introductionDetail, setIntroductionDetail] = useState(null);
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [dataReviewDetail, setDataReviewDetail] = useState(null);
  const [engagementDetail, setEngagementDetail] = useState(null);
  const [ehiiaResultDetail, setEhiiaResultDetail] = useState(null);
  const [nextSection, setNextSection] = useState(null);
  const [setionStatus, setSectionStatus] = useState([]);
  const navigate = useNavigate();
  var classes = useStyles();
  useEffect(() => {
    dispatch(
      getEhiiaProjectByProjectIdwithSectionId({ projectId, sectionId: null }),
    ).then(({ payload }) => {
      console.log("data-payload", payload);
      if (payload?.success) {
        setEhiiaProjectDetail(payload?.data?.form);
        setProjectSummaryData(payload?.data?.projectSummary);
        setIntroductionDetail(payload?.data?.form?.introduction);
        setAssessmentDetail(payload?.data?.form?.assessment?.questions);
        setDataReviewDetail(payload?.data?.form?.dataReview?.questions);
        setEngagementDetail(payload?.data?.form?.engagement?.questions);
        setEhiiaResultDetail(payload?.data?.form?.finalApproval);
        setSectionStatus(payload?.data?.form?.sections);
        setNextSection(payload?.data?.form?.nextSection);
      }
    });
  }, []);

  const renderTabContent = (id, tabKey) => {
    switch (tabKey) {
      case "introduction":
        return (<Grid container spacing={4}>
          <Grid className={classes.gridItem} item xs={12}>
          <Widget>
          <Introduction introductionDetail={introductionDetail} />
        </Widget>
          </Grid>
          
        </Grid>)
      case "assessment":
        return (<Widget>
          <Assessment assessmentDetail={assessmentDetail} />
        </Widget>)
      case "dataReview":
        return (<Widget>
          <DataReview dataReviewDetail={dataReviewDetail} />
        </Widget>)
      case "engagement":
        return ( <Widget>
          <Engagement engagementDetail={engagementDetail} />
        </Widget>)
      case "approvalResult":
        return (<Grid container spacing={4}>
          <Grid item xs={12}>
          <Result ehiiaResultDetail={ehiiaResultDetail} />
          </Grid>
        </Grid>)
      default:
        return null
    }
  }
  
  return (
    <>
      <PageTitle
        subtitle="Ongoing Projects >"
        title="Details"
        urlLink="/admin/manage-module/ehiia"
      />
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          {projectSummaryData?.map((item, index) => (
            <Grid
              item
              xs={12}
              md={index % 2 === 0 ? 4 : 8}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {item?.title}:
              </Typography>
              <Typography className={classes.formHeadingValue}>
                {item?.title === "Status" ? (
                  <>
                    {item?.value === "In-Progress" ? (
                      <span style={{ color: "#FEAA00" }}>{item?.value}</span>
                    ) : (
                      <span style={{ color: "#10940D" }}>{item?.value}</span>
                    )}
                  </>
                ) : (
                  <>{item?.value}</>
                )}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Widget>

      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        {setionStatus.map((section, index) => (
          <Tab
            key={index}
            label={section.title}
            classes={{ root: classes.tab }}
            disabled={
              section.completed
                ? false
                : section.value === nextSection
                ? false
                : true
            } // Disable the tab if the section is not completed
            onClick={() => setActiveTabId(index)}
          />
        ))}
      </Tabs>
      {setionStatus.map((section, index) => (
            activeTabId === index && renderTabContent(index,section?.value)
        ))}
      
    </>
  );
}
