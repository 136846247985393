import React, { useEffect, useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  Inbox as InboxIcon,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Dot from "../Dot";

export default function SidebarLink({
  link,
  icon,icon1,
  label,
  children,
  location,
  moduleOption,
  isSidebarOpened,
  nested,
  type,
}) {
  var classes = useStyles();
 useEffect(()=>{
  console.log("location", location);
  console.log("moduleOption", moduleOption);
  console.log("children", children);
  // children =[...new moduleOption]
  // console.log(children);
  
 })


  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location?.pathname === link || location?.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon style={{marginRight:'-8px'}}
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {isLinkActive  ? icon1 : icon }
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );
    const childrenArray = moduleOption?.find((item) => item.link === link)?.children;
    console.log("moduleOption",moduleOption);
    console.log("childrenArray",childrenArray);
  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon style={{marginRight:'-8px'}}
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {isLinkActive  ? icon1 : icon }
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />

        {isSidebarOpened && !isOpen ? (
          <KeyboardArrowDown
            style={{ marginLeft: "10px", right: "0", position: "absolute" }}
          />
        ) : (
          ""
        )}

        {isSidebarOpened && isOpen ? (
          <KeyboardArrowUp
            style={{ marginLeft: "10px", right: "0", position: "absolute" }}
          />
        ) : (
          ""
        )}
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children?.map((childrenLink,index) => (
              <SidebarLink
                key={index}
                icon={childrenLink.icon}
                icon1={childrenLink.icon1}
                location={location}
                isSidebarOpened={isSidebarOpened}
                classes={classes}
                nested
                {...childrenLink}
              />
            ))}
            
           
          </List>
          
         
        </Collapse>
      )}
      
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
