import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import Widget from "../../component/Widget/Widget";
import downloadBoxIcon from "../../assets/download-white.png";

import useStyles from "./styles";

export default function Overview({ overviewData ,projectSummaryData}) {
  console.log("overviewData", overviewData);
  var classes = useStyles();

  return (
    <>
      <Widget>
        <Grid
          container
          spacing={2}
          className={classes.formBox}
          style={{  border: "0px", padding: "0px" }}
        >
          <Grid item xs={12} md={12} style={{display:'block'}} className={classes.textGroup}>
          <Typography
              className={classes.formHeading}
              style={{ fontWeight: "500",width:'100%', marginBottom: "10px" }}
            >
              {projectSummaryData?.map((item, index) => (
                <>
                  {item?.title === "Status" && (
                    <>
                      {item?.value === "Pending" ? (
                        <span style={{ color: "#FEAA00" }}>
                          Your approval is not completed yet.
                        </span>
                      ) : (
                        <span style={{ color: "#10940D" }}>
                          Your approval is complete.
                        </span>
                      )}
                    </>
                  )}
                </>
              ))}
            </Typography>
            <Typography
              className={classes.formHeading}
              style={{ fontWeight: "500",width:'100%' }}
            >
              You have now completed the EHIA. You can review your work using
              the buttons below, you have three options:
              <ul type="disc">
                <li>
                  View Project :- This is a complete record of all the
                  information you have provided throughout your assessment
                </li>
                <li>Detailed Report :- This is a full EHIA report.</li>
                <li>
                  Outcome Report :- This is the summary report that will be
                  submitted for final approval.
                </li>
              </ul>
              Please check that the report reflects your assessment and
              findings. You then need to send your assessment to the Equality
              Specialist. They will review your assessment to ensure that you
              have paid due regard to any potential issues. Use the buttons
              below to send your assessment for approval. You will need to do
              this separately for staff and patients.
            </Typography>
            <Typography
              className={classes.formHeading}
              style={{ fontWeight: "500",width:'100%' }}
            >
              You have now completed the EHIA. You can review your work using
              the buttons below, you have three options:
              <Grid
                container
                spacing={2}
                // className={classes.formBox}
                style={{ marginTop:'8px' }}
              >
                <Grid item xs={12} md={4} className={classes.textGroup} style={{display:'inline'}}>
                  <a
                    href={overviewData?.viewReportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff",
                      backgroundColor: "#03997B",
                      border: "1px solid #03997B",
                      textDecoration: "none",
                      marginRight: "10px",
                      padding: "6px 15px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    View Project
                  </a>{" "}
                 
                </Grid>
                <Grid item xs={12} md={4} className={classes.textGroup} style={{display:'inline'}}>
                  <a
                    href={overviewData?.downloadReportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: "#FEAA00",
                      color: "#fff",
                      textDecoration: "none",
                      marginRight: "10px",
                      padding: "6px 15px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={downloadBoxIcon}
                      style={{ height: "20px", marginRight: "4px" }}
                      alt="img"
                    />{" "}
                    Detailed Report
                  </a>{" "}
                 
                </Grid>
                <Grid item xs={12} md={4} className={classes.textGroup} style={{display:'inline'}}>
                  <a
                    href={overviewData?.downloadSummaryReportLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      backgroundColor: "#0445AF",
                      color: "#fff",
                      marginRight: "10px",
                      textDecoration: "none",
                      padding: "6px 15px",
                      borderRadius: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={downloadBoxIcon}
                      style={{ height: "20px", marginRight: "4px" }}
                      alt="img"
                    />{" "}
                    Outcome Report
                  </a>{" "}
                  
                </Grid>
              </Grid>
              
            </Typography>
          </Grid>
         
        </Grid>
      </Widget>
    </>
  );
}
