import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
 
  tableCell: {
    // display: "flex",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  tableBodyCell: {
    // display: "flex",
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    alignContent: "center",
    alignItems: "center",
   
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  avatar: {
    width: "68px",
    height: "68px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
    paddingLeft:theme.spacing(0.5),
    fontSize:'14px',
    color:'#4A5568',
    fontWeight:400
  },
  txtForm: {
    marginTop: theme.spacing(0.5),
    padding: "0px 4px",
    fontSize:'14px !important',
    
  },
  modelFooter: {
    fontFamily: "Poppins",
    padding: theme.spacing(2),
  },
  blueButton: {
    fontFamily: "Poppins",
    background: "#0078D4",
    fontSize:'12px',
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
    height:'26px',
    
    textTransform:'none',
    borderRadius:'2px',
    padding:'5px, 8px, 5px, 8px'
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  errorMessage:{
    fontSize:'12px',
    color:'red',
    paddingLeft:'6px'
  },
  dropdownFormControl: {
    marginTop: theme.spacing(0.5),
   
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    background: "#fff",
    fontSize: "14px",
    height:'40px !important',
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  iconsBar: {
    marginBottom: theme.spacing(4),
    borderBottom: "1px solid #03997B",
    // borderBottomColor: theme.palette.text.hint + "80",
    background: "#fff",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
  },
  tab: {
    color: "#201F1E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    marginRight:'2px',
    height:'42px',
    padding:'0px, 18px, 0px, 18px',
    width:'200px',
    "&:hover": {
      border: "1px solid #03997B",
      color: "#201F1E",
      borderRadius:'25px',
      backgroundColor: "#E8FFFA",
    },
    "&:active": {
      border: "1px solid #03997B",
      color: "#201F1E",
      borderRadius:'25px',
      backgroundColor: "#E8FFFA",
    },
    "&.Mui-selected": {
      border: "1px solid #03997B",
      // borderBottomColor: "#03997B",
      color: "#201F1E",
      borderRadius:'25px',
      backgroundColor: "#E8FFFA",
    },
    "& .MuiTabs-indicator": {
      border: "1px solid #03997B",
      borderRadius:'25px',
      backgroundColor: "#E8FFFA",
      // borderBottomColor: "#0445AF",
    },
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  //tab color
  iconsBgBar: {
    marginBottom: theme.spacing(1),
    backgroundColor: "#fff",
   
  },
  tabBg: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    marginRight:'2px',
    "&:hover": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&:active": {
      
      color: "#fff",
      background:'#03997B'
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#03997B'
    }
  },

  tableMainBox: {
    display: "flex",

    minHeight: "100%",
  },
  menuBox: {
    padding: "4px",
    fontSize: "12px !important",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  menuItem:{
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "4px 10px",
    textAlign: "center",
    fontFamily: "Poppins",
  },
  // page title
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display:'inline-block',
      marginBottom: theme.spacing(2),
    }
  },
  typo: {
    color: "#2A2338",
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "20px !important",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "3px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    }
  },
  button1: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    backgroundColor: "#3CCB97",
    color: "#fff",
    marginRight: theme.spacing(2),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    borderRadius: "4px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(0),
    },
  },
  typoGray: {
    color: "#ABAFB7",
  },
  icons: {
    // width:'12px',
    // height:'44px'
    // marginTop:theme.spacing(2),
    // paddingTop:theme.spacing(2)
  },
  groupBtn: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display:'inline-grid',
    }
  },
  searchSvg2 :{
    position: "absolute",
    color: "#03997B",
    top: '4px',
    right: '0px',
    cursor: 'pointer',
    height:'2px'
},
  search: {
    position: "relative",
    borderRadius: '2px',
    border: "1px solid #F0F0F0",
    paddingLeft: theme.spacing(2.5),
    width: 36,
    // backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
    },
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
    marginRight: theme.spacing(2),
    background: "#fff",
    height: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  },
  searchFocused: {
    // backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 300,
    },
    background: "#fff",
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
  },
  searchIcon: {
    width: 36,
    left: 0,
    color: "#0078D4",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    // backgroundColor:'#fff'
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    // height: 36,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    paddingLeft: "10px",
    fontSize:'14px',
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
    
  },
  downloadImg: {
    marginRight: theme.spacing(1),
  },
  editicons: {
    marginRight: theme.spacing(0.5),
  },
  // page title
}));
