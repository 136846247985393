import React from "react";
import { BrowserRouter } from "react-router-dom";

import RoutesApp from "./RoutesApp";
import { ToastProvider } from "react-toast-notifications";
import CustomToast from "./CustomToast";

export default function App() {
  return (
    <BrowserRouter>
    <ToastProvider
       autoDismissTimeout={3000} style={{display: "flex",
       alignItems: "center",}}
      >
    <RoutesApp />
    </ToastProvider>
    </BrowserRouter>
  );
}
