import React, { useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Fade,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import Validation from "../../hooks/Validation";
import EhiiaValidation from '../../hooks/EhiiaValidation'
// styles
import useStyles from "./styles";
const formElements = [
  {
    key: "name",
    label: "Version Name",
    placeHolder: "Enter Version Name",
    type: "text",
  },
  {
    key: "description",
    label: "Description",
    placeHolder: "Type here...",
    type: "text",
  },
];
export default function ManageEhiia(props) {
  //
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
  EhiiaValidation({
    name:'',
    description:''
  });

  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          {formElements.map((element, i) => {
            return (
              (!selectclient || element.key !== "password") && (
                <DialogContentText className={classes.formBox}>
                  {/* name */}
                  <Typography className={classes.formHeading} key={i}>
                    {element.label}
                  </Typography>
                  {element.type == "select" ? (
                    <>
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          value={value}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="MSEFT">MSEFT</MenuItem>
                          <MenuItem value="MSEICB">MSEICB</MenuItem>
                          <MenuItem value="EPUT">EPUT</MenuItem>
                          <MenuItem value="PROVID">PROVIDE</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        type={element.type}
                        name={element.key}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Enter Name"
                        variant="outlined"
                        isInvalid={error && error[element.key]}
                      />
                      <Fade in={error}>
                        <Typography
                          color="secondary"
                          className={classes.errorMessage}
                        >
                          {error && error[element.key]}
                        </Typography>
                      </Fade>
                    </>
                  )}
                </DialogContentText>
              )
            );
          })}
        </DialogContent>
      </Popup>
    </>
  );
}
