import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Grid,
  Tabs,
  Typography,
  Tab,
  Paper,
  Button,
  Avatar,
} from "@material-ui/core";

import useStyles from "./styles";
import moment from "moment";
import notes_bg from "../../images/notes_bg.png";
import actor from "../../assets/icons/actor.svg";
import watch from "../../images/watch.png";
import checked from "../../images/checked.png";
import blue_edit from "../../images/blue_edit.png";
import { formatDate } from "../../helperHooks/helper";
import { reviewFinalApprovalModule } from "../../user-admin/store/actions/reviewAction";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
import FinalSendReview from "./FinalSendReview";
import FinalApprovalConfirmation from "./FinalApprovalConfirmation";
import ApprovalResultModal from "./ApprovalResultModal";

export default function ApprovalStage({ setUpdated, subStage, data,isStage1Pending }) {
  var classes = useStyles();
  console.log("isStage1Pending",isStage1Pending);
  console.log("subStage", subStage);
  console.log("data", data);
  const { projectId } = useParams();
  console.log("projectId", projectId);
  const searchParams = new URLSearchParams(useLocation().search);
  const type = searchParams.get("type");
  const stage = searchParams.get("stage");
  const [stageValue, setStageValue] = useState(stage);
  const [stageTypeValue, setStageTypeValue] = useState(subStage);
  const [stageWiseData, setStageWiseData] = useState(data);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [confirmApproveModalShow, setConfirmApproveModalShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const sendReview = () => {
    // setReviewModalShow(true);
    setAlertModalShow(true);
  };
  const submitApprovalHandler = (e) => {
    setConfirmApproveModalShow(true)
    
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  return (
    <>
      <ApprovalResultModal
        setConfirmApproveModalShow={setConfirmApproveModalShow}
        confirmApproveModalShow={confirmApproveModalShow}
        projectId={projectId}
        type={type}
        stage={stage}
      />
      <FinalApprovalConfirmation
        alertModalShow={alertModalShow}
        setAlertModalShow={setAlertModalShow}
        projectId={projectId}
        type={type}
        stage={stage}
      />
      <FinalSendReview
        setReviewModalShow={setReviewModalShow}
        reviewModalShow={reviewModalShow}
        projectId={projectId}
        type={type}
        stage={stage}
      />
      {data?.status === "pending" && (
        <div
          style={{
            padding: "16px",
            backgroundColor: "#F8FFFE",
            border: "2px solid #03997B",
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={1}>
              <Avatar
                style={{ borderRadius: "0px", width: "45px", height: "45px" }}
                src={actor}
              />
            </Grid>{" "}
            <Grid item xs={12} md={8}>
              {data?.status === "pending" ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.textGroup_inline}
                  >
                    <Typography style={{textTransform:"none"}} className={classes.formHeadingValue_right}>
                      User requested to approve {subStage=== "stage1" ? "equality sign-off" : "report sign-off"} case
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.textGroup_inline}
                  >
                    <Typography className={classes.formHeading}>
                      {moment(data?.sentAt).format("lll")}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container spacing={2}>
                {data?.status === "approved" && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={classes.textGroup_inline}
                  >
                    <Typography
                      className={classes.formHeadingValue_right}
                      style={{
                        color: "#03997B",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      Approved
                    </Typography>
                    <Avatar
                      style={{ borderRadius: "0px", marginLeft: "0px" }}
                      src={checked}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      {data?.history.length > 0 && (
        <div
          style={{
            marginTop: "16px",
            background: "#F8FFFE",
            border: "1px solid #03997B",
            borderRadius: "5px",
            padding: "36px",
          }}
        >
          <Grid container spacing={2}>
            {data?.history.length > 0 &&
              data?.history.map((item, index) => (
                <>
                  {/* comments */}
                  {item?.byEntity === "team" && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        backgroundColor: "#EDF8F6",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#201F1E",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          Approver Comment:
                        </Typography>
                        <Typography
                          style={{
                            display: "contents",
                            color: "#52575C",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {moment(item?.timestamp).format("lll")}
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          color: "#201F1E",
                          fontSize: "13px",
                          paddingLeft: "4px",
                          textAlign: "justify",
                          marginTop: "4px",
                        }}
                      >
                        {item?.comments}
                      </Typography>
                    </Grid>
                  )}
                  {/* answer */}
                  {item?.byEntity === "self" && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        backgroundColor: "#ffffff",
                        // border: "1px solid #03997B",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#201F1E",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          User Comment:
                        </Typography>
                        <Typography
                          style={{
                            display: "contents",
                            color: "#52575C",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          {moment(item?.timestamp).format("lll")}
                        </Typography>
                      </div>
                      <Typography
                        style={{
                          color: "#201F1E",
                          fontSize: "13px",
                          paddingLeft: "4px",
                          textAlign: "justify",
                          marginTop: "4px",
                        }}
                      >
                        {item?.comments}
                      </Typography>
                    </Grid>
                  )}
                </>
              ))}
          </Grid>
        </div>
      )}
      {!(data?.status === "notSent" || data?.status === "approved")  && (
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "20px",
          }}
        >
          <Button
            className={classes.btnleftOutline}
            variant="outlined"
            size="large"
            style={{ marginRight: "10px" }}
            onClick={(e) => sendReview(e)}
          >
            Further Work Needed
          </Button>
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            size="large"
            color="secondary"
            onClick={(e) => submitApprovalHandler(e)}
          >
            Approve
          </Button>
        </div>
      )}
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Send Approval ",
          successMsg: successMessage,
          errorRes: errorApiMessage,
        }}
      />
    </>
  );
}
