import React, { useState } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import AssessmentOne from "./AssessmentPages/AssessmentOne";
import AssessmentTwo from "./AssessmentPages/AssessmentTwo";
import AssessmentThree from "./AssessmentPages/AssessmentThree";
import AssessmentFour from "./AssessmentPages/AssessmentFour";
export default function Assessment() {
  var classes = useStyles();
  var [activeComponent, setActiveComponent] = useState(0);
  const nextPage = () => {
    if (activeComponent < 3) {
      setActiveComponent(activeComponent + 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };
  const previewsPage = () => {
    if (activeComponent > 0) {
      setActiveComponent(activeComponent - 1);
    } else {
      setActiveComponent(activeComponent);
    }
  };
  return (
    <>
      {activeComponent === 0 && <AssessmentOne />}
      {activeComponent === 1 && <AssessmentTwo />}
      {activeComponent === 2 && <AssessmentThree />}
      {activeComponent === 3 && <AssessmentFour />}
      <div className={classes.buttonGroup}>
        <Button
          onClick={previewsPage}
          className={classes.leftBluetButton}
          variant="contained"
          size="large"
        >
          Previews
        </Button>
        <Button
          className={classes.rightButton}
          onClick={nextPage}
          variant="contained"
          size="large"
          color="secondary"
        >
          Next
        </Button>
      </div>
    </>
  );
}
