import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  Paper,
  Link,
} from "@material-ui/core";
// styles
import useStyles from "./styles";
import expand from "../../assets/expand.png";
import hide from "../../assets/hide.png";

export default function DataReview({ dataReviewDetail }) {
  var classes = useStyles();
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  return (
    <>
      {dataReviewDetail &&
        dataReviewDetail.map((element, i) => {
          const {
            key,
            title,
            type,
            questionId,
            questionSerial,
            value,
            options,
          } = element;
          return (
            <Grid item xs={12} md={11} className={classes.textGroupInline}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "6px",
                }}
              >
                <Typography
                  className={classes.formHeading}
                  style={{
                    marginTop: "10px",
                    width: "90%",
                    color: "#201F1E",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {/* {questionSerial}{" "} */}
                  {title}
                </Typography>
                {show && checkQuestionId === element?.questionId ? (
                  <div
                    style={{ marginLeft: "8px" }}
                    onClick={() => handleHideContent(questionId)}
                  >
                    <img src={hide} style={{ marginTop: "5px" }} alt="hide" />
                  </div>
                ) : (
                  <div
                    style={{ marginLeft: "8px" }}
                    onClick={() => handleShowContent(questionId)}
                  >
                    <img
                      src={expand}
                      style={{ marginTop: "5px" }}
                      alt="expand"
                    />
                  </div>
                )}
              </div>
              {show && checkQuestionId === element?.questionId && (
                <>
                  {type === "acknowledgeTableLinks" ? (
                    <>
                      <TableContainer
                        style={{ marginTop: "16px" }}
                        sx={{ boxShadow: 0 }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: "100%", boxShadow: 0 }}
                          aria-label="simple table"
                        >
                          <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                            <TableRow>
                              {element.tableData.columns.map((column) => (
                                <TableCell
                                  key={column}
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "center",
                                  }}
                                >
                                  {column}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {element.tableData.rows.length > 0 ? element.tableData.rows.map((row, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: "1px solid #B6B6B6",
                                  },
                                }}
                              >
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "30%",
                                    fontSize: "14px",
                                  }}
                                >
                                  {row[0]}
                                </TableCell>
                                <TableCell
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    textAlign: "left",
                                    fontSize: "14px",
                                    verticalAlign: "top",
                                    padding: "8px 16px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                      color: "#03997B",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                      textDecoration: "none",
                                      padding: "5px",
                                    }}
                                  >
                                    {row[1].map((item, i) => (
                                      <div key={i} style={{ marginBottom: "6px" }}>
                                      {item.title === "N/A" ? (
                                        item.title
                                      ) : (
                                        <a
                                          style={{
                                            textDecoration: "none",
                                            display: "list-item",
                                          }}
                                          href={
                                            item.link ? 
                                              (!/^https?:\/\//.test(item.link) ? `https://${item.link}` : item.link) 
                                              : "#"
                                          }
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {item.title}
                                        </a>
                                      )}
                                    </div>
                                    ))}
                                  </div>
                                </TableCell>
                              </TableRow>
                            )):<>
                            <TableRow
                                    style={{
                                      border: "1px solid #B6B6B6",
                                    }}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: "1px solid #B6B6B6",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        verticalAlign: "top",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                        whiteSpace: "pre-line",
                                        verticalAlign: "top",
                                        width: "30%",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                            </>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : type === "multipleFreeText" ? (
                    <>
                      {element?.options.map((field, idx) => (
                        <TextField
                          size="small"
                          style={{ marginTop: "4px" }}
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Text"
                          variant="outlined"
                          value={field.value || ""}
                        />
                      ))}
                    </>
                  ) : type === "multipleFreeTextTable" ? (
                    <Grid item xs={12} md={12}>
                      <TableContainer
                        style={{ marginTop: "16px" }}
                        sx={{ boxShadow: 0 }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: "100%", boxShadow: 0 }}
                          aria-label="simple table"
                        >
                          <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                            <TableRow>
                              <TableCell
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                Identified Groups
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                List of Data Source
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {element.findings?.length > 0 ? element.findings.map((finding, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={finding.groupName}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "30%",
                                    fontSize: "14px",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {finding.groupName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "50%",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                    padding: "4px 16px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      flexWrap: "wrap",
                                      fontSize: "14px",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    {finding.values && finding.values.length
                                      ? (
                                          
                                            <ul>
                                              {finding.values.map(
                                                (value, linkIndex) => (
                                                  <li key={linkIndex}>
                                                    {value}
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                         
                                        )
                                      : ""}
                                  </div>
                                </TableCell>
                              </TableRow>
                            )):<>
                            <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: "1px solid #B6B6B6",
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "30%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                            </>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : type === "findingsActionTable" ? (
                    <Grid item xs={12} md={12}>
                      <TableContainer
                        style={{ marginTop: "16px" }}
                        sx={{ boxShadow: 0 }}
                        component={Paper}
                      >
                        <Table
                          sx={{ minWidth: "100%", boxShadow: 0 }}
                          aria-label="simple table"
                        >
                          <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                            <TableRow>
                              <TableCell
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                Identified Groups
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                What are your Findings?
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                Is Action Required?
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {element.findings.length>0 ? element.findings.map((row, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={row.groupName}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "30%",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                  }}
                                >
                                  {row.groupName}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "50%",
                                    verticalAlign: "top",
                                    fontSize: "14px",
                                  }}
                                >
                                  {row?.findings || ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "20%",
                                    verticalAlign: "top",
                                  }}
                                >
                                  <FormControl>
                                    <RadioGroup
                                      style={{ display: "flex" }}
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="row-radio-buttons-group"
                                      value={
                                        row?.actionRequired === true
                                          ? "yes"
                                          : "no"
                                      }
                                    >
                                      <FormControlLabel
                                        style={{ fontSize: "14px" }}
                                        value="yes"
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        style={{ fontSize: "14px" }}
                                        value="no"
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )):<>
                            <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "30%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "50%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ width: "20%" }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                            </>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : type === "actionPlanTable" ? (
                    <Grid item xs={12} md={12}>
                      <TableContainer
                        style={{ marginTop: "16px" }}
                        component={Paper}
                      >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead style={{ backgroundColor: "#EDFDFA" }}>
                            <TableRow>
                              <TableCell
                                style={{
                                  border: "1px solid #B6B6B6",
                                  textAlign: "center",
                                }}
                              >
                                Identified Groups
                              </TableCell>
                              <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Findings
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Issues
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                        align="center"
                                      >
                                        Action Required
                                      </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {element.findings.length > 0 ? element.findings.map((row, rowIndex) => (
                              <TableRow
                                style={{ border: "1px solid #B6B6B6" }}
                                key={rowIndex}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    border: "1px solid #B6B6B6",
                                    width: "30%",
                                    fontSize: "14px",
                                    verticalAlign: "top",whiteSpace:'nowrap'
                                  }}
                                >
                                  {row?.groupName}
                                </TableCell>
                                <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'nowrap'
                                            }}
                                          >
                                            
                                            {row?.findings}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'nowrap'
                                            }}
                                          >
                                            
                                            {row?.issues}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "25%",
                                              verticalAlign: "top",whiteSpace:'nowrap'
                                            }}
                                          >
                                            
                                            {row?.actionPlan}
                                          </TableCell>
                              </TableRow>
                            )):<>
                            <TableRow
                                    style={{ border: "1px solid #B6B6B6" }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        border: "1px solid #B6B6B6",
                                        width: "25%",
                                        textAlign: "center",
                                      }}
                                    >
                                      None
                                    </TableCell>
                                  </TableRow>
                            </>}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  ) : (
                    "null"
                  )}
                </>
              )}
            </Grid>
          );
        })}
    </>
  );
}
