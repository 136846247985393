import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Avatar } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { deleteSubAdmin } from "../../store/actions/subAdminAction";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
// styles
import useStyles from "./styles";

import { useToasts } from "react-toast-notifications";

export default function DeletePopup({
  deleteRec,
  onHide,
  deleteModalShow,
  setDeleteModalShow,
}) {
  console.log("deleteModalShow", deleteModalShow);
  const {
    isRecordUpdated,
    error: apiError,
    isLoader,
  } = useSelector((state) => {
    return state.subadmins;
  });
  const [isAction, setIsAction] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const dispatch = useDispatch();
  var classes = useStyles();
  const { addToast } = useToasts();

  const submitHandler = (mode) => {
    setIsAction(true);
    return dispatch(
      deleteSubAdmin({
        rootAdminId: deleteRec,
      }),
    ).then((payload) => {
      if (payload?.payload?.success) {
        // return;
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:'34px'
          },
        });
      }else{
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setIsDone(true);
      onHide();
      setDeleteModalShow(false);
    });
  };
  // useEffect(() => {
  //   if (isRecordUpdated) {
  //     setIsDone(true);
  //     onHide();
  //     setDeleteModalShow(false);
  //   }
  // }, [isRecordUpdated]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            <Typography className={classes.formActiveHeading}>
              Are you sure, you want to delete this sub-admin?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
