import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button, Box } from "@material-ui/core";

// styles
import useStyles from "./styles";

// context
import { useUserDispatch, loginUser } from "../../../context/UserContext";

function RegisterSucess(props) {
  var classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // global
  var userDispatch = useUserDispatch();
  const history = useNavigate();
  const handleOnSubmit = () => {
    history.push(`/activation`);
  };
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.topBar}>
          <Box sx={{ textAlign: "center", m: 1 }}>Client Organization Name</Box>
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <Typography className={classes.subGreeting}>
          <Box sx={{ textAlign: "center", m: 1 }}>Thanks for Registration</Box>
        </Typography>
        <div elevation={1} className={classes.form}>
          <React.Fragment>
            <Typography className={classes.greeting}>
              <Box sx={{ textAlign: "center", m: 1 }}>
                We have received your registration request. Please wait for some
                time, our admin will update you on your registered email.
                Thanks.
              </Box>
            </Typography>

            <div className={classes.creatingButtonContainer}>
              <Button
                onClick={handleOnSubmit}
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.createAccountButton}
              >
                Close
              </Button>
            </div>
            {/* <Box sx={{ textAlign: 'center', m: 2 }}>
            <Link href="#/login" className={classes.Link} underline="none">
   Back to login
</Link>
       </Box>     */}
          </React.Fragment>
        </div>
        {/* <div className={classes.bottomLink}>
          <Link className={classes.linkItem} href="#" underline="none">
            {'Privacy & terms'}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
            {'Contact Us'}
          </Link>
          <Link className={classes.linkItem} href="#" underline="none">
            <Icons.Language className={classes.iconGlobal}/>
            {'Change region'}
            <Icons.ArrowDropDown />
          </Link>
        </div> */}
      </div>
    </Grid>
  );
}

export default RegisterSucess;
