import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#969DAB",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
    flexGrow: 1,
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  txtForm: {
    padding: "0px 4px",
  },
  textGroup: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  btnlRightDark: {
    height: "38px",
    textTransform: "none",
    fontSize:'14px',
    fontWeight:'500',
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  btnleftOutline: {
    height: "38px",
    fontWeight:'400',
    textTransform: "none",
    border: "1px solid #0078D4",
    color: "#0078D4",
    fontSize:'14px',
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  btn: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },

  rightButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },
  Link: {
    color: "#0445AF",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  iconsBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    padding: "4px 0px",
   
  },
  stickyTabs : {
    position: 'sticky',
    top: '60px',
    backgroundColor: 'white',/* Adjust as needed */
    zIndex: '1000', /* Make sure it's on top of other content */
  },
  stickyTabsTop:{
    position:'fixed',
    top:'40px'
  },
  tab: {
    color: "#201F1E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    marginRight: "2px",
    borderRight: "1px solid #EDEBE9",
    "&:hover": {
      color: "#fff",
      background: "#03997B",
    },
    "&:active": {
      color: "#fff",
      background: "#03997B",
    },
    "&.Mui-selected": {
      color: "#fff",
      background: "#03997B",
    },
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  // fixedTabs: {
  //   position: "fixed",
  //   top: "50px",
  //   zIndex: 1,
  //   backgroundColor: "white",
  //   width: "100%",
  //   border: "1px solid #03997B",
  // },
  scrollableContentContainer: {
    // position: "fixed",
    // top: "150px", // Adjust this value to match the height of your fixed tabs
    // width: "100%",
    // height: "calc(100% - 50px)", // Subtract the height of the fixed tabs
    overflowY: "auto",
  },
  scrollableContent: {
    padding: "16px",
  },
}));
