import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";

// data
import mock from "./mock";
// styles
import useStyles from "./styles";

import TableDesign from "../../../component/common/table/TableDesign";

export default function ProjectLogs() {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
      align: "left",
    },
    {
      label: "ID",
    },
    {
      label: "Title",
    },
    {
      label: "Lead Name",
    },
    {
      label: "Number of Audit Logs",
    },
    {
      label: "Date Completed",
    },
    {
      label: "Status",
    },

    {
      label: "Action",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  var classes = useStyles();
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "",
                tableHeadings,
              }}
            >
              {mock.table && mock.table.length ? (
                mock.table.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {row.projectId}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {row.projectTitle}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.leadName}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.number}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.date}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {row.status}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem className={classes.menuItem}>
                              {" "}
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Logs
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
