import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Box,
  Link,
} from "@material-ui/core";

import Validation from "../../hooks/Validation";
// styles
import useStyles from "../../../assets/login/styles";
// logo
import logo from "../../../images/logo.png";
import production from "../../../images/provider.png";
// import production from "../../../assets/product.png";

import ehiiaLogo from "../../../assets/icons/ehiia_logo.svg";
// context
// import { useUserDispatch, loginUser } from "../../context/UserContext";
import { useDispatch, useSelector } from "react-redux";
// api
import { resetError } from "../../store/reducerSlice/admin";
import { adminLoginWithEmail } from "../../store/actions/adminAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import staffIcon from "../../../assets/icons/staff-icon.png";
import clientIcon from "../../../assets/icons/client-icon.png";
import staffBlackIcon from "../../../assets/staff-svg.svg";
import clientBlackIcon from "../../../assets/client-svg.svg";
import { getOrgData } from "../../../store/actions/reviewsAction";

import { useToasts } from "react-toast-notifications";
function Login() {
  var classes = useStyles();
  const [email, setEmail] = useState("");
  const { addToast } = useToasts();
  const [isShow, setIsShow] = useState(false);

  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [subdomain, setSubdomain] = useState("");
  const [subPath, setSubPath] = useState("");
  const [userType, setUserType] = useState("user");
  const [isTab, setIsTab] = useState("");
  const [redirectUrlValue, setRedirectUrlValue] = useState("");
  const [orgDetails, setOrgDetails] = useState(null);
  let accessPath = "";
  useEffect(() => {
    console.log("userType", userType);
  }, [userType]);
  useEffect(() => {
    const url = window.location.href;
    console.log("URL:", url);

    const hostname = window.location.hostname;
    console.log("Hostname:", hostname);

    const subdomain = hostname.split(".")[0];
    console.log("Subdomain:", subdomain);

    const path = window.location.pathname;
    console.log("Path:", path);
    setSubPath(path);
    const pathSegments = path.split("/");
    accessPath = pathSegments[1]; // Assuming "client-admin" is the first segment of the path
    console.log("Client Admin Path 4:", accessPath);
  }, []);

  // useEffect(() => {
  //   if(userType){
  //     console.log("userType",userType);
  //     if((subPath === "/" && userType==='user') || (userType==='admin' && subPath === "/")){
  //       navigate('/login')
  //     } else{
  //       navigate('/login')
  //     }
  //   }
  // }, [userType]);
  // useEffect(() => {
  //   console.log("accessPath", accessPath);
  //   if (accessPath === "user") {
  //     setUserType(accessPath);
  //   } else {
  //     setUserType("admin");
  //   }
  // }, [accessPath]);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({ email });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAdminLoggedIn,
    adminDetail,
    error: apiError,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    localStorage.removeItem("authorization");
    // localStorage.clear();
    if (redirectUrlValue) {
      navigate(redirectUrlValue);
    }
    return () => {
      dispatch(resetError());
    };
  }, []);
  useEffect(() => {
    dispatch(getOrgData()).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.data?.success) {
        setOrgDetails(payload?.data?.data);
      }
    });
  }, []);
  useEffect(() => {
    if (subdomain) {
      dispatch(getOrgData()).then(({ payload }) => {
        console.log("data", payload);
        if (payload?.data?.success) {
          setOrgDetails(payload?.data?.data);
        }
      });
    }
  }, [subdomain]);
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      const hostname = window.location.hostname;
      const newSubdomain =
        hostname === "localhost" ? "super-admin" : hostname.split(".")[0]; // Set default subdomain to "super-admin" when running locally
      setSubdomain(newSubdomain); // Set the subdomain value in state
      console.log("subdomain", subdomain);
      console.log("api hitted");
      dispatch(adminLoginWithEmail({ email, userType })).then((payload) => {
        console.log("payload-", payload);
        console.log("payload", payload?.payload);
        if (payload?.payload?.data?.success) {
          let redirectUrl = payload?.payload?.data?.data?.redirectUrl;
          // local setup
          // if (redirectUrl)
          //   redirectUrl =
          //     process.env.REACT_APP_TYPE === "LOCAL"
          //       ? redirectUrl.replace(".online", ".com:3000")
          //       : redirectUrl;
          // redirectUrl =
          //   process.env.REACT_APP_TYPE === "LOCAL"
          //     ? redirectUrl.replace("https://", "http://")
          //     : redirectUrl;
          setRedirectUrlValue(redirectUrl);
          if (redirectUrl) {
            // navigate(redirectUrl);
            window.open(redirectUrl, "_self");
            // const newTab = window.open(redirectUrl, "_blank");
            // newTab.focus();
          }
        } else {
          console.log("payload--", payload);
          const toastHeight =
            payload?.payload?.data?.message.length > 35 ? "auto" : "34px";
          // setIsShow(true);
          addToast(payload?.payload?.data?.message, {
            appearance: "error",
            autoDismiss: true,
            style: {
              zIndex: "99999",
              height: toastHeight,
            },
          });

          setErrorApiMessage(payload?.payload?.data?.message);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled, subdomain]);

  const hideHandlerRes = () => {
    setIsShow(false);
  };
  const gotoHome = () => {
    navigate("/");
  };
  const gotoClient = () => {
    // navigate("/admin");
    // window.location.href = "/admin";
    setIsTab("admin");
  };

  const gotoStaff = () => {
    setIsTab("user");
    // navigate("/user");
    // window.location.href = "/user"; // Redirect to the "/staff" URL
  };
  useEffect(() => {
    if (isTab) {
      setUserType(isTab);
    }
  }, [isTab]);
  return (
    <>
      <section className={classes.mainSectionContainer}>
        <div
          style={{
            // width: "1120px",
            cursor: "pointer",
            justifyItems: "center",
            position: "relative",
            margin: "auto",
            marginBottom: "16px",
          }}
        >
          <img
            style={{ width: "170.52px", height: "34.17px" }}
            onClick={gotoHome}
            src={ehiiaLogo}
            alt="icons"
          />
        </div>
        <Grid className={classes.cardContainer}>
          <div className={classes.logotypeContainer}>
            <div className={classes.groupContainer}>
              {/* <div className={classes.imageContainer}>
                <img src={topDot} className={classes.topImage} alt="icons" />
                <img src={spin} className={classes.topRightImage} alt="icons" />
              </div> */}
              <Typography
                style={{
                  color: "#101010",
                  marginTop: "-10px",
                  marginBottom: "6px",
                }}
                className={classes.loginTitle}
              >
                Welcome to ImpactEQ Platform
              </Typography>
              <Typography
                style={{ color: "#03997B" }}
                className={classes.loginSubTitle}
              >
                {orgDetails ? orgDetails?.name : "ImpactEQ"}
              </Typography>
              <center style={{ marginTop: "10px" }}>
                <img
                  src={logo}
                  alt="logo"
                  className={classes.logotypeImage}
                  style={{ width: "407px", height: "251px" }}
                />
              </center>
              {/* <div className={classes.imageContainer}>
                <img src={curve} className={classes.bottomImage} alt="icons" />
                <img
                  src={bottomDot}
                  className={classes.bottomRightImage}
                  alt="icons"
                />
              </div> */}
            </div>
          </div>
          <div className={classes.formContainer}>
            <div className={classes.form} style={{ marginTop: "48px" }}>
              <React.Fragment>
                {/* <Typography className={classes.greeting}>Welcome back</Typography> */}
                <Typography
                  style={{ fontWeight: "500", fontSize: "24px" }}
                  className={classes.subGreeting}
                >
                  Login as...
                </Typography>
                <br />
                <div
                  className={classes.creatingButtonContainer}
                  style={{ border: "1px solid #3D7DDA", borderRadius: "5px" }}
                >
                  <Button
                    onClick={gotoStaff}
                    size="large"
                    variant="contained"
                    style={{
                      background:
                        userType === "user" ? "#3D7DDA" : "transparent",
                      borderRadius: "0px",
                      boxShadow: "none",
                      color: userType === "user" ? "white" : "black",
                    }}
                    color="secondary"
                    fullWidth
                    className={classes.createAccountChangeButton}
                  >
                    {userType === "user" ? (
                      <img
                        src={staffIcon}
                        alt="icon"
                        style={{ marginRight: "6px" }}
                      />
                    ) : (
                      <img
                        src={staffBlackIcon}
                        alt="icon"
                        style={{ marginRight: "6px" }}
                      />
                    )}
                    Staff
                  </Button>
                  <Button
                    onClick={gotoClient}
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background:
                        userType === "admin" ? "#3D7DDA" : "transparent",
                      borderRadius: "0px",
                      boxShadow: "none",
                      color: userType === "admin" ? "white" : "black",
                    }}
                    fullWidth
                    className={classes.createAccountChangeButton}
                  >
                    {userType === "admin" ? (
                      <img
                        src={clientIcon}
                        alt="icon"
                        style={{ marginRight: "6px" }}
                      />
                    ) : (
                      <img
                        src={clientBlackIcon}
                        alt="icon"
                        style={{ marginRight: "6px" }}
                      />
                    )}
                    Admin
                  </Button>
                </div>
                <br />
                <Typography variant="h6" className={classes.txtHeading}>
                  Email
                </Typography>
                <TextField
                  id="email"
                  name="email"
                  InputProps={{
                    classes: {
                      border: classes.textFieldline,
                      input: classes.textField,
                    },
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    resetValidator(e.target.name);
                  }}
                  isInvalid={error?.email}
                  margin="normal"
                  placeholder="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <Fade in={error} type="invalid">
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error?.email}
                  </Typography>
                </Fade>
                <br />

                <div className={classes.creatingButtonContainer}>
                  <Button
                    onClick={(e) => submitHandler(e)}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Next
                  </Button>
                </div>
                <center style={{ marginTop: "16px" }}>
                  {/* <Box sx={{ textAlign: "center" }}>Powered by</Box> */}
                  <img
                    src={production}
                    alt="production"
                    style={{ width: "30%", marginTop: "4px" }}
                  />
                </center>
              </React.Fragment>
              <ApiResponsePopup
                {...{
                  hideHandlerRes,
                  isRecordUpdated: isShow,
                  title: "Login",
                  successMsg: successMessage,
                  errorRes: errorApiMessage,
                }}
              />
            </div>
          </div>
          <div className={classes.bottomLink}>
            <div style={{ display: "flex" }}>
              <Link className={classes.linkItem} href="#" underline="none">
                {"Terms & Conditions"}
              </Link>{" "}
              ||
              <Link
                style={{ marginLeft: "16px" }}
                className={classes.linkItem}
                href="/privacy-policy"
                underline="none"
              >
                {"Privacy & Policy"}
              </Link>
            </div>
            <div>
              <Link
                align="right"
                className={classes.linkItem}
                href="#"
                underline="none"
              >
                © 2024 Provide Digital Ltd. All rights reserved
              </Link>
            </div>
          </div>
        </Grid>

        {/* <div
            className={classes.logotypeContainer}
            style={{
              overflow: "hidden",
              color: "#fff",
              textAlign: "center",
              backgroundColor: "#03997B",
              // position: "fixed",
              bottom: "0px",
              fontSize: "13px",
              padding: "4px",
              width: "auto",
              // display: "inline",
              justifyContent: "center",
              
              // alignItems: "center",
              height: "28px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                // left: "20px",
                paddingTop:'6px',
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
            >
              <div >
                <span style={{ marginRight: "6px", cursor: "pointer" }}>
                  Terms & Conditions
                </span>
                ||
                <span style={{ marginLeft: "6px", cursor: "pointer" }}>
                  {" "}
                  Privacy & Policy
                </span>
              </div>
              <div >
                © 2024 Provide Digital Ltd. All rights reserved
              </div>
            </div>
          </div> */}
      </section>
    </>
  );
}

export default Login;
