import { createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from "../../../store/constant";
const { path } = constant;
export const fetchPortalUserList = createAsyncThunk(
  "portalusers/fetchPortalUserList",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(
      `/admin/org-users/v1?search=${arg?.search}&page=${arg?.page}&orderIn=${arg?.orderIn}&orderBy=${arg?.orderBy}`,
      arg,
      rejectWithValue,
    ),
);

export const createUpdatePortalUser = createAsyncThunk(
  "portalusers/createUpdatePortalUser",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org-users/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
export const updatePortalUser = createAsyncThunk(
  "portalusers/updatePortalUser",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.put("/admin/org-users/v1", arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const deletePortalUser = createAsyncThunk(
  "portalusers/deletePortalUser",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.delete(`/admin/org-users/v1/${arg?.userId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const toggleActivation = createAsyncThunk(
  "portalusers/toggleActivation",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/admin/org-users/v1/toggle-status/${arg?.userId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const sendActivationLink = createAsyncThunk(
  "portalusers/sendActivationLink",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.patch(
      `/admin/org-users/v1/resend-account-mail/${arg?.userId}`,
      arg,
    )
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);

export const getPortalUserByUserId = createAsyncThunk(
  "portalusers/getPortalUserByUserId",
  (arg, { rejectWithValue }) =>
    AxiosInstance?.get(`/admin/org-users/v1/${arg?.userId}`, arg)
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data)),
);
