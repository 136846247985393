import React from 'react'
import ClientDetail from "../../components/Client/Detail/ClientDetail";
export default function ClientDetails() {
  return (
    <>
     
        <ClientDetail/>
        
    </>
  )
}
