import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { sendActivationMail } from "../../store/actions/clientsAction";
// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
export default function ResetPasswordClientPopup({
  resetPasswordRec,
  onHide,
  resetPasswordModalShow,
  setresetPasswordModalShow,
}) {
  //
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { isRecordUpdated,error: apiError, isLoader } = useSelector((state) => state.clients);

  const submitHandler = () => {
    return dispatch(
      sendActivationMail({
        orgId: resetPasswordRec,
      }),
    ).then((payload) => {
      console.log("payload",payload);
      if (payload?.payload?.success) {
        addToast(payload?.payload?.message, {
          appearance: "success",
          autoDismiss: true,
          style: {
            zIndex: '99999',height:'34px'
          },
        });
        // return;
      }else{
        addToast(payload?.payload?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      onHide();
      setresetPasswordModalShow(false);
    });
  };
  
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={resetPasswordModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            <Typography className={classes.formActiveHeading}>
              Are you sure you want to resend password link to this client?
            </Typography>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
