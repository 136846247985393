import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;

export const getDashboard = createAsyncThunk(
    'dashboard/getDashboard',
    (arg, { rejectWithValue }) => AxiosInstance?.get('/user/my/v1', rejectWithValue)
)
