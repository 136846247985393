import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Fade,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import { createUpdatePortalUser } from "../../store/actions/portalUserAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../component/common/loader/Loader";

// styles
import useStyles from "./styles";
import { useToasts } from "react-toast-notifications";
import { createUpdateProjectLevel } from "../../store/actions/approvalAction";
import ProjectValidation from "../../hooks/ProjectValidation";
export default function ManageProject(props) {
  //
  console.log("props-24", props);
  const { selectProject, subOrg, type } = props;

  console.log("selectProject", selectProject);
  const [isShow, setIsShow] = useState(false);

  const formElements = [
    {
      key: "name",
      label: "Project Type Name",
      placeHolder: "Enter Project Type Name",
      type: "text",
      requiredField: true,
    },
    {
      key: "description",
      label: "Project Description",
      placeHolder: "Enter Project Description",
      type: "textarea",
      // requiredField: true,
    },
    {
      key: "engagement",
      label: "Is Engagement Required ?",
      placeHolder: "Enter ",
      type: "radio",
      value: ["Email", "Service Manager", "Line Manager", "Not Required"],
      requiredField: true,
    },
    {
      key: "engagementEmail",
      label: "Engagement Plan Approver Email Id",
      placeHolder: "Enter Email ID",
      type: "text",
    },
    {
      key: "equality",
      label: "Equality Sign-off",
      placeHolder: "Enter ",
      type: "radio",
      value: ["Email", "Service Manager", "Line Manager", "Not Required"],
      requiredField: true,
    },
    {
      key: "equalityEmail",
      label: "Enter Email Id",
      placeHolder: "Enter Email Id",
      type: "text",
    },
    {
      key: "report",
      label: "Report Sign-off",
      placeHolder: "Enter ",
      type: "radio",
      value: ["Email", "Service Manager", "Line Manager", "Not Required"],
      requiredField: true,
    },
    {
      key: "reportEmail",
      label: "Enter Email Id",
      placeHolder: "Enter Email Id",
      type: "text",
    },
  ];
  var classes = useStyles();
  const { addToast } = useToasts();
  const [projectTypeDetails, setProjectTypeDetails] = useState({
    name: "",
    level: 0,
    description: "",
    engagement:'None',
    engagementEmail: "",
    equality: "None",
    equalityEmail: "",
    report: "None",
    reportEmail: "",
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ProjectValidation({
      name: projectTypeDetails?.name,
      ...(projectTypeDetails?.engagement === "Email" && {
        engagementEmail: projectTypeDetails?.engagementEmail,
      }),
      ...(projectTypeDetails?.equality === "Email" && {
        equalityEmail: projectTypeDetails?.equalityEmail,
      }),
      ...(projectTypeDetails?.report === "Email" && {
        reportEmail: projectTypeDetails?.reportEmail,
      }),
    });

  const [fullWidth, setFullWidth] = React.useState(true);
  const [orgDataValue, setOrgDataValue] = React.useState([]);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [orgOption, setOrgOption] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "subOrg") {
      setOrgDataValue(value);
    } else {
      setProjectTypeDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    props.onHide();
    setProjectTypeDetails({
      name: "",
      level: 0,
      description: "",
      engagement: "None",
      engagementEmail: "",
      equality: "None",
      equalityEmail: "",
      report: "None",
      reportEmail: "",
    });
  };

  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  console.log("adminDetail", adminDetail);

  useEffect(() => {
    if (selectProject) {
      setProjectTypeDetails({
        name: selectProject?.name,
        level: selectProject?.level,
        description: selectProject?.description,
        engagement:selectProject?.engagement?.email ? "Email" : selectProject?.engagement?.option,
        engagementEmail: selectProject?.engagement?.email,
        equality: selectProject?.approver?.stage1?.email
          ? "Email"
          : selectProject?.approver?.stage1?.option,
        equalityEmail: selectProject?.approver?.stage1?.email,
        report: selectProject?.approver?.stage2?.email
          ? "Email"
          : selectProject?.approver?.stage2?.option,
        reportEmail: selectProject?.approver?.stage2?.email,
      });
    }
  }, [selectProject]);
  const hideHandlerRes = () => {
    setErrorApiMessage(null);
    setSuccessMessage(null);
    setIsShow(false);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    const formData = {
      name: projectTypeDetails?.name || "",
      description: projectTypeDetails?.description || "",
      level: projectTypeDetails?.level || 0,
      engagement: { 
        email: projectTypeDetails?.engagementEmail || "",
        option:
            projectTypeDetails?.engagement &&
            projectTypeDetails?.engagement === "Email"
              ? ""
              : projectTypeDetails?.engagementEmail
              ? ""
              : projectTypeDetails?.engagement,
        
      },
      approver: {
        stage1: {
          email: projectTypeDetails?.equalityEmail || "",
          option:
            projectTypeDetails?.equality &&
            projectTypeDetails?.equality === "Email"
              ? ""
              : projectTypeDetails?.equalityEmail
              ? ""
              : projectTypeDetails?.equality,
        },
        stage2: {
          email: projectTypeDetails?.reportEmail || "",
          option:
            projectTypeDetails?.report && projectTypeDetails?.report === "Email"
              ? ""
              : projectTypeDetails?.reportEmail
              ? ""
              : projectTypeDetails?.report,
        },
      },
    };
    console.log("formData", formData);
    if (!isError() && error !== null && isActionCalled) {
      dispatch(createUpdateProjectLevel({ subOrg, type, ...formData })).then(
        ({ payload }) => {
          console.log("payload", payload);
          if (payload?.success) {
            const toastHeight = payload?.message.length > 38 ? "auto" : "34px";

            addToast(payload?.message, {
              appearance: "success",
              autoDismiss: true,
              style: {
                zIndex: "99999",
                height: toastHeight,
              },
            });
            props.onHide();
            setProjectTypeDetails({
              name: "",
              level: 0,
              description: "",
              engagement: "None",
              engagementEmail: "",
              equality: "None",
              equalityEmail: "",
              report: "None",
              reportEmail: "",
            });
          } else {
            const toastHeight = payload?.message.length > 38 ? "auto" : "34px";
            console.log("payload?.error", payload);

            addToast(payload?.message, {
              appearance: "error",
              autoDismiss: true,
              style: {
                height: toastHeight,
              },
            });
            // setIsShow(true);
          }
        },
      );
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);
  const styles = {
    radioLabel: {
      fontSize: "12px",
      "& .MuiFormControlLabel-label .MuiTypography-body1": {
        fontSize: "12px !important",
      },
    },
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              {selectProject ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent style={{ paddingTop: "10px" }}>
          <DialogContentText className={classes.formAddBox}>
            {formElements.map((element, i) => {
              return (
                (!selectProject || element.key !== "password") && (
                  <div key={i}>
                    <Typography className={classes.formHeading}>
                      {(projectTypeDetails?.equality === "Email" &&
                        element.key === "equalityEmail") ||
                      (projectTypeDetails?.report === "Email" &&
                        element.key === "reportEmail") ||
                      (projectTypeDetails?.engagement === "Email" &&
                        element.key === "engagementEmail")
                        ? element.label
                        : element.key === "name" ||
                          element.key === "description" ||
                          element.key === "engagement" ||
                          element.key === "equality" ||
                          element.key === "report"
                        ? element?.label
                        : null}
                      {""}
                      {element?.requiredField && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          *
                        </span>
                      )}
                    </Typography>
                    {element.type === "select" ? (
                      <>
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            value={orgDataValue}
                            onChange={handleChange}
                            renderValue={(selected) =>
                              selected
                                .map((value) =>
                                  orgOption.includes(value) ? value : "",
                                )
                                .join(", ")
                            }
                            multiple
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "subOrg" && orgOption
                              ? orgOption.map((item) => (
                                  <MenuItem value={item}>
                                    <Checkbox
                                      checked={orgDataValue.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                        {/* <Fade in={error}>
                          <Typography className={classes.errorMessage}>
                            {error && error[element.key]}
                          </Typography>
                        </Fade> */}
                      </>
                    ) : element.type === "text" && element.key === "name" ? (
                      <>
                        <TextField id="name-text" autoComplete="off"
                          hiddenLabel
                          size="small"
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          className={classes.txtForm}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: "14px" }, // Adjust the font size as needed
                          }}
                          InputProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the input as needed
                          }}
                          placeholderTypographyProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the placeholder as needed
                          }}
                          value={
                            projectTypeDetails &&
                            projectTypeDetails[element.key] === null
                              ? ""
                              : projectTypeDetails &&
                                projectTypeDetails[element.key]
                          }
                          variant="outlined"
                          onChange={async (e) => {
                            setProjectTypeDetails({
                              ...projectTypeDetails,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log(
                              "projectTypeDetails",
                              projectTypeDetails,
                            );
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "radio" ? (
                      <>
                        <FormControl style={{ fontSize: "12px !important" }}>
                          <RadioGroup
                            name={element.key}
                            row
                            style={{
                              border: "1px solid #E8E8E8",
                              borderRadius: "5px",
                              padding: "0px 10px",
                              fontWeight: "400",
                              fontSize: "14px",
                              marginTop: "2px",
                              display: "table-column-group",
                              columns: "2",
                            }}
                            value={
                              projectTypeDetails &&
                              projectTypeDetails[element.key] === null
                                ? "Not Required"
                                : projectTypeDetails &&
                                  projectTypeDetails[element.key]
                            }
                            onChange={async (e) => {
                              setProjectTypeDetails({
                                ...projectTypeDetails,
                                [e.target.name]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                          >
                            {element.value &&
                              element?.value.map((item, i) => (
                                <>
                                  <FormControlLabel
                                    style={{
                                      ...styles.radioLabel,
                                      fontSize: "10px",
                                      marginRight: "16px",
                                    }}
                                    value={
                                      item && item === "Not Required"
                                        ? "None"
                                        : item
                                    }
                                    size="small"
                                    control={
                                      <Radio
                                        size="small"
                                        style={styles.radioLabel}
                                        inputProps={{ "font-size": "12px" }}
                                      />
                                    }
                                    label={
                                      item === "Email" ? "Enter Email" : item
                                    }
                                    inputProps={{
                                      fontSize: "12px",
                                    }}
                                  />
                                  {/* <Typography
                                    style={{
                                      fontSize: "14px",
                                      marginRight: "16px",
                                      display: "inline-flex",
                                      verticalAlign: "middle",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item}
                                  </Typography> */}
                                </>
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </>
                    ) : (projectTypeDetails?.equality === "Email" &&
                        element.key === "equalityEmail") ||
                      (projectTypeDetails?.report === "Email" &&
                        element.key === "reportEmail") ||
                      (projectTypeDetails?.engagement === "Email" &&
                        element.key === "engagementEmail") ? (
                      <>
                        {" "}
                        <TextField autoComplete="off"
                          hiddenLabel
                          size="small"
                          type="text"
                          name={element.key}
                          placeholder={element.placeHolder}
                          className={classes.txtForm}
                          fullWidth
                          value={
                            projectTypeDetails &&
                            projectTypeDetails[element.key] === null
                              ? ""
                              : projectTypeDetails &&
                                projectTypeDetails[element.key]
                          }
                          variant="outlined"
                          onChange={(e) => {
                            setProjectTypeDetails({
                              ...projectTypeDetails,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);

                            console.log(
                              "projectTypeDetails",
                              projectTypeDetails,
                            );
                          }}
                          InputLabelProps={{
                            style: { fontSize: "14px" }, // Adjust the font size as needed
                          }}
                          InputProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the input as needed
                          }}
                          placeholderTypographyProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the placeholder as needed
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "textarea" ? (
                      <>
                        <TextField autoComplete="off"
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          type={element.type}
                          name={element.key}
                          placeholder={element.placeHolder}
                          variant="outlined"
                          multiline
                          rows={4}
                          value={
                            projectTypeDetails &&
                            projectTypeDetails[element.key] === null
                              ? ""
                              : projectTypeDetails &&
                                projectTypeDetails[element.key]
                          }
                          onChange={async (e) => {
                            setProjectTypeDetails({
                              ...projectTypeDetails,
                              [e.target.name]: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                          InputLabelProps={{
                            style: { fontSize: "14px" }, // Adjust the font size as needed
                          }}
                          InputProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the input as needed
                          }}
                          placeholderTypographyProps={{
                            style: { fontSize: "14px" }, // Adjust the font size of the placeholder as needed
                          }}
                        />
                      </>
                    ) : null}
                  </div>
                )
              );
            })}
            {/* Name */}
          </DialogContentText>
        </DialogContent>
        <ApiResponsePopup
          {...{
            hideHandlerRes,
            isRecordUpdated: isShow,
            title: "Manage Project",
            successMsg: successMessage,
            errorRes: errorApiMessage,
            // errorRes: apiError?.message,
          }}
        />
      </Popup>
    </>
  );
}
