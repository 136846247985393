import React, { useState } from "react";
import { Typography } from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import alertIcon from "../../../images/alert.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleActivation } from "../../store/actions/ehiiasAction";
import Loader from "../../../component/common/loader/Loader";
import { Alert } from "@material-ui/lab";
// styles
import useStyles from "./styles";
export default function ToggleActivationPopup({
  toggleActivateRec,
  toggleActivateStatus,
  onHide,
  toggleActivateModalShow,
  setToggleActivateModalShow,
}) {
  //
  console.log("toggleActivateModalShow", toggleActivateModalShow);
  
  //  const { selectclient } = props;
  const { error: apiError, isLoader } = useSelector((state) => state.versions);
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const dispatch = useDispatch();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  //  const [toggleActivateModalShow, setToggleActivateModalShow] = React.useState(false);
  const submitHandler = () => {
    return dispatch(
      toggleActivation({
        versionId: toggleActivateRec,
        // isActive: toggleActivateStatus,
      }),
    ).then(() => {
      
      onHide();
      setToggleActivateModalShow(false);
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setToggleActivateModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setToggleActivateModalShow(false);
    setOpen(false);
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={toggleActivateModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={(e) => onHide()}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler()}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
          <center style={{padding:'10px'}}>
            <img src={alertIcon} alt="icons" />
            {isLoader && <Loader />}
            {apiError ? (
              <Alert severity="error">
                {apiError?.message || "Access denied! Please try again"}
              </Alert>
            ) : (
              <Typography className={classes.formActiveHeading}>
                {toggleActivateStatus ? (
                  <>Are you sure, you want to deactivate this version?</>
                ) : (
                  <>Are you sure, you want to activate this version?</>
                )}
              </Typography>
            )}
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
