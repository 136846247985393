import axios from "axios";
import { constant } from "./constant";
import store from "./store";
const { path } = constant;

class DataService {
  post(arg, endpoint, rejectWithValue) {
    const authDetail = localStorage.getItem("authorization");
    console.log("authDetail", authDetail);
    if (authDetail) {
      const token = JSON.parse(authDetail).token;
      console.log("token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    return axios
      .post(endpoint, arg)
      .then(({ data }) => {
        console.log("data", data);

        if (
          endpoint === path + "root/auth/v1/login" ||
          endpoint === path + "staff/auth/v1/login"
        ) {
          if (data.code === 200) {
            localStorage.setItem("authorization", JSON.stringify(data.data));
            const token = data.data.token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          }
        }
        return data;
      })
      .catch((error) => {
        console.log("error", error);
        const { data } = error.response;
        if (data.code === 502 || data.code === 403) {
          return rejectWithValue(
            "Something went wrong, please refresh or try again",
          );
        } else if (data.code === 500 && data.code === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/dashboard");
        }
        return rejectWithValue(error.response.data);
      });
  }

  get(arg, endpoint, rejectWithValue) {
    const authDetail = localStorage.getItem("authorization");
    if (authDetail) {
      const token = JSON.parse(authDetail).token;
      console.log("token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    return axios
      .get(endpoint, { params: arg })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.code === 502 || data.code === 403) {
          return rejectWithValue(
            "Something went wrong, please refresh or try again",
          );
        } else if (data.code === 500 || data.code === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/login");
        }

        return rejectWithValue(error.response.data);
      });
  }

  delete(arg, endpoint, rejectWithValue) {
    const authDetail = localStorage.getItem("authorization");
    if (authDetail) {
      const token = JSON.parse(authDetail).token;
      console.log("token", token);
      // config.headers["Authorization"] = "Bearer " + token;
      axios.defaults.headers.common["Authorization"] = token;
    }
    return axios
      .delete(endpoint)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.code === 502 || data.code === 403) {
          return rejectWithValue(
            "Something went wrong, please refresh or try again",
          );
        } else if (data.code === 500 || data.code === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/login");
        }

        return rejectWithValue(error.response.data);
      });
  }

  put(arg, endpoint, rejectWithValue) {
    const authDetail = localStorage.getItem("authorization");
    if (authDetail) {
      const token = JSON.parse(authDetail).token;
      console.log("token", token);
      // config.headers["Authorization"] = "Bearer " + token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
    return axios
      .put(endpoint, arg)
      .then(({ data }) => {
        console.log("data", data);
        if (
          endpoint === path + "/login" ||
          endpoint === path + "client/auth/v1/login"
        ) {
          if (data.code === 200) {
            localStorage.setItem("authorization", JSON.stringify(data.data));
            const token = data.data.token;
            // config.headers["Authorization"] = "Bearer " + token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          }
        }
        return data;
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.code === 502 || data.code === 403) {
          return rejectWithValue(
            "Something went wrong, please refresh or try again",
          );
        } else if (data.code === 500 && data.code === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/dashboard");
        }
        return rejectWithValue(error.response.data);
      });
  }

  patch(arg, endpoint, rejectWithValue) {
    const authDetail = localStorage.getItem("authorization");
    if (authDetail) {
      const token = JSON.parse(authDetail).token;
      console.log("token", token);
      // config.headers["Authorization"] = "Bearer " + token;
      axios.defaults.headers.common["Authorization"] = token;
    }
    return axios
      .patch(endpoint, arg)
      .then(({ data }) => {
        console.log("data", data);
        if (
          endpoint === path + "/login" ||
          endpoint === path + "client/auth/v1/login"
        ) {
          if (data.code === 200) {
            localStorage.setItem("authorization", JSON.stringify(data.data));
            const token = data.data.token;
            // config.headers["Authorization"] = "Bearer " + token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          }
        }
        return data;
      })
      .catch((error) => {
        const { data } = error.response;
        if (data.code === 502 || data.code === 403) {
          return rejectWithValue(
            "Something went wrong, please refresh or try again",
          );
        } else if (data.code === 500 && data.code === 401) {
          localStorage.removeItem("authorization");
          window.location.replace("/dashboard");
        }
        return rejectWithValue(error.response.data);
      });
  }
}
export default new DataService();
