import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import { Grid, Typography } from "@material-ui/core";
import documentIcon from "../../../../assets/document.png";
// components
import Widget from "../../../../component/Widget";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { getSinglePrivateData } from "../../../store/actions/privateDataAction";
// styles
import useStyles from "./styles";
import ManageDataItam from "../ManageDataItem";
import Loader from "../../../../component/common/loader/Loader";
export default function DataContentDetails() {
  const navigate = useNavigate();
  const { organization, id } = useParams();
  console.log("organization", organization);
  console.log("id", id);
  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [dataItemDetail, setDataItemDetail] = useState(null);
  const [updateDetail, setUpdateDetail] = useState(null);
  const [selectOrg, setSelectOrg] = useState(organization);
  const [isLoader, setIsLoader] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSinglePrivateData({ organization, id })).then(({ payload }) => {
      console.log("data", payload);
      if (payload?.success) {
        setDataItemDetail(payload?.data?.content);
        setIsLoader(false);
      }
      setUpdateDetail(false);
      setIsLoader(false);
    });
  }, [organization, id, updateDetail]);
  const [title, setTitle] = useState(null);
  const formatedDate = (val) => {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(val).toLocaleDateString("en-GB", options);
  };
  var classes = useStyles();
  const addDataItem = () => {
    if (dataItemDetail) {
      setselectclient(dataItemDetail);
    } else {
      setselectclient(null);
    }

    setTitle("Update New Data Item");
    setOpen(true);
  };
  const hideHandle = () => {
    setselectclient(null);
    setModalShow(false);
    setOpen(false);
    setUpdateDetail(true);
  };
  const handleViewDocumentClick = () => {
    const link = dataItemDetail?.link;
  
    // Check if the link starts with "http://" or "https://"
    if (!/^https?:\/\//.test(link)) {
      // If not, add "https://"
      window.open(`https://${link}`, '_blank');
    } else {
      // Otherwise, keep the link as is
      window.open(link, '_blank');
    }
  };
  return (
    <>
      <ManageDataItam
        open={open}
        onHide={hideHandle}
        title={"Edit Data Item"}
        selectclient={selectclient}
        selectOrg={selectOrg}
        setModalShow={setModalShow}
      />
      <PageTitle
        subtitle="Data Review Content > My Data items >"
        title="Details"
        button="Edit"
        urlLink="/admin/approval/data-review-content"
        editIcon="true"
        onClick={addDataItem}
      />
      {isLoader && <Loader />}
      <Widget>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid
            item
            xs={12}
            md={12}
            className={classes.textGroup}
            // style={{ display: "block" }}
          >
            <Typography className={classes.formHeading} style={{width:'20%'}}>Data Title:</Typography>
            <Typography
              className={classes.formHeadingValue}
              style={{ width: "100%" }}
            >
              {dataItemDetail?.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Assessment Type:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {dataItemDetail?.userType}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Repository Type:
            </Typography>
            <Typography className={classes.formHeadingValue}>
            {dataItemDetail?.type}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Last Updated on:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {formatedDate(dataItemDetail?.updatedAt)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Added By:</Typography>
            <Typography className={classes.formHeadingValue}>
            {dataItemDetail?.addedBy?.email}
            </Typography>
          </Grid>
          {dataItemDetail?.type === "link" && (
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Link:</Typography>
              <Typography className={classes.formHeadingValue}>
              <span
                  target=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "6px",
                    borderRadius: "5px",
                    border: "1px solid #E8E8E8",
                    color: "#006ACC",
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={handleViewDocumentClick}
                >
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src={documentIcon}
                    alt="document"
                  />
                  View Link
                </span>
              </Typography>
            </Grid>
          )}
          {dataItemDetail?.type === "document" && (
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <Typography className={classes.formHeading}>Document:</Typography>
              <Typography className={classes.formHeadingValue}>
                <span
                  target=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "6px",
                    borderRadius: "5px",
                    border: "1px solid #E8E8E8",
                    color: "#006ACC",
                    fontSize: "14px",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={handleViewDocumentClick}
                >
                  <img
                    style={{ width: "18px", height: "18px" }}
                    src={documentIcon}
                    alt="document"
                  />
                  View Document
                </span>
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} md={5} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Groups Attached:
            </Typography>
            <Typography className={classes.formHeadingValue}>
              {dataItemDetail?.identifiedGroups.length}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={5} className={classes.textGroup}></Grid> */}
          {dataItemDetail?.identifiedGroups.map((item, i) => (
            <Grid item xs={12} md={6} className={classes.textGroup} key={i}>
              <div
                style={{
                  display: "flex",
                  border: "1px solid #E8E8E8",
                  borderRadius: "5px",
                  padding: "8px",
                  width: "100%",
                  fontSize: "14px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.10)",
                }}
              >
                {item}
              </div>
            </Grid>
          ))}
        </Grid>
      </Widget>
    </>
  );
}
