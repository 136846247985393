import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { difference as _difference, cloneDeep as _cloneDeep } from "lodash";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Avatar,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import Widget from "../../component/Widget";
import checked from "../../images/checked.png";
import expand from "../../assets/expand.png";
import hide from "../../assets/hide.png";
import moment from "moment";
// styles
import useStyles from "./styles";
import PatientReport from "./PatientReport";
import SendReview from "./SendReview";
// API data imports
import { reviewEngagementModule } from "../../user-admin/store/actions/reviewAction";
import ApiResponsePopup from "../../component/common/modal/ApiResponsePopup";
import Confirmation from "./Confirmation";

import ApprovalEngagementModal from "./ApprovalEngagementModal";
export default function Engagement({ engagementDetail, type }) {
  console.log("engagementDetail", engagementDetail);
  console.log("type", type);
  var classes = useStyles();
  const { projectId } = useParams();

  var [activeTabId, setActiveTabId] = useState(0);
  const [qusType, setQusType] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorApiMessage, setErrorApiMessage] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [reviewModalShow, setReviewModalShow] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const [confirmApproveModalShow, setConfirmApproveModalShow] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setQusType(type);
  }, [type]);
  useEffect(() => {
    console.log("qusType", qusType);
  }, [qusType]);
  const sendReview = () => {
    // setReviewModalShow(true);
    setAlertModalShow(true);
  };

  const submitApprovalHandler = (e) => {
    setConfirmApproveModalShow(true);
  };
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  const [show, setShow] = useState(false);
  const [checkQuestionId, setCheckQuestionId] = useState("");
  const handleShowContent = (questionId) => {
    setShow(true); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  const handleHideContent = (questionId) => {
    setShow(false); // Toggle the show state when the question is clicked.
    setCheckQuestionId(questionId);
  };
  return (
    <>
      <Confirmation
        alertModalShow={alertModalShow}
        setAlertModalShow={setAlertModalShow}
        projectId={projectId}
        type={type}
      />
      <ApprovalEngagementModal
        setConfirmApproveModalShow={setConfirmApproveModalShow}
        confirmApproveModalShow={confirmApproveModalShow}
        projectId={projectId}
        type={type}
      />
      <SendReview
        setReviewModalShow={setReviewModalShow}
        reviewModalShow={reviewModalShow}
        projectId={projectId}
        type={type}
      />
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        className={classes.iconsBar}
      >
        <Tab
          label={type}
          style={{ textTransform: "capitalize" }}
          classes={{ root: classes.tab }}
        />
        {/* <Tab label="Staff" classes={{ root: classes.tab }} /> */}
      </Tabs>
      {activeTabId === 0 && (
        <>
          <Widget>
            <Grid container spacing={2}>
              {engagementDetail &&
                engagementDetail.map((element, i) => {
                  const {
                    key,
                    title,
                    type,
                    questionId,
                    questionSerial,
                    value,
                    options,
                  } = element;
                  return (
                    <Grid item xs={12} md={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "6px",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#201F1E",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {title}
                        </Typography>
                        {/* {show && checkQuestionId === element?.questionId ? (
                          <div
                            style={{ marginLeft: "8px" }}
                            onClick={() => handleHideContent(questionId)}
                          >
                            <img
                              src={hide}
                              style={{ marginTop: "5px" }}
                              alt="hide"
                            />
                          </div>
                        ) : (
                          // type !== "patientEngagementApproval" &&
                          // type !== "staffEngagementApproval" && (
                            <div
                              style={{ marginLeft: "8px" }}
                              onClick={() => handleShowContent(questionId)}
                            >
                              <img
                                src={expand}
                                style={{ marginTop: "5px" }}
                                alt="expand"
                              />
                            </div>
                          // )
                        )} */}
                      </div>

                      <Grid item xs={12} md={12}>
                        {/* {show && checkQuestionId === element?.questionId && ( */}
                          <>
                            {type === "insightsActionTable" ? (
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: "100%", boxShadow: 0 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{ backgroundColor: "#EDFDFA" }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                          width: "30%",
                                        }}
                                      >
                                        Identified Groups
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Engagement Required
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.insights.length>0 ?element.insights.map((row, rowIndex) => (
                                      <TableRow
                                        style={{ border: "1px solid #B6B6B6" }}
                                        key={row.group}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: "1px solid #B6B6B6",
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            width: "60%",
                                          }}
                                        >
                                          {row.groupName}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{
                                            width: "40%",
                                            border: "1px solid #B6B6B6",
                                          }}
                                        >
                                          <FormControl>
                                            <RadioGroup
                                              style={{ display: "flex" }}
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="actionRequired"
                                              value={row.actionRequired}
                                            >
                                              <FormControlLabel
                                                value={true}
                                                control={<Radio />}
                                                label="Yes"
                                              />
                                              <FormControlLabel
                                                value={false}
                                                control={<Radio />}
                                                label="No"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </TableCell>
                                      </TableRow>
                                    )):<>
                                    <TableRow
                                   style={{
                                     border: "1px solid #B6B6B6",
                                   }}
                                  
                                   sx={{
                                     "&:last-child td, &:last-child th":
                                       {
                                         border: "1px solid #B6B6B6",
                                       },
                                   }}
                                 >
                                   <TableCell
                                     component="th"
                                     scope="row"
                                     style={{
                                       border: "1px solid #B6B6B6",
                                       width: "60%",textAlign: "center",
                                     }}
                                   >
                                     None
                                   </TableCell>

                                   <TableCell
                                     align="center"
                                     style={{
                                       width: "40%",
                                       border: "1px solid #B6B6B6",
                                     }}
                                   >
                                     None
                                   </TableCell>
                                 </TableRow>
                                   </>}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : type === "insightsActionPlanTable" ? (
                              <>
                                <TableContainer
                                  style={{ marginTop: "16px" }}
                                  sx={{ boxShadow: 0 }}
                                  component={Paper}
                                >
                                  <Table
                                    sx={{ minWidth: "100%", boxShadow: 0 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead
                                      style={{ backgroundColor: "#EDFDFA" }}
                                    >
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          Identified Groups
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          Selected Engagement Options
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          Details
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {element.insights.length>0 ? element?.insights?.map(
                                        (engageGrp, engageGrpIndex) => 
                                        {
                                          const groupName =
                                            engageGrp.groupName;
                                          const engagementGroups =
                                            engageGrp.engagementGroups?.length>0 ? engageGrp.engagementGroups :
                                            [""];
                                          const details =
                                            engageGrp.details.length>0 ? engageGrp.details : [];
                                          // Determine the number of rows needed
                                          const numRows = Math.max(
                                            engagementGroups.length ,
                                            details.length ,
                                          );
                                         
                                          return Array.from({
                                            length: numRows,
                                          }).map((_, rowIndex) => (
                                          <>
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              key={`${engageGrpIndex}-${rowIndex}`}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                             {rowIndex === 0 && (
                                                      <>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              width: "40%",
                                              verticalAlign: "top",
                                            }}
                                            rowSpan={numRows}
                                          >
                                            {groupName}
                                          </TableCell>
</>)}
<TableCell
                                            align="left"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              verticalAlign: "middle",
                                              padding: "0px 10px",
                                              width: "30%",
                                            }}
                                          >
                                            {engagementGroups[
                                                              rowIndex
                                                            ] || "N/A"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              verticalAlign: "middle",
                                              padding: "0px 10px",
                                              width: "30%",
                                            }}
                                          >
                                             {details[
                                                              rowIndex
                                                            ] || "N/A"}
                                          </TableCell>
                                            </TableRow>
                                          </>
                                        ))},
                                      ):<>
                                      <TableRow
                                     style={{
                                       border: "1px solid #B6B6B6",
                                     }}
                                    
                                     sx={{
                                       "&:last-child td, &:last-child th":
                                         {
                                           border: "1px solid #B6B6B6",
                                         },
                                     }}
                                   >
                                     <TableCell
                                       component="th"
                                       scope="row"
                                       style={{
                                         border: "1px solid #B6B6B6",
                                         width: "40%",textAlign: "center",
                                       }}
                                     >
                                       None
                                     </TableCell>

                                     <TableCell
                                       align="center"
                                       style={{
                                         width: "30%",
                                         border: "1px solid #B6B6B6",
                                       }}
                                     >
                                       None
                                     </TableCell>
                                     <TableCell
                                       align="center"
                                       style={{
                                         width: "30%",
                                         border: "1px solid #B6B6B6",
                                       }}
                                     >
                                       None
                                     </TableCell>
                                   </TableRow>
                                     </>}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            ) : type === "staffEngagementApproval" ||
                            type === "patientEngagementApproval" ? (
                            <>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                component={Paper}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "#EDFDFA",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        {element?.type ===
                                        "patientEngagementApproval"
                                          ? "Patient "
                                          : "Staff "}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th":
                                          {
                                            border: 0,
                                          },
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          width: "50%",
                                          color: "#201F1E",
                                          fontWeight: "500",
                                        }}
                                        component="th"
                                        scope="row"
                                      >
                                        <>
                                          <Typography
                                            className={
                                              classes.formHeading
                                            }
                                            style={{
                                              fontSize: "16px", color: "#201F1E",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Sent On Date:{" "}
                                            {moment(
                                              element?.sentAt,
                                            ).format("lll")}
                                          </Typography>
                                        </>
                                        {element.history.map(
                                          (review, reviewIndex) =>
                                            review?.status ===
                                              "pending" ||
                                            review?.status ===
                                              "approved" ? (
                                              <div key={reviewIndex}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  style={{
                                                    color: "#201F1E",
                                                    fontWeight: "500",
                                                    backgroundColor:
                                                      "#ffffff",
                                                    // border: "1px solid #03997B",
                                                    marginTop: "10px",
                                                    borderRadius:
                                                      "5px",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex", color: "#201F1E",
                                                      fontWeight: "500",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.formHeading
                                                      }style={{ color: "#201F1E",
                                                      fontWeight: "500",}}
                                                    >
                                                      Comment From{" "}
                                                     {review?.byEntity==="team" ? "Engagement Specialist" : "Applicant"} :
                                                    </Typography>
                                                    <Typography
                                                      style={{
                                                        display:
                                                          "contents",
                                                        color:
                                                          "#52575C",
                                                        fontWeight:
                                                          "400",
                                                        fontSize:
                                                          "14px",
                                                      }}
                                                    >
                                                      {moment(
                                                        review?.timestamp,
                                                      ).format("lll")}
                                                    </Typography>
                                                  </div>
                                                  <Typography
                                                    style={{
                                                      color:
                                                        "#201F1E",
                                                      fontSize:
                                                        "13px",
                                                      paddingLeft:
                                                        "4px",
                                                      textAlign:
                                                        "justify",
                                                      marginTop:
                                                        "4px",
                                                    }}
                                                  >
                                                    <>
                                                      {
                                                        review?.comments
                                                      }
                                                    </>
                                                  </Typography>
                                                </Grid>
                                              </div>
                                            ) : review?.status ===
                                              "rejected" ? (
                                              <div key={reviewIndex}>
                                                <Grid
                                                  item
                                                  xs={12}
                                                  md={12}
                                                  style={{
                                                    color: "#201F1E",
                                                    fontWeight: "500",
                                                    backgroundColor:
                                                      "#EDF8F6",
                                                    marginTop: "10px",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.formHeading
                                                      }
                                                    >
                                                      Comment From
                                                      {review?.byEntity==="team" ? "Engagement Specialist" : "Applicant"} :
                                                      :
                                                    </Typography>
                                                    <Typography
                                                      style={{
                                                        display:
                                                          "contents",
                                                        color:
                                                          "#52575C",
                                                        fontWeight:
                                                          "400",
                                                        fontSize:
                                                          "14px",
                                                      }}
                                                    >
                                                      {moment(
                                                        review?.timestamp,
                                                      ).format("lll")}
                                                    </Typography>
                                                  </div>
                                                  <Typography
                                                    style={{
                                                      color:
                                                        "#201F1E",
                                                      fontSize:
                                                        "13px",
                                                      paddingLeft:
                                                        "4px",
                                                      textAlign:
                                                        "justify",
                                                      marginTop:
                                                        "4px",
                                                    }}
                                                  >
                                                    <>
                                                      {
                                                        review?.comments
                                                      }
                                                    </>
                                                  </Typography>
                                                </Grid>
                                              </div>
                                            ) : null,
                                        )}
                                        {element?.status ===
                                          "approved" && (
                                          <>
                                            <Typography
                                              className={
                                                classes.formHeading
                                              }
                                              style={{
                                                color: "#201F1E",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: "8px",
                                              }}
                                            >
                                              Your Plan Is Approved
                                              Successfully{" "}
                                              <Avatar
                                                style={{
                                                  borderRadius: "0px",
                                                  marginLeft: "0px",
                                                }}
                                                src={checked}
                                              />
                                            </Typography>
                                            <Typography
                                              className={
                                                classes.formHeading
                                              }
                                              style={{
                                                color: "#201F1E",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                marginTop: "6px",
                                              }}
                                            >
                                              Plan Approval Date:{" "}
                                              <span
                                                style={{
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {moment(
                                                  element?.approvedAt,
                                                ).format("lll")}
                                              </span>
                                            </Typography>
                                            <Typography
                                              className={
                                                classes.formHeading
                                              }
                                              style={{
                                                color: "#201F1E",
                                                fontWeight: "500",
                                                fontSize: "16px",
                                                marginTop: "6px",
                                              }}
                                            >
                                              Plan Approved By:{" "}
                                              <span
                                                style={{
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {element?.name}
                                              </span>
                                            </Typography>
                                          </>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                            ) : type === "multipleFreeTextTable" ? (
                              <>
                                <TableContainer
                                  style={{ marginTop: "16px" }}
                                  sx={{ boxShadow: 0 }}
                                  component={Paper}
                                >
                                  <Table
                                    sx={{ minWidth: "100%", boxShadow: 0 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead
                                      style={{ backgroundColor: "#EDFDFA" }}
                                    >
                                      <TableRow>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          Identified Groups
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            border: "1px solid #B6B6B6",
                                            textAlign: "center",
                                          }}
                                        >
                                          Impact
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {element.findings.length>0 ?element.findings.map(
                                        (finding, rowIndex) => (
                                          <TableRow
                                            style={{
                                              border: "1px solid #B6B6B6",
                                            }}
                                            key={rowIndex}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: "1px solid #B6B6B6",
                                                },
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                width: "30%",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {finding.groupName}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border: "1px solid #B6B6B6",
                                                textAlign: "left",
                                                padding: "0px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "grid",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {finding.values &&
                                                finding.values.length ? (
                                                  <ul>
                                                    {finding.values.map(
                                                      (value, linkIndex) => (
                                                        <li key={linkIndex}>
                                                          {value}
                                                        </li>
                                                      ),
                                                    )}
                                                  </ul>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      ):<>
                                      <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border:
                                                      "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "20%",
                                                  textAlign:'center',
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding: "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                             
                                            </TableRow>
                                      </>}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            ): type ===
                            "multipleActivityFindingsActionTable" ? (
                            <>
                              <TableContainer
                                style={{ marginTop: "16px" }}
                                sx={{ boxShadow: 0 }}
                                component={Paper}
                              >
                                <Table
                                  sx={{
                                    minWidth: "100%",
                                    boxShadow: 0,
                                  }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "#EDFDFA",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Identified Groups
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        List of Engagement Activity
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Findings
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          border: "1px solid #B6B6B6",
                                          textAlign: "center",
                                        }}
                                      >
                                        Is Action Required ?
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {element.findings.length > 0 ? (
                                      element.findings.map(
                                        (finding, rowIndex) => (
                                          <TableRow
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                            }}
                                            key={rowIndex}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border:
                                                    "1px solid #B6B6B6",
                                                },
                                            }}
                                          >
                                            <TableCell
                                              style={{
                                                border:
                                                  "1px solid #B6B6B6",
                                                width: "20%",
                                                fontSize: "14px",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {finding.groupName}
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                border:
                                                  "1px solid #B6B6B6",
                                                textAlign: "left",
                                                fontSize: "14px",
                                                padding: "0px",
                                                width: "30%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "grid",
                                                  flexWrap: "wrap",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {finding.values &&
                                                finding.values
                                                  .length ? (
                                                  <ul>
                                                    {finding.values.map(
                                                      (
                                                        value,
                                                        linkIndex,
                                                      ) => (
                                                        <li
                                                          key={
                                                            linkIndex
                                                          }
                                                        >
                                                          {value}
                                                        </li>
                                                      ),
                                                    )}
                                                  </ul>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              style={{
                                                border:
                                                  "1px solid #B6B6B6",
                                                width: "30%",
                                                fontSize: "14px",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {finding?.findings ||
                                                ""}
                                            </TableCell>
                                            <TableCell
                                              align="center"
                                              style={{
                                                width: "20%",
                                              }}
                                            >
                                              <FormControl>
                                                <RadioGroup
                                                  style={{
                                                    display: "flex",
                                                  }}
                                                  row
                                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                                  name="row-radio-buttons-group"
                                                  value={
                                                    finding?.actionRequired ===
                                                    true
                                                      ? "yes"
                                                      : "no"
                                                  }
                                                >
                                                  <FormControlLabel
                                                    style={{
                                                      fontSize:
                                                        "14px",
                                                    }}
                                                    value="yes"
                                                    control={
                                                      <Radio />
                                                    }
                                                    label="Yes"
                                                  />
                                                  <FormControlLabel
                                                    style={{
                                                      fontSize:
                                                        "14px",
                                                    }}
                                                    value="no"
                                                    control={
                                                      <Radio />
                                                    }
                                                    label="No"
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </TableCell>
                                          </TableRow>
                                        ),
                                      )
                                    ) : (
                                      <>
                                        <TableRow
                                          style={{
                                            border:
                                              "1px solid #B6B6B6",
                                          }}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border:
                                                  "1px solid #B6B6B6",
                                              },
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              width: "25%",
                                              textAlign: "center",
                                              // verticalAlign: "top",
                                              padding:
                                                "0px 16px 16px",
                                            }}
                                          >
                                            None
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              width: "25%",
                                              textAlign: "center",
                                              // verticalAlign: "top",
                                              padding:
                                                "0px 16px 16px",
                                            }}
                                          >
                                            None
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              width: "25%",
                                              textAlign: "center",
                                              // verticalAlign: "top",
                                              padding:
                                                "0px 16px 16px",
                                            }}
                                          >
                                            None
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              width: "25%",
                                              textAlign: "center",
                                              // verticalAlign: "top",
                                              padding:
                                                "0px 16px 16px",
                                            }}
                                          >
                                            None
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : type === "findingsActionTable" ? (
                              <>
                                <Grid item xs={12} md={12}>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    sx={{ boxShadow: 0 }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: "100%", boxShadow: 0 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{ backgroundColor: "#EDFDFA" }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            What are your Findings?
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            style={{
                                              border: "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Is Action Required?
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element.findings.length>0 ?element.findings.map(
                                          (row, rowIndex) => (
                                            <TableRow
                                              style={{
                                                border: "1px solid #B6B6B6",
                                              }}
                                              key={row.groupName}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "30%",
                                                }}
                                              >
                                                {row.groupName}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                  width: "50%",
                                                }}
                                              >
                                                {row?.findings || ""}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                style={{ width: "20%" }}
                                              >
                                                <FormControl>
                                                  <RadioGroup
                                                    style={{ display: "flex" }}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={
                                                      row?.actionRequired ===
                                                      true
                                                        ? "yes"
                                                        : "no"
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      value="yes"
                                                      control={<Radio />}
                                                      label="Yes"
                                                    />
                                                    <FormControlLabel
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      value="no"
                                                      control={<Radio />}
                                                      label="No"
                                                    />
                                                  </RadioGroup>
                                                </FormControl>
                                              </TableCell>
                                            </TableRow>
                                          ),
                                        ):<>
                                        <TableRow
                                                style={{
                                                  border: "1px solid #B6B6B6",
                                                }}
                                                
                                                sx={{
                                                  "&:last-child td, &:last-child th":
                                                    {
                                                      border:
                                                        "1px solid #B6B6B6",
                                                    },
                                                }}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "30%",
                                                    textAlign:'center',
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "50%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                                <TableCell
                                                  style={{
                                                    border: "1px solid #B6B6B6",
                                                    width: "20%",
                                                    textAlign: "center",
                                                    // verticalAlign: "top",
                                                    padding: "0px 16px 16px",
                                                  }}
                                                >
                                                  None
                                                </TableCell>
                                              </TableRow>
                                        </>}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </>
                            ) : type === "actionPlanTable" ? (
                              <>
                                <Grid item xs={12} md={12}>
                                  <TableContainer
                                    style={{ marginTop: "16px" }}
                                    component={Paper}
                                  >
                                    <Table
                                      sx={{ minWidth: 650 }}
                                      aria-label="simple table"
                                    >
                                      <TableHead
                                        style={{
                                          backgroundColor: "#EDFDFA",
                                        }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                          >
                                            Identified Groups
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                            align="center"
                                          >
                                            Findings
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                            align="center"
                                          >
                                            Action Required
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              border:
                                                "1px solid #B6B6B6",
                                              textAlign: "center",
                                            }}
                                            align="center"
                                          >
                                            Expected Date of Completion 
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {element.findings.length > 0 ? (
                                          element.findings.map(
                                            (row, rowIndex) => (
                                              <TableRow
                                                style={{
                                                  border:
                                                    "1px solid #B6B6B6",
                                                }}
                                                key={rowIndex}
                                              >
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border:
                                                      "1px solid #B6B6B6",
                                                    width: "30%",
                                                    verticalAlign:
                                                      "top",
                                                  }}
                                                >
                                                  {row?.groupName}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border:
                                                      "1px solid #B6B6B6",
                                                    width: "25%",
                                                  }}
                                                >
                                                  {row?.findings}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border:
                                                      "1px solid #B6B6B6",
                                                    width: "25%",
                                                  }}
                                                >
                                                  {/* <TextField
                                            size="small"
                                            style={{ marginTop: "4px" }}
                                            className={`${classes.txtForm} ${classes.disabledTextField}`}
                                            fullWidth
                                            placeholder="Please enter your action plan"
                                            variant="outlined"
                                            name="actionPlan"
                                            multiline
                                            value={
                                              row?.actionPlan || ""
                                            }
                                          /> */}
                                                  {row?.actionPlan ||
                                                    ""}
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  style={{
                                                    border:
                                                      "1px solid #B6B6B6",
                                                    width: "20%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {
                                                    row?.expectedCompletionDate
                                                  }
                                                </TableCell>
                                              </TableRow>
                                            ),
                                          )
                                        ) : (
                                          <>
                                            <TableRow
                                              style={{
                                                border:
                                                  "1px solid #B6B6B6",
                                              }}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border:
                                                      "1px solid #B6B6B6",
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                style={{
                                                  border:
                                                    "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding:
                                                    "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border:
                                                    "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding:
                                                    "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border:
                                                    "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding:
                                                    "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  border:
                                                    "1px solid #B6B6B6",
                                                  width: "25%",
                                                  textAlign: "center",
                                                  // verticalAlign: "top",
                                                  padding:
                                                    "0px 16px 16px",
                                                }}
                                              >
                                                None
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        {/* )} */}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Widget>

          {engagementDetail &&
            engagementDetail.map((element, i) => (
              <div key={i}>
                {element?.type === "patientEngagementApproval" ||
                element?.type === "staffEngagementApproval" ? (
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      marginTop: "16px",
                      marginBottom: "10px",
                    }}
                  >
                    {element.status !== "approved" && (
                      <>
                        <Button
                          className={classes.btnleftOutline}
                          variant="outlined"
                          size="large"
                          style={{ marginRight: "16px" }}
                          onClick={(e) => sendReview(e)}
                        >
                          Further Work Needed
                        </Button>
                        <Button
                          className={classes.btnlRightDark}
                          variant="contained"
                          size="large"
                          color="secondary"
                          onClick={(e) => submitApprovalHandler(e)}
                        >
                          Approve
                        </Button>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            ))}
        </>
      )}
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Send Approval ",
          successMsg: successMessage,
          errorRes: errorApiMessage,
        }}
      />
    </>
  );
}
