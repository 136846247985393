import React from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import notes from "./noteEdit.svg";
import useStyles from "../styles";
export default function AssessmentOne() {
  var classes = useStyles();
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <img src={notes} className={classes.noteIcons} />
        <Grid item xs={12} md={9} className={classes.textGroup}>
          <Typography className={classes.formHeading}>
            Q13. Who could your change potentially impact?
          </Typography>
          <FormControl fullWidth className={classes.dropdownFormControl}>
            <Select
              className={classes.selectDropdown}
              value={value}
              onChange={handleChange}
            >
              <MenuItem value="patient">Only Impacts Patients</MenuItem>
              <MenuItem value="staff">Only Impacts Staff</MenuItem>
              <MenuItem value="both">Impacts Both Staff and Patients</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
