import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { TableRow, TableCell, IconButton } from "@material-ui/core";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../../component/PageTitle/PageTitle";
import TableWidget from "../../../component/Widget/TableWidget";

// data
import mock from "./mock";
// styles
import useStyles from "./styles";

import TableDesign from "../../../component/common/table/TableDesign";

export default function AccountLogs() {
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
      align: "left !important",
    },
    {
      label: "Account Email",
      
    },
    {
      label: "Date & Time of Action",
    },
    {
      label: "Action Performed",
    },
    {
      label: "Performed By",
    },

    
  ];

  var classes = useStyles();
  return (
    <>
    
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {mock.accounts && mock.accounts.length ? (
                mock.accounts.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          
                          className={classes.tableCell}
                        >
                          {row.email}
                        </TableCell>

                        <TableCell  className={classes.tableCell}>
                          {row.date}
                        </TableCell>
                        <TableCell  className={classes.tableCell}>
                          {row.performance}
                        </TableCell>
                        <TableCell  className={classes.tableCell}>
                          {row.performed}
                        </TableCell>
                        
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Record Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  )
}
