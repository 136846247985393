import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Fade,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../component/common/modal/Popup";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../hooks/Validation";
import { createUpdateSubAdmin } from "../../store/actions/subAdminAction";
import ApiResponsePopup from "../../../component/common/modal/ApiResponsePopup";
import Loader from "../../../component/common/loader/Loader";
import uploadImage from "../../../assets/upload.png";
// styles
import useStyles from "./styles";
import closeIcon from "../../../assets/close.png";
export default function SelectedGroupList(props) {
  //
  console.log("props-24", props);
  const { orgListData,btnClick } = props;
  console.log("orgListData", orgListData);
  const [isShow, setIsShow] = useState(false);

  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [orgDataValue, setOrgDataValue] = React.useState([]);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const hideHandler = () => {
    console.log("hide handler called");

    props.onHide();
  };

  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const { adminDetail } = admin;
  console.log("adminDetail", adminDetail);

  const hideHandlerRes = () => {
    setIsShow(false);
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup style={{zIndex:'99999999999999999'}}
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={hideHandler}
            >
              Confirm
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {orgListData && orgListData.map((item, index) => {
              return (
                <div
                  style={{
                    border: "1px solid #E8E8E8",
                    padding: "5px",
                    borderRadius: "5px",
                    width: "474px",
                    margin: "3px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize:'12px',
                  }}
                  key={index}
                >
                  {item}
                  <img
                     onClick={() => btnClick(index)}
                    style={{ cursor: "pointer" }}
                    src={closeIcon}
                    alt="close-icons"
                  />
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
