import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../../../store/service";
import AxiosInstance from "../../../store/axios";
import { constant } from '../../../store/constant'
const { path } = constant;
export const fetchOrganizationList = createAsyncThunk(
    'organizations/fetchOrganization',
    (arg, { rejectWithValue }) => DataService.get(arg,  path+'/sub-organizations', rejectWithValue)
)

export const createUpdateOrganization = createAsyncThunk(
    'organizations/createUpdateOrganization',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/sub-organizations', rejectWithValue)
)
export const updateOrganization = createAsyncThunk(
    'organizations/createOrganization',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'/sub-organizations/update', rejectWithValue)
)

export const deleteOrganization = createAsyncThunk(
    'organizations/deleteOrganization',
    (arg, { rejectWithValue }) => DataService.delete(arg, path+`/sub-organizations/${arg?.orgId}`, rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'organizations/toggleActivation',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/sub-organizations/toggle-activation/`, rejectWithValue)
)

export const sendActivationLink = createAsyncThunk(
    'organizations/sendActivationLink',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/organizations/resend-activation/${arg?.orgId}`, rejectWithValue)
)

export const sendResetPasswordLink = createAsyncThunk(
    'organizations/sendResetPasswordLink',
    (arg, { rejectWithValue }) => DataService.put(arg, path+`/sub-organizations/reset-password/${arg?.orgId}`, rejectWithValue)
)

export const getOrganizationByOrganizationId = createAsyncThunk(
    'organizations/getOrganizationByOrganizationId',
    (arg, { rejectWithValue }) => DataService.get(arg, path+`/sub-organizations/${arg?.orgId}`, rejectWithValue)
)